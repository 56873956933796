export const defaultValidationMessagesWithTranslation = (t) => ({
  required: t("validations:required"),
  whitespace: t("validations:whitespace"),
  date: {
    format: t("validations:format"),
    parse: t("validations:parse"),
    invalid: t("validations:invalid"),
  },
  types: {
    string: t("validations:type"),
    method: t("validations:type"),
    array: t("validations:type"),
    object: t("validations:type"),
    number: t("validations:type"),
    date: t("validations:type"),
    boolean: t("validations:type"),
    integer: t("validations:type"),
    float: t("validations:type"),
    regexp: t("validations:type"),
    email: t("validations:type"),
    url: t("validations:type"),
    hex: t("validations:type"),
  },
  string: {
    len: t("validations:string.len"),
    min: t("validations:string.min"),
    max: t("validations:string.max"),
    range: t("validations:string.range"),
  },
  number: {
    len: t("validations:number.len"),
    min: t("validations:number.min"),
    max: t("validations:number.max"),
    range: t("validations:number.range"),
  },
  array: {
    len: t("validations:array.len"),
    min: t("validations:array.min"),
    max: t("validations:array.max"),
    range: t("validations:array.range"),
  },
  pattern: {
    mismatch: t("validations:pattern.mismatch"),
  },
});
