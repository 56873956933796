import React, { useRef, useState, useEffect } from "react";
import "./CustomDropdown.scss";
import dropdownUpArrow from "assets/images/poc/dropdownUpArrow.svg";
import dropdownDownArrow from "assets/images/poc/dropdownDownArrow.svg";

const CustomDropdown = ({
  fieldName,
  items,
  selectedItem,
  onSelect,
  style,
  background,
  showArrow = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownBelow, setIsDropdownBelow] = useState(false);
  const dropdownRef = useRef(null);
  const fieldRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(showArrow && !isOpen);
  };

  const handleItemClick = (item) => {
    if (!item.disabled) {
      onSelect(item);
      setIsOpen(false);
    }
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };
  const handleClickOutside = (event) => {
    console.log("Clicked outside");
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      fieldRef.current &&
      !fieldRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    console.log("isOpen:", isOpen);
    if (isOpen && fieldRef.current && dropdownRef.current) {
      const fieldRect = fieldRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      const isDropdownBelow = fieldRect.bottom + dropdownHeight < windowHeight;
      setIsDropdownBelow(isDropdownBelow);
      console.log("Dropdown1:", fieldRect);
      console.log("Dropdown2:", dropdownHeight);
      console.log("Dropdown3:", windowHeight);
      console.log("Dropdown4:", isDropdownBelow);

      if (!isDropdownBelow) {
        dropdownRef.current.style.bottom = `${fieldRect.height}px`;
        console.log("Dropdown positioned above field");
      } else {
        dropdownRef.current.style.bottom = "auto";
        console.log("Dropdown positioned below field");
      }
    }
  }, [isOpen]);
  console.log("locationType3", selectedItem);
  return (
    <div className="custom-dropdown" style={{ width: "100%" }}>
      <div className="inputDropdownFieldConatiner" ref={dropdownRef}>
        <p className="inputDropdownFieldName">{fieldName}</p>{" "}
        <div
          className="dropdown-header"
          onClick={toggleDropdown}
          style={{ background: background }}
          ref={fieldRef}
        >
          <div className="selectedItem">{selectedItem}</div>
          {showArrow ? (
            isOpen ? (
              <img src={dropdownUpArrow} />
            ) : (
              <img src={dropdownDownArrow} />
            )
          ) : (
            ""
          )}
        </div>
        {isOpen && (
          <div
            className="dropdownPicker"
            style={{
              top: !isDropdownBelow ? "auto" : "100%",
              bottom: !isDropdownBelow ? "70%" : "",
            }}
          >
            <div className="dropdownOptionsConatiner">
              <ul class="dropdownOptionsConatiner">
                {items.map((item, index) => (
                  <>
                    <li
                      key={item}
                      onClick={() => handleItemClick(item)}
                      className={`selectedItem `}
                    >
                      {item}
                    </li>
                    {index == items.length - 1 ? (
                      ""
                    ) : (
                      <div
                        key={index}
                        style={{
                          background: "#ECEEF1",
                          height: "1px",
                        }}
                      ></div>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
