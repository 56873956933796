import React, { useEffect } from "react";
import { Provider } from "react-redux";
import configureStore from "app/store";
import Layout from "antd/es/layout";
import { configureAntUIElements } from "app/config/antd-message";
import { initializeInterceptors } from "app/utils/auth";
import App from "App";

const store = configureStore();

function AppContainer() {
  useEffect(() => {
    initializeInterceptors();
    configureAntUIElements();
  }, []);
  return (
    <Layout id="transport_admin_app">
      <Provider store={store}>
        <App />
      </Provider>
    </Layout>
  );
}

export default AppContainer;
