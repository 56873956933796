import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Alert,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MilePlusLogo from "assets/images/MilePlusLogo.svg";
import backToLogin from "assets/images/sidebar/backToLogin.svg";
import forgotPasswordMail from "assets/images/sidebar/forgotPasswordMail.svg";

import "./ForgotPassword.scss";
import OTPInputs from "../OTPInputs/OTPInputs";
import LoginForm from "../LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  loginAPI,
  forgotPassword,
} from "../../../../../apiServices/apiServices";
import ChangePassword from "../ChangePassword/ChangePassword";
import BackgroundContainer from "app/views/commonComponents/BackgroundContainer/BackgroundContainer";
const ForgotPassword = ({ toggleForgotPassword }) => {
  const [username, setUsername] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  //const [password, setPassword] = useState("");
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [userName, setUserName] = useState("");
  const [preferrdName, setPreferrdName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("");
  const history = useHistory();
  const handleEmailAddressChange = (e) => {
    const emailAddress = e.target.value;
    console.log("emailAddress", emailAddress);
    setEmailAddress(emailAddress); // Update the local state directly
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("userDataToken", token);
    setToken(token);
    const authUserData = localStorage.getItem("authUser");
    console.log("authUserData", authUserData);
    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
      const userName = `${userData.first_name} ${userData.last_name}`;
      console.log("userData4", userName);
      setUserName(userName);
      const preferrdName = `${userData.first_name.charAt(
        0
      )} ${userData.last_name.charAt(0)}`;
      console.log("userData5", preferrdName);
      setPreferrdName(preferrdName);
      console.log("userData6", userData._id);
      setUserId(userData._id);
      console.log("userData7", userData.email);
      setEmail(userData.email);
      console.log("userData7", userData.phone_number);
      setPhoneNumber(userData.phone_number);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
  }, []);
  const handleBackToLogin = () => {
    // Navigate back to the login page
    history.push("/");
    // setTimeout(() => {
    //   window.location.reload();
    // });
    // Toggle showForgotPassword to false
    // toggleForgotPassword(false);
  };
  const handleSendResetLink = () => {
    console.log("Forgot password clicked!");
    const forgotPasswordDetails = {
      email: emailAddress,
    };
    forgotPassword(forgotPasswordDetails)
      .then((response) => {
        //history.push(`/passwordReset`);
        console.log("forgotPasswordDetails", response);
        const status = response.status;
        console.log("forgotPasswordDetailsStatus", response.status);
        setStatus(status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleResendLink = () => {
    setStatus("");
  };
  const handleValidSubmit = () => {
    // This function will be called only when the form is valid
    //handleLogin();
  };
  return (
    <React.Fragment>
      <BackgroundContainer>
        <Container fluid className="p-0">
          <Row xl={12} md={12} sm={6} xs={6}>
            <Col xl={12} md={12} sm={6} xs={6}>
              <>
                <div className="forgotPasswordBlock">
                  <div className="forgotPasswordMailImage">
                    <img src={forgotPasswordMail} alt="Forgot Password Mail" />
                  </div>
                  <div className="forgotPasswordHeader">
                    {" "}
                    <div className="forgotPasswordTitle">Forgot Password?</div>
                    {status == 200 || status == 200 ? (
                      <div className="forgotPasswordMessage">
                        Please check the email address {emailAddress} for
                        instructions to reset your password.
                      </div>
                    ) : (
                      <div className="forgotPasswordMessage">
                        Enter your email address and we will send you
                        Instructions to reset your password.
                      </div>
                    )}
                  </div>
                  <AvForm
                    id="loginForm"
                    className="form-horizontal"
                    //onValidSubmit={handleValidSubmit}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {status == 200 || status == 201 ? (
                        ""
                      ) : (
                        <div className="forgotPassword-form-group">
                          <Label
                            htmlFor="username"
                            className="forgotPassword-form-label"
                          >
                            Email Address
                          </Label>
                          <AvField
                            name="emailAddress"
                            value={emailAddress}
                            onChange={handleEmailAddressChange}
                            type="text"
                            className="forgotPassword-form-input"
                            id="emailAddress"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required.",
                              },
                              minLength: {
                                value: 6,
                                errorMessage:
                                  "EmailAddress must be at least 6 characters.",
                              },
                              maxLength: {
                                value: 50,
                                errorMessage:
                                  "EmailAddress cannot exceed 50 characters.",
                              },
                            }}
                          />
                        </div>
                      )}
                      {status == 200 || status == 201 ? (
                        <div
                          className="text-center"
                          style={{ marginTop: "20px" }}
                        >
                          <Button
                            className="login-button"
                            onClick={handleResendLink}
                            type="submit"
                            //disabled={!isFormValid}
                          >
                            RESEND LINK
                          </Button>
                        </div>
                      ) : (
                        <div className="text-center" style={{}}>
                          <Button
                            className="login-button"
                            onClick={handleSendResetLink}
                            type="submit"
                            //disabled={!isFormValid}
                          >
                            SEND RESET LINK
                          </Button>
                        </div>
                      )}
                    </div>
                  </AvForm>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="backToLogin" onClick={handleBackToLogin}>
                      {" "}
                      <img src={backToLogin} />
                      Back to login
                    </div>
                  </div>
                </div>
              </>
            </Col>
          </Row>
        </Container>
      </BackgroundContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
