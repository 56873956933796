import React from "react";
import flag from "../../../../assets/images/driversTable/flag.svg";
import health from "../../../../assets/images/driversTable/health.svg";
import truckTick from "../../../../assets/images/driversTable/truckTick.svg";
import personalCard from "../../../../assets/images/driversTable/personalCard.svg";
import "./Expirations.scss"; // Use a common SCSS file for styling

const Expirations = ({
  children,
  //imageType, //fingerType,userType,profileType
  color,
  onClick,
  iconPosition,
  image,
  imageType, //Driver License, TWIC Card,Medical Report,Road Test Certificate,Drug & Alcohol
  ...rest
}) => {
  const renderIcon = () => {
    const icon =
      imageType == "Driver License"
        ? truckTick
        : imageType == "Medical Report"
        ? health
        : imageType == "TWIC Card"
        ? personalCard
        : imageType == "Road Test Certificate"
        ? flag
        : "";
    const marginRight = iconPosition === "left" ? { marginRight: "" } : {};
    const marginLeft = iconPosition === "right" ? { marginLeft: "" } : {};
    // const width = size == "small" ? { width: "16px" } : {};
    // const height = size == "small" ? { height: "16px" } : {};
    return (
      <img
        src={icon}
        // style={{
        //   ...marginRight,
        //   ...marginLeft,
        //   ...width,
        //   ...height,
        // }}
        alt="Cross Icon"
      />
    );
  };
  return (
    <div
      className={"expirations"}
      onClick={onClick}
      {...rest}
      style={{ borderRadius: "40px" }}
    >
      {/* {contenType === "specialCase" ? (
        size === "medium" ? (
          <div>
            {iconPosition === "left" && renderIcon()}
            {children}
            {iconPosition === "right" && renderIcon()}
          </div>
        ) : size === "large" ? (
          <div></div>
        ) : (
          ""
        )
      ) : (
        ""
      )} */}
      {iconPosition === "left" && renderIcon()}
      {children}
      {iconPosition === "right" && renderIcon()}
    </div>
  );
};
export default Expirations;
