import { LOGOUT_MUTATION } from "./queries";
import * as types from "./types";
import { createLogic } from "redux-logic";
import * as appActions from "./actions";
import { LogoutGraphQLService } from "app/services/graphql";

const logoutLogic = createLogic({
  type: types.LOGOUT,
  latest: true,
  process({ getState, action }, dispatch, done) {
    LogoutGraphQLService(LOGOUT_MUTATION, undefined, false, false)
      .then((res) => {
        console.log("Logged Out!");
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(appActions.clearReduxStore());
        done();
      });
  },
});

export default [logoutLogic];
