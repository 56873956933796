import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./LoadsPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTablePOC from "../../TablePOC/TablePOC";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import moment from "moment-timezone";
import downloadReportIcon from "assets/images/poc/downloadReportIcon.svg";
import starGradient from "assets/images/kpi/starGradient.svg";
import userGradient from "assets/images/kpi/userGradient.svg";
import userTagGradient from "assets/images/kpi/userTagGradient.svg";
import accidentGradient from "assets/images/kpi/accidentGradient.svg";
import enterArrow from "assets/images/poc/enterArrow.svg";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import {
  getAllLoadsData,
  getLoadsHeaderKPIData,
  getAllLoadsNewData,
  getLoadsWeeklyChart,
  getLoadsMonthlyChart,
  getLoadsQuarterlyChart,
  getLoadsYearlyChart,
} from "app/apiServices/apiServices";
import HeaderSection from "app/views/commonComponents/HeaderSection/HeaderSection";
import ReactApexChart from "react-apexcharts";
import activeTabTriangle from "assets/images/loads/activeTabTriangle.svg";
const LoadsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTabId, setActiveCalendarTabId] = useState(0);
  const [activeCalendarTabLabel, setActiveCalendarTabLabel] =
    useState("Weekly");
  const [dateLabel, setDateLabel] = useState("");
  const [activeTableTabId, setActiveTableTabId] = useState(0);
  const [activeTableTabLabel, setActiveTableTabLabel] = useState("");
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [isDriversFilterOpen, setIsDriversFilterOpen] = useState(false);
  const [loadsOriginalData, setLoadsOriginalData] = useState([]);
  const [loadsOriginalNewData, setLoadsOriginalNewData] = useState([]);

  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [avgRevenuePerLoad, setAvgRevenuePerLoad] = useState(0);
  const [loadsDetails, setLoadsDetails] = useState([]);
  const [clickedRowIndex, setClickedRowIndex] = useState();
  const [loadDetailsTableData, setLoadDetailsTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const loadsKPIData = [
    //{ icon: userTag, values: "23,435", title: "On Fleet Drivers" },
    {
      icon: starGradient,
      values: loadsDelivered
        ? Math.round(loadsDelivered).toLocaleString("en-US")
        : 0,
      title: "Loads Delivered",
    },
    {
      icon: starGradient,
      values: milesLoaded ? Math.round(milesLoaded).toLocaleString("en-US") : 0,
      title: "Miles Loaded",
    },
    {
      icon: userGradient,
      values: avgRevenuePerLoad
        ? `$${Math.round(avgRevenuePerLoad).toLocaleString("en-US")}`
        : "$0.00",
      title: "Avg. RPM",
    },
    {
      icon: starGradient,
      values: revenue ? `$${Math.round(revenue).toLocaleString("en-US")}` : 0,
      title: "Revenue",
    },
    {
      icon: userTagGradient,
      values: grossMargin
        ? `$${Math.round(grossMargin).toLocaleString("en-US")}`
        : "$0.00",
      title: "Net RPM",
    },
    {
      icon: accidentGradient,
      values: headerRPM
        ? `$${headerRPM.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      title: "Net Profit",
    },

    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const calendarTabsData = [
    { id: 0, label: "Weekly" },
    { id: 1, label: "Month" },
    { id: 2, label: "Quarter" },
    { id: 3, label: "Year" },
    // Add more tabs as needed
  ];
  const tableTabsData = [
    { id: 0, label: "In-Transit" },
    { id: 1, label: "Upcoming" },
    { id: 2, label: "Completed" },
    { id: 3, label: "Draft" },
    { id: 4, label: "All" },
    // Add more tabs as needed
  ];
  const loadsTableHeaders = {
    loadId: "Load Id.",
    dates: "Dates",
    origin: "Origin",
    destination: "Destination",
    driver: "Driver",
    vehicleId: "Vehicle ID",
    //grossMargin: "Gross Margin",
    milesLoaded: "Miles Loaded",
    rpm: "RPM",
    revenue: "Revenue",
    netPay: "Net Pay",
    netProfit: "Net Profit",
  };
  console.log("loadsOriginalDataGGG", loadsOriginalData);
  const loadsTableDataAPI = loadsOriginalData.map((load, index) => {
    // console.log("getAllLoadsData2", item);
    // const load = item.load;
    // console.log("getAllLoadsData3", load);
    // Check if load_stops exists, if yes, skip processing
    // if (load && Array.isArray(load.load_stops) && load.load_stops.length > 0) {
    //   return null; // Skip rendering
    // }

    //console.log("getAllLoadsData4", load.load_stops);
    const originalDateString = load.pu_date && load.pu_date;
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).date();
    const year = moment(originalDate).year();
    const originalDateString2 = load.del_date && load.del_date;
    let originalDate2 = moment.utc(originalDateString2);
    const month2 = moment(originalDate2).format("MMM");
    const day2 = moment(originalDate2).date();
    const year2 = moment(originalDate2).year();
    let formattedDate = `${month} ${day}`;
    let formattedDate2 = `${month2} ${day2}`;
    let formattedDates = "";
    // Check if month1 is not equal to month2 and day1 is not equal to day2
    if (month !== month2 && day !== day2) {
      formattedDates = `${month} ${day} - ${month2} ${day2}, ${year}`;
    } else {
      // Check if both dates have the same month and day
      if (month === month2 && day === day2) {
        formattedDates = `${formattedDate}, ${year}`;
      } else {
        // Check if the years are different
        if (year !== year2) {
          formattedDates = `${formattedDate}, ${year}-${formattedDate2}, ${year2}`;
        } else {
          // Adjust the format based on different cases
          if (formattedDate === formattedDate2) {
            formattedDates = `${formattedDate}, ${year}`;
          } else if (day === day2) {
            formattedDates = `${formattedDate}${
              formattedDate2 ? `-${formattedDate2}` : ""
            }, ${year}`;
          } else if (month === month2) {
            formattedDates = `${month} ${day}-${day2}, ${year}`;
          }
        }
      }
    }
    formattedDates = `${formattedDate}${
      formattedDate2 ? `-${formattedDate2}` : ""
    }, ${year}`;
    const truncatedOrigin =
      load.pu_location && load.pu_location.length > 20
        ? load.pu_location.substring(0, 20) + "..."
        : load.pu_location;
    const truncatedDestination =
      load.del_location && load.del_location.length > 20
        ? load.del_location.substring(0, 20) + "..."
        : load.del_location;
    // Check if load_stops exists and is not empty
    // let truncatedOrigin = "";
    // let truncatedDestination = "";
    // if (load && Array.isArray(load.load_stops) && load.load_stops.length > 0) {
    //   const firstStop = load.load_stops[0];
    //   const lastStop = load.load_stops[load.load_stops.length - 1];
    //   truncatedOrigin = firstStop.city_state || "--";
    //   truncatedDestination = lastStop.city_state || "--";
    //}

    const loadIDWithCommas = parseInt(load.load_id).toLocaleString("en");
    return {
      loadId: load.load_id ? load.load_id : 0,
      dates: formattedDates,
      origin: truncatedOrigin ? truncatedOrigin : "--",
      destination: truncatedDestination ? truncatedDestination : "--",
      driver: `${load.driver_1_full_name ? load.driver_1_full_name : "--"} ${
        load.driver_2_full_name ? "-" : ""
      } ${load.driver_2_full_name ? load.driver_2_full_name : ""}`, // You can replace this with the driver's name if available in your data
      vehicleId: load.vehicle_id ? load.vehicle_id : 0,
      // grossMargin: load.gross_margin
      //   ? `$${load.gross_margin.toLocaleString("en-US", {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     })}`
      //   : "$0.00",
      milesLoaded: load.loaded_miles ? load.loaded_miles.toLocaleString() : 0,
      rpm: load.rpm
        ? `$${load.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      revenue: load.load_revenue
        ? `$${load.load_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netPay: load.netPay
        ? `$${load.company_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netProfit: load.company_revenue
        ? `$${load.company_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
    };
  });
  const loadsTableDataNewAPI = loadsOriginalNewData.map((item, index) => {
    console.log("getAllLoadsData2", item);
    const load = item.load;
    console.log("getAllLoadsData3", load);
    // Check if load_stops exists, if yes, skip processing
    // if (load && Array.isArray(load.load_stops) && load.load_stops.length > 0) {
    //   return null; // Skip rendering
    // }

    //console.log("getAllLoadsData4", load.load_stops);
    const originalDateString = load.pu_date && load.pu_date;
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).date();
    const year = moment(originalDate).year();
    const originalDateString2 = load.del_date && load.del_date;
    let originalDate2 = moment.utc(originalDateString2);
    const month2 = moment(originalDate2).format("MMM");
    const day2 = moment(originalDate2).date();
    const year2 = moment(originalDate2).year();
    let formattedDate = `${month} ${day}`;
    let formattedDate2 = `${month2} ${day2}`;
    let formattedDates = "";
    // Check if month1 is not equal to month2 and day1 is not equal to day2
    if (month !== month2 && day !== day2) {
      formattedDates = `${month} ${day} - ${month2} ${day2}, ${year}`;
    } else {
      // Check if both dates have the same month and day
      if (month === month2 && day === day2) {
        formattedDates = `${formattedDate}, ${year}`;
      } else {
        // Check if the years are different
        if (year !== year2) {
          formattedDates = `${formattedDate}, ${year}-${formattedDate2}, ${year2}`;
        } else {
          // Adjust the format based on different cases
          if (formattedDate === formattedDate2) {
            formattedDates = `${formattedDate}, ${year}`;
          } else if (day === day2) {
            formattedDates = `${formattedDate}${
              formattedDate2 ? `-${formattedDate2}` : ""
            }, ${year}`;
          } else if (month === month2) {
            formattedDates = `${month} ${day}-${day2}, ${year}`;
          }
        }
      }
    }
    formattedDates = `${formattedDate}${
      formattedDate2 ? `-${formattedDate2}` : ""
    }, ${year}`;
    //  const truncatedOrigin =
    //    load.pu_location && load.pu_location.length > 20
    //      ? load.pu_location.substring(0, 20) + "..."
    //      : load.pu_location;
    //  const truncatedDestination =
    //    load.del_location && load.del_location.length > 20
    //      ? load.del_location.substring(0, 20) + "..."
    //      : load.del_location;
    // Check if load_stops exists and is not empty
    let truncatedOrigin = "";
    let truncatedDestination = "";
    if (load && Array.isArray(load.load_stops) && load.load_stops.length > 0) {
      const firstStop = load.load_stops[0];
      const lastStop = load.load_stops[load.load_stops.length - 1];
      truncatedOrigin = firstStop.city_state || "--";
      truncatedDestination = lastStop.city_state || "--";
    }
    const loadIDWithCommas = parseInt(load.load_id).toLocaleString("en");
    return {
      loadId: load.load_id ? load.load_id : 0,
      dates: formattedDates,
      origin: truncatedOrigin ? truncatedOrigin : "--",
      destination: truncatedDestination ? truncatedDestination : "--",
      driver: `${load.driver_1_full_name ? load.driver_1_full_name : "--"} ${
        load.driver_2_full_name ? "-" : ""
      } ${load.driver_2_full_name ? load.driver_2_full_name : ""}`, // You can replace this with the driver's name if available in your data
      vehicleId: load.vehicle_id ? load.vehicle_id : 0,
      // grossMargin: load.gross_margin
      //   ? `$${load.gross_margin.toLocaleString("en-US", {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     })}`
      //   : "$0.00",
      milesLoaded: load.loaded_miles ? load.loaded_miles.toLocaleString() : 0,
      rpm: load.rpm
        ? `$${load.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      revenue: load.load_revenue
        ? `$${load.load_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netPay: load.netPay
        ? `$${load.company_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netProfit: load.company_revenue
        ? `$${load.company_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
    };
  });
  const loadDetailsTableHeaders = {
    loadID: enterArrow,
    payDescription: "Pay Description",
    revenue: "Revenue",
    // additionalPaymentDescriptions: "Additional Payment Descriptions",
    // itemisedTotal: "Itemised Total",
    // grossPercentage: "%Gross",
    // contractorAmount: "Contractor Amount",
  };
  const loadSubTableData = loadsOriginalData.map((item) => item.load_details);
  // // Extract each array of objects
  for (let i = 0; i < loadSubTableData.length; i++) {
    console.log(`loadDetailsArray2 ${i}:`, loadSubTableData[i]);
  }
  console.log("loadsOriginalDataGGG2", loadDetailsTableData);
  const handleCalenderTabChange = (tabId, tabLabel) => {
    console.log("handleCalenderTabChange1", tabId);
    console.log("handleCalenderTabChange2", tabLabel);
    setActiveCalendarTabId(tabId);
    setActiveCalendarTabLabel(tabLabel);
  };
  const handleTableTabChange = (tabId, tabLabel) => {
    setActiveTableTabId(tabId);
    setActiveTableTabLabel(tabLabel);
  };
  const handleRowClick = (rowIndex) => {
    console.log("ClickedrowIndex", rowIndex);
    setClickedRowIndex(rowIndex);
    const clickedRowData = loadSubTableData[rowIndex];
    setLoadDetailsTableData(clickedRowData);
  };
  const handleDateChange = (startDate, endDate, dateLabel) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    console.log("dateLabel", dateLabel);
    setDateLabel(dateLabel);
    // Call getLoadsWeeklyChartAPI with  start and end dates
    getLoadsWeeklyChartAPI(startDate, endDate);
    // Call getDetailsHeaderKPIDataAPI with  start and end dates
    getDetailsHeaderKPIData(startDate, endDate);
  };
  useEffect(() => {
    handleReturnLoadsPOC();
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 325; /* subtract other elements height */
      // windowHeight - 240;
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");
    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
    if (activeTableTabLabel == "All") {
      getAllLoadsDataAPI();
    } else {
      getAllLoadsDataNewAPI();
    }
    if (
      activeCalendarTabLabel == "Weekly" ||
      activeCalendarTabLabel == "Month" ||
      activeCalendarTabLabel == "Quarter" ||
      activeCalendarTabLabel == "Year"
    ) {
      getDetailsHeaderKPIData();
      getLoadsWeeklyChartAPI();
    }
  }, [activeTableTabLabel, activeCalendarTabLabel]);
  const getAllLoadsDataNewAPI = () => {
    const storedToken = localStorage.getItem("token");
    getAllLoadsNewData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllLoadsData", response.data ? response.data : []);
        setLoadsOriginalNewData(response.data ? response.data : []);
        //let detailsArray = response.data
        //setLoadsDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getAllLoadsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getAllLoadsData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllLoadsData", response.data ? response.data : []);
        setLoadsOriginalData(response.data ? response.data : []);
        //let detailsArray = response.data
        //setLoadsDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDetailsHeaderKPIData = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    const { currentStartDate, currentEndDate } = getCurrentDate();
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    console.log("currentNewDate7", finalStartDate);
    console.log("currentNewDate8", finalEndDate);
    getLoadsHeaderKPIData(finalStartDate, finalEndDate)
      .then((response) => {
        //console.log("insideif", response);
        console.log(
          "getDetailsHeaderKPIData",
          response.data ? response.data : ""
        );
        setGrossMargin(
          response.data.gross_margin ? response.data.gross_margin : ""
        );
        setHeaderRPM(response.data.rpm ? response.data.rpm : "");
        setMilesLoaded(
          response.data.miles_loaded ? response.data.miles_loaded : ""
        );
        setLoadsDelivered(
          response.data.loads_delivered ? response.data.loads_delivered : ""
        );
        setRevenue(response.data.revenue ? response.data.revenue : "");
        setAvgRevenuePerLoad(
          response.data.average_revenue_per_load
            ? response.data.average_revenue_per_load
            : ""
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageLoadsPOC, setCurrentPageLoadsPOC] = useState(1);
  const [itemsPerPageLoadsPOC, setItemsPerPageLoadsPOC] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageLoadsPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageLoadsPOC(perPage);
    setCurrentPageLoadsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem("currentPageLoadsPOC", currentPageLoadsPOC);
    //localStorage.setItem("itemsPerPageLoadsPOC", itemsPerPageLoadsPOC);
  };
  const handleReturnLoadsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageLoadsPOC");
    // const savedItemsPerPage = localStorage.getItem("itemsPerPageLoadsPOC");
    setCurrentPageLoadsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageLoadsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  console.log("getAllLoadsData1", loadsOriginalData);
  const [pastPeriodValues, setPastPeriodValues] = useState([]);
  const [currentYearValues, setCurrentYearValues] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const loadsSeries = [
    {
      name: "Past Period",
      //data: [70, 80, 90, 70, 80, 90, 60, 80, 50, 70, 80, 90, 70],
      data: pastPeriodValues,
    },
    {
      name: "Current Year",
      //data: [50, 60, 60, 80, 20, 70, 90, 60, 60, 25, 50, 50, null],
      data: currentYearValues,
    },
    {
      name: "Forecasting",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        90,
      ],
    },
  ];
  const loadsQuarterSeries = [
    {
      name: "Past Period",
      data: [80, 90, 70, 80, 90, 60, 80, 70],
    },
    {
      name: "Current Year",
      data: [60, 60, 80, 20, 70, 90, 60, null],
    },
    {
      name: "Forecasting",
      data: [null, null, null, null, null, null, null, 90],
    },
  ];
  const loadsWeeklyOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 0, to: 0, color: "transparent" },
            { from: 0, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: 1,
              to: 25,
              color: "#F35631",
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 1],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 4], // Apply dash to the last series
    },
    xaxis: {
      categories: [
        "W45 • 2023",
        "W46 • 2023",
        "W47 • 2023",
        "W48 • 2023",
        "W49 • 2023",
        "W50 • 2023",
        "W51 • 2023",
        "W52 • 2023",
        "W1",
        "W2",
        "W3",
        "W4",
        "W5",
      ],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 50000,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:12px;font-weight:400;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: 0.5,
        width: 4,
        //height: 6,
      },
    },
  };
  const loadsOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 0, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: 0,
              to: 25,
              color: "#F35631",
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 1],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 4], // Apply dash to the last series
    },
    xaxis: {
      categories: [
        "W45 • 2023",
        "W46 • 2023",
        "W47 • 2023",
        "W48 • 2023",
        "W49 • 2023",
        "W50 • 2023",
        "W51 • 2023",
        "W52 • 2023",
        "W1",
        "W2",
        "W3",
        "W4",
        "W5",
      ],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:12px;font-weight:400;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: 0.5,
        width: 4,
        //height: 6,
      },
    },
  };
  const loadsYearlySeries = [
    {
      name: "Past Period",
      data: [90, 90, 80],
      //data: pastPeriodValues,
    },
    {
      name: "Current Year",
      data: [80, 90, null],
      //data: currentYearValues,
    },
    {
      name: "Forecasting",
      data: [null, null, 90],
    },
  ];
  const loadsYearlyOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 0, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: -Infinity,
              to: 25,
              color: "#F35631",
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 1],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 4], // Apply dash to the last series
    },
    xaxis: {
      categories: ["2023", "2024", "2025"],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:14px;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: 0.5,
        //width: 4,
        //height: 6,
      },
    },
  };
  const [chartSeries, setChartSeries] = useState(loadsQuarterSeries);
  console.log("DivDateLabel", dateLabel);
  // Helper function to generate week labels
  const generateWeekLabels = (dateLabel) => {
    const weeks = [];
    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }
    const parts = dateLabel.split(" ");
    let weekNum = parseInt(parts[0].slice(1));
    let year = parseInt(parts[parts.length - 1]);

    // Add the previous 11 weeks
    for (let i = -11; i <= 0; i++) {
      let newWeek = weekNum + i;
      let newYear = year;

      if (newWeek <= 0) {
        newYear -= 1;
        newWeek += 52; // Assuming the previous year had 52 weeks
      } else if (newWeek > 52) {
        newYear += 1;
        newWeek -= 52; // Moving into the next year
      }

      if (newYear !== year) {
        weeks.push(`W${newWeek} • ${newYear}`);
      } else {
        weeks.push(`W${newWeek}`);
      }
    }

    // Add the "next" week with year
    let nextWeek = weekNum + 1;
    let nextYear = year;
    if (nextWeek > 52) {
      nextWeek -= 52;
      nextYear += 1;
    }
    if (nextYear !== year) {
      weeks.push(`W${nextWeek} • ${nextYear}`);
    } else {
      weeks.push(`W${nextWeek}`);
    }

    return weeks;
  };
  // Helper function to generate month labels
  const generateMonthLabels = (dateLabel) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }

    const [month, year] = dateLabel.split(" ");
    const monthIndex = months.indexOf(month);
    const currentYear = parseInt(year);
    const xAxisLabels = [];

    for (let i = 11; i >= 0; i--) {
      let index = (monthIndex - i + 12) % 12;
      let labelYear = currentYear;
      if (index > monthIndex) {
        labelYear -= 1;
        xAxisLabels.push(`${months[index]} • ${labelYear}`);
      } else {
        xAxisLabels.push(`${months[index]}`);
      }
    }

    let nextMonthIndex = (monthIndex + 1) % 12;
    let nextMonthYear = currentYear;
    if (nextMonthIndex < monthIndex) {
      nextMonthYear += 1;
      xAxisLabels.push(`${months[nextMonthIndex]} • ${nextMonthYear}`);
    } else {
      xAxisLabels.push(`${months[nextMonthIndex]}`);
    }

    return xAxisLabels;
  };
  // Helper function to generate quarter labels
  const generateQuarterLabels = (dateLabel) => {
    const quarters = [];

    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }

    const parts = dateLabel.split(" ");
    const quarterNum = parseInt(parts[0].slice(1));
    const year = parseInt(parts[parts.length - 1]);

    // Calculate the starting quarter and year based on the current quarter
    let startYear = 2023; // Start from Q1 2023
    let startQuarter = 1;

    // Generate quarter labels
    for (let y = startYear; y <= year; y++) {
      for (let q = 1; q <= 4; q++) {
        if (y === year && q > quarterNum) break;
        quarters.push(`Q${q} • ${y}`);
      }
    }

    // Add a forecasting quarter after the current quarter
    let forecastingQuarter = quarterNum + 1;
    let forecastingYear = year;
    if (forecastingQuarter > 4) {
      forecastingQuarter -= 4;
      forecastingYear++;
    }
    quarters.push(`Q${forecastingQuarter} • ${forecastingYear}`);

    // Only return the last 8 quarters
    return quarters.slice(-8);
  };
  // Helper function to generate year labels
  const generateYearLabels = (dateLabel) => {
    const currentYear = parseInt(dateLabel);
    const xAxisLabels = [];

    // Generate xAxis labels dynamically
    for (let i = -2; i <= 1; i++) {
      xAxisLabels.push(currentYear + i);
    }

    return xAxisLabels;
  };
  const getCurrentDate = () => {
    let currentStartDate = null;
    let currentEndDate = null;
    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0
    switch (activeCalendarTabLabel) {
      case "Weekly": //Current Week
        // Start from the first Tuesday of the current week
        const currentWeekStart = moment().startOf("isoWeek").day(2);
        // End on the following Monday
        const currentWeekEnd = moment().endOf("isoWeek").day(1);
        currentStartDate = currentWeekStart.format("YYYY-MM-DD");
        currentEndDate = currentWeekEnd.format("YYYY-MM-DD");
        break;
      case "Month": // Current Month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Quarter": // Current Quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "Year": // Current Year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    return currentStartDate, currentEndDate;
  };
  const filterSeriesData = (series, length) => {
    return series.map((serie) => ({
      ...serie,
      data: serie.data.slice(0, length),
    }));
  };
  const truncateSeriesData = (labels, series) => {
    const labelCount = labels.length;
    return series.map((s) => ({
      ...s,
      data: s.data
        .slice(0, labelCount)
        .concat(new Array(Math.max(0, labelCount - s.data.length)).fill(null)),
    }));
  };
  // Update chart options when dateLabel changes
  useEffect(() => {
    let xAxisOptions = {};
    const updateXAxisOptions = (categories) => {
      xAxisOptions = {
        categories: categories,
        tickPlacement: "between",
        axisBorder: {
          show: true,
          color: "rgba(220, 220, 220, 0.2)",
          width: "100%",
        },
        axisTicks: { show: false },
        labels: { style: { colors: "#FFFFFF" } },
      };
    };
    if (activeCalendarTabLabel === "Weekly") {
      updateXAxisOptions(generateWeekLabels(dateLabel));
    } else if (activeCalendarTabLabel === "Month") {
      updateXAxisOptions(generateMonthLabels(dateLabel));
    } else if (activeCalendarTabLabel === "Quarter") {
      const quarterLabels = generateQuarterLabels(dateLabel);
      updateXAxisOptions(quarterLabels);
      const truncatedSeries = truncateSeriesData(
        quarterLabels,
        loadsQuarterSeries
      );
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: xAxisOptions,
        series: truncatedSeries,
      }));
      return;
      //setChartSeries(filterSeriesData(loadsQuarterSeries, categories.length));
    } else if (activeCalendarTabLabel === "Year") {
      updateXAxisOptions(generateYearLabels(dateLabel));
    }
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: xAxisOptions,
    }));
  }, [dateLabel, activeCalendarTabLabel]);
  const getLoadsWeeklyChartAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    const { currentStartDate, currentEndDate } = getCurrentDate();
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    console.log("currentNewDate7", finalStartDate);
    console.log("currentNewDate8", finalEndDate);
    if (activeCalendarTabLabel == "Weekly") {
      getLoadsWeeklyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.weeklyData) {
            if (response.data.weeklyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.weeklyData[week].currentYear
              );
              previousYearValues.push(
                response.data.weeklyData[week].previousYear
              );
            }
          }

          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Month") {
      getLoadsMonthlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];
          for (const week in response.data.monthlyData) {
            if (response.data.monthlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.monthlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.monthlyData[week].previousYear
              );
            }
          }
          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Quarter") {
      getLoadsQuarterlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.quarterlyData) {
            if (response.data.quarterlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.quarterlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.quarterlyData[week].previousYear
              );
            }
          }

          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Year") {
      getLoadsYearlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.yearlyData) {
            if (response.data.yearlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.yearlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.yearlyData[week].previousYear
              );
            }
          }
          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };
  console.log("YearValuesCurrent2:", currentYearValues);
  console.log("YearValuesPrevious2:", pastPeriodValues);
  return (
    <React.Fragment>
      <Container fluid>
        <div className="main-page-design">
          <Row xl={12} md={12} sm={12} xs={12}>
            <Col xl={12} md={12} sm={12} xs={12}>
              <div className="stickyHeaderSectionLoads">
                <HeaderSection title={"Loads"}></HeaderSection>
              </div>
              {/* <div class="slanted-lines"></div> */}
              {/* <div id="loads-chart" className="apex-charts">
                {activeCalendarTabLabel == "Weekly" ? (
                  <ReactApexChart
                    options={{ ...loadsWeeklyOptions, ...chartOptions }}
                    series={loadsSeries}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Month" ? (
                  <ReactApexChart
                    options={{ ...loadsOptions, ...chartOptions }}
                    series={loadsSeries}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Quarter" ? (
                  <ReactApexChart
                    options={{ ...loadsOptions, ...chartOptions }}
                    // series={chartSeries}
                    series={chartOptions.series || loadsQuarterSeries}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Year" ? (
                  <ReactApexChart
                    options={loadsYearlyOptions}
                    series={loadsYearlySeries}
                    type="bar"
                    height={210}
                  />
                ) : (
                  ""
                )}
              </div> */}
              {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <img src={activeTabTriangle} />
                </div> */}
              <div className="kpiButtonTableSection">
                <div className="tabButtonsCalendar">
                  <div className="calenderChangeDownloadReportContainer">
                    <div className="calenderChangeButtonContainerLoads">
                      <CalenderChanger
                        activeTabLabel={activeCalendarTabLabel}
                        activeTabId={activeCalendarTabId}
                        onWeekChange={handleDateChange}
                        onMonthChange={handleDateChange}
                        onQuarterChange={handleDateChange}
                        onYearChange={handleDateChange}
                      />
                    </div>
                    <div>
                      <Buttons
                        dimensions={"responsive"}
                        iconPosition={"left"}
                        buttonType="secondary"
                        size="small"
                        image={downloadReportIcon}
                        hover={false}
                        color={"#000000"}
                        fontSize={"12px"}
                      >
                        Download Report
                      </Buttons>
                    </div>
                    {/* <div class="triangle"></div> */}
                  </div>
                  <div className="stickyTabButtonsLoads">
                    <TabButtons
                      tabs={calendarTabsData}
                      onTabChange={handleCalenderTabChange}
                    />
                  </div>
                </div>
                <div className="kpiTableTitleContainer">
                  <div className="detailsHeaderContainer">
                    <DetailsHeader data={loadsKPIData} />
                  </div>
                  <div className="tableTitleContainerLoads">
                    <div className="">
                      <TabButtons
                        tabs={tableTabsData}
                        onTabChange={handleTableTabChange}
                      />
                    </div>

                    {/* <div style={{ flex: "1" }}>
                        <Title
                          name={`Delivered Loads (${loadsTableDataAPI.length})`}
                          type={"table"}
                        />
                      </div> */}
                    {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search load no."
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="tableContainerLoads">
                <div
                  className="driversTableContainerLoads"
                  style={{
                    maxHeight: `${maxTableHeight}px`,
                    marginBottom: "16px",
                  }}
                >
                  {console.log("loadDetailsTableData", loadDetailsTableData)}
                  {activeTableTabLabel == "All" ? (
                    <div className="recentSettelmentsTableContainer">
                      <DriversTablePOC
                        headers={loadsTableHeaders}
                        data={loadsTableDataAPI}
                        activeTab={activeTab}
                        subHeaders={loadDetailsTableHeaders}
                        subData={loadDetailsTableData}
                        tableName={"loadsDetailsTable"}
                        rowClick={true}
                        rowClickHandler={handleRowClick}
                        paginationEnabled={true}
                        currentPage={currentPageLoadsPOC}
                        itemsPerPage={itemsPerPageLoadsPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    </div>
                  ) : (
                    <div className="recentSettelmentsTableContainer">
                      <DriversTablePOC
                        headers={loadsTableHeaders}
                        data={loadsTableDataNewAPI}
                        activeTab={activeTab}
                        subHeaders={loadDetailsTableHeaders}
                        subData={loadDetailsTableData}
                        tableName={"loadsDetailsTable"}
                        rowClick={true}
                        rowClickHandler={handleRowClick}
                        paginationEnabled={true}
                        currentPage={currentPageLoadsPOC}
                        itemsPerPage={itemsPerPageLoadsPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default LoadsPOC;
