import axios from "axios";
import { store } from "app/store";
import { appOperations } from "app/store/state/app";

axios.defaults.withCredentials = true;

export const initializeInterceptors = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        store.dispatch(appOperations.logout());
      } else {
        return Promise.reject(error);
      }
    }
  );
};
