import React from "react";
import "./Labels.scss"; // Use a common SCSS file for styling
const Labels = ({
  children,
  color,
  onClick,
  image,
  arrow, //true or false
  labelType, //light or dark
  labelTag, //purple,green,skyblue,yellow ,grey,red
  iconPosition,
  // Other props as needed
  ...rest
}) => {
  const renderIcon = () => {
    const marginRight = iconPosition === "left" ? { marginRight: "4px" } : {};
    const marginLeft = iconPosition === "right" ? { marginLeft: "4px" } : {};
    return (
      <img
        src={image || ""}
        style={{ ...marginRight, ...marginLeft }}
        alt="Cross Icon"
      />
    );
  };
  // const renderIcon = () => {
  //   return <img src={image || ""} style={{}} alt="Cross Icon" />;
  // };
  return (
    <div
      className={"bgLabels"} // Apply a common class for styling
      style={{
        background:
          labelType === "light"
            ? labelTag == "purple"
              ? "rgba(128, 96, 255, 0.10)"
              : labelTag == "green"
              ? "rgba(42, 203, 106, 0.15)"
              : labelTag == "skyblue"
              ? "rgba(0, 154, 237, 0.10)"
              : labelTag == "yellow"
              ? "rgba(255, 181, 0, 0.15)"
              : labelTag == "grey"
              ? "#ECEEF1"
              : labelTag == "red"
              ? "rgba(253, 38, 54, 0.15)"
              : ""
            : labelType === "dark"
            ? labelTag == "purple"
              ? "#8060FF"
              : labelTag == "green"
              ? "#2ACB6A"
              : labelTag == "skyblue"
              ? "#0097E6"
              : labelTag == "yellow"
              ? "#FDB900"
              : labelTag == "grey"
              ? "#6B6C72"
              : labelTag == "red"
              ? "#E94040"
              : ""
            : "",
      }}
      onClick={onClick}
      {...rest}
    >
      <p
        className="bgLabelName"
        style={{
          // background:
          //   labelType === "light"
          //     ? labelTag == "purple"
          //       ? "rgba(128, 96, 255, 0.10)"
          //       : labelTag == "green"
          //       ? "rgba(42, 203, 106, 0.15)"
          //       : labelTag == "skyblue"
          //       ? "rgba(0, 154, 237, 0.10)"
          //       : labelTag == "yellow"
          //       ? "rgba(255, 181, 0, 0.15)"
          //       : labelTag == "grey"
          //       ? "#ECEEF1"
          //       : labelTag == "red"
          //       ? "rgba(253, 38, 54, 0.15)"
          //       : ""
          //     : labelType === "dark"
          //     ? labelTag == "purple"
          //       ? "#8060FF"
          //       : labelTag == "green"
          //       ? "#2ACB6A"
          //       : labelTag == "skyblue"
          //       ? "#0097E6"
          //       : labelTag == "yellow"
          //       ? "#FDB900"
          //       : labelTag == "grey"
          //       ? "#6B6C72"
          //       : labelTag == "red"
          //       ? "#E94040"
          //       : ""
          //     : "",
          color:
            labelType === "light"
              ? labelTag == "purple"
                ? "#676bcd"
                : labelTag == "green"
                ? "#00A478"
                : labelTag == "skyblue"
                ? "#0197C7"
                : labelTag == "yellow"
                ? "#E59904"
                : labelTag == "grey"
                ? "#838383"
                : labelTag == "red"
                ? "#E84B23"
                : ""
              : "#ffffff",
        }}
      >
        {iconPosition === "left" && image != "" && renderIcon()}
        {children}
        {iconPosition === "right" && image != "" && renderIcon()}
      </p>
    </div>
  );
};

export default Labels;
