import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./Drivers.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../commonComponents/DetailsHeader/DetailsHeader";
import driverProfile from "../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../assets/images/drivers/accidents.svg";
import calendarRemove from "../../../../../assets/images/drivers/calendarRemove.svg";
import moneys from "../../../../../assets/images/drivers/moneys.svg";
import presentionChart from "../../../../../assets/images/drivers/presentionChart.svg";
import star from "../../../../../assets/images/drivers/star.svg";
import userTag from "../../../../../assets/images/drivers/userTag.svg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTable from "../DriversTable/DriversTable";
import DriversFilterButton from "../DriversFilterButton/DriversFilterButton";

const Drivers = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [isDriversFilterOpen, setIsDriversFilterOpen] = useState(false);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const driverData = [
    { icon: driverProfile, values: "21,242", title: "Total Drivers" },
    { icon: userTag, values: "23,435", title: "On Fleet Drivers" },
    { icon: star, values: "4.3", title: "Safety Rating" },
    { icon: accidents, values: "72", title: "Accident" },
    { icon: calendarRemove, values: "13", title: "Near Expirations" },
    { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const tabsData = [
    { id: 0, label: "Current Drivers" },
    { id: 1, label: "Prospective Drivers" },
    { id: 2, label: "Precious Drivers" },
    // Add more tabs as needed
  ];
  const tableHeaders = {
    driverNo: "Driver No.",
    fullName: "Full Name",
    cityState: "City, State",
    assignedTruck: "Assigned Truck",
    phoneNo: "Phone No.",
    milesCovered: "Miles Covered",
    totalLoad: "Total Load",
    expirations: "Expirations",
  };
  const tableData = [
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    // Add more data as needed

    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [],
    },
    {
      driverNo: "KH4235",
      fullName: "Abbott, Mickey R.",
      cityState: "Seattle, Washington",
      assignedTruck: "CEA3245",
      phoneNo: "(319) 555-0115",
      milesCovered: "19,897",
      totalLoad: "50",
      expirations: [{ type: "TWIC Card", duration: "34 d" }],
    },
  ];
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 280; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);

  const handleDriversFilterToggle = () => {
    setIsDriversFilterOpen(!isDriversFilterOpen);
  };

  const handleApplyDriversFilter = () => {
    setIsDriversFilterOpen(!isDriversFilterOpen);
  };

  const handleCancelDriversFilter = () => {
    setIsDriversFilterOpen(false);
  };
  return (
    <React.Fragment>
      <div className="drivers-page">
        <Container fluid>
          <div className="">
            <Row
              xl={12}
              md={12}
              sm={12}
              xs={12}
              style={{ background: "#ffffff" }}
            >
              {" "}
              <Col xl={12} md={12} sm={12} xs={12}>
                {" "}
                <div style={{}} className="driverStickyHeader">
                  {/* <Breadcrumbs title="Drivers" breadcrumbItems={breadcrumbItems} /> */}
                  <Breadcrumbs items={breadcrumbItems} />
                  <div style={{ marginTop: "8px" }}>
                    <Title name={"Drivers"} type={"page"} />
                  </div>
                </div>
                <div style={{ marginTop: "16px" }} className="">
                  <DetailsHeader data={driverData} />
                </div>
                <div className="stickyTabButtons">
                  <TabButtons tabs={tabsData} onTabChange={handleTabChange} />
                </div>
                {/* <Card>
                  <CardBody className="tableContainer"> */}
                <div className="tableContainer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "24px",
                    }}
                    className="tableTitleContainer"
                  >
                    {" "}
                    <div style={{ flex: "1" }}>
                      <Title name={"Current Drivers (64325)"} type={"table"} />
                    </div>
                    <div>
                      <DriversFilterButton
                        isDriversFilterOpen={isDriversFilterOpen}
                        handleOpenDriversFilter={handleDriversFilterToggle}
                        handleCancelDriversFilter={handleCancelDriversFilter}
                        handleApplyDriversFilter={handleApplyDriversFilter}
                      />
                    </div>
                  </div>
                  <div
                    className="driversTableContainer"
                    style={{ maxHeight: `${maxTableHeight}px` }}
                  >
                    <DriversTable headers={tableHeaders} data={tableData} />
                  </div>
                </div>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Drivers.propTypes = {};
export default Drivers;
