import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./Logout.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import { useHistory, Redirect } from "react-router-dom";
const Logout = ({ onClose }) => {
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const history = useHistory();

  useEffect(() => {
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");

    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("token");
    onClose();
    window.location.href = "/";
    //window.location.reload();
    history.push("/");
    return <Redirect to="/" exact />;
  };
  return (
    <Card className={"unassignVehicleCard"}>
      <CardBody>
        <div className="unassignVehicleContainer">
          <div className="unassignVehicleTitleMessage">
            <div className="unassignVehicleTitle">
              <div className="unassignVehicleStyle">Are you sure?</div>
            </div>
            <div className="unassignVehicleMessage">
              You are going to log out {`${firstNameUser} ${lastNameUser}`}.
            </div>
          </div>
          <div className="removeSection">
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              onClick={onClose}
              imageReplace={false}
            >
              No, Don’t Log Out
            </Buttons>
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              imageReplace={false}
              // onClick={() => {
              //   localStorage.removeItem("token");
              //   document.cookie =
              //     "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
              //   onClose();
              // window.location.href = "/";
              // window.location.reload();
              //   //history.push("/");
              //   return <Redirect to="/" exact />;
              // }}
              onClick={handleLogout}
            >
              Yes, Log Out
            </Buttons>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Logout;
