import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Modal,
  TablePagination,
  Snackbar,
} from "@material-ui/core";
import "./AdditionalPaymentTable.scss";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import addRowIcon from "../../../../../../assets/images/poc/addRowIcon.svg";
import deleteRow from "../../../../../../assets/images/poc/deleteRow.svg";
import submitPayment from "../../../../../../assets/images/poc/submitPayment.svg";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import {
  addOtherRevenue,
  addDeduction,
  deleteOtherRevenue,
  deleteDeduction,
} from "app/apiServices/apiServices";
const AdditionalPaymentTable = ({
  tableName,
  uploadId,
  driverId,
  headers,
  data,
}) => {
  console.log("dataPay", data);
  const [rows, setRows] = useState(data || []);
  const [totalAmount, setTotalAmount] = useState(0);
  // Calculate total amount whenever rows changes
  useEffect(() => {
    // Calculate total amount from rows
    const total = rows.reduce((acc, curr) => acc + (curr.amount || 0), 0);
    setTotalAmount(total);
  }, [rows]);
  useEffect(() => {
    if (Array.isArray(data)) {
      if (tableName == "additionalPaymentTable") {
        // Convert the received data into an array of objects
        const rowData = data.map((item) => {
          console.log("dataPayItem", item.additionalPaymentDescriptions);
          console.log("dataPayItem2", item.amount);
          return {
            id: item.id,
            description: item.additionalPaymentDescriptions || "", // Add default value for description
            amount: parseFloat(item.amount || null), // Add default value for amount
          };
        });
        console.log("rowData", rowData);
        setRows(rowData);
      } else {
        // Convert the received data into an array of objects
        const rowData = data.map((item) => {
          console.log("dataPayItem", item.deductionDescription);
          console.log("dataPayAmount", item.amount);
          return {
            id: item.id,
            description: item.deductionDescription || "", // Add default value for description
            amount: parseFloat(item.amount), // Add default value for amount
          };
        });
        console.log("rowData1", rowData);
        console.log("rowData2", rowData[0]);
        setRows(rowData);
      }
    }
  }, [data]);
  const addRow = () => {
    setRows([...rows, { name: "", email: "" }]);
  };
  const deleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
    const storedToken = localStorage.getItem("token");
    if (tableName == "additionalPaymentTable") {
      deleteOtherRevenue(index)
        .then((response) => {
          //console.log("insideif", response);
          console.log("addVehicleData", response.data);
          setTimeout(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      deleteDeduction(index)
        .then((response) => {
          //console.log("insideif", response);
          console.log("addVehicleData", response.data);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };
  return (
    <div>
      <InputTable
        rows={rows}
        onDelete={deleteRow}
        tableName={tableName}
        uploadId={uploadId}
        driverId={driverId}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <div style={{ flex: "1" }}>
          {" "}
          <Buttons
            size={"regular"}
            dimensions={"normal"}
            buttonType={"secondary"}
            iconPosition={"left"}
            image={addRowIcon}
            width={"auto"}
            onClick={addRow}
            hover={false}
            color={"#000000"}
          >
            {tableName == "additionalPaymentTable"
              ? "Add Other Revenue"
              : tableName == "deductionDescriptionTable"
              ? "Add Deduction"
              : ""}
          </Buttons>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: "",
          }}
        >
          <div className="subTotal" style={{ flex: "", float: "right" }}>
            Sub Total
          </div>
          <div
            className={
              tableName == "additionalPaymentTable"
                ? "subTotalAmountAdd"
                : tableName == "deductionDescriptionTable"
                ? "subTotalAmountDeduction"
                : ""
            }
            style={{ marginLeft: "20px" }}
          >
            $
            {totalAmount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function InputTable({ rows, onDelete, tableName, uploadId, driverId }) {
  return (
    <Table
      style={{
        width: "100%",
      }}
    >
      <TableHead className={"tableStickyHeader"} style={{ width: "100%" }}>
        <TableRow style={{}}>
          <>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={""}
              style={{
                cursor: "pointer",
                borderTopLeftRadius: "8px",
              }}
            >
              {tableName == "additionalPaymentTable"
                ? "Additional Payment Description"
                : tableName == "deductionDescriptionTable"
                ? "Deduction Description"
                : ""}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"left"}
              style={{
                cursor: "pointer",
              }}
            >
              {"Amount"}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
              }}
            ></TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
                borderTopRightRadius: "8px",
              }}
            ></TableData>
          </>
        </TableRow>
      </TableHead>
      <tbody>
        {rows.map((row, index) => (
          <InputRow
            key={index}
            index={index}
            name={row.name}
            email={row.email}
            row={row}
            onDelete={onDelete}
            tableName={tableName}
            uploadId={uploadId}
            driverId={driverId}
          />
        ))}
      </tbody>
    </Table>
  );
}

function InputRow({ index, row, onDelete, tableName, uploadId, driverId }) {
  const [descriptionRevenue, setDescriptionRevenue] = useState("");
  const [amountRevenue, setAmountRevenue] = useState();
  const [descriptionDeduction, setDescriptionDeduction] = useState("");
  const [amountDeduction, setAmountDeduction] = useState();
  const [hasBlurred, setHasBlurred] = useState(false);
  // const { additionalPaymentDescriptions, amount } = row;
  const { description, amount, id } = row;
  const [descriptionRevenueData, setDescriptionRevenueData] = useState(
    description || ""
  );
  const [amountRevenueData, setAmountRevenueData] = useState(amount);
  const [descriptionDeductionData, setDescriptionDeductionData] = useState(
    description || ""
  );
  const [amountDeductionData, setAmountDeductionData] = useState(amount);
  // Check if the row is newly added
  const isNewRow = row.id === undefined || row.id === null;
  // console.log("dataPay1", description);
  console.log("dataPay2", amount);
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (tableName == "additionalPaymentTable") {
      if (description != "") {
        setDescriptionRevenueData(value);
      } else {
        setDescriptionRevenue(value);
      }
    } else {
      if (description != "") {
        setDescriptionDeductionData(value);
      } else {
        setDescriptionDeduction(value);
      }
    }
  };
  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (tableName == "additionalPaymentTable") {
      if (amount != 0) {
        setAmountRevenueData(parseFloat(value));
      } else {
        setAmountRevenue(parseFloat(value));
      }
    } else {
      if (amount != 0) {
        setAmountDeductionData(parseFloat(value));
      } else {
        setAmountDeduction(parseFloat(value));
      }
    }
  };
  console.log("dataPay3", amountDeductionData);
  const handleBlur = () => {
    setHasBlurred(true);
  };
  useEffect(() => {
    if (hasBlurred) {
      if (amountRevenue != null && descriptionRevenue !== "") {
        // AddOtherRevenueAPI();
      }
      if (amountDeduction != null && descriptionDeduction !== "") {
        //  AddDeductionAPI();
      }
    }
  }, [hasBlurred]);
  const AddOtherRevenueAPI = () => {
    const storedToken = localStorage.getItem("token");
    const otherRevenueData = {
      driver_id: driverId,
      amount: parseFloat(amountRevenueData ? amountRevenueData : amountRevenue),
      description: descriptionRevenueData
        ? descriptionRevenueData
        : descriptionRevenue,
      upload_id: parseFloat(uploadId),
    };
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/other-incomes",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       driver_id: driverId,
    //       amount: parseFloat(
    //         amountRevenueData ? amountRevenueData : amountRevenue
    //       ),
    //       description: descriptionRevenueData
    //         ? descriptionRevenueData
    //         : descriptionRevenue,
    //       upload_id: parseFloat(uploadId),
    //     }),
    //   }
    // )
    //   .then((result) => {
    //     if (result.status === 200) {
    //       return result.json();
    //     } else {
    //       alert("Please check login credentials!");
    //     }
    //   })
    addOtherRevenue(otherRevenueData)
      .then((response) => {
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
        setTimeout(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const AddDeductionAPI = () => {
    const storedToken = localStorage.getItem("token");
    const deductionData = {
      driver_id: driverId,
      amount: parseFloat(
        amountDeductionData ? amountDeductionData : amountDeduction
      ),
      description: descriptionDeductionData
        ? descriptionDeductionData
        : descriptionDeduction,
      upload_id: parseFloat(uploadId),
    };
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/deductions",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       driver_id: driverId,
    //       amount: parseFloat(
    //         amountDeductionData ? amountDeductionData : amountDeduction
    //       ),
    //       description: descriptionDeductionData
    //         ? descriptionDeductionData
    //         : descriptionDeduction,
    //       upload_id: parseFloat(uploadId),
    //     }),
    //   }
    // )
    //   .then((result) => {
    //     if (result.status === 200) {
    //       return result.json();
    //     } else {
    //       alert("Please check login credentials!");
    //     }
    //   })
    addDeduction(deductionData)
      .then((response) => {
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
        setTimeout(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <TableRow style={{}}>
      <>
        <TableCell
          className={
            descriptionRevenueData.length == 0 ||
            descriptionDeductionData.length == 0
              ? "inputCellStyles"
              : "dataCellStyles"
          }
          style={{
            cursor: "pointer",
            background:
              descriptionRevenueData.length == 0 ||
              descriptionDeductionData.length == 0
                ? "#F4F5F8"
                : "#FFFFFF",
            width: "80%",
          }}
        >
          <input
            type="text"
            value={
              tableName == "additionalPaymentTable"
                ? descriptionRevenueData
                  ? descriptionRevenueData
                  : descriptionRevenue
                : descriptionDeductionData
                ? descriptionDeductionData
                : descriptionDeduction
            }
            onChange={handleDescriptionChange}
            placeholder="Add Description"
            className="inputStyle"
            style={{
              width: "100%",
              height: "100%",
              background:
                descriptionRevenueData.length == 0 ||
                descriptionDeductionData.length == 0
                  ? "#F4F5F8"
                  : "#FFFFFF",
              border: "none",
            }}
          />
        </TableCell>
        <TableCell
          className={
            amountRevenueData == null || amountDeductionData == null
              ? "inputCellStyles"
              : "dataCellStyles"
          }
          style={{
            cursor: "pointer",
            background:
              amountRevenueData == null || amountDeductionData == null
                ? "#F4F5F8"
                : "#FFFFFF",
            display: "flex",
          }}
        >
          $
          <input
            type="number"
            value={
              tableName == "additionalPaymentTable"
                ? amountRevenueData
                  ? amountRevenueData
                  : amountRevenue
                : amountDeductionData
                ? amountDeductionData
                : amountDeduction
            }
            min="-999999" // Set minimum value
            max="-1" // Set maximum value
            step="any" // Allow any step
            onChange={handleAmountChange}
            onBlur={handleBlur}
            placeholder="Amount"
            className="inputStyle"
            style={{
              width: "100%",
              height: "100%",
              background:
                amountRevenueData == null || amountDeductionData == null
                  ? "#F4F5F8"
                  : "#FFFFFF",
              border: "none",
            }}
          />
        </TableCell>
        {/* Conditionally render submit button for newly added rows */}
        {isNewRow ? (
          <TableCell
            align="right"
            style={{
              cursor: "pointer",
            }}
            onClick={
              tableName === "additionalPaymentTable"
                ? AddOtherRevenueAPI
                : AddDeductionAPI
            }
          >
            <img src={submitPayment} />
          </TableCell>
        ) : (
          <TableCell
            align="right"
            style={{
              cursor: "pointer",
            }}
          ></TableCell>
        )}
        <TableCell
          align="right"
          style={{
            cursor: "pointer",
          }}
          onClick={() => onDelete(id)}
        >
          <img src={deleteRow} />
        </TableCell>
      </>
    </TableRow>
  );
}
export default AdditionalPaymentTable;
