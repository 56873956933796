import produce from "immer";
import * as types from "./types";
import { EN } from "app/constants/keys";

const initialState = {
  auth: {
    isAuthenticated: false,
    errorMsg: undefined,
    saving: false,
  },
  user: undefined,
  lang: EN,
  theme: localStorage.getItem("transport_app_theme") || "default",
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.LOGOUT: {
        draft.auth.isAuthenticated = false;
        draft.auth.errorMsg = undefined;
        draft.auth.saving = false;
        draft.user = undefined;
        break;
      }
      default:
        break;
    }
  });

export default appReducer;
