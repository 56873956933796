import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import closeAddDriver from "../../../../../assets/images/addDriver/closeAddDiver.svg";
import "./AddAsset.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
import "react-toastify/dist/ReactToastify.css";
import { HashSpinner } from "app/views/commonComponents/Spinners/Spinners";
import { addVehicleData } from "../../../../apiServices/apiServices";
const AddAsset = ({ onClose, onVehicleAdd }) => {
  const [vehicleId, setVehicleId] = useState("");
  const [vinNumber, setVINNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [year, setYear] = useState();
  const [licencePlateNo, setLicencePlateNo] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [colour, setColour] = useState("");
  const [hasBlurred, setHasBlurred] = useState(false);
  const [makeAuto, setMakeAuto] = useState("");
  const [modelAuto, setModelAuto] = useState("");
  const [typeAuto, setTypeAuto] = useState("");
  const [yearAuto, setYearAuto] = useState();
  const [fetchDetails, setFetchDetails] = useState(false);
  const [validVINNumber, setValidVINNumber] = useState(false);
  const [validVehicleId, setValidVehicleId] = useState(false);
  const [validLicencePlateNo, setValidLicencePlateNo] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelectedValue, setDropdownSelectedValue] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMake, setSelectedMake] = useState("");
  const [showMake, setShowMake] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedState, setSelectedState] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVehicleAdded, setIsVehicleAdded] = useState(false);
  const [assetName, setAssetName] = useState("");
  const [assetLocation, setAssetLocation] = useState("");
  const [assetTask, setAssetTask] = useState("");
  const [assetDateFrom, setAssetDateFrom] = useState("");
  const [assetDateTo, setAssetDateTo] = useState("");
  const [assetAssignedTo, setAssetAssignedTo] = useState("");
  const handleVINNumberChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    const trimmedValue = inputValue.slice(0, 17);
    const validVINNumber = trimmedValue.length === 17;
    setValidVINNumber(validVINNumber);
    const regex = /^[0-9A-Z]{0,17}$/;
    if (regex.test(inputValue)) {
      setVINNumber(inputValue);
    }
  };
  // const handleMakeChange = (e) => {
  //   const inputValue = e.target.value;
  //   const formattedValue =
  //     inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase();
  //   // Regular expression to match only alphabetic characters
  //   const regex = /^[A-Za-z\s]*$/;
  //   if (regex.test(formattedValue)) {
  //     setMake(formattedValue);
  //   }
  // };

  const handleAssetNameChange = (e) => {
    const inputValue = e.target.value; // No need to trim spaces here
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space

    // Regular expression to match only alphabetic characters and spaces
    const regex = /^[A-Za-z-\s]*$/;

    if (regex.test(inputValue)) {
      setAssetName(formattedValue);
    }
  };
  const handleAssetTaskChange = (e) => {
    const inputValue = e.target.value; // No need to trim spaces here
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space

    // Regular expression to match only alphabetic characters and spaces
    const regex = /^[A-Za-z-\s]*$/;

    if (regex.test(inputValue)) {
      setAssetTask(formattedValue);
    }
  };
  const handleAssignedToChange = (e) => {
    const inputValue = e.target.value; // No need to trim spaces here
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space

    // Regular expression to match only alphabetic characters and spaces
    const regex = /^[A-Za-z-\s]*$/;

    if (regex.test(inputValue)) {
      setAssetAssignedTo(formattedValue);
    }
  };
  const handleModelChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setModel(formattedValue);
    }
  };
  const handleTypeChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setType(formattedValue);
    }
  };
  const handleColorChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setColour(formattedValue);
    }
  };
  const handleVehicleIdChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    const trimmedValue = inputValue.slice(0, 5); // Limit to 5 digits
    const validVehicleId = trimmedValue.length === 5; // Check if it contains exactly 10 digits
    setValidVehicleId(validVehicleId);
    const regex = /^[0-9A-Z]{0,8}$/;
    if (regex.test(inputValue)) {
      setVehicleId(inputValue);
    }
  };
  const handleLicencePlateNoChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    // const trimmedValue = inputValue.slice(0, 15); // Limit to 5 digits
    // const validVehicleId = trimmedValue.length === 15; // Check if it contains exactly 10 digits
    //setValidVehicleId(validVehicleId);
    const regex = /^[0-9A-Z-]{0,9}$/;
    if (regex.test(inputValue)) {
      setLicencePlateNo(inputValue);
    }
  };
  const handleStateRegistrationChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setStateRegistration(formattedValue);
    }
  };
  const addVehicleDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    // const vehicleData={     vehicle_id: vehicleId,
    //       vin_number: vinNumber,
    //       make: makeAuto ? formattedMakeAuto : selectedMake,
    //       type: typeAuto ? formattedTypeAuto : selectedType,
    //       model: modelAuto ? formattedModelAuto : selectedModel,
    //       year: yearAuto ? yearAuto : selectedYear,
    //       license_plate_number: licencePlateNo,
    //       state_registration: selectedState,
    //       colour: selectedColor,
    //     }
    const vehicleData = {
      vehicle_id: vehicleId,
      vin_number: vinNumber,
      make: makeAuto ? formattedMakeAuto : make,
      type: typeAuto ? formattedTypeAuto : type,
      model: modelAuto ? formattedModelAuto : model,
      year: yearAuto ? yearAuto : year,
      license_plate_number: licencePlateNo,
      state_registration: stateRegistration,
      colour: colour,
    };
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/vehicles",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     // body: JSON.stringify({
    //     //   vehicle_id: vehicleId,
    //     //   vin_number: vinNumber,
    //     //   make: makeAuto ? formattedMakeAuto : selectedMake,
    //     //   type: typeAuto ? formattedTypeAuto : selectedType,
    //     //   model: modelAuto ? formattedModelAuto : selectedModel,
    //     //   year: yearAuto ? yearAuto : selectedYear,
    //     //   license_plate_number: licencePlateNo,
    //     //   state_registration: selectedState,
    //     //   colour: selectedColor,
    //     // }),
    //     body: JSON.stringify({
    //       vehicle_id: vehicleId,
    //       vin_number: vinNumber,
    //       make: makeAuto ? formattedMakeAuto : make,
    //       type: typeAuto ? formattedTypeAuto : type,
    //       model: modelAuto ? formattedModelAuto : model,
    //       year: yearAuto ? yearAuto : year,
    //       license_plate_number: licencePlateNo,
    //       state_registration: stateRegistration,
    //       colour: colour,
    //     }),
    //   }
    // )
    addVehicleData(vehicleData)
      // .then((result) => {
      //   if (result.status === 200) {
      //     return result.json();
      //   } else {
      //     alert("Please check login credentials!");
      //   }
      // })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsVehicleAdded(true);
          handleAddVehicleStatus(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
        }
        console.log("isVehicleAdded0", isVehicleAdded);
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleBlur = () => {
    setHasBlurred(true);
  };
  const handleFetchDetails = () => {
    addVinNumber(vinNumber);
    isLoading ? setFetchDetails(false) : setFetchDetails(true);
  };
  useEffect(() => {
    if (hasBlurred) {
      if (vinNumber != "") {
        // addVinNumber(vinNumber);
      }
    }
  }, [hasBlurred]);
  const addVinNumber = (vinNumber) => {
    setIsLoading(true); // Set loading to true when fetching data
    const storedToken = localStorage.getItem("token");
    fetch(
      `https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/decode/vin?VIN=${vinNumber}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    )
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          return result.json();
        } else {
          alert("Please check login credentials!");
        }
      })
      .then((response) => {
        //console.log("insideif", response);
        console.log("GetVehicleData", response.data);
        setMakeAuto(response.data.make ? response.data.make : "");
        setModelAuto(response.data.model ? response.data.model : "");
        setTypeAuto(response.data.type ? response.data.type : "");
        setYearAuto(response.data.year ? response.data.year : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  // Function to handle changes in showDropdown value
  const handleShowDropdownChange = (showDropdownValue) => {
    // Update the showDropdownParent value in the parent component
    setShowDropdown(showDropdownValue);
  };
  // Event handler for opening a dropdown
  const handleDropdownOpen = () => {
    setShowDropdown(true);
  };
  const handleYearSelection = (selectedYear) => {
    setSelectedYear(selectedYear);
    handleDropdownOpen();
  };
  // Event handler for selecting a make
  const handleMakeSelection = (selectedMake) => {
    setSelectedMake(selectedMake != "" ? selectedMake : "");
    // Reset selected model, type, and color when a new make is selected
    setSelectedModel("");
    setSelectedType("");
    setSelectedColor("");
    handleDropdownOpen();
  };
  // Event handler for selecting a model
  const handleModelSelection = (selectedModel) => {
    //setShowMake(true);
    setSelectedModel(selectedModel);
    handleDropdownOpen();
  };
  // Event handler for selecting a type
  const handleTypeSelection = (selectedType) => {
    setSelectedType(selectedType);
    handleDropdownOpen();
  };
  // Event handler for selecting a color
  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };
  const handleStateSelection = (selectedValue) => {
    setSelectedState(selectedValue);
  };
  const handleAddVehicleStatus = (value) => {
    console.log("isDriverAdded1", value);
    onVehicleAdd(value);
  };
  // useEffect(() => {
  //   if (isVehicleAdded) {
  //     // Call the callback function passed from the parent
  //     console.log("isVehicleAdded1", isVehicleAdded);
  //     onVehicleAdd(isVehicleAdded);
  //   }
  // }, [isVehicleAdded, onVehicleAdd]);
  console.log("isVehicleAdded2", isVehicleAdded);
  const formattedMakeAuto =
    makeAuto.charAt(0).toUpperCase() + makeAuto.slice(1).toLowerCase();
  const formattedModelAuto =
    modelAuto.charAt(0).toUpperCase() + modelAuto.slice(1).toLowerCase();
  const formattedTypeAuto =
    typeAuto.charAt(0).toUpperCase() + typeAuto.slice(1).toLowerCase();
  const formattedSelectedMake =
    makeAuto.charAt(0).toUpperCase() + selectedMake.slice(1).toLowerCase();
  const formattedSelectedModel =
    modelAuto.charAt(0).toUpperCase() + selectedModel.slice(1).toLowerCase();
  const formattedSelectedType =
    typeAuto.charAt(0).toUpperCase() + selectedType.slice(1).toLowerCase();
  console.log("vin1", makeAuto);
  console.log("vin2", modelAuto);
  console.log("vin3", typeAuto);
  console.log("vin4", yearAuto);
  console.log("selectedYear", selectedYear);
  return (
    <Card className={"addVehicleCard"} style={{ width: "65%" }}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <HashSpinner color={"#393A3D"} />
        </div>
      )}
      <CardBody>
        <div className="addVehicleContainer">
          <div className="addVehicleTitle">
            <div className="vinVehicleConatiner">
              {" "}
              <div className="addVehicleStyle">Add Asset</div>
            </div>
            <Icon
              size="regular"
              buttonType="secondary"
              image={closeAddDriver}
              onClick={onClose}
              imageReplace={true}
              hover={false}
            ></Icon>
          </div>
          <>
            <div className="inputSectionAddVehicle">
              <div className="inputRowVehicle" style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  {/* <InputDropdown
                    fieldName="Year"
                    dropdownOptions={yearList}
                    initialValue={yearAuto || ""}
                    selectedValue={selectedYear || ""}
                    onValueSelection={handleYearSelection}
                    inputType="number"
                    //setShowDropdownParent={handleShowDropdownChange}
                  /> */}
                  <AddInputField
                    fieldName="Asset Name"
                    fieldValue={assetName}
                    inputType="text"
                    onChange={handleAssetNameChange}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  {/* Dropdown for selecting make */}
                  {/* <InputDropdown
                    fieldName="Make"
                    dropdownOptions={makeList.map((make) => make.name)}
                    initialValue={formattedMakeAuto || ""}
                    selectedValue={formattedSelectedMake || ""}
                    onValueSelection={handleMakeSelection}
                    //setShowDropdownParent={handleShowDropdownChange}
                    inputType="text"
                  /> */}
                  <AddInputField
                    fieldName="Location"
                    fieldValue={assetLocation}
                    onChange={(e) => setAssetLocation(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  {/* <InputDropdown
                    fieldName="Model"
                    // dropdownOptions={
                    //   selectedMake
                    //     ? makeList.find((make) => make.name === selectedMake)
                    //         .models
                    //     : []
                    // }
                    dropdownOptions={
                      selectedMake != ""
                        ? makeList.find((make) => make.name === selectedMake)
                            ?.models
                        : allModels.sort()
                    }
                    initialValue={formattedModelAuto || ""}
                    selectedValue={formattedSelectedModel || ""}
                    onValueSelection={handleModelSelection}
                    //setShowDropdownParent={handleShowDropdownChange}
                    inputType="textNumber"
                  /> */}
                  <AddInputField
                    fieldName="Task"
                    fieldValue={assetTask}
                    onChange={handleAssetTaskChange}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="inputRowVehicle" style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <AddInputField
                    fieldName="Date From"
                    fieldValue={assetDateFrom}
                    // onChange={(e) => setType(e.target.value)}
                    //onChange={handleTypeChage}
                    style={{ width: "100%" }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <AddInputField
                    fieldName="Date To"
                    fieldValue={assetDateTo}
                    // onChange={(e) => setColour(e.target.value)}
                    //onChange={handleColorChage}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <AddInputField
                    fieldName="Assigned To"
                    fieldValue={assetAssignedTo}
                    onChange={handleAssignedToChange}
                    inputType="text"
                  />
                </div>
              </div>
            </div>
            <div className="submitButtonVehicle">
              <Buttons
                size={"small"}
                dimensions={"normal"}
                buttonType={"primary"}
                // disabled={
                //   (makeAuto === "" && selectedMake === "") ||
                //   (modelAuto === "" && selectedModel === "") ||
                //   (typeAuto === "" && selectedType === "") ||
                //   (yearAuto === "" && selectedYear === "") ||
                //   validVehicleId == false ||
                //   licencePlateNo === "" ||
                //   selectedState === "" ||
                //   selectedColor === ""
                // }
                // disabled={
                //   (makeAuto === "" && make === "") ||
                //   (modelAuto === "" && model === "") ||
                //   (typeAuto === "" && type === "") ||
                //   (yearAuto === "" && year === "") ||
                //   validVehicleId == false ||
                //   //licencePlateNo == "" ||
                //   stateRegistration == "" ||
                //   colour == ""
                // }
                imageReplace={true}
                onClick={() => {
                  addVehicleDataAPI();
                  onClose();
                }}
              >
                Submit
              </Buttons>
            </div>
          </>
          {/* )} */}
        </div>
      </CardBody>
    </Card>
  );
};

export default AddAsset;
