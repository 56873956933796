import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@material-ui/core";
import "./DriversTable.scss";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import Expirations from "app/views/commonComponents/Expirations/Expirations";
const DriversTable = ({ headers, data }) => {
  return (
    <>
      <Table style={{ width: "100%" }}>
        <TableHead className="tableStickyHeader">
          <TableRow>
            {Object.values(headers).map((header, index) => (
              <TableData key={index} cellType={"headerCell"} size={"regular"}>
                {header}
              </TableData>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              style={{
                borderBottom: "1px solid var(--T---LT-Gray, #ECEEF1)",
                height: "75px",
              }}
              className="driver-table"
            >
              {Object.keys(row).map((key, cellIndex) => (
                <TableData key={cellIndex} cellType={"bodyCell"} size={"large"}>
                  {Array.isArray(row[key]) ? (
                    <div style={{ display: "flex", gap: "8px" }}>
                      {row[key].map((expiration, i) => (
                        <Expirations
                          key={i}
                          iconPosition="left"
                          imageType={expiration.type}
                        >
                          {expiration.type
                            ? `${expiration.duration}`
                            : expiration.duration}
                        </Expirations>
                      ))}
                    </div>
                  ) : (
                    row[key]
                  )}
                </TableData>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DriversTable;
