import React, { useState } from "react";
import "./ToggleInput.scss"; // Use a common SCSS file for styling
import PropTypes from "prop-types";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useSSR } from "react-i18next";
const ToggleInput = ({
  fieldName,
  fieldValue,
  inputType,
  onChange,
  onBlur,
  style,
  placeholder,
  readOnly,
  autoComplete,
  zIndex,
  background,
  label,
  onToggleParent,
}) => {
  const [toggleValue, setToggleValue] = useState(Boolean);
  const handleToggleValue = (value) => {
    console.log("toggleValue", value);
    setToggleValue(value);
    onToggleParent(value);
  };
  console.log("toggleValue2", toggleValue);
  return (
    <>
      {" "}
      <div className="toggleFieldConatiner" style={style}>
        <p className="toggleFieldName">{fieldName}</p>
        <div className="labelToggleContainer">
          <div className="toggleLabel">{toggleValue ? "Yes" : "No"}</div>
          <ToggleSwitch onToggle={handleToggleValue}></ToggleSwitch>
        </div>
      </div>
    </>
  );
};

export default ToggleInput;
