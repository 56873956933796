import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import closeAddDriver from "../../../../../../assets/images/addDriver/closeAddDiver.svg";
import "./AddVehicle.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
import { HashSpinner } from "app/views/commonComponents/Spinners/Spinners";
import {
  addVehicleData,
  getVehiclesTypes,
  addVehiclesTypes,
  addVinNumber,
} from "../../../../../apiServices/apiServices";
const AddVehicle = ({ onClose, onVehicleAdd }) => {
  const [vehicleId, setVehicleId] = useState("");
  const [vinNumber, setVINNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [year, setYear] = useState();
  const [licencePlateNo, setLicencePlateNo] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [colour, setColour] = useState("");
  const [hasBlurred, setHasBlurred] = useState(false);
  const [makeAuto, setMakeAuto] = useState("");
  const [modelAuto, setModelAuto] = useState("");
  const [typeAuto, setTypeAuto] = useState("");
  const [yearAuto, setYearAuto] = useState();
  const [fetchDetails, setFetchDetails] = useState(false);
  const [validVINNumber, setValidVINNumber] = useState(false);
  const [validVehicleId, setValidVehicleId] = useState(false);
  const [validLicencePlateNo, setValidLicencePlateNo] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelectedValue, setDropdownSelectedValue] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedState, setSelectedState] = useState();
  const [enteredMake, setEnteredMake] = useState("");
  const [enteredModel, setEnteredModel] = useState("");
  const [enteredType, setEnteredType] = useState("");
  const [enteredColor, setEnteredColor] = useState("");
  const [enteredState, setEnteredState] = useState("");
  const [enteredYear, setEnteredYear] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMake, setShowMake] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVehicleAdded, setIsVehicleAdded] = useState(false);
  const yearList = [
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];
  const fordModels = ["F-150", "Mustang", "Explorer", "Escape", "Focus"];
  const chevroletModels = ["Silverado", "Malibu", "Equinox", "Tahoe", "Camaro"];
  const dodgeModels = [
    "Challenger",
    "Charger",
    "Durango",
    "Grand Caravan",
    "Journey",
  ];
  const jeepModels = [
    "Wrangler",
    "Grand Cherokee",
    "Cherokee",
    "Compass",
    "Renegade",
  ];
  const chryslerModels = ["300", "Pacifica"];
  const gmcModels = ["Sierra", "Yukon", "Acadia", "Terrain", "Canyon"];
  const teslaModels = ["Model S", "Model 3", "Model X", "Model Y"];
  const cadillacModels = ["Escalade", "XT5", "CT4", "CT5"];
  const buickModels = ["Encore", "Enclave", "Regal", "Envision"];
  const lincolnModels = ["Navigator", "Aviator", "Continental", "Nautilus"];
  const ramModels = ["1500", "2500", "ProMaster"];
  const toyotaModels = ["Camry", "Corolla", "RAV4", "Tacoma", "Highlander"];
  const hondaModels = ["Civic", "Accord", "CR-V", "Pilot", "Odyssey"];
  const nissanModels = ["Altima", "Sentra", "Rogue", "Pathfinder", "Frontier"];
  const subaruModels = [
    "Outback",
    "Forester",
    "Impreza",
    "Legacy",
    "Crosstrek",
  ];
  const volkswagenModels = ["Jetta", "Passat", "Tiguan", "Atlas", "Golf"];
  const bmwModels = ["3 Series", "5 Series", "X3", "X5", "7 Series"];
  const mercedesModels = ["C-Class", "E-Class", "GLE", "GLC", "S-Class"];
  const audiModels = ["A4", "A6", "Q5", "Q7", "A8"];
  const hyundaiModels = ["Sonata", "Elantra", "Tucson", "Santa Fe", "Palisade"];
  const kiaModels = ["Optima", "Sorento", "Sportage", "Soul", "Telluride"];
  const lexusModels = ["ES", "RX", "IS", "NX", "GX"];
  const infinitiModels = ["Q50", "QX60", "QX80", "QX50"];
  const acuraModels = ["TLX", "MDX", "RDX", "ILX"];
  const mazdaModels = ["Mazda3", "Mazda6", "CX-5", "CX-9"];
  const volvoModels = ["S60", "XC60", "XC90", "V60"];
  const porscheModels = ["911", "Cayenne", "Macan", "Panamera"];
  const jaguarModels = ["XE", "XF", "F-Pace", "E-Pace"];
  const landRoverModels = [
    "Range Rover",
    "Discovery",
    "Defender",
    "Range Rover Sport",
  ];
  const miniModels = ["Cooper", "Countryman", "Clubman"];
  const mitsubishiModels = ["Outlander", "Eclipse Cross", "Mirage"];
  const fiatModels = ["500", "500X", "500L"];
  const fordColors = [
    "Oxford White",
    "Shadow Black",
    "Magnetic Metallic",
    "Ingot Silver",
    "Race Red",
  ];
  const chevroletColors = [
    "Summit White",
    "Black",
    "Silver Ice Metallic",
    "Satin Steel Metallic",
    "Red Hot",
  ];
  const dodgeColors = [
    "White Knuckle Clearcoat",
    "Pitch Black Clearcoat",
    "Granite Crystal Metallic Clearcoat",
    "Billet Clearcoat",
    "TorRed Clearcoat",
  ];
  const jeepColors = [
    "Bright White Clearcoat",
    "Black Clearcoat",
    "Granite Crystal Metallic Clearcoat",
    "Billet Silver Metallic Clearcoat",
    "Firecracker Red Clearcoat",
  ];
  const chryslerColors = [
    "Bright White Clearcoat",
    "Gloss Black",
    "Velvet Red Pearlcoat",
    "Ceramic Grey Clearcoat",
    "Ocean Blue Metallic Clearcoat",
  ];
  const gmcColors = [
    "Summit White",
    "Onyx Black",
    "Quicksilver Metallic",
    "Carbon Black Metallic",
    "Cayenne Red Tintcoat",
  ];
  const teslaColors = [
    "Solid Black",
    "Midnight Silver Metallic",
    "Deep Blue Metallic",
    "Pearl White Multi-Coat",
    "Red Multi-Coat",
  ];
  const cadillacColors = [
    "Crystal White Tricoat",
    "Black Raven",
    "Radiant Silver Metallic",
    "Stellar Black Metallic",
    "Red Passion Tintcoat",
  ];
  const buickColors = [
    "Summit White",
    "Ebony Twilight Metallic",
    "Quicksilver Metallic",
    "White Frost Tricoat",
    "Satin Steel Metallic",
  ];
  const lincolnColors = [
    "Infinite Black Metallic",
    "White Platinum Metallic Tri-Coat",
    "Ingot Silver Metallic",
    "Burgundy Velvet Metallic Tinted Clearcoat",
    "Rhapsody Blue",
  ];
  const ramColors = [
    "Bright White Clearcoat",
    "Diamond Black Crystal Pearlcoat",
    "Maximum Steel Metallic Clearcoat",
    "Billet Silver Metallic Clearcoat",
    "Granite Crystal Metallic Clearcoat",
  ];
  const toyotaColors = [
    "Super White",
    "Midnight Black Metallic",
    "Celestial Silver Metallic",
    "Blizzard Pearl",
    "Ruby Flare Pearl",
  ];
  const hondaColors = [
    "Platinum White Pearl",
    "Crystal Black Pearl",
    "Lunar Silver Metallic",
    "Modern Steel Metallic",
    "Radiant Red Metallic",
  ];
  const nissanColors = [
    "Glacier White",
    "Super Black",
    "Gun Metallic",
    "Brilliant Silver Metallic",
    "Scarlet Ember Tintcoat",
  ];
  const subaruColors = [
    "Crystal White Pearl",
    "Crystal Black Silica",
    "Ice Silver Metallic",
    "Magnetite Gray Metallic",
    "Crimson Red Pearl",
  ];
  const volkswagenColors = [
    "Pure White",
    "Deep Black Pearl",
    "Platinum Gray Metallic",
    "Silk Blue Metallic",
    "Habanero Orange Metallic",
  ];
  const bmwColors = [
    "Alpine White",
    "Jet Black",
    "Mineral White Metallic",
    "Black Sapphire Metallic",
    "Melbourne Red Metallic",
  ];
  const mercedesColors = [
    "Polar White",
    "Obsidian Black Metallic",
    "Selenite Grey Metallic",
    "Iridium Silver Metallic",
    "Lunar Blue Metallic",
  ];
  const audiColors = [
    "Ibis White",
    "Mythos Black Metallic",
    "Glacier White Metallic",
    "Florett Silver Metallic",
    "Tango Red Metallic",
  ];
  const hyundaiColors = [
    "Polar White",
    "Phantom Black",
    "Symphony Silver",
    "Stellar Silver",
    "Scarlet Red",
  ];
  const kiaColors = [
    "Snow White Pearl",
    "Aurora Black Pearl",
    "Gravity Grey",
    "Everlasting Silver",
    "Passion Red",
  ];
  const lexusColors = [
    "Eminent White Pearl",
    "Obsidian",
    "Caviar",
    "Atomic Silver",
    "Nebula Gray Pearl",
  ];
  const infinitiColors = [
    "Pure White",
    "Graphite Shadow",
    "Liquid Platinum",
    "Hermosa Blue",
    "Dynamic Sunstone Red",
  ];
  const acuraColors = [
    "Platinum White Pearl",
    "Modern Steel Metallic",
    "Majestic Black Pearl",
    "Performance Red Pearl",
    "Apex Blue Pearl",
  ];
  const mazdaColors = [
    "Snowflake White Pearl Mica",
    "Jet Black Mica",
    "Machine Gray Metallic",
    "Soul Red Crystal Metallic",
    "Deep Crystal Blue Mica",
  ];
  const volvoColors = [
    "Ice White",
    "Black Stone",
    "Onyx Black Metallic",
    "Osmium Grey Metallic",
    "Fusion Red Metallic",
  ];
  const porscheColors = [
    "White",
    "Black",
    "Guards Red",
    "Carrara White Metallic",
    "Night Blue Metallic",
  ];
  const jaguarColors = [
    "Yulong White Metallic",
    "Santorini Black Metallic",
    "Fuji White",
    "Corris Grey Metallic",
    "Firenze Red Metallic",
  ];
  const landRoverColors = [
    "Fuji White",
    "Santorini Black Metallic",
    "Firenze Red Metallic",
    "Corris Grey Metallic",
    "Loire Blue Metallic",
  ];
  const miniColors = [
    "Pepper White",
    "Midnight Black Metallic",
    "Melting Silver Metallic",
    "Electric Blue Metallic",
    "Chili Red",
  ];
  const mitsubishiColors = [
    "Pearl White",
    "Labrador Black Metallic",
    "Mercury Gray Metallic",
    "Alloy Silver Metallic",
    "Red Diamond",
  ];
  const fiatColors = [
    "Bianco White Ice",
    "Nero Puro (Straight Black)",
    "Rosso (Red)",
    "Grigio (Gray)",
    "Bianco Gelato (White Clear Coat)",
  ];
  const statesRegistrationList = [
    "AL - Alabama",
    "AK - Alaska",
    "AZ - Arizona",
    "AR - Arkansas",
    "CA - California",
    "CO - Colorado",
    "CT - Connecticut",
    "DE - Delaware",
    "FL - Florida",
    "GA - Georgia",
    "HI - Hawaii",
    "ID - Idaho",
    "IL - Illinois",
    "IN - Indiana",
    "IA - Iowa",
    "KS - Kansas",
    "KY - Kentucky",
    "LA - Louisiana",
    "ME - Maine",
    "MD - Maryland",
    "MA - Massachusetts",
    "MI - Michigan",
    "MN - Minnesota",
    "MS - Mississippi",
    "MO - Missouri",
    "MT - Montana",
    "NE - Nebraska",
    "NV - Nevada",
    "NH - New Hampshire",
    "NJ - New Jersey",
    "NM - New Mexico",
    "NY - New York",
    "NC - North Carolina",
    "ND - North Dakota",
    "OH - Ohio",
    "OK - Oklahoma",
    "OR - Oregon",
    "PA - Pennsylvania",
    "RI - Rhode Island",
    "SC - South Carolina",
    "SD - South Dakota",
    "TN - Tennessee",
    "TX - Texas",
    "UT - Utah",
    "VT - Vermont",
    "VA - Virginia",
    "WA - Washington",
    "WV - West Virginia",
    "WI - Wisconsin",
    "WY - Wyoming",
  ];

  const fordVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const chevroletVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const dodgeVehicleTypes = ["Sedan", "SUV", "Truck", "Van"];
  const jeepVehicleTypes = ["SUV"];
  const chryslerVehicleTypes = ["Sedan", "Van"];
  const gmcVehicleTypes = ["Truck", "SUV", "Van"];
  const teslaVehicleTypes = ["Sedan", "SUV"];
  const cadillacVehicleTypes = ["Sedan", "SUV"];
  const buickVehicleTypes = ["Sedan", "SUV"];
  const lincolnVehicleTypes = ["Sedan", "SUV"];
  const ramVehicleTypes = ["Truck", "Van"];
  const toyotaVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const hondaVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const nissanVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const subaruVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const volkswagenVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const bmwVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const mercedesVehicleTypes = ["Sedan", "SUV"];
  const audiVehicleTypes = ["Sedan", "SUV"];
  const hyundaiVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const kiaVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const lexusVehicleTypes = ["Sedan", "SUV"];
  const infinitiVehicleTypes = ["Sedan", "SUV"];
  const acuraVehicleTypes = ["Sedan", "SUV"];
  const mazdaVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const volvoVehicleTypes = ["Sedan", "SUV"];
  const porscheVehicleTypes = ["Sedan", "SUV"];
  const jaguarVehicleTypes = ["Sedan", "SUV"];
  const landRoverVehicleTypes = ["SUV"];
  const miniVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const mitsubishiVehicleTypes = ["Sedan", "SUV"];
  const fiatVehicleTypes = ["Sedan", "Hatchback"];

  const makeList = [
    {
      name: "Ford",
      models: fordModels,
      types: fordVehicleTypes,
      colors: fordColors,
    },
    {
      name: "Chevrolet",
      models: chevroletModels,
      types: chevroletVehicleTypes,
      colors: chevroletColors,
    },
    {
      name: "Dodge",
      models: dodgeModels,
      types: dodgeVehicleTypes,
      colors: dodgeColors,
    },
    {
      name: "Jeep",
      models: jeepModels,
      types: jeepVehicleTypes,
      colors: jeepColors,
    },
    {
      name: "Chrysler",
      models: chryslerModels,
      types: chryslerVehicleTypes,
      colors: chryslerColors,
    },
    {
      name: "GMC",
      models: gmcModels,
      types: gmcVehicleTypes,
      colors: gmcColors,
    },
    {
      name: "Tesla",
      models: teslaModels,
      types: teslaVehicleTypes,
      colors: teslaColors,
    },
    {
      name: "Cadillac",
      models: cadillacModels,
      types: cadillacVehicleTypes,
      colors: cadillacColors,
    },
    {
      name: "Buick",
      models: buickModels,
      types: buickVehicleTypes,
      colors: buickColors,
    },
    {
      name: "Lincoln",
      models: lincolnModels,
      types: lincolnVehicleTypes,
      colors: lincolnColors,
    },
    {
      name: "Ram",
      models: ramModels,
      types: ramVehicleTypes,
      colors: ramColors,
    },
    {
      name: "Toyota",
      models: toyotaModels,
      types: toyotaVehicleTypes,
      colors: toyotaColors,
    },
    {
      name: "Honda",
      models: hondaModels,
      types: hondaVehicleTypes,
      colors: hondaColors,
    },
    {
      name: "Nissan",
      models: nissanModels,
      types: nissanVehicleTypes,
      colors: nissanColors,
    },
    {
      name: "Subaru",
      models: subaruModels,
      types: subaruVehicleTypes,
      colors: subaruColors,
    },
    {
      name: "Volkswagen",
      models: volkswagenModels,
      types: volkswagenVehicleTypes,
      colors: volkswagenColors,
    },
    {
      name: "BMW",
      models: bmwModels,
      types: bmwVehicleTypes,
      colors: bmwColors,
    },
    {
      name: "Mercedes-Benz",
      models: mercedesModels,
      types: mercedesVehicleTypes,
      colors: mercedesColors,
    },
    {
      name: "Audi",
      models: audiModels,
      types: audiVehicleTypes,
      colors: audiColors,
    },
    {
      name: "Hyundai",
      models: hyundaiModels,
      types: hyundaiVehicleTypes,
      colors: hyundaiColors,
    },
    {
      name: "Kia",
      models: kiaModels,
      types: kiaVehicleTypes,
      colors: kiaColors,
    },
    {
      name: "Lexus",
      models: lexusModels,
      types: lexusVehicleTypes,
      colors: lexusColors,
    },
    {
      name: "Infiniti",
      models: infinitiModels,
      types: infinitiVehicleTypes,
      colors: infinitiColors,
    },
    {
      name: "Acura",
      models: acuraModels,
      types: acuraVehicleTypes,
      colors: acuraColors,
    },
    {
      name: "Mazda",
      models: mazdaModels,
      types: mazdaVehicleTypes,
      colors: mazdaColors,
    },
    {
      name: "Volvo",
      models: volvoModels,
      types: volvoVehicleTypes,
      colors: volvoColors,
    },
    {
      name: "Porsche",
      models: porscheModels,
      types: porscheVehicleTypes,
      colors: porscheColors,
    },
    {
      name: "Jaguar",
      models: jaguarModels,
      types: jaguarVehicleTypes,
      colors: jaguarColors,
    },
    {
      name: "Land Rover",
      models: landRoverModels,
      types: landRoverVehicleTypes,
      colors: landRoverColors,
    },
    {
      name: "Mini",
      models: miniModels,
      types: miniVehicleTypes,
      colors: miniColors,
    },
    {
      name: "Mitsubishi",
      models: mitsubishiModels,
      types: mitsubishiVehicleTypes,
      colors: mitsubishiColors,
    },
    {
      name: "Fiat",
      models: fiatModels,
      types: fiatVehicleTypes,
      colors: fiatColors,
    },
  ];
  const allModels = [
    "F-150",
    "Mustang",
    "Explorer",
    "Escape",
    "Focus",
    "Silverado",
    "Malibu",
    "Equinox",
    "Tahoe",
    "Camaro",
    "Challenger",
    "Charger",
    "Durango",
    "Grand Caravan",
    "Journey",
    "Wrangler",
    "Grand Cherokee",
    "Cherokee",
    "Compass",
    "Renegade",
    "300",
    "Pacifica",
    "Sierra",
    "Yukon",
    "Acadia",
    "Terrain",
    "Canyon",
    "Model S",
    "Model 3",
    "Model X",
    "Model Y",
    "Escalade",
    "XT5",
    "CT4",
    "CT5",
    "Encore",
    "Enclave",
    "Regal",
    "Envision",
    "Navigator",
    "Aviator",
    "Continental",
    "Nautilus",
    "1500",
    "2500",
    "ProMaster",
    "Camry",
    "Corolla",
    "RAV4",
    "Tacoma",
    "Highlander",
    "Civic",
    "Accord",
    "CR-V",
    "Pilot",
    "Odyssey",
    "Altima",
    "Sentra",
    "Rogue",
    "Pathfinder",
    "Frontier",
    "Outback",
    "Forester",
    "Impreza",
    "Legacy",
    "Crosstrek",
    "Jetta",
    "Passat",
    "Tiguan",
    "Atlas",
    "Golf",
    "3 Series",
    "5 Series",
    "X3",
    "X5",
    "7 Series",
    "C-Class",
    "E-Class",
    "GLE",
    "GLC",
    "S-Class",
    "A4",
    "A6",
    "Q5",
    "Q7",
    "A8",
    "Sonata",
    "Elantra",
    "Tucson",
    "Santa Fe",
    "Palisade",
    "Optima",
    "Sorento",
    "Sportage",
    "Soul",
    "Telluride",
    "ES",
    "RX",
    "IS",
    "NX",
    "GX",
    "Q50",
    "QX60",
    "QX80",
    "QX50",
    "TLX",
    "MDX",
    "RDX",
    "ILX",
    "Mazda3",
    "Mazda6",
    "CX-5",
    "CX-9",
    "S60",
    "XC60",
    "XC90",
    "V60",
    "911",
    "Cayenne",
    "Macan",
    "Panamera",
    "XE",
    "XF",
    "F-Pace",
    "E-Pace",
    "Range Rover",
    "Discovery",
    "Defender",
    "Range Rover Sport",
    "Cooper",
    "Countryman",
    "Clubman",
    "Outlander",
    "Eclipse Cross",
    "Mirage",
    "500",
    "500X",
    "500L",
  ];
  const vehicleTypes = [
    ...fordVehicleTypes,
    ...chevroletVehicleTypes,
    ...dodgeVehicleTypes,
    ...jeepVehicleTypes,
    ...chryslerVehicleTypes,
    ...gmcVehicleTypes,
    ...teslaVehicleTypes,
    ...cadillacVehicleTypes,
    ...buickVehicleTypes,
    ...lincolnVehicleTypes,
    ...ramVehicleTypes,
    ...toyotaVehicleTypes,
    ...hondaVehicleTypes,
    ...nissanVehicleTypes,
    ...subaruVehicleTypes,
    ...volkswagenVehicleTypes,
    ...bmwVehicleTypes,
    ...mercedesVehicleTypes,
    ...audiVehicleTypes,
    ...hyundaiVehicleTypes,
    ...kiaVehicleTypes,
    ...lexusVehicleTypes,
    ...infinitiVehicleTypes,
    ...acuraVehicleTypes,
    ...mazdaVehicleTypes,
    ...volvoVehicleTypes,
    ...porscheVehicleTypes,
    ...jaguarVehicleTypes,
    ...landRoverVehicleTypes,
    ...miniVehicleTypes,
    ...mitsubishiVehicleTypes,
    ...fiatVehicleTypes,
  ];
  const allVehicleTypes = [...new Set(vehicleTypes)];
  console.log("allVehicleTypes", allVehicleTypes);
  const allColors = [
    ...fordColors,
    ...chevroletColors,
    ...dodgeColors,
    ...jeepColors,
    ...chryslerColors,
    ...gmcColors,
    ...teslaColors,
    ...cadillacColors,
    ...buickColors,
    ...lincolnColors,
    ...ramColors,
    ...toyotaColors,
    ...hondaColors,
    ...nissanColors,
    ...subaruColors,
    ...volkswagenColors,
    ...bmwColors,
    ...mercedesColors,
    ...audiColors,
    ...hyundaiColors,
    ...kiaColors,
    ...lexusColors,
    ...infinitiColors,
    ...acuraColors,
    ...mazdaColors,
    ...volvoColors,
    ...porscheColors,
    ...jaguarColors,
    ...landRoverColors,
    ...miniColors,
    ...mitsubishiColors,
    ...fiatColors,
  ];
  const handleVINNumberChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    const trimmedValue = inputValue.slice(0, 17);
    const validVINNumber = trimmedValue.length === 17;
    setValidVINNumber(validVINNumber);
    const regex = /^[0-9A-Z]{0,17}$/;
    if (regex.test(inputValue)) {
      setVINNumber(inputValue);
    }
  };
  const handleMakeChange = (e) => {
    const inputValue = e.target.value; // No need to trim spaces here
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space

    // Regular expression to match only alphabetic characters and spaces
    const regex = /^[A-Za-z-\s]*$/;

    if (regex.test(inputValue)) {
      setMake(formattedValue);
    }
  };
  const handleModelChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setModel(formattedValue);
    }
  };
  const handleTypeChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setType(formattedValue);
    }
  };
  const handleColorChage = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setColour(formattedValue);
    }
  };
  const handleVehicleIdChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    const trimmedValue = inputValue.slice(0, 8); // Limit to 5 digits
    const validVehicleId = trimmedValue.length === 8; // Check if it contains exactly 10 digits
    //setValidVehicleId(validVehicleId);
    const regex = /^[0-9A-Z]{0,8}$/;
    if (regex.test(inputValue)) {
      setVehicleId(inputValue);
    }
  };
  const handleLicencePlateNoChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    // const trimmedValue = inputValue.slice(0, 15); // Limit to 5 digits
    // const validVehicleId = trimmedValue.length === 15; // Check if it contains exactly 10 digits
    //setValidVehicleId(validVehicleId);
    const regex = /^[0-9A-Z-]{0,9}$/;
    if (regex.test(inputValue)) {
      setLicencePlateNo(inputValue);
    }
  };
  const handleStateRegistrationChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z-\s]*$/;
    if (regex.test(formattedValue)) {
      setStateRegistration(formattedValue);
    }
  };
  const addVehicleDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    const vehicleData = {
      vehicle_id: vehicleId,
      vin_number: vinNumber,
      make: makeAuto ? formattedMakeAuto : selectedMake || enteredMake,
      type: typeAuto ? formattedTypeAuto : selectedType || enteredType,
      model: modelAuto ? formattedModelAuto : selectedModel || enteredModel,
      year: yearAuto ? yearAuto : selectedYear,
      license_plate_number: licencePlateNo,
      state_registration: selectedState,
      colour: selectedColor || enteredColor,
    };
    // const vehicleData = {
    //   vehicle_id: vehicleId,
    //   vin_number: vinNumber,
    //   make: makeAuto ? formattedMakeAuto : make,
    //   type: typeAuto ? formattedTypeAuto : type,
    //   model: modelAuto ? formattedModelAuto : model,
    //   year: yearAuto ? yearAuto : year,
    //   license_plate_number: licencePlateNo,
    //   state_registration: stateRegistration,
    //   colour: colour,
    // };
    addVehicleData(vehicleData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setIsVehicleAdded(true);
          handleAddVehicleStatus(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
        }
        console.log("isVehicleAdded0", isVehicleAdded);
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleBlur = () => {
    setHasBlurred(true);
  };
  const handleFetchDetails = () => {
    addVinNumberAPI(vinNumber);
    isLoading ? setFetchDetails(false) : setFetchDetails(true);
  };
  useEffect(() => {
    getVehiclesTypesAPI();
  }, []);
  const addVinNumberAPI = (vinNumber) => {
    setIsLoading(true); // Set loading to true when fetching data
    const storedToken = localStorage.getItem("token");
    addVinNumber(vinNumber)
      .then((response) => {
        console.log("GetVINData1", response);
        setIsLoading(false);
        console.log("GetVINData2", response.data);
        setMakeAuto(response.data.make ? response.data.make : "");
        setModelAuto(response.data.model ? response.data.model : "");
        setTypeAuto(response.data.type ? response.data.type : "");
        setYearAuto(response.data.year ? response.data.year : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getVehiclesTypesAPI = () => {
    getVehiclesTypes()
      .then((response) => {
        console.log("getVehiclesTypes", response);
        console.log("getVehiclesTypes2", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const addVehiclesTypesAPI = () => {
    const vehicleTypeData = {
      make: enteredMake,
      model: enteredModel,
      type: enteredType,
      color: enteredColor,
    };
    addVehiclesTypes(vehicleTypeData)
      .then((response) => {
        console.log("getVehiclesTypes", response);
        console.log("getVehiclesTypes2", response.data);
        // setMakeAuto(response.data.make ? response.data.make : "");
        // setModelAuto(response.data.model ? response.data.model : "");
        // setTypeAuto(response.data.type ? response.data.type : "");
        // setYearAuto(response.data.year ? response.data.year : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  // Function to handle changes in showDropdown value
  const handleShowDropdownChange = (showDropdownValue) => {
    // Update the showDropdownParent value in the parent component
    setShowDropdown(showDropdownValue);
  };
  // Event handler for opening a dropdown
  const handleDropdownOpen = () => {
    setShowDropdown(true);
  };
  const handleYearSelection = (selectedYear) => {
    setSelectedYear(selectedYear);
    handleDropdownOpen();
  };
  const handleYearEntered = (value) => {
    // Handle the received value here
    console.log("Enteredyear1", value);
    setEnteredYear(value);
  };
  // Event handler for selecting a make
  const handleMakeSelection = (selectedMake) => {
    console.log("selectedMake", selectedMake);
    setSelectedMake(selectedMake != "" ? selectedMake : "");
    // Reset selected model, type, and color when a new make is selected
    setSelectedModel("");
    setSelectedType("");
    setSelectedColor("");
    handleDropdownOpen();
  };
  const handleMakeEntered = (value) => {
    // Handle the received value here
    console.log("EnteredMake1", value);
    setEnteredMake(value);
  };
  // Event handler for selecting a model
  const handleModelSelection = (selectedModel) => {
    //setShowMake(true);
    setSelectedModel(selectedModel);
    handleDropdownOpen();
  };
  const handleModelEntered = (value) => {
    console.log("EnteredModelValue");
    // Handle the received value here
    console.log("EnteredModel1", value);
    setEnteredModel(value);
  };
  // Event handler for selecting a type
  const handleTypeSelection = (selectedType) => {
    setSelectedType(selectedType);
    handleDropdownOpen();
  };
  const handleTypeEntered = (value) => {
    // Handle the received value here
    console.log("EnteredType1", value);
    setEnteredType(value);
  };
  // Event handler for selecting a color
  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };
  const handleColorEntered = (value) => {
    // Handle the received value here
    console.log("EnteredColor1", value);
    setEnteredColor(value);
  };
  const handleStateSelection = (selectedValue) => {
    setSelectedState(selectedValue);
  };
  const handleStateEntered = (value) => {
    // Handle the received value here
    console.log("EnteredState1", value);
    setEnteredState(value);
  };
  const handleAddVehicleStatus = (value) => {
    console.log("isDriverAdded1", value);
    onVehicleAdd(value);
  };
  console.log("isVehicleAdded2", isVehicleAdded);
  const formattedMakeAuto =
    makeAuto.charAt(0).toUpperCase() + makeAuto.slice(1).toLowerCase();
  const formattedModelAuto =
    modelAuto.charAt(0).toUpperCase() + modelAuto.slice(1).toLowerCase();
  const formattedTypeAuto =
    typeAuto.charAt(0).toUpperCase() + typeAuto.slice(1).toLowerCase();
  const formattedSelectedMake =
    makeAuto.charAt(0).toUpperCase() + selectedMake.slice(1).toLowerCase();
  const formattedSelectedModel =
    modelAuto.charAt(0).toUpperCase() + selectedModel.slice(1).toLowerCase();
  const formattedSelectedType =
    typeAuto.charAt(0).toUpperCase() + selectedType.slice(1).toLowerCase();
  console.log("vin1", makeAuto);
  console.log("vin2", modelAuto);
  console.log("vin3", typeAuto);
  console.log("vin4", yearAuto);
  console.log("selectedYear", selectedYear);
  console.log("EnteredYear2", enteredYear);
  console.log("EnteredMake2", enteredMake);
  console.log("EnteredModel2", enteredModel);
  console.log("EnteredType2", enteredType);
  console.log("EnteredColor2", enteredColor);
  console.log("EnteredState2", enteredState);
  return (
    <Card
      className={"addVehicleCard"}
      style={{ width: fetchDetails ? "65%" : "30%" }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <HashSpinner color={"#393A3D"} />
        </div>
      )}{" "}
      {/* Show loader when isLoading is true */}
      <CardBody>
        <div className="addVehicleContainer">
          <div className="addVehicleTitle">
            <div className="vinVehicleConatiner">
              {" "}
              <div className="addVehicleStyle">Add Vehicle</div>
              {fetchDetails && (
                <div className="vinNumberStyle">VIN: {vinNumber}</div>
              )}
            </div>
            <Icon
              size="regular"
              buttonType="secondary"
              image={closeAddDriver}
              onClick={onClose}
              imageReplace={true}
              hover={false}
            ></Icon>
          </div>
          {!fetchDetails && (
            <>
              <div className="inputSectionVINNumber">
                <div className="inputRowVehicle">
                  <AddInputField
                    fieldName="VIN Number"
                    fieldValue={vinNumber}
                    onChange={handleVINNumberChange}
                    onBlur={handleBlur}
                    inputType="text"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="submitButtonAddVehicle">
                <Buttons
                  size={"regular"}
                  dimensions={"normal"}
                  buttonType={"primary"}
                  disabled={validVINNumber == false && true}
                  onClick={handleFetchDetails}
                  imageReplace={true}
                >
                  Fetch Details
                </Buttons>
              </div>
            </>
          )}
          {fetchDetails && (
            <>
              <div className="inputSectionAddVehicle">
                <div className="inputRowVehicle" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <InputDropdown
                      fieldName="Year"
                      dropdownOptions={yearList}
                      initialValue={yearAuto || ""}
                      selectedValue={selectedYear || ""}
                      onValueSelection={handleYearSelection}
                      onValueEnter={handleYearEntered}
                      inputType="number"
                    />
                    {/* <AddInputField
                      fieldName="Year"
                      fieldValue={yearAuto ? yearAuto : year}
                      inputType="number"
                      onChange={(e) => setYear(e.target.value)}
                    /> */}
                  </div>
                  <div style={{ width: "100%" }}>
                    {/* Dropdown for selecting make */}
                    <InputDropdown
                      fieldName="Make"
                      dropdownOptions={makeList.map((make) => make.name)}
                      initialValue={formattedMakeAuto || ""}
                      selectedValue={formattedSelectedMake || enteredMake}
                      onValueSelection={handleMakeSelection}
                      onValueEnter={handleMakeEntered}
                      inputType="text"
                    />
                    {/* <AddInputField
                      fieldName="Make"
                      fieldValue={makeAuto ? formattedMakeAuto : make}
                      //onChange={(e) => setMake(e.target.value)}
                      onChange={handleMakeChange}
                      style={{ width: "100%" }}
                    /> */}
                  </div>
                  <div style={{ width: "100%" }}>
                    {/* Dropdown for selecting model */}
                    <InputDropdown
                      fieldName="Model"
                      dropdownOptions={
                        selectedMake != ""
                          ? makeList.find((make) => make.name === selectedMake)
                              ?.models
                          : allModels.sort()
                      }
                      initialValue={formattedModelAuto || ""}
                      selectedValue={formattedSelectedModel || enteredModel}
                      onValueSelection={handleModelSelection}
                      onValueEnter={handleModelEntered}
                      inputType="textNumber"
                    />
                    {/* <AddInputField
                      fieldName="Model"
                      fieldValue={modelAuto ? formattedModelAuto : model}
                      //onChange={(e) => setModel(e.target.value)}
                      onChange={handleModelChage}
                      style={{ width: "100%" }}
                    /> */}
                  </div>
                  <div style={{ width: "100%" }}>
                    {/* Dropdown for selecting type */}
                    <InputDropdown
                      fieldName="Type"
                      dropdownOptions={
                        selectedMake != ""
                          ? makeList.find((make) => make.name === selectedMake)
                              .types
                          : allVehicleTypes.sort()
                      }
                      initialValue={formattedTypeAuto || ""}
                      selectedValue={formattedSelectedType || enteredType}
                      onValueSelection={handleTypeSelection}
                      onValueEnter={handleTypeEntered}
                      inputType="textNumber"
                    />
                    {/* <AddInputField
                      fieldName="Type"
                      fieldValue={typeAuto ? formattedTypeAuto : type}
                      // onChange={(e) => setType(e.target.value)}
                      onChange={handleTypeChage}
                      style={{ width: "100%" }}
                    /> */}
                  </div>
                </div>
                <div className="inputRowVehicle" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    {/* Dropdown for selecting color */}
                    <InputDropdown
                      fieldName="Color"
                      dropdownOptions={
                        selectedMake
                          ? makeList.find((make) => make.name === selectedMake)
                              .colors
                          : allColors.sort()
                      }
                      initialValue={""}
                      selectedValue={selectedColor || enteredColor}
                      onValueSelection={handleColorSelection}
                      onValueEnter={handleColorEntered}
                      inputType="text"
                    />
                    {/* <AddInputField
                      fieldName="Color"
                      fieldValue={colour}
                      // onChange={(e) => setColour(e.target.value)}
                      onChange={handleColorChage}
                    /> */}
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputDropdown
                      fieldName="State Registration"
                      dropdownOptions={statesRegistrationList}
                      initialValue={""}
                      selectedValue={selectedState || ""}
                      onValueSelection={handleStateSelection}
                      onValueEnter={handleStateEntered}
                      inputType="text"
                    />
                    {/* <AddInputField
                      fieldName="State Registration"
                      fieldValue={stateRegistration}
                      onChange={handleStateRegistrationChange}
                    /> */}
                  </div>
                  <div style={{ width: "100%" }}>
                    <AddInputField
                      fieldName="License Plate No."
                      fieldValue={licencePlateNo}
                      onChange={handleLicencePlateNoChange}
                      inputType="text"
                      //zIndex={showDropdown == true && "-1"}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <AddInputField
                      fieldName="Vehicle Id"
                      fieldValue={vehicleId}
                      onChange={handleVehicleIdChange}
                      inputType="text"
                      //zIndex={showDropdown == true && "-1"}
                    />
                  </div>
                </div>
              </div>
              <div className="submitButtonVehicle">
                <Buttons
                  size={"small"}
                  dimensions={"normal"}
                  buttonType={"primary"}
                  disabled={
                    (makeAuto === "" &&
                      selectedMake === "" &&
                      enteredMake == "") ||
                    (modelAuto === "" &&
                      selectedModel === "" &&
                      enteredModel == "") ||
                    (typeAuto === "" &&
                      selectedType === "" &&
                      enteredType == "") ||
                    (yearAuto === "" &&
                      selectedYear === "" &&
                      enteredYear == "") ||
                    vehicleId == "" ||
                    //licencePlateNo == "" ||
                    (selectedState == "" && enteredState == "") ||
                    (selectedColor == "" && enteredColor == "")
                  }
                  onClick={() => {
                    addVehicleDataAPI();
                    addVehiclesTypesAPI();
                    onClose();
                  }}
                  imageReplace={true}
                >
                  Submit
                </Buttons>
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default AddVehicle;
