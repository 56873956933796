import React from "react";
import "./FieldNameValue.scss"; // Use a common SCSS file for styling
const FieldNameValue = ({ fieldName, fileldValue, image }) => {
  return (
    <>
      {" "}
      <div className="fieldConatiner">
        {" "}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <p className="fileldValue">{fileldValue}</p> <img src={image} />
        </div>
        <p className="fieldName">{fieldName}</p>
      </div>
    </>
  );
};

export default FieldNameValue;
