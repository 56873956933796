import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import { Modal } from "@material-ui/core";
import "./DriversDetailsPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import truck from "../../../../../../assets/images/driverDetails/truck.svg";
import assignVehicleWhite from "assets/images/poc/assignVehicleWhite.svg";
import assignVehicleBlack from "assets/images/poc/assignVehicleBlack.svg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import Labels from "app/views/commonComponents/Labels/Labels";
import Profile from "../../../DriverDetails/ProfileDetails/Profile/Profile";
import Credentials from "../../../DriverDetails/CredentialDetails/Credentials/Credentials";
import backRightArrow from "../../../../../../assets/images/poc/backRightArrow.svg";
import ProfilePOC from "../../ProfilePOC/ProfilePOC";
import { Link } from "react-router-dom";
import QuickActionsDropdown from "app/views/commonComponents/QuickActionsDropdown/QuickActionsDropdown";
import DriversInformationPOC from "../DriversInformationPOC/DriversInformationPOC";
import searchIcon from "../../../../../../assets/images/poc/searchIcon.svg";
import unassignVehicle from "../../../../../../assets/images/poc/unassignVehicle.svg";
import TablePOC from "../../TablePOC/TablePOC";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import DriversLicensePOC from "../DriversLicensePOC/DriversLicensePOC";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import grossRevenueGradient from "../../../../../../assets/images/poc/grossRevenueGradient.svg";
import grossMarginGradient from "../../../../../../assets/images/poc/grossMarginGradient.svg";
import rpmGradient from "../../../../../../assets/images/poc/rpmGradient.svg";
import driverPaymentsGradient from "../../../../../../assets/images/poc/driverPaymentsGradient.svg";
import milesLoadedGradient from "../../../../../../assets/images/poc/milesLoadedGradient.svg";
import loadsDeliveredGradient from "../../../../../../assets/images/poc/loadsDeliveredGradient.svg";
import toastIcon from "../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getDriverDetailsHeaderKPIData,
  getDriverDetailsProfileData,
  getDriverDetailsLoadsData,
  getDriverDetailsSettlementsData,
} from "../../../../../apiServices/apiServices";
const DriverDetailsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [calendarTab, setCalendarTab] = useState(0);
  const [activeCalendarTab, setActiveCalendarTab] = useState(0);
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [driverLoadsOriginalData, setDriverLoadsOriginalData] = useState([]);
  const [driverSettlementsOriginalData, setDriverSettlementsOriginalData] =
    useState([]);
  const [driverVehicleOriginalData, setDriverVehicleOriginalData] = useState(
    []
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [fullName, setFullName] = useState("");
  const [preferrdName, setPreferredName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [planId, setPlanId] = useState("");
  const [email, setEmail] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [driverClassification, setDriverClassification] = useState("");
  const [initialHireDate, setInitialHireDate] = useState("");
  const [suffix, setSuffix] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [drivingLicense, setDrivingLicense] = useState([]);
  const [showAssignTruck, setShowAssignTruck] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [assignCheck, setAssignCheck] = useState(false);
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("Jan 2024");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vehicleEndDate, setVehicleEndDate] = useState("");
  const { driverId, assignedTruck } = useParams();
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const driverData = [
    {
      icon: grossRevenueGradient,
      values: `$${Math.round(grossRevenue).toLocaleString("en-US")}`,
      title: "Gross Revenue",
    },
    {
      icon: grossMarginGradient,
      values: `$${Math.round(grossMargin).toLocaleString("en-US")}`,
      title: "Gross Margin",
    },
    {
      icon: driverPaymentsGradient,
      values: `$${Math.round(driverPayments).toLocaleString("en-US")}`,
      title: "Driver Payments",
    },

    {
      icon: rpmGradient,
      values: `$${headerRPM.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      title: "RPM",
    },
    {
      icon: milesLoadedGradient,
      values: milesLoaded.toLocaleString("en-US"),
      title: "Loaded Miles",
    },
    {
      icon: loadsDeliveredGradient,
      values: loadsDelivered.toLocaleString("en-US"),
      title: "Delivered Loads",
    },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const tabsData = [
    { id: 0, label: "Profile" },
    { id: 1, label: "Loads" },
    { id: 2, label: "Settlements" },
    // Add more tabs as needed
  ];
  const calendarTabsData = [
    { id: 0, label: "Month" },
    { id: 1, label: "Quarter" },
    { id: 2, label: "Year" },
    // Add more tabs as needed
  ];
  const vehicleTableHeaders = {
    vehicleNo: "Vehicle No.",
    dates: "Dates",
    make: "Make",
    type: "Type",
    model: "Model",
    year: "Year",
    grossMargin: "Gross Margin",
    rpm: "RPM",
    milesLoaded: "Loaded Miles",
    loadsDelivered: "Delivered Loads",
    action: "Action",
  };
  const vehicleTableData =
    driverVehicleOriginalData &&
    driverVehicleOriginalData.map((vehicle, index) => {
      const dateString = vehicle.start_date ? vehicle.start_date : "";
      let originalDate = moment.utc(dateString);
      const month1 = moment(originalDate).format("MMM");
      const day1 = moment(originalDate).format("DD");
      const year1 = moment(originalDate).year();
      //const formattedDate1 = `${month1} ${day1}, ${year1}`;
      const dateString2 = vehicle.end_date == null ? null : vehicle.end_date;
      let originalDate2 = moment.utc(dateString2);
      const month2 = moment(originalDate2).format("MMM");
      const day2 = moment(originalDate2).format("DD");
      const year2 = moment(originalDate2).year();
      //const formattedDate2 = `${month2} ${day2}, ${year2}`;
      let formattedDate = `${month1} ${day1}`;
      let formattedDate2 = `${month2} ${day2}`;
      let formattedDates = "";
      //Check if month1 is not equal to month2 and day1 is not equal to day2
      if (month1 !== month2 && day1 !== day2) {
        formattedDates = `${month1} ${day1}${
          vehicle.end_date ? `-${month2} ${day2}` : ""
        }, ${year1}`;
      } else {
        // Check if both dates have the same month and day
        if (month1 === month2 && day1 === day2) {
          formattedDates = `${formattedDate} - ${formattedDate2}, ${year1}`;
        } else {
          // Check if the years are different
          if (year1 !== year2) {
            formattedDates = `${formattedDate}, ${year1} ${
              vehicle.end_date ? `-${formattedDate2}, ${year2}` : ""
            }`;
          } else {
            // Adjust the format based on different cases
            if (formattedDate === formattedDate2) {
              formattedDates = `${formattedDate}, ${year1}`;
            } else if (day1 === day2) {
              formattedDates = `${formattedDate}${
                formattedDate2 ? `-${formattedDate2}` : ""
              }, ${year1}`;
            } else if (month1 === month2) {
              formattedDates = `${month1} ${day1}-${day2}, ${year1}`;
            }
          }
        }
      }
      return {
        vehicleNo: vehicle.vehicle_id ? vehicle.vehicle_id : "",
        dates: `${formattedDates} ${
          vehicle.end_date == null ? "- Present Day" : ""
        }`,
        // `${formattedDate1} ${vehicle.end_date ? "-" : ""} ${
        //   vehicle.end_date ? formattedDate2 : ""
        // }`,
        endDate: vehicle.end_date == null ? null : vehicle.end_date,
        make: vehicle.make ? vehicle.make : "",
        type: vehicle.type ? vehicle.type : "",
        model: vehicle.model ? vehicle.model : "",
        year: vehicle.year ? vehicle.year : "",
        grossMargin: vehicle.gross_margin
          ? `$${vehicle.gross_margin.toFixed(2).toLocaleString()}`
          : "$0.00",
        rpm: vehicle.rpm ? vehicle.rpm.toFixed(2).toLocaleString() : "$0.00",
        milesLoaded: vehicle.loaded_miles
          ? vehicle.loaded_miles.toLocaleString()
          : 0,
        loadsDelivered: vehicle.loads_delivered
          ? vehicle.loads_delivered.toLocaleString()
          : 0,
        action: index == 0 && vehicle.end_date == null ? "Unassign" : "", // You can replace this with the action if available in your data
        // driverId: driverId,
        // assignedTruck: assignedTruck,
      };
    });
  const loadsTableHeaders = {
    loadId: "Load No.",
    dates: "Dates",
    origin: "Origin",
    destination: "Destination",
    vehicle: "Vehicle",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    grossMargin: "Gross Margin",
    revenue: "Revenue",
  };
  const loadsTableData =
    driverLoadsOriginalData != null &&
    driverLoadsOriginalData.map((load, index) => {
      //const [year, month, day] = load.pu_date ? load.pu_date.split("-") : "";
      const originalDateString = load.pu_date && load.pu_date;
      let originalDate = moment.utc(originalDateString);
      const month = moment(originalDate).format("MMM");
      const day = moment(originalDate).date();
      const year = moment(originalDate).year();
      //const [year2, month2, day2] = load.del_date ? load.del_date.split("-") : "";

      const originalDateString2 = load.del_date && load.del_date;
      let originalDate2 = moment.utc(originalDateString2);
      const month2 = moment(originalDate2).format("MMM");
      const day2 = moment(originalDate2).date();
      const year2 = moment(originalDate2).year();
      let formattedDate = `${month} ${day}`;
      let formattedDate2 = `${month2} ${day2}`;
      let formattedDates = "";
      // Check if month1 is not equal to month2 and day1 is not equal to day2
      if (month !== month2 && day !== day2) {
        formattedDates = `${month} ${day} - ${month2} ${day2}, ${year}`;
      } else {
        // Check if both dates have the same month and day
        if (month === month2 && day === day2) {
          formattedDates = `${formattedDate}, ${year}`;
        } else {
          // Check if the years are different
          if (year !== year2) {
            formattedDates = `${formattedDate}, ${year}-${formattedDate2}, ${year2}`;
          } else {
            // Adjust the format based on different cases
            if (formattedDate === formattedDate2) {
              formattedDates = `${formattedDate}, ${year}`;
            } else if (day === day2) {
              formattedDates = `${formattedDate}${
                formattedDate2 ? `-${formattedDate2}` : ""
              }, ${year}`;
            } else if (month === month2) {
              formattedDates = `${month} ${day}-${day2}, ${year}`;
            }
          }
        }
      }
      const truncatedOrigin =
        load.pu_location && load.pu_location.length > 20
          ? load.pu_location.substring(0, 20) + "..."
          : load.pu_location;
      const truncateddestination =
        load.del_location && load.del_location.length > 20
          ? load.del_location.substring(0, 20) + "..."
          : load.del_location;
      return {
        loadId: load.load_id ? load.load_id : 0,
        dates: `${formattedDates}`,
        origin: truncatedOrigin,
        destination: truncateddestination,
        vehicleId: load.vehicle_id ? load.vehicle_id : 0,
        milesLoaded: load.loaded_miles ? load.loaded_miles.toLocaleString() : 0,
        rpm: load.rpm ? `$${load.rpm.toLocaleString()}` : "$0.00",
        grossMargin: load.gross_margin
          ? `$${load.gross_margin.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",

        revenue: load.load_revenue
          ? `$${load.load_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
      };
    });
  const settlementsTableHeaders = {
    datesDriver: "Dates",
    totalLoad: "Loads",

    loadedMiles: "Loaded Miles",
    rpm: "RPM",
    totalRevenue: "Load Revenue",
    totalOtherRevenue: "Other Revenue",
    totalDeduction: "Deductions",
    netPay: "Net Pay",
    status: "Status",
  };
  const settlementsTableData =
    driverSettlementsOriginalData != null &&
    driverSettlementsOriginalData.map((settlement) => {
      const dateString1 = settlement.statement_date
        ? settlement.statement_date
        : "";
      let originalDate = moment.utc(dateString1);
      const month1 = moment(originalDate).format("MMM");
      const day1 = moment(originalDate).format("DD");
      const year1 = moment(originalDate).year();
      const formattedDate1 = `${month1} ${day1}, ${year1}`;
      return {
        datesDriver: formattedDate1,
        totalLoad: settlement.total_loads
          ? settlement.total_loads.toLocaleString()
          : "0",

        loadedMiles: settlement.loaded_miles
          ? settlement.loaded_miles.toLocaleString()
          : "0",
        rpm: settlement.rpm ? `$${settlement.rpm.toLocaleString()}` : "$0.00",
        totalRevenue: settlement.total_load_revenue
          ? `$${settlement.total_load_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        totalOtherRevenue: `$${
          settlement.total_other_revenue
            ? settlement.total_other_revenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        } • ${
          settlement.total_other_revenue_count
            ? settlement.total_other_revenue_count.toLocaleString()
            : "0"
        }`,
        totalDeduction: `$${
          settlement.total_deductions
            ? settlement.total_deductions.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        } • ${
          settlement.total_deductions_count
            ? settlement.total_deductions_count.toLocaleString()
            : "0"
        }`,
        netPay: `$${
          settlement.driver_revenue
            ? settlement.driver_revenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "$0.00"
        }`,
        status: settlement.status
          ? capitalizeFirstLetter(settlement.status)
          : "-", // You can replace this with the status if available in your data
        uploadIdDriver: settlement.upload_id,
        driverId: driverId,
        assignedTruck: assignedTruck,
      };
    });
  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handlePageTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const handleTabChange = (tabId) => {
    setActiveCalendarTab(tabId);
  };
  const handleCalendarTabChange = (tabId) => {
    setCalendarTab(tabId);
  };

  // Function to handle month change
  const handleDateChange = (startDate, endDate) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    // Call getDetailsHeaderKPIDataAPI with final start and end dates
    getDriverDetailsHeaderKPIDataAPI(startDate, endDate);
  };
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 418; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  console.log("fullName1", fullName);
  console.log("state", city);
  console.log("state1", state);
  console.log("state2", zip);
  const informationSection = {
    component: (
      <DriversInformationPOC
        fullName={fullName}
        firstName={firstName}
        lastName={lastName}
        middleName={middleName}
        suffix={suffix ? suffix : ""}
        preferrdName={preferrdName}
        phoneNumber={phoneNumber}
        planId={planId}
        email={email}
        homeAddress={homeAddress}
        driverClassification={driverClassification}
        initialHireDate={initialHireDate}
        city={city ? city : ""}
        state={state ? state : ""}
        zip={zip ? zip : ""}
        dateOfBirth={dateOfBirth ? dateOfBirth : ""}
        //vehicleNo={vehicleNo}
        driverId={driverId ? driverId : ""}
        vehicleId={assignCheck && vehicleId ? vehicleId : ""}
        assignCheck={assignCheck ? assignCheck : false}
      />
    ),
  };
  useEffect(() => {
    handleReturnDriverLoadsPOC();
    handleReturnDriverSettelmentsPOC();
    if (
      activeCalendarTab == 0 ||
      activeCalendarTab == 1 ||
      activeCalendarTab == 2
    ) {
      getDriverDetailsHeaderKPIDataAPI();
    }
    if (activeTab == 0) {
      getDriverDetailsProfileDataAPI();
    }
    if (activeTab == 1) {
      getDriverDetailsLoadsDataAPI();
    }
    if (activeTab == 2) {
      getDriverDetailsSettlementsDataAPI();
    }
    // getDriverDetailsProfileDataAPI();
    // getDriverDetailsLoadsDataAPI();
    // getDriverDetailsSettlementsDataAPI();
  }, [activeCalendarTab, driverId, activeTab]);
  const getDriverDetailsHeaderKPIDataAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    let currentStartDate = null;
    let currentEndDate = null;

    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0

    switch (activeCalendarTab) {
      case 0: // Current month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case 1: // Current quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case 2: // Current year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    getDriverDetailsHeaderKPIData(finalStartDate, finalEndDate, driverId)
      .then((response) => {
        //console.log("insideif", response);
        console.log(
          "getDetailsHeaderKPIDataAPI",
          response.data ? response.data : ""
        );
        setGrossRevenue(
          response.data.gross_revenue ? response.data.gross_revenue : 0
        );
        setDriverPayments(
          response.data.driver_payments ? response.data.driver_payments : 0
        );
        setGrossMargin(
          response.data.gross_margin ? response.data.gross_margin : 0
        );
        setHeaderRPM(response.data.rpm ? response.data.rpm : 0);
        setMilesLoaded(
          response.data.miles_loaded ? response.data.miles_loaded : 0
        );
        setLoadsDelivered(
          response.data.loads_delivered ? response.data.loads_delivered : 0
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDriverDetailsProfileDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    console.log("ooo", storedToken);
    getDriverDetailsProfileData(driverId)
      .then((response) => {
        //console.log("getAllDriverProfileData", response.data.driverVehicles[0].end_date);
        setDriverVehicleOriginalData(
          response.data.driverVehicles ? response.data.driverVehicles : []
        );
        setVehicleId(
          response.data &&
            response.data.driverVehicles &&
            response.data.driverVehicles[0] &&
            response.data.driverVehicles[0].vehicle_id
            ? response.data.driverVehicles[0].vehicle_id
            : ""
        );
        setFullName(response.data.full_name ? response.data.full_name : "");
        setFirstName(response.data.firstName ? response.data.firstName : "");
        setLastName(response.data.lastName ? response.data.lastName : "");
        setMiddleName(response.data.middleName ? response.data.middleName : "");
        setSuffix(response.data.suffix ? response.data.suffix : "");
        setCity(response.data.city ? response.data.city : "");
        setState(response.data.state ? response.data.state : "");
        setZip(response.data.zip ? response.data.zip : "");
        setDateOfBirth(
          response.data.date_of_birth ? response.data.date_of_birth : ""
        );
        setPreferredName(
          response.data.preferred_name ? response.data.preferred_name : ""
        );
        setPhoneNumber(
          response.data.phone_number ? response.data.phone_number : ""
        );
        setEmail(response.data.email ? response.data.email : "");
        setPlanId(response.data.plan_id ? response.data.plan_id : "");
        setHomeAddress(
          response.data.home_address ? response.data.home_address : ""
        );
        setDriverClassification(
          response.data.driver_classification
            ? response.data.driver_classification
            : ""
        );
        setInitialHireDate(
          response.data.inital_hire_date ? response.data.inital_hire_date : ""
        );

        setDrivingLicense(
          response.data.driving_license ? response.data.driving_license : []
        );
        // const check =
        //   response.data.driverVehicles[0].end_date == null ? true : false;
        // console.log("AssignCheck1", response.data.driverVehicles[0].end_date);
        const check =
          response.data &&
          response.data.driverVehicles &&
          response.data.driverVehicles[0] &&
          response.data.driverVehicles[0].end_date == null
            ? true
            : false;
        console.log("AssignCheck1", response.data.driverVehicles);
        console.log("AssignCheck", check);
        setAssignCheck(check);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDriverDetailsLoadsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getDriverDetailsLoadsData(driverId)
      .then((response) => {
        //console.log("insideif", response);
        console.log(
          "getAllDriverLoadsData",
          response.data ? response.data : ""
        );
        setDriverLoadsOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDriverDetailsSettlementsDataAPI = () => {
    console.log("getDriverDetailsSettlementsData");
    const storedToken = localStorage.getItem("token");
    getDriverDetailsSettlementsData(driverId)
      .then((response) => {
        console.log("getDriverDetailsSettlementsData", response);
        console.log(
          "getAllDriverSettlementsData",
          response.data ? response.data : ""
        );
        setDriverSettlementsOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageDriverLoadsPOC, setCurrentPageDriverLoadsPOC] = useState(1);
  const [itemsPerPageDriverLoadsPOC, setItemsPerPageDriverLoadsPOC] =
    useState(50);
  const [currentPageDriverSettelmentsPOC, setCurrentPageDriverSettelmentsPOC] =
    useState(1);
  const [
    itemsPerPageDriverSettelmentsPOC,
    setItemsPerPageDriverSettelmentsPOC,
  ] = useState(50);
  const handlePageLoadsChange = (pageNumber) => {
    setCurrentPageDriverLoadsPOC(pageNumber);
  };
  const handleItemsPerPageLoadsChange = (perPage) => {
    setItemsPerPageDriverLoadsPOC(perPage);
    setCurrentPageDriverLoadsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateLoadsAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageDriverLoadsPOC",
      currentPageDriverLoadsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageDriverLoadsPOC",
    //   itemsPerPageDriverLoadsPOC
    // );
  };
  const handleReturnDriverLoadsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverLoadsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageDriverLoadsPOC"
    // );
    setCurrentPageDriverLoadsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverLoadsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  const handlePageSettelmentsChange = (pageNumber) => {
    setCurrentPageDriverSettelmentsPOC(pageNumber);
  };
  const handleItemsPerPageSettelmentsChange = (perPage) => {
    setItemsPerPageDriverSettelmentsPOC(perPage);
    setCurrentPageDriverSettelmentsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateSettelmentsAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageDriverSettelmentsPOC",
      currentPageDriverSettelmentsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageDriverSettelmentsPOC",
    //   itemsPerPageDriverSettelmentsPOC
    // );
  };
  const handleReturnDriverSettelmentsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverSettelmentsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageDriverSettelmentsPOC"
    // );
    setCurrentPageDriverSettelmentsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverSettelmentsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  const handleUnassignVehicleStatus = (value) => {
    //  console.log("isVehicleAssigned6", value);
    //  setUpdated(value);
    toast("Vehicle is unassigned.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log("vehicleIdNew", vehicleId);
  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="details-page-design">
              <div className="stickyRowDriverDetails">
                <div className="headerDriverDetails">
                  {/* <Breadcrumbs items={breadcrumbItems} /> */}
                  <div className="titleContainer">
                    <div
                      className="backToDriversDetails"
                      style={{ display: "flex", width: "100%" }}
                    >
                      <img
                        src={backRightArrow}
                        className="backRightArrow"
                      ></img>
                      <Link to="/drivers" className="backLinkDriversDetails">
                        Back
                      </Link>
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <Title
                        name={fullName ? fullName : ""}
                        type={"page"}
                        color={"#fff"}
                      />
                    </div>
                  </div>
                  <div className="calenderChangeButtonContainer">
                    <CalenderChanger
                      activeTab={activeCalendarTab}
                      onMonthChange={handleDateChange}
                      onQuarterChange={handleDateChange}
                      onYearChange={handleDateChange}
                    />
                  </div>
                  <div className="stickyTabButtonsDriverDetailsPOC">
                    <TabButtons
                      tabs={calendarTabsData}
                      onTabChange={handleTabChange}
                    />
                  </div>
                </div>
                <div className="">
                  <div
                    className="certificateSection"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="certificatesContainer">
                      <div className="serialNumbers">
                        <div className="driverNoDriversDetails">
                          {driverId ? driverId : ""}
                        </div>
                        <div className="seperatorDriversDetails">•</div>
                        <div className="truckNoDriversDetails">
                          <img src={truck} />
                          {vehicleId ? vehicleId : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <DetailsHeader data={driverData} />
                  </div>
                </div>
                <div className="detailsTabButtonsPOC">
                  <TabButtons
                    tabs={tabsData}
                    onTabChange={handlePageTabChange}
                  />
                </div>
                {activeTab == 1 || activeTab == 2 ? (
                  <div className="tableTitleContainerDriverDetails">
                    <div style={{ flex: "1" }}>
                      {activeTab === 1 ? (
                        <Title
                          name={`Loads (${loadsTableData.length})`}
                          type={"table"}
                        />
                      ) : activeTab === 2 ? (
                        <Title
                          name={`Settlements (${settlementsTableData.length})`}
                          type={"table"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {activeTab == 1 || activeTab == 2
                      ? // <div style={{ flex: "" }} className="searchBox">
                        //   <img src={searchIcon} />
                        //   <input
                        //     type="text"
                        //     placeholder={
                        //       activeTab == 1
                        //         ? "Search load no."
                        //         : activeTab == 2
                        //         ? "Search settlement no."
                        //         : ""
                        //     }
                        //     className="searchTextStyle"
                        //     style={{
                        //       border: "none",
                        //       background: "#fcfcfc",
                        //       padding: "0",
                        //     }}
                        //   />
                        // </div>
                        ""
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {activeTab === 0 ? (
                <>
                  <ProfilePOC sections={[informationSection]} />
                  {/* <div
                      style={{ marginTop: "16px" }}
                      className="driverLicensePOCContainer"
                    >
                      <DriversLicensePOC />
                    </div> */}
                  <div className="tableContainer">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "24px 32px",
                      }}
                      className="tableTitleContainer"
                    >
                      {" "}
                      <div style={{ flex: "1" }}>
                        <Title
                          name={`Vehicle History (${vehicleTableData.length})`}
                          type={"table"}
                        />
                      </div>
                      {/* <div style={{ flex: "" }} className="searchBox">
                          <img src={searchIcon} />
                          <input
                            type="text"
                            placeholder="Search vehicle no."
                            className="searchTextStyle"
                            style={{
                              border: "none",
                              background: "#fcfcfc",
                              padding: "0",
                            }}
                          />
                        </div> */}
                    </div>
                    <div
                      className="driversTableContainer"
                      style={{ maxHeight: `${maxTableHeight}px` }}
                    >
                      <div className="recentSettelmentsTableContainer">
                        <TablePOC
                          headers={vehicleTableHeaders}
                          data={vehicleTableData}
                          activeTab={activeTab}
                          tableName={"vehicleHistoryDrivers"}
                          driverId={driverId ? driverId : 0}
                          vehicleId={assignedTruck ? assignedTruck : 0}
                          fullDriverName={fullName}
                          //unassignCheck={unassignCheck}
                          paginationEnabled={false}
                          currentPage={currentPageDriverLoadsPOC}
                          itemsPerPage={itemsPerPageDriverLoadsPOC}
                          onPageChange={handlePageLoadsChange}
                          onItemsPerPageChange={handleItemsPerPageLoadsChange}
                          onNavigateAway={handleNavigateLoadsAway}
                          sendUnassignVehicleStatus={
                            handleUnassignVehicleStatus
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="tableContainerDriverDetails">
                  <div
                    className="driversTableContainerDriverDetails"
                    style={{
                      maxHeight: `${maxTableHeight}px`,
                      padding: "0 16px 0 16px",
                      marginBottom: "16px",
                    }}
                  >
                    {activeTab === 1 ? (
                      <div className="recentSettelmentsTableContainer">
                        <TablePOC
                          headers={loadsTableHeaders}
                          data={loadsTableData}
                          activeTab={activeTab}
                          paginationEnabled={true}
                          currentPage={currentPageDriverLoadsPOC}
                          itemsPerPage={itemsPerPageDriverLoadsPOC}
                          onPageChange={handlePageLoadsChange}
                          onItemsPerPageChange={handleItemsPerPageLoadsChange}
                          onNavigateAway={handleNavigateLoadsAway}
                        />
                      </div>
                    ) : activeTab === 2 ? (
                      <div className="recentSettelmentsTableContainer">
                        <TablePOC
                          headers={settlementsTableHeaders}
                          data={settlementsTableData}
                          activeTab={activeTab}
                          rowClick={true}
                          tableName={"driversSettelmentsTable"}
                          paginationEnabled={true}
                          currentPage={currentPageDriverSettelmentsPOC}
                          itemsPerPage={itemsPerPageDriverSettelmentsPOC}
                          onPageChange={handlePageSettelmentsChange}
                          onItemsPerPageChange={
                            handleItemsPerPageSettelmentsChange
                          }
                          onNavigateAway={handleNavigateSettelmentsAway}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <ToastContainer
                className={"toast-message"}
                toastStyle={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "8px 12px 8px 8px",
                  background: "rgba(0, 188, 249, 0.1)",
                  borderRadius: "54px",
                  color: "#00bfff",
                  fontFamily: "Red Hat Display",
                  fontSize: "14px",
                  fontWeight: "600",
                  //height: "36px",
                  minHeight: "none",
                }}
                hideProgressBar={true}
                closeButton={<img src={toastCross} />}
                icon={<img src={toastIcon} />}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DriverDetailsPOC;
