import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./DeleteStop.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
//import { useHistory, Redirect } from "react-router-dom";
const DeleteStop = ({}) => {
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  //const history = useHistory();

  return (
    // <Card className={"deleteStopCard"}>
    //   <CardBody>
    <div className="deleteStopContainer">
      <div className="deleteStopTitleMessage">
        <div className="deleteStopTitle">Are you sure?</div>
        <div className="deleteStopMessage">You want to remove other stop?</div>
      </div>
      <div className="removeSectionDeleteStop">
        <Buttons
          size={"regular"}
          dimensions={"responsive"}
          buttonType={"secondary"}
          //onClick={onClose}
          imageReplace={false}
        >
          No, Don’t Remove
        </Buttons>
        <Buttons
          size={"regular"}
          dimensions={"responsive"}
          buttonType={"secondary"}
          imageReplace={false}
          // onClick={() => {
          //   localStorage.removeItem("token");
          //   document.cookie =
          //     "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
          //   onClose();
          // window.location.href = "/";
          // window.location.reload();
          //   //history.push("/");
          //   return <Redirect to="/" exact />;
          // }}
          // onClick={handleLogout}
        >
          Yes, Remove
        </Buttons>
      </div>
    </div>
    //   </CardBody>
    // </Card>
  );
};

export default DeleteStop;
