import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import { useMountEffect } from "app/utils/hooks";
import "./ProfilePOC.scss";
import InformationPOC from "../DriversDetails/DriversInformationPOC/DriversInformationPOC";
const ProfilePOC = ({ sections }) => {
  return (
    <>
      {sections.map((section, index) => (
        <Row key={index} xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <Card
              className="cardStyleProfilePOC"
              style={{ marginTop: index === 0 ? "0" : "" }}
            >
              <CardBody>{section.component}</CardBody>
            </Card>
          </Col>
        </Row>
      ))}
    </>
  );
};
export default ProfilePOC;
