import React, { useEffect, useState } from "react";
import notificationBellIcon from "assets/images/loads/notificationBellIcon.svg";
import searchIcon from "assets/images/loads/searchIcon.svg";
import messageIcon from "assets/images/loads/messageIcon.svg";
import Title from "../Title/Title";
import "./HeaderSection.scss";
export default function HeaderSection({ title }) {
  return (
    <>
      <div className="headerSection">
        <div className="titleContainer">
          <div style={{ marginTop: "", display: "flex" }}>
            <Title name={title} type={"page"} color="#fff" />
          </div>
        </div>
        <div className="searchNotificationBellContainer">
          <div className="searchBoxContainer">
            <img src={searchIcon} />
            <input
              type="text"
              placeholder="Search anything"
              className="searchInputStyle"
            />
          </div>
          <div className="notificationBellContainer">
            <img src={notificationBellIcon} />
          </div>
          <div className="notificationBellContainer">
            <img src={messageIcon} />
          </div>
        </div>
      </div>
    </>
  );
}
