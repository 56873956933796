import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import closeAddDriver from "../../../../../../../assets/images/addDriver/closeAddDiver.svg";
import "./AssignVehicle.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import TablePOC from "../../../TablePOC/TablePOC";
import AssignVehicleTable from "../AssignVehicleTable/AssignVehicleTable";
import { availableVehicles } from "app/apiServices/apiServices";
const AssignVehicle = ({ onClose, driverId, sendVehicleAssignd }) => {
  const [availableVehicleOriginalData, setAvailableVehicleOriginalData] =
    useState([]);
  const [isVehicleAssigned, setIsVehicleAssigned] = useState(false);
  const assignVehicleTableHeaders = {
    vehicleId: "Vehicle Id.",
    coDriver: "Co-Driver",
    make: "Make",
    type: "Type",
    model: "Model",
    year: "Year",
    action: "Action",
  };

  const assignVehicleTableData = availableVehicleOriginalData.map(
    (item, index) => {
      return {
        vehicleId: item.vehicle_id ? item.vehicle_id : "",
        coDriver: item.co_driver ? item.co_driver : "",
        make: item.make ? item.make : "",
        type: item.type ? item.type : "",
        model: item.model ? item.model : "",
        year: item.year ? item.year : "",
        action: "Assign Vehicle", // You can define the action as per your requirements
      };
    }
  );
  const [currentPageDriverPOC, setCurrentPageDriverPOC] = useState(1);
  const [itemsPerPageDriverPOC, setItemsPerPageDriverPOC] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageDriverPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageDriverPOC(perPage);
    setCurrentPageDriverPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    //localStorage.setItem("currentPageDriverPOC", currentPageDriverPOC);
    //localStorage.setItem("itemsPerPageDriverPOC", itemsPerPageDriverPOC);
  };
  const handleReturnDriversPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverPOC");
    //const savedItemsPerPage = localStorage.getItem("itemsPerPageDriverPOC");
    setCurrentPageDriverPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  const handleVehicleAssign = (value) => {
    console.log("isVehicleAssigned4", value);
    setIsVehicleAssigned(value);
    sendVehicleAssignd(value);
  };
  useEffect(() => {
    availableVehicleAPI();
  }, []);
  const availableVehicleAPI = () => {
    console.log("helloAssign");
    const storedToken = localStorage.getItem("token");
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/available/vehicles",
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //     },
    //   }
    // )
    // .then((result) => {
    //   if (result.status === 200) {
    //     return result.json();
    //   } else {
    //     alert("Please check login credentials!");
    //   }
    // })
    availableVehicles()
      .then((response) => {
        console.log("availableVehicle", response.data);
        setAvailableVehicleOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const assignVehicle = () => {
    console.log("helloNext");
    const storedToken = localStorage.getItem("token");

    fetch(
      "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/vehicle_drivers/assign",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          // vehicle_id: vehicleId,
          // driver_id: driverId,
          // start_date: "2024-01-10",
        }),
      }
    )
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          alert("Please check login credentials!");
        }
      })
      .then((response) => {
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  console.log("isVehicleAssigned2", isVehicleAssigned);

  return (
    <Card className={"assignVehicleCard"}>
      <CardBody>
        <div className="assignVehicleContainer">
          <div className="assignVehicleTitle">
            <div className="assignVehicleStyle">Assign Vehicle:</div>
            <Icon
              size="regular"
              buttonType="secondary"
              image={closeAddDriver}
              onClick={onClose}
              imageReplace={true}
              hover={false}
            ></Icon>
          </div>
          <div
            className="assignVehicleTableContainer"
            style={{ width: "100%" }}
          >
            <TablePOC
              headers={assignVehicleTableHeaders}
              data={assignVehicleTableData}
              driverId={driverId}
              onClose={onClose}
              onVehicleAssign={handleVehicleAssign}
              //rowClick={true}
              paginationEnabled={false}
              currentPage={currentPageDriverPOC}
              itemsPerPage={itemsPerPageDriverPOC}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
              onNavigateAway={handleNavigateAway}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AssignVehicle;
