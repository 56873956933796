// LocationSection.jsx
import React, { useState, useEffect } from "react";
import "./LocationSection.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
import { Address } from "app/views/commonComponents/Address/Address";
import CustomDatePicker from "app/views/commonComponents/CustomDatePicker/CustomDatePicker";
import CustomDropdown from "app/views/commonComponents/CustomDropdown/CustomDropdown";
import datePicker from "assets/images/poc/datePicker.svg";
import location from "assets/images/addLoad/location.svg";
import pickupPoint from "assets/images/addLoad/pickupPoint.svg";
import addInstruction from "assets/images/addLoad/addInstruction.svg";
import dotsSixVertical from "assets/images/addLoad/dotsSixVertical.svg";
import deleteStop from "assets/images/addLoad/deleteStop.svg";
import deleteInstructions from "assets/images/addLoad/deleteInstructions.svg";
import showMoreOptions from "assets/images/addLoad/showMoreOptions.svg";
import pickupIcon from "assets/images/addLoad/pickupIcon.svg";
import dropIcon from "assets/images/addLoad/dropIcon.svg";
import gasStop from "assets/images/addLoad/gasStop.svg";
import pickupDropIcon from "assets/images/addLoad/pickupDropIcon.svg";
import safeHeavenIcon from "assets/images/addLoad/safeHeavenIcon.svg";
import DeleteStop from "../DeleteStop/DeleteStop";
const LocationSection = ({
  arrivalDate: initialArrivalDate, // Store initial arrivalDate
  etaSelection: initialEtaSelection, // Store initial etaSelection
  etaEntered: initialEtaEntered,
  selectedAddress,
  //completeAddress,
  locationType,
  locationItems,
  selectedCity,
  selectedState,
  //cityState,
  handleLocationType,
  handleAddressChange,
  handleCityChange,
  handleStateChange,
  handleZipAddressChange,
  handleLatitudeChange,
  handleLongitudeChange,
  handleArrivalDateChange,
  handleETASelection,
  handleETAEntered,
  handleShipperNameChange,
  handleContactNameChange,
  handleContactNoChange,
  updateLoadStopDataAPI,
  shipperName,
  contactName,
  contactNo,
  timeOptions,
  isVisible,
  toggleLocationDetails,
  index,
  handleToggleStop,
  handleDeleteStop,
  isDeleteDisabled,
  stopsData,
  setStopsData,
  distanceAndTime,
  locationTypeName,
}) => {
  const [showAddInstruction, setShowAddInstruction] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [deleteStopPopup, setDeleteStopPopup] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(initialArrivalDate);
  const [etaSelection, setEtaSelection] = useState(initialEtaSelection);
  const [etaEntered, setEtaEntered] = useState(initialEtaEntered);

  const handleShowAddInstruction = () => {
    setShowAddInstruction(!showAddInstruction);
  };
  const handleAddInstructionChange = (e) => {
    setInstructions(e.target.value);
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  // Function to handle deletion of the current stop
  const onDeleteStop = () => {
    //setDeleteStopPopup(!deleteStopPopup);
    handleDeleteStop(); // Call the handleDeleteStop function passed from props
  };
  const onHoverLeaveDeleteStop = () => {
    //setDeleteStopPopup(!isHovering && false);
  };
  const handleDeleteStopPopupEnter = () => {
    setIsHovering(true);
  };
  const handleDeleteStopPopupLeave = () => {
    setIsHovering(false);
    setDeleteStopPopup(false);
  };
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (event) => {
    setIsDragging(true);
    event.dataTransfer.setData("index", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    // Highlight the drop zone visually if needed
  };
  const handleDragLeave = () => {
    // Remove any visual indication from the drop zone
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedIndex = event.dataTransfer.getData("index");
    setIsDragging(false);

    // Convert the droppedIndex and currentIndex to integers
    const currentIndex = parseInt(droppedIndex);
    const targetIndex = index;
    // Swap arrivalDate and etaSelection values
    const updatedStopsData = [...stopsData];
    const tempArrivalDate = updatedStopsData[currentIndex].arrivalDate;
    const tempEtaSelection = updatedStopsData[currentIndex].etaSelection;

    updatedStopsData[currentIndex].arrivalDate =
      updatedStopsData[targetIndex].arrivalDate;
    updatedStopsData[currentIndex].etaSelection =
      updatedStopsData[targetIndex].etaSelection;

    updatedStopsData[targetIndex].arrivalDate = tempArrivalDate;
    updatedStopsData[targetIndex].etaSelection = tempEtaSelection;

    // Update the stopsData state with the rearranged items
    setStopsData(updatedStopsData);
    // Call the function to handle rearranging the items
    handleRearrangeItems(currentIndex, targetIndex);

    // Call the function to update load stop data API
    updateLoadStopDataAPI();
  };
  // Function to handle rearranging items
  const handleRearrangeItems = (currentIndex, targetIndex) => {
    // Copy the stopsData array to a new array
    const updatedStopsData = [...stopsData];

    // Remove the item from its current position
    const draggedItem = updatedStopsData.splice(currentIndex, 1)[0];

    // Insert the dragged item at the new position
    updatedStopsData.splice(targetIndex, 0, draggedItem);

    // Update the stopsData state with the rearranged items
    setStopsData(updatedStopsData);
  };
  // Reset arrivalDate and etaSelection when initialArrivalDate or initialEtaSelection changes
  useEffect(() => {
    setArrivalDate(initialArrivalDate);
    setEtaSelection(initialEtaSelection);
  }, [initialArrivalDate, initialEtaSelection]);
  const cityState = `${selectedCity}${
    selectedState ? "," : ""
  } ${selectedState}`;
  const completeAddress = `${selectedAddress}${
    selectedCity || selectedState ? "," : ""
  } ${selectedCity}${selectedState ? "," : ""} ${selectedState}`;
  console.log("distanceAndTime4", distanceAndTime);
  console.log("locationType1", locationType);
  const startDate = index === 0 ? null : stopsData[index - 1]?.arrivalDate;
  const endDate =
    index === stopsData.length - 1 ? null : stopsData[index + 1]?.arrivalDate;
  // Calculate the start date for the current stop
  const calculateStartDate = () => {
    // If it's the first stop, there's no previous stop, so return null
    if (index === 0) return null;
    // Otherwise, return the arrival date of the previous stop
    return stopsData[index - 1].arrivalDate;
  };
  // Calculate the end date for the current stop
  const calculateEndDate = () => {
    // If it's the last stop, return null
    if (index === stopsData.length - 1) return null;
    // Otherwise, return the arrival date of the next stop
    return stopsData[index + 1].arrivalDate;
  };
  return (
    <>
      <div
        className={`pickupLocationConatiner ${!isVisible ? "hoverable" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        draggable="true"
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div className={`pickupButtonConatianer`} style={{ display: "flex" }}>
            {!isVisible ? (
              !isHovering ? (
                <div className="pickupPointContainer2">
                  {/* <img src={pickupPoint} /> */}
                </div>
              ) : (
                <div
                  className="dotsSixVerticalContainer"
                  //onDragStart={(event) => handleDragStart(event)}
                >
                  <img src={dotsSixVertical} />
                </div>
              )
            ) : (
              <div className="pickupPointContainer2">
                {/* <img src={pickupPoint} /> */}
              </div>
            )}
            <div
              className="pickupPointContainer"
              onClick={() => handleToggleStop(index)}
            >
              {locationType == "Pickup" ? (
                <img src={pickupIcon} />
              ) : locationType == "Drop" ? (
                <img src={dropIcon} />
              ) : locationType == "Safe Heaven" ? (
                <img src={safeHeavenIcon} />
              ) : locationType == "Drop-Pickup" ? (
                <img src={pickupDropIcon} />
              ) : locationType == "Gas Stop" ? (
                <img src={gasStop} />
              ) : (
                <img src={pickupIcon} />
              )}
            </div>
          </div>
          {isVisible && locationTypeName != "Destination" && (
            <div class="vertical-line-visible">
              <div></div>
            </div>
          )}
        </div>
        <div
          className={
            !isVisible && isHovering
              ? "locationSectionHover"
              : `locationSection`
          }
          onMouseLeave={onHoverLeaveDeleteStop}
          style={{ gap: !isVisible ? "" : "24px" }}
        >
          <div className={`locationTitleContainer`}>
            <div className="locationTitle">
              {index == 0
                ? "Origin"
                : index == stopsData.length - 1
                ? "Destination"
                : locationType == "Pickup"
                ? "Pickup"
                : locationType == "Drop"
                ? "Drop"
                : locationType == "Safe Heaven"
                ? "Safe Heaven"
                : locationType == "Drop-Pickup"
                ? "Drop-Pickup"
                : locationType == "Gas Stop"
                ? "Gas Stop"
                : "Parking"}
            </div>
            <div className="locationDateTime">
              <div className="dateTimeContainer">
                <img src={datePicker} alt="Date Picker Icon" />
                <div className="dateTime">
                  {arrivalDate} - {etaSelection}
                </div>
              </div>
              <div className="dotContainer">•</div>
              <div className="dateTimeContainer">
                <img src={location} alt="Location Icon" />
                <div className="dateTime">{completeAddress}</div>
              </div>
            </div>
          </div>
          {isVisible && (
            <>
              <div className="locationDetails">
                <div className="rowContainer">
                  <div style={{ width: "100%", flex: "1" }}>
                    <CustomDropdown
                      fieldName="Location Type"
                      items={
                        index == 0 || index == stopsData.length - 1
                          ? []
                          : locationItems
                      }
                      showArrow={
                        index == 0 || index == stopsData.length - 1
                          ? false
                          : true
                      }
                      selectedItem={locationType}
                      onSelect={handleLocationType}
                      background={"#ffffff"}
                    />
                  </div>
                  <div style={{ width: "100%", flex: "2.1" }}>
                    <Address
                      fieldName={"Address"}
                      addressCountryFilters={["us"]}
                      initialAddress={selectedAddress}
                      handleAddressChange={handleAddressChange}
                      handleCityChange={handleCityChange}
                      handleStateChange={handleStateChange}
                      handleZipChange={handleZipAddressChange}
                      handleLatitudeChange={handleLatitudeChange}
                      handleLongitudeChange={handleLongitudeChange}
                      background="#ffffff"
                    />
                  </div>
                </div>
                <div className="rowContainer">
                  <div style={{ width: "100%" }}>
                    <Address
                      fieldName={"City, State"}
                      addressCountryFilters={["us"]}
                      initialAddress={cityState}
                      handleAddressChange={handleAddressChange}
                      handleCityChange={handleCityChange}
                      handleStateChange={handleStateChange}
                      handleZipChange={handleZipAddressChange}
                      background="#ffffff"
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      fieldName="Arrival Date"
                      selectedDate={arrivalDate}
                      setSelectedDate={handleArrivalDateChange}
                      background="#ffffff"
                      startDate={calculateStartDate()}
                      endDate={calculateEndDate()}
                      validateRange={
                        index !== 0 && index !== stopsData.length - 1
                      }
                      disablePastDates={true}
                    />
                  </div>
                  <InputDropdown
                    fieldName="ETA"
                    dropdownOptions={timeOptions}
                    initialValue={""}
                    selectedValue={etaSelection || etaEntered}
                    onValueSelection={handleETASelection}
                    onValueEnter={handleETAEntered}
                    inputType="textNumber"
                    style={{ width: "100%" }}
                    background="#ffffff"
                  />
                </div>
                <div className="rowContainer">
                  <AddInputField
                    fieldName="Shipper Name"
                    fieldValue={shipperName}
                    onChange={handleShipperNameChange}
                    inputType="text"
                    style={{
                      width: "100%",
                      //background: "#ffffff",
                    }}
                    background="#ffffff"
                  />
                  <AddInputField
                    fieldName="Contact Name"
                    fieldValue={contactName}
                    onChange={handleContactNameChange}
                    inputType="text"
                    style={{ width: "100%" }}
                    background="#ffffff"
                  />
                  <AddInputField
                    fieldName="Contact No."
                    fieldValue={contactNo}
                    inputType="text"
                    onChange={handleContactNoChange}
                    style={{ width: "100%" }}
                    autoComplete="tel"
                    background="#ffffff"
                  />
                </div>
                <div className="addInstructionButton" style={{}}>
                  {/* {!showAddInstruction ? (
                    <Buttons
                      dimensions={"responsive"}
                      iconPosition={"left"}
                      buttonType="secondary"
                      size="regular"
                      image={addInstruction}
                      hover={false}
                      color={"#000000"}
                      onClick={handleShowAddInstruction}
                    >
                      Add Instructions
                    </Buttons>
                  ) : ( */}
                  <>
                    <div className="addInstructionContainer">
                      <div className="addInstructionLabel">
                        Add Instructions
                      </div>
                      <div className="">
                        <textarea
                          id="instructions"
                          name="instructions"
                          value={instructions}
                          className="addInstructionInputContainer"
                          onChange={handleAddInstructionChange}
                          //style={{ width: "100%" }}
                          rows={4}
                          cols={100}
                        />
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>
              </div>
            </>
          )}
          {!isVisible && isHovering && (
            <div className="hoveringOptions">
              {!isDeleteDisabled && (
                <div
                  className={`deleteStopIconContainer ${
                    isDeleteDisabled ? "disabled" : ""
                  }`}
                  onClick={onDeleteStop}
                >
                  <img src={deleteStop} />
                </div>
              )}
              {/* <div className="showMoreOptionsContainer">
                <img src={showMoreOptions} />{" "}
              </div> */}
            </div>
          )}
        </div>
      </div>
      {/* {!isVisible && isHovering && deleteStopPopup && (
        <div
          className="deleteStopPopup"
          onMouseEnter={handleDeleteStopPopupEnter}
          onMouseLeave={handleDeleteStopPopupLeave}
        >
          <DeleteStop />
        </div>
      )} */}
      {isVisible == false && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {locationTypeName != "Destination" && (
            <div class="vertical-line">
              <div></div>
            </div>
          )}
          <div className="spaceDiv">
            {index < stopsData.length - 1 && (
              <>
                {" "}
                <div className="minMilesBetween">
                  <div className="minBetween">
                    {distanceAndTime[index]?.time
                      ? `${Math.round(distanceAndTime[index]?.time)}`
                      : ""}
                    min
                    {/* {`${
                      distanceAndTime[index]?.time
                        ? Math.round(distanceAndTime[index]?.time)`min`
                        : "--"
                    } `} */}
                  </div>
                  <div className="dotBetween">•</div>
                  <div className="milesBetween">
                    {distanceAndTime[index]?.distance
                      ? `${Math.round(
                          distanceAndTime[index]?.distance
                        ).toLocaleString("en-Us", {
                          minimumFractionDigits: 1,
                        })} `
                      : ""}
                    miles
                  </div>
                </div>
                <div className="horizontalDiv" />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LocationSection;
