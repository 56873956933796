import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./DriversPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import driverProfile from "../../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../../assets/images/drivers/accidents.svg";
import calendarRemove from "../../../../../../assets/images/drivers/calendarRemove.svg";
import star from "../../../../../../assets/images/drivers/star.svg";
import userTag from "../../../../../../assets/images/drivers/userTag.svg";
import moneys from "../../../../../../assets/images/drivers/moneys.svg";
import presentionChart from "../../../../../../assets/images/drivers/presentionChart.svg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTablePOC from "../../TablePOC/TablePOC";
//import DriversFilterButton from "../DriversFilterButton/DriversFilterButton";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import addIcon from "../../../../../../assets/images/poc/addIcon.svg";
import searchIcon from "../../../../../../assets/images/poc/searchIcon.svg";
import helloImage from "../../../../../../assets/images/poc/helloImage.png";
import leftDecrementArrow from "../../../../../../assets/images/poc/leftDecrementArrow.svg";
import rightIncrementArrow from "../../../../../../assets/images/poc/rightIncrementArrow.svg";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import {
  getAllDriversData,
  getDriversHeaderKPIData,
} from "../../../../../apiServices/apiServices";
import HeaderSection from "app/views/commonComponents/HeaderSection/HeaderSection";
import moment from "moment-timezone";
const DriversPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTabId, setActiveCalendarTabId] = useState(0);
  const [activeCalendarTabLabel, setActiveCalendarTabLabel] = useState("Month");
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [isDriversFilterOpen, setIsDriversFilterOpen] = useState(false);
  const [driversOriginalData, setDriversOriginalData] = useState([]);
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("Jan 2024");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const driverData = [
    {
      icon: userTag,
      values: `$${Math.round(grossMargin).toLocaleString("en-US")}`,
      title: "Gross Margin",
    },
    {
      icon: star,
      values: `$${headerRPM.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      title: "RPM",
    },
    {
      icon: star,
      values: milesLoaded.toLocaleString("en-US"),
      title: "Loaded Miles",
    },
    {
      icon: accidents,
      values: loadsDelivered.toLocaleString("en-US"),
      title: "Delivered Loads",
    },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const calendarTabsData = [
    { id: 0, label: "Month" },
    { id: 1, label: "Quarter" },
    { id: 2, label: "Year" },
    // Add more tabs as needed
  ];
  const driversTableHeaders = {
    driverId: "Driver Id.",
    fullDriverName: "Full Name",
    phoneNo: "Phone No.",
    assignedTruck: "Assigned Truck",
    milesCovered: "Miles Covered",
    loadsCompleted: "Loads Completed",
    rpm: "RPM",
    grossMargin: "Gross Margin",
    netProfit: "Net Profit",
    //optionsMenu: "Options Menu",
  };
  const driversTableData = driversOriginalData.map((driver, index) => {
    const truncatedDriverName =
      driver.full_name && driver.full_name.length > 20
        ? driver.full_name.substring(0, 20) + "..."
        : driver.full_name;
    return {
      driverId: driver.driver_id ? driver.driver_id : 0,
      fullDriverName: truncatedDriverName,
      phoneNo: driver.phone_number ? driver.phone_number : "",
      assignedTruck: driver.vehicle_id ? driver.vehicle_id : "Unassigned",
      milesCovered: (driver.loaded_miles
        ? driver.loaded_miles
        : 0
      ).toLocaleString(),
      loadsCompleted: (driver.loads_delivered
        ? driver.loads_delivered
        : 0
      ).toLocaleString(),
      rpm: driver.rpm
        ? `$${driver.rpm.toLocaleString("en-Us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      grossMargin: driver.gross_margin
        ? `$${driver.gross_margin.toLocaleString("en-Us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netProfit: driver.company_revenue
        ? `$${driver.company_revenue.toLocaleString("en-Us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      //optionsMenu: "Options Menu",
    };
  });
  const handleTabChange = (tabId, tabLabel) => {
    setActiveCalendarTabId(tabId);
    setActiveCalendarTabLabel(tabLabel);
  };
  // Function to handle month change
  const handleDateChange = (startDate, endDate) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    // Call getDetailsHeaderKPIDataAPI with final start and end dates
    getDetailsHeaderKPIDataAPI(startDate, endDate);
  };
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 240; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    handleReturnDriversPOC();
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");

    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
    getAllDriversDataAPI();
    if (
      activeCalendarTabLabel == "Month" ||
      activeCalendarTabLabel == "Quarter" ||
      activeCalendarTabLabel == "Year"
    ) {
      getDetailsHeaderKPIDataAPI();
    }
  }, [activeCalendarTabLabel]);
  const getAllDriversDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getAllDriversData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllDriversData", response.data);
        setDriversOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDetailsHeaderKPIDataAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    let currentStartDate = null;
    let currentEndDate = null;

    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0

    switch (activeCalendarTabLabel) {
      case "Month": // Current month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Quarter": // Current quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "Year": // Current year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    getDriversHeaderKPIData(finalStartDate, finalEndDate)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getDetailsHeaderKPIData", response.data);
        setGrossMargin(
          response.data.gross_margin ? response.data.gross_margin : 0
        );
        setHeaderRPM(response.data.rpm ? response.data.rpm : 0);
        setMilesLoaded(
          response.data.miles_loaded ? response.data.miles_loaded : 0
        );
        setLoadsDelivered(
          response.data.loads_delivered ? response.data.loads_delivered : 0
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageDriverPOC, setCurrentPageDriverPOC] = useState(1);
  const [itemsPerPageDriverPOC, setItemsPerPageDriverPOC] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageDriverPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageDriverPOC(perPage);
    setCurrentPageDriverPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem("currentPageDriverPOC", currentPageDriverPOC);
    //localStorage.setItem("itemsPerPageDriverPOC", itemsPerPageDriverPOC);
  };
  const handleReturnDriversPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverPOC");
    //const savedItemsPerPage = localStorage.getItem("itemsPerPageDriverPOC");
    setCurrentPageDriverPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  return (
    <React.Fragment>
      <Container fluid>
        <div className="main-page-design">
          <Row xl={12} md={12} sm={12} xs={12}>
            <Col xl={12} md={12} sm={12} xs={12}>
              <div className="stickyRowDriver">
                <HeaderSection title={"Drivers"}></HeaderSection>
                {/* <div className="calenderChangeButtonContainer">
                    <CalenderChanger
                        activeTabLabel={activeCalendarTabLabel}
                    activeTabId={activeCalendarTabId}
                        onMonthChange={handleDateChange}
                        onQuarterChange={handleDateChange}
                        onYearChange={handleDateChange}
                      />
                  </div> */}
                {/* <div className="stickyTabButtons">
                    <TabButtons
                        tabs={calendarTabsData}
                        onTabChange={handleTabChange}
                      />
                  </div> */}
                <div style={{ marginTop: "24px" }}>
                  {/* <DetailsHeader data={driverData} /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px",
                  }}
                  className="tableTitleContainerDriver"
                >
                  {" "}
                  <div style={{ flex: "1" }}>
                    <Title
                      name={`Current Drivers (${driversOriginalData.length})`}
                      type={"table"}
                    />
                  </div>
                  {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search driver no."
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                </div>
              </div>
              {/* <div className="stickyTabButtons">
                  <TabButtons tabs={tabsData} onTabChange={handleTabChange} />
                </div> */}
              <div className="tableContainerDriver">
                <div
                  className="driversTableContainer"
                  style={{
                    maxHeight: `${maxTableHeight}px`,
                    padding: "0 16px 0 16px",
                    marginBottom: "16px",
                  }}
                >
                  <div className="recentSettelmentsTableContainer">
                    <DriversTablePOC
                      headers={driversTableHeaders}
                      data={driversTableData}
                      rowClick={true}
                      tableName={"driverDataTable"}
                      paginationEnabled={true}
                      currentPage={currentPageDriverPOC}
                      itemsPerPage={itemsPerPageDriverPOC}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onNavigateAway={handleNavigateAway}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default DriversPOC;
