import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@material-ui/core";
import KatherineMoss from "../../../../../assets/images/table/KatherineMoss.svg";
import userSquare from "../../../../../assets/images/table/userSquare.svg";
import twoArrows from "../../../../../assets/images/table/twoArrows.svg";
import fingerCircle from "../../../../../assets/images/table/fingerCircle.svg";
import "./TableData.scss"; // Use a common SCSS file for styling

const TableData = ({
  children,
  cellType, //headerCell and bodyCell
  imageType, //fingerType,userType,profileType
  color,
  onClick,
  iconPosition,
  size, //regular , small ,medium and large
  image,
  contenType, //specialCase
  align, // left, center, or right
  style, // Style object to be applied to the TableCell
  colSpan,
  ...rest
}) => {
  const renderIcon = () => {
    const icon =
      imageType == "fingerType"
        ? fingerCircle
        : imageType == "userType"
        ? userSquare
        : imageType == "profileType"
        ? KatherineMoss
        : "";
    const marginRight = iconPosition === "left" ? { marginRight: "" } : {};
    const marginLeft = iconPosition === "right" ? { marginLeft: "" } : {};
    const width = size == "small" ? { width: "16px" } : {};
    const height = size == "small" ? { height: "16px" } : {};
    return (
      <img
        src={image}
        // style={{
        //   ...marginRight,
        //   ...marginLeft,
        //   ...width,
        //   ...height,
        // }}
        alt="Cross Icon"
      />
    );
  };

  return (
    <TableCell
      align={align || "left"}
      colSpan={colSpan}
      className={
        contenType === "specialCase"
          ? "specialCase"
          : cellType === "headerCell" && size === "regular"
          ? "headerCell"
          : cellType === "bodyCell"
          ? size === "small"
            ? "smallBodyCell"
            : size === "medium"
            ? "mediumBodyCell"
            : "largeBodyCell"
          : ""
      }
      style={{
        padding:
          image && size == "small"
            ? "8px 16px"
            : size == "medium"
            ? "12px 16px"
            : size == "large"
            ? "16px"
            : "",
        ...style, // Applying the style prop
      }}
      onClick={onClick}
      {...rest}
    >
      {/* {contenType === "specialCase" ? (
        size === "medium" ? (
          <div>
            {iconPosition === "left" && renderIcon()}
            {children}
            {iconPosition === "right" && renderIcon()}
          </div>
        ) : size === "large" ? (
          <div></div>
        ) : (
          ""
        )
      ) : (
        ""
      )} */}
      {iconPosition === "left" && renderIcon()}
      {children}
      {iconPosition === "right" && renderIcon()}
    </TableCell>
  );
};
export default TableData;
