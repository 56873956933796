import React from "react";
import Layout from "antd/es/layout";
import { Link } from "react-router-dom";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { useSelector } from "react-redux";
import brandLogo from "assets/images/transport-full-logo.png";
import brandLogoWhite from "assets/images/transport-full-logo-white.png";
import "./header.scss";

const { Header: LayoutHeader } = Layout;

function Header(props) {
  const appStore = useSelector((state) => state.app);

  const { theme } = appStore;

  return (
    <LayoutHeader id="transport_admin_header">
      <Row justify="space-between" align="middle" className="h-100" gutter={0}>
        <Col>
          <div className="logo-wrapper">
            <Link to={`/`}>
              <img
                src={theme === "dark" ? brandLogoWhite : brandLogo}
                alt="Transport Inc."
              />
            </Link>
          </div>
        </Col>
      </Row>
    </LayoutHeader>
  );
}

export default Header;
