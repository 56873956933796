import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Alert,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import MilePlusLogo from "assets/images/MilePlusLogo.svg";
import backToLogin from "assets/images/sidebar/backToLogin.svg";
import forgotPasswordMail from "assets/images/sidebar/forgotPasswordMail.svg";
import passwordChanged from "assets/images/sidebar/passwordChanged.svg";
import "./ChangePassword.scss";
import OTPInputs from "../OTPInputs/OTPInputs";
import LoginForm from "../LoginForm/LoginForm";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  loginAPI,
  forgotPassword,
  changePassword,
} from "../../../../../apiServices/apiServices";
import BackgroundContainer from "app/views/commonComponents/BackgroundContainer/BackgroundContainer";
const ChangePassword = ({ toggleForgotPassword }) => {
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [status, setStatus] = useState("");
  const history = useHistory();
  // Access location object to get query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const id = searchParams.get("id");
  console.log("URL1", token);
  console.log("URL2", id);
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };
  const handleReEnterPasswordChange = (e) => {
    const reEnterPassword = e.target.value;
    setReEnterPassword(reEnterPassword);
  };
  const handleBackToLogin = () => {
    setStatus("");
    history.push("/");
  };
  const handleChangePassword = () => {
    console.log("Change password clicked!");
    const changePasswordDetails = {
      token: token,
      user_id: id,
      password: password,
    };
    changePassword(changePasswordDetails)
      .then((response) => {
        console.log("forgotPasswordDetails", response);
        setStatus(response.status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleValidSubmit = () => {
    // This function will be called only when the form is valid
    //handleLogin();
  };
  return (
    <React.Fragment>
      <BackgroundContainer>
        <Container fluid className="p-0">
          <Row xl={12} md={12} sm={6} xs={6}>
            <Col xl={12} md={12} sm={6} xs={6}>
              <>
                <div
                  className="changePasswordBlock"
                  style={{
                    height: status == 500 ? "305px" : "410px",
                  }}
                >
                  {status == 500 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={passwordChanged} />
                    </div>
                  )}
                  <div className="changePasswordHeader">
                    {" "}
                    {status == 500 || status == 501 ? (
                      <>
                        <div
                          className="changePasswordTitle"
                          style={{ marginTop: "20px" }}
                        >
                          Password Changed
                        </div>
                        <div className="changePasswordMessage">
                          Your password has been changed successfully.
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="changePasswordTitle">
                          Change your password
                        </div>
                        <div className="changePasswordMessage">
                          Enter a new password below to change your password
                        </div>
                        <AvForm
                          id="loginForm"
                          className="form-horizontal"
                          //onValidSubmit={handleValidSubmit}
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div className="changePassword-form-group">
                              <Label
                                htmlFor="password"
                                className="changePassword-form-label"
                              >
                                New Password
                              </Label>
                              <AvField
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                type="password"
                                className="changePassword-form-input"
                                id="password"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This field is required.",
                                  },
                                  minLength: {
                                    value: 6,
                                    errorMessage:
                                      "Username must be at least 6 characters.",
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage:
                                      "Username cannot exceed 50 characters.",
                                  },
                                }}
                              />
                            </div>
                            <div className="changePassword-form-group">
                              <Label
                                htmlFor="reEnterPassword"
                                className="changePassword-form-label"
                              >
                                Re-enter new Password
                              </Label>
                              <AvField
                                name="reEnterPassword"
                                value={reEnterPassword}
                                onChange={handleReEnterPasswordChange}
                                type="password"
                                className="changePassword-form-input"
                                id="reEnterPassword"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This field is required.",
                                  },
                                  minLength: {
                                    value: 8,
                                    errorMessage:
                                      "Password must be at least 8 characters.",
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage:
                                      "Password cannot exceed 50 characters.",
                                  },
                                  // pattern: {
                                  //   value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                  //   errorMessage: "Invalid password.",
                                  // },
                                }}
                              />
                            </div>
                            <div
                              className="text-center"
                              style={{ marginTop: "20px" }}
                            >
                              <Button
                                className="login-button"
                                onClick={handleChangePassword}
                                type="submit"
                                //disabled={!isFormValid}
                              >
                                RESET PASSWORD
                              </Button>
                            </div>
                          </div>
                        </AvForm>
                      </>
                    )}
                  </div>
                  {status == 500 && (
                    <div className="text-center" style={{ marginTop: "20px" }}>
                      <Button
                        className="login-button"
                        onClick={handleBackToLogin}
                        type="submit"
                        //disabled={!isFormValid}
                      >
                        BACK TO LOGIN
                      </Button>
                    </div>
                  )}
                </div>
              </>
            </Col>
          </Row>
        </Container>
      </BackgroundContainer>
    </React.Fragment>
  );
};
export default ChangePassword;
