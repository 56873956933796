import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Modal } from "@material-ui/core";
//import DriversComponent from "../DriversOld/DriversComponent";
import { useMountEffect } from "app/utils/hooks";
import "./DriversInformationPOC.scss";
import Title from "app/views/commonComponents/Title/Title";
import profileImage from "../../../../../../assets/images/driverDetails/profileImage.png";
import FieldNameValue from "../../../../commonComponents/FieldNameValue/FieldNameValue";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import updateInformation from "../../../../../../assets/images/profile/updateInormation.svg";
import deactivate from "../../../../../../assets/images/poc/deactivate.svg";
import avatarProfile from "../../../../../../assets/images/poc/avatarProfile.svg";
import assignVehicleWhite from "../../../../../../assets/images/poc/assignVehicleWhite.svg";
import assignVehicleBlack from "../../../../../../assets/images/poc/assignVehicleBlack.svg";
import AssignVehicle from "../AssignVehicle/AssignVehicle/AssignVehicle";
import UpdateDriverModal from "../UpdateDriver/UpdateDriverModal/UpdateDriverModal";
import UpdateDriverInformation from "../UpdateDriver/UpdateDriverInformation/UpdateDriverInformation";
import InputMask from "react-input-mask";
import OptionsMenu from "app/views/commonComponents/OptionsMenu/OptionsMenu";
import dropdownDownArrow from "../../../../../../assets/images/poc/dropdownDownArrow.svg";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import toastIcon from "../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Dropdown, Space } from "antd";
const DriversInformationPOC = ({
  fullName,
  firstName,
  lastName,
  middleName,
  suffix,
  preferrdName,
  phoneNumber,
  planId,
  email,
  homeAddress,
  driverClassification,
  initialHireDate,
  city,
  state,
  zip,
  dateOfBirth,
  vehicleNo,
  vehicleId,
  driverId,
  assignCheck,
}) => {
  const [showAssignTruck, setShowAssignTruck] = useState(false);
  const [showUpdateDriver, setShowUpdateDriver] = useState(false);
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [selectedOptionsMenuItem, setSelectedOptionsMenuItem] = useState(null);
  const [assigned, setAssigned] = useState(false);
  const [updated, setUpdated] = useState(false);
  const handleAssignedValue = (value) => {
    console.log("isVehicleAssigned6", value);
    setAssigned(value);
    toast("Vehicle is assigned.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleUpdatededValue = (value) => {
    console.log("isVehicleAssigned6", value);
    setUpdated(value);
    toast("Driver is updateded.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log("middleName", middleName);
  const newFullName = `${firstName} ${
    middleName ? middleName + " " : ""
  }${lastName} ${suffix ? suffix : ""}`;
  const newHomeAddress = `${homeAddress}, ${city}, ${state}\t\t${zip}`;
  // Assuming phoneNumber is a string containing the phone number
  //const phoneNumberFormat = phoneNumber ? phoneNumber : "";
  // Define a regular expression to match the phone number format
  //const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/;
  const menuList = [{ item: "Delete", icon: "" }];
  const formatPhoneNumber = (data) => {
    // Remove all non-digit characters
    const cleaned = data.replace(/\D/g, "");

    // Apply formatting
    const formatted = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
    return formatted;
  };
  // Use replace method to format the phone number
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  const handleOpenAssignTruck = () => {
    setShowAssignTruck(true);
  };
  const handleCloseAssignTruck = () => {
    setShowAssignTruck(false);
  };
  const handleOpenUpdateDriver = () => {
    setShowUpdateDriver(true);
  };
  const handleCloseUpdateDriver = () => {
    setShowUpdateDriver(false);
  };
  const handleMenuClick = (index) => {
    setOptionsMenuOpen(!optionsMenuOpen);
  };
  const handleOptionsMenu = (selectedItem) => {
    console.log("SelectedItem", selectedItem);
    setSelectedOptionsMenuItem(selectedItem);
    setOptionsMenuOpen(false);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelectedValue, setDropdownSelectedValue] = useState("");
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const handleDropdownChange = (value) => {
    setDropdownSelectedValue(value);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item (disabled)
        </a>
      ),
      icon: <SmileOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];
  const options = [
    "Woman",
    "Man",
    "Straight",
    "Gay",
    "Lesbian",
    "Asexual",
    "Bisexual",
    "Demisexual",
    "Bicurious",
    "Pansexual",
    "Queer",
    "Aromantic",
  ];
  console.log("driverId1", driverId);
  console.log("isVehicleAssigned5", assigned);
  return (
    <>
      <div className="informationConatiner">
        <div className="profile-title">
          <div style={{ flex: "1" }}>
            <Title name={"Information"} type={"card"} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            {assignCheck == false && (
              <div style={{ flex: "" }}>
                <Buttons
                  dimensions={"responsive"}
                  iconPosition={"left"}
                  buttonType={"primary"}
                  size={"small"}
                  imageReplace={true}
                  onMouseEnterImage={assignVehicleBlack}
                  onMouseLeaveImage={assignVehicleWhite}
                  onClick={handleOpenAssignTruck}
                >
                  Assign Vehicle
                </Buttons>
              </div>
            )}
            <div style={{ flex: "" }}>
              <Buttons
                dimensions={"responsive"}
                iconPosition={"left"}
                buttonType="secondary"
                size="small"
                image={updateInformation}
                onClick={handleOpenUpdateDriver}
                hover={false}
                color={"#000000"}
              >
                Update Information
              </Buttons>
            </div>
            <>
              {/* <div style={{ flex: "" }}>
              <Buttons
                dimensions={"responsive"}
                iconPosition="left"
                buttonType="secondary"
                size="small"
                image={deactivate}
              >
                Deactivate
              </Buttons>
            </div> */}
            </>
            {/* <OptionsMenu
              menuList={menuList}
              onClickMenuItem={handleOptionsMenu}
              onClick={handleMenuClick}
              optionsMenuOpen={optionsMenuOpen}
              //statusPopupOpen={false}
            /> */}
          </div>
        </div>
        <div className="informationDetails">
          <div className="profileImage">
            <img src={avatarProfile} alt="Profile Image" />{" "}
          </div>
          <div style={{ width: "100%" }}>
            <div className="detailsSection">
              <div className="valueConatinerPOC">
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Full Name"}
                    fileldValue={newFullName ? newFullName : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  {" "}
                  <FieldNameValue
                    fieldName={"Preferred Name"}
                    fileldValue={preferrdName ? preferrdName : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Driver Id"}
                    fileldValue={driverId ? driverId : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  {" "}
                  <FieldNameValue
                    fieldName={"Vehicle Id"}
                    fileldValue={vehicleId ? vehicleId : "--"}
                  />
                </div>
              </div>
              <div className="valueConatiner">
                <div style={{ flex: "" }}>
                  {" "}
                  <FieldNameValue
                    fieldName={"Mobile Phone Number"}
                    fileldValue={
                      formattedPhoneNumber
                        ? formattedPhoneNumber
                        : phoneNumber
                        ? phoneNumber
                        : "--"
                    }
                  />
                </div>
                <div style={{ flex: "" }}>
                  {" "}
                  <FieldNameValue
                    fieldName={"Email Address"}
                    fileldValue={email ? email : "--"}
                  />
                </div>
              </div>
              <div className="valueConatiner">
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Home Address"}
                    fileldValue={newHomeAddress ? newHomeAddress : "--"}
                  />
                </div>
              </div>
            </div>
            <hr className="horizontalLine" />
            <div className="valueConatiner" style={{ marginTop: "40px" }}>
              <div style={{ flex: "" }}>
                {" "}
                <FieldNameValue
                  fieldName={"Driver Classification"}
                  fileldValue={
                    driverClassification ? driverClassification : "--"
                  }
                />
              </div>
              <div style={{ flex: "" }}>
                <FieldNameValue
                  fieldName={"Pay Plan"}
                  fileldValue={planId ? planId : "--"}
                />
              </div>

              <div style={{ flex: "" }}>
                <FieldNameValue
                  fieldName={"Initial Hire Date"}
                  fileldValue={initialHireDate ? initialHireDate : "--"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showAssignTruck}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //disableBackdropClick
        style={{ overflowY: "scroll" }}
      >
        <AssignVehicle
          onClose={handleCloseAssignTruck}
          driverId={driverId}
          sendVehicleAssignd={handleAssignedValue}
        />
      </Modal>
      <Modal
        open={showUpdateDriver}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //disableBackdropClick
        style={{ overflowY: "scroll" }}
      >
        <UpdateDriverInformation
          onClose={handleCloseUpdateDriver}
          driverId={driverId}
          fullName={fullName}
          firstName={firstName}
          lastName={lastName}
          middleName={middleName}
          suffix={suffix ? suffix : ""}
          preferrdName={preferrdName}
          phoneNumber={phoneNumber}
          planId={planId}
          email={email}
          homeAddress={homeAddress}
          city={city ? city : ""}
          state={state ? state : ""}
          zip={zip ? zip : ""}
          dateOfBirth={dateOfBirth ? dateOfBirth : ""}
          driverClassification={driverClassification}
          initialHireDate={initialHireDate}
          vehicleNo={vehicleNo}
          onDriverUpdated={handleUpdatededValue}
        />
      </Modal>
      <ToastContainer
        className={"toast-message"}
        toastStyle={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 12px 8px 8px",
          background: "rgba(0, 188, 249, 0.1)",
          borderRadius: "54px",
          color: "#00bfff",
          fontFamily: "Red Hat Display",
          fontSize: "14px",
          fontWeight: "600",
          //height: "36px",
          minHeight: "none",
        }}
        hideProgressBar={true}
        closeButton={<img src={toastCross} />}
        icon={<img src={toastIcon} />}
      />
    </>
  );
};
export default DriversInformationPOC;
