import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Container,
} from "reactstrap";
import "./AdvancedDetails.scss";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
import CustomDropdown from "app/views/commonComponents/CustomDropdown/CustomDropdown";
import showAdvancedDetailsIcon from "assets/images/addLoad/showAdvancedDetails.svg";
import ToggleSwitch from "app/views/commonComponents/ToggleSwitch/ToggleSwitch";
import ToggleInput from "app/views/commonComponents/ToggleInput/ToggleInput";
const AdvancedDetails = ({
  billLandingNo,
  sealNo,
  authorisation,
  piecesNo,
  grossWeight,
  dhMiles,
  speedToggleValue,
  loadBoardToggleValue,
  hazmatToggleValue,
  refrigerantToggleValue,
  selectedScale,
  selectedTemperature,
  enterdedTemperature,
  point,
  temperaturesCelsius,
  temperaturesFahrenheit,
  handleBillLandingNo,
  handleSealNo,
  handleAuthorisation,
  handlePiecesNo,
  handleDHMiles,
  handleGrossWeight,
  scaleItems,
  handleTemperatureScaleType,
  handleSpeedToggleValue,
  handleLoadBoardToggleValue,
  handleHazmatToggleValue,
  handleRefrigerantToggleValue,
  handleTemperatureSelection,
  handleTemperatureEntered,
  handlePoint,
  billNumber,
}) => {
  const [showAdvancedDetails, setShowAdvancedDetails] = useState(true);
  const handleShowAdvancedDetails = () => {
    setShowAdvancedDetails(!showAdvancedDetails);
  };
  return (
    <>
      <div className="advancedDetailsSection">
        <div
          className="advancedDetailsTitleContainer"
          style={{
            borderBottom: showAdvancedDetails
              ? "1px solid  rgba(195, 206, 222, 0.6)"
              : "",
          }}
        >
          <div className="advancedDetailsTitle">Advance Details</div>
          {/* <div
            className="advancedDetailsDownArrow"
            onClick={handleShowAdvancedDetails}
          >
            {!showAdvancedDetails ? (
              <img src={showAdvancedDetailsIcon} />
            ) : (
              <img
                src={showAdvancedDetailsIcon}
                style={{ transform: showAdvancedDetails && "rotate(180deg)" }}
              />
            )}
          </div> */}
        </div>
        {showAdvancedDetails && (
          <div className="advancedDetailsContainer">
            <div className="rowContent">
              <AddInputField
                fieldName="Bill of Lading No."
                fieldValue={billLandingNo}
                onChange={handleBillLandingNo}
                inputType="number"
                style={{
                  width: "100%",
                  //background: "#ffffff",
                }}
                background="#ffffff"
              />
              <AddInputField
                fieldName="Seal No."
                fieldValue={sealNo}
                onChange={handleSealNo}
                inputType="number"
                style={{ width: "100%" }}
                background="#ffffff"
              />
              <AddInputField
                fieldName="Pick-up or Authorisation #"
                fieldValue={authorisation}
                inputType="text"
                onChange={handleAuthorisation}
                style={{ width: "100%" }}
                autoComplete="tel"
                background="#ffffff"
              />
            </div>
            <div className="rowContent">
              <AddInputField
                fieldName="No. of Pieces"
                fieldValue={piecesNo}
                onChange={handlePiecesNo}
                inputType="number"
                style={{
                  width: "100%",
                  //background: "#ffffff",
                }}
                background="#ffffff"
              />
              <AddInputField
                fieldName="Gross Weight"
                fieldValue={grossWeight}
                onChange={handleGrossWeight}
                inputType="number"
                style={{ width: "100%" }}
                background="#ffffff"
              />
              <AddInputField
                fieldName="DH Miles"
                fieldValue={dhMiles}
                inputType="number"
                onChange={handleDHMiles}
                style={{ width: "100%" }}
                autoComplete="tel"
                background="#ffffff"
              />
            </div>
            <div className="rowContent">
              <ToggleInput
                fieldName={"<75 Speed"}
                onToggleParent={handleSpeedToggleValue}
              />
              <ToggleInput
                fieldName={"Load Board"}
                onToggleParent={handleLoadBoardToggleValue}
              />
              <ToggleInput
                fieldName={"Hazmat"}
                onToggleParent={handleHazmatToggleValue}
              />
              <ToggleInput
                fieldName={"Refrigerant"}
                onToggleParent={handleRefrigerantToggleValue}
              />
            </div>
            {refrigerantToggleValue && (
              <div className="rowContent">
                <CustomDropdown
                  fieldName="Scale"
                  items={scaleItems}
                  selectedItem={selectedScale}
                  onSelect={handleTemperatureScaleType}
                  background={"#ffffff"}
                />
                {console.log("selectedScale", selectedScale)}
                {selectedScale == "C" ? (
                  <InputDropdown
                    fieldName="Temperature"
                    dropdownOptions={temperaturesCelsius}
                    initialValue={""}
                    selectedValue={selectedTemperature || enterdedTemperature}
                    onValueSelection={handleTemperatureSelection}
                    onValueEnter={handleTemperatureEntered}
                    inputType="textNumber"
                    style={{ width: "100%" }}
                    background="#ffffff"
                  />
                ) : selectedScale == "F" ? (
                  <InputDropdown
                    fieldName="Temperature"
                    dropdownOptions={temperaturesFahrenheit}
                    initialValue={""}
                    selectedValue={selectedTemperature || enterdedTemperature}
                    onValueSelection={handleTemperatureSelection}
                    onValueEnter={handleTemperatureEntered}
                    inputType="textNumber"
                    style={{ width: "100%" }}
                    background="#ffffff"
                  />
                ) : (
                  <InputDropdown
                    fieldName="Temperature"
                    dropdownOptions={temperaturesFahrenheit}
                    initialValue={""}
                    selectedValue={selectedTemperature || enterdedTemperature}
                    onValueSelection={handleTemperatureSelection}
                    onValueEnter={handleTemperatureEntered}
                    inputType="textNumber"
                    style={{ width: "100%" }}
                    background="#ffffff"
                  />
                )}
                <AddInputField
                  fieldName="Set Point"
                  fieldValue={point}
                  inputType="number"
                  onChange={handlePoint}
                  style={{ width: "100%" }}
                  autoComplete="tel"
                  background="#ffffff"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default AdvancedDetails;
