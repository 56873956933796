import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import { Modal } from "@material-ui/core";
//import DriversComponent from "../DriversOld/DriversComponent";
import { useMountEffect } from "app/utils/hooks";
import "./VehicleInformationPOC.scss";
import Title from "app/views/commonComponents/Title/Title";
import vehicleImage from "../../../../../../assets/images/poc/vehicleImage.png";
import FieldNameValue from "../../../../commonComponents/FieldNameValue/FieldNameValue";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import updateInformation from "../../../../../../assets/images/profile/updateInormation.svg";
import UpdateVehicleInformation from "../UpdateVehicleInformation/UpdateVehicleInformation";
import toastIcon from "../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const VehicleInformationPOC = ({
  make,
  model,
  type,
  year,
  vinNumber,
  colour,
  licensePlateNumber,
  stateRegistration,
  loadsCompleted,
  currentDriver,
  milesCovered,
  dateInService,
  vehicleId,
}) => {
  const [showUpdateVehicle, setShowUpdateVehicle] = useState(false);
  const [isVehicleUpdated, setIsVehicleUpdated] = useState(false);
  const handleOpenUpdateVehicle = () => {
    setShowUpdateVehicle(true);
  };
  const handleCloseUpdateVehicle = () => {
    setShowUpdateVehicle(false);
  };
  const handleUpdatededValue = (value) => {
    console.log("isVehicleAssigned6", value);
    setIsVehicleUpdated(value);
    toast("Vehicle is updateded.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log("vehiclId2", vehicleId);
  return (
    <>
      <div className="informationConatiner">
        <div className="profile-title">
          <div style={{ flex: "" }}>
            <Title name={"VEHICLE INFORMATION"} type={"card"} />
          </div>
          <div style={{ flex: "" }}>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="secondary"
              size="small"
              image={updateInformation}
              onClick={handleOpenUpdateVehicle}
              hover={false}
              color={"#000000"}
            >
              Update Information
            </Buttons>
          </div>
        </div>
        <div className="informationDetails">
          <div className="profileImage">
            <img src={vehicleImage} alt="Profile Image" />{" "}
          </div>
          <div style={{ width: "100%" }}>
            <div className="detailsSection">
              <div className="valueConatinerPOC">
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Make"}
                    fileldValue={make ? make : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Model"}
                    fileldValue={model ? model : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Type"}
                    fileldValue={type ? type : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Year"}
                    fileldValue={year ? year : "--"}
                  />
                </div>
              </div>
              <div className="valueConatiner">
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"Color"}
                    fileldValue={colour ? colour : "--"}
                  />
                </div>
              </div>
              <hr className="horizontalLine" />
              <div className="valueConatiner">
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"VIN"}
                    fileldValue={vinNumber ? vinNumber : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"License Plate No."}
                    fileldValue={licensePlateNumber ? licensePlateNumber : "--"}
                  />
                </div>
                <div style={{ flex: "" }}>
                  <FieldNameValue
                    fieldName={"State Registration "}
                    fileldValue={stateRegistration ? stateRegistration : "--"}
                  />
                </div>
              </div>
            </div>
            <hr className="horizontalLine" />
            <div className="valueConatiner" style={{ marginTop: "40px" }}>
              <div style={{ flex: "" }}>
                <FieldNameValue
                  fieldName={"Current Driver"}
                  fileldValue={currentDriver ? currentDriver : "--"}
                />
              </div>
              <div style={{ flex: "" }}>
                <FieldNameValue
                  fieldName={"Miles Covered"}
                  fileldValue={milesCovered ? milesCovered : "--"}
                />
              </div>
              <div style={{ flex: "" }}>
                {" "}
                <FieldNameValue
                  fieldName={"Loads Completed"}
                  fileldValue={loadsCompleted ? loadsCompleted : "--"}
                />
              </div>
              <div style={{ flex: "" }}>
                <FieldNameValue
                  fieldName={"Date In Service"}
                  fileldValue={dateInService ? dateInService : "--"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showUpdateVehicle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //disableBackdropClick
        style={{ overflowY: "scroll" }}
      >
        <UpdateVehicleInformation
          onClose={handleCloseUpdateVehicle}
          vehicleId={vehicleId}
          make={make}
          model={model}
          type={type}
          year={year}
          vinNumber={vinNumber}
          colour={colour}
          licensePlateNumber={licensePlateNumber}
          stateRegistration={stateRegistration}
          onVehicleUpdated={handleUpdatededValue}
        />
      </Modal>
      <ToastContainer
        className={"toast-message"}
        toastStyle={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 12px 8px 8px",
          background: "rgba(0, 188, 249, 0.1)",
          borderRadius: "54px",
          color: "#00bfff",
          fontFamily: "Red Hat Display",
          fontSize: "14px",
          fontWeight: "600",
          //height: "36px",
          minHeight: "none",
        }}
        hideProgressBar={true}
        closeButton={<img src={toastCross} />}
        icon={<img src={toastIcon} />}
      />
    </>
  );
};
export default VehicleInformationPOC;
