import React from "react";
import PropTypes from "prop-types";
import "./RadioButtonGroup.scss";

const RadioButtonGroup = ({ fieldName, options, selectedOption, onChange }) => {
  return (
    <div className="radioButtonGroup">
      <label className="radioGroupName">{fieldName}:</label>
      {options.map((option) => (
        <div className="radioButton">
          <label key={option.value} className="radioButtonLabel">
            {" "}
            {option.label}
          </label>
          <input
            type="radio"
            className="radioButtonInput"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={() => onChange(option.value)}
          />
        </div>
      ))}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
