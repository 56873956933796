import { PAGE_URLS } from 'app/routes/app';
import _find from 'lodash/find';

const USER_TASK_CONFIG = {
  DRIVER_ONBOARDING_STEPS_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_REGISTRATION_STEPS,
    progress: 5,
    key: 'driver_onboarding_steps_user_task',
  },
  DRIVING_LICENSE_UPLOAD_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_REGISTRATION_LICENSE_DETAILS,
    progress: 10,
    key: 'driving_license_upload_user_task',
  },
  DRIVING_LICENSE_FRONT_UPLOAD_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_REGISTRATION_LICENSE_DETAILS,
    progress: 12,
    key: 'driving_license_front_upload_user_task',
  },
  DRIVING_LICENSE_BACK_UPLOAD_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_REGISTRATION_LICENSE_DETAILS,
    progress: 15,
    key: 'driving_license_back_upload_user_task',
  },
  DRIVING_LICENSE_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_REGISTRATION_LICENSE_DETAILS,
    progress: 20,
    key: 'driving_license_details_form_user_task',
  },
  PERSONAL_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 30,
    key: 'personal_details_form_user_task',
  },
  EMPLOYMENT_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 40,
    key: 'employment_details_form_user_task',
  },
  EDUCATION_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 50,
    key: 'education_details_form_user_task',
  },
  DRIVING_LICENSE_HISTORY_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 60,
    key: 'driving_license_history_form_user_task',
  },
  MEDICAL_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 70,
    key: 'medical_details_form_user_task',
  },
  USER_REVIEW_AND_CONSENT_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 80,
    key: 'provide_consent_form_user_task',
  },
  TERMS_CONDITIONS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 90,
    key: 'terms_conditions_form_user_task',
  },
  REVIEW_APPLICATION_DETAILS_FORM_USER_TASK: {
    page_url: PAGE_URLS.DRIVER_ONBOARDING,
    progress: 100,
    key: 'review_application_details_user_task',
  },
};

export const DRIVER_ONBOARDING_USER_TASKS = (() => {
  const tasks = {};
  Object.keys(USER_TASK_CONFIG).forEach((k) => {
    tasks[k] = USER_TASK_CONFIG[k].key;
  });
  return tasks;
})();

export const GET_USER_TASK_PROGRESS = (USER_TASK_NAME) => {
  // let redirection_url = PAGE_URLS.DRIVER_REGISTRATION_STEPS;
  const task = _find(USER_TASK_CONFIG, (x) => {
    return x.key === USER_TASK_NAME;
  });
  if (task) {
    return task.progress;
  } else {
    console.error(
      `No such task with name ${USER_TASK_NAME} exist! Setting the default progress to 0!`,
    );
    return 0;
  }
};
export const GET_USER_TASK_REDIRECT_PAGE = (USER_TASK_NAME) => {
  let redirection_url = PAGE_URLS.DRIVER_REGISTRATION_STEPS;
  // return PAGE_URLS.DRIVER_REGISTRATION_LICENSE_DETAILS;
  const task = _find(USER_TASK_CONFIG, (x) => {
    return x.key === USER_TASK_NAME;
  });
  if (task) {
    return task.page_url;
  } else {
    console.error(
      `No such task with name ${USER_TASK_NAME} exist! Redirecting to default page!`,
    );
    return redirection_url;
  }
};
