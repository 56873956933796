import React, { Component } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./Breadcrumbs.scss";

// i18n
import { withTranslation } from "react-i18next";

const Breadcrumbs = ({ items }) => {
  console.log("items", items);
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {items.map((item, index) => (
        <span className="breadcrumb" key={index}>
          {index > 0 && ""}
          {index === items.length - 1 ? (
            <Link to={item.link} className="titleLink">
              {item.title}
            </Link>
          ) : (
            <Link to={item.link} className="titleLink">
              {item.title}
            </Link>
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
