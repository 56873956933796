import { combineReducers } from "redux";
import app from "./app";
import { CLEAR_REDUX_STORE } from "app/store/state/app/types";

const appReducer = combineReducers({
  app,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_REDUX_STORE) {
    const { app } = state;
    return appReducer({ app }, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
