import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./DriversSettelmentsPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import backRightArrow from "../../../../../../assets/images/poc/backRightArrow.svg";
import userIcon from "../../../../../../assets/images/poc/userIcon.svg";
import { Link } from "react-router-dom";
import QuickActionsDropdown from "app/views/commonComponents/QuickActionsDropdown/QuickActionsDropdown";
import driverProfile from "../../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../../assets/images/drivers/accidents.svg";
import calendarRemove from "../../../../../../assets/images/drivers/calendarRemove.svg";
import moneys from "../../../../../../assets/images/drivers/moneys.svg";
import presentionChart from "../../../../../../assets/images/drivers/presentionChart.svg";
import star from "../../../../../../assets/images/drivers/star.svg";
import previousBlackArrow from "../../../../../../assets/images/poc/previousBlackArrow.svg";
import nextBlackArrow from "../../../../../../assets/images/poc/nextBlackArrow.svg";
import previousGreyArrow from "../../../../../../assets/images/poc/previousGreyArrow.svg";
import nextGreyArrow from "../../../../../../assets/images/poc/nextGreyArrow.svg";
import grossArrowDown from "../../../../../../assets/images/poc/grossArrowDown.svg";
import enterArrow from "../../../../../../assets/images/poc/enterArrow.svg";
import DriversTablePOC from "../../TablePOC/TablePOC";
import { useParams, useHistory } from "react-router-dom";
import Icon from "app/views/commonComponents/Icon/Icon";
import moment from "moment-timezone";
import AdditionalPaymentTable from "../../SettlementsDetails/AdditionalPaymentTable/AdditionalPaymentTable";
import {
  getDriverDetailsSettlementsData,
  getDriverSettlementsListData,
  getDriverSettelmentDetailsData,
} from "../../../../../apiServices/apiServices";
const DriversSettelmentsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0); // Track the index of the currently displayed driver
  const [driverName, setDriverName] = useState("");
  const [
    settelmentsDriverDetailsOriginalData,
    setSettelmentsDriverDetailsOriginalData,
  ] = useState([]);
  const [loadDetailsTableDataNew, setLoadDetailsTableDataNew] = useState([]);
  const [statementDate, setStatementDate] = useState("");
  const [settelmentsListOriginalData, setSettlementsListOriginalData] =
    useState([]);
  const [driverSettlementsOriginalData, setDriverSettlementsOriginalData] =
    useState([]);
  const history = useHistory();
  const { driverId, uploadIdDriver, datesDriver, assignedTruck } = useParams();
  const breadcrumbItems = [
    { title: "Drivers", link: "/drivers" },
    {
      title: statementDate ? statementDate : "",
      link: `/drivers/${driverId}/${assignedTruck}`,
    },
    {
      title: driverName,
    },
  ];
  // const driverData = [
  //   { icon: accident, values: "2", title: "Accidents" },
  //   { icon: inspections, values: "21", title: "Inspections" },
  //   { icon: safetyBonuses, values: "12", title: "Safety Bonuses" },
  //   { icon: loads, values: "72", title: "Loads" },
  //   { icon: milesLoaded, values: "82,325", title: "Loaded Miles" },
  //   { icon: daysOnboard, values: "1,032", title: "Days On-Board" },
  //   { icon: inService, values: "86.3%", title: "In-Service" },
  //   // Add more data items as needed
  // ];
  const driverData = [
    { icon: driverProfile, values: "19", title: "Loads" },
    //{ icon: userTag, values: "23,435", title: "On Fleet Drivers" },
    { icon: star, values: "$10,978", title: "Revenue" },
    { icon: accidents, values: "$2,978", title: "Other Revenue" },
    { icon: calendarRemove, values: "$1,978", title: "Deduction" },
    { icon: moneys, values: "$30,544", title: "Net Pay" },
    //{ icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const tabsData = [
    { id: 0, label: "Profile" },
    { id: 1, label: "Loads" },
    { id: 2, label: "Driver History" },

    // Add more tabs as needed
  ];
  const settlementsTableDataALL = settelmentsListOriginalData.map((driver) => ({
    driverId: driver.driver_id,
    driverName: driver.driver_name,
    loadsDelivered: driver.loads_delivered.toLocaleString(),
    milesLoaded: driver.loaded_miles.toLocaleString(),
    rpm: driver.rpm
      ? `$${driver.rpm.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : null, // Formatting rpm with 2 decimal places
    totalRevenue: `$${driver.total_revenue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    totalOtherRevenue: `$${driver.other_revenue_amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} •  ${driver.other_revenue_count.toLocaleString()}`,
    totalDeduction: `$${driver.deduction_amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} • ${driver.deduction_count.toLocaleString()}`,
    netPay: `$${driver.net_pay.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    status: capitalizeFirstLetter(driver.status),
  }));
  console.log("driversSettlementsTableDataALL", settlementsTableDataALL);
  console.log("driversSettlementsTableDataALL1", settlementsTableDataALL[0]);
  const settlementsTableHeaders = {
    driverId: "Driver Id.",
    driverName: "Driver Name",
    totalLoad: "Total Load",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    totalRevenue: "Total Revenue",
    totalOtherRevenue: "Total Other Revenue",
    totalDeduction: "Total Deduction",
    netPay: "Net Pay",
    status: "Status",
  };
  const settlementsTableDataSettelmentDate =
    driverSettlementsOriginalData != null &&
    driverSettlementsOriginalData.map((settlement) => {
      const dateString1 = settlement.statement_date
        ? settlement.statement_date
        : "";
      let originalDate = moment.utc(dateString1);
      const month1 = moment(originalDate).format("MMM");
      const day1 = moment(originalDate).format("DD");
      const year1 = moment(originalDate).year();
      const formattedDate1 = `${month1} ${day1}, ${year1}`;
      return {
        datesDriver: formattedDate1,
        totalLoad: settlement.total_loads
          ? settlement.total_loads.toLocaleString()
          : "0",
        rpm: settlement.rpm ? settlement.rpm.toLocaleString() : "$0.00",
        loadedMiles: settlement.loaded_miles
          ? settlement.loaded_miles.toLocaleString()
          : "0",
        totalRevenue: settlement.total_load_revenue
          ? `$${settlement.total_load_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "0.00",
        totalOtherRevenue: `$${
          settlement.total_other_revenue
            ? settlement.total_other_revenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        } • ${
          settlement.total_other_revenue_count
            ? settlement.total_other_revenue_count.toLocaleString()
            : "0"
        }`,
        totalDeduction: `$${
          settlement.total_deductions
            ? settlement.total_deductions.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        } • ${
          settlement.total_deductions_count
            ? settlement.total_deductions_count.toLocaleString()
            : "0"
        }`,
        netPay: `$${
          settlement.driver_revenue
            ? settlement.driver_revenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        }`,
        status: settlement.status ? settlement.status : "-", // You can replace this with the status if available in your data
        uploadIdDriver: settlement.upload_id,
        driverId: driverId,
        assignedTruck: assignedTruck,
      };
    });
  console.log(
    "settlementsTableDataSettelmentDate",
    settlementsTableDataSettelmentDate
  );
  const settlementsTableData = [
    {
      driverId: "KH4235",
      driverName: "Black, Marvin",
      loadsDelivered: "3",
      MilesLoaded: "300",
      rpm: "$30.25",
      totalRevenue: "$8,729.16",
      totalOtherRevenue: "$229.00    •    2",
      totalDeduction: "$229.00    •    3",
      netPay: "$229.00 ",
      status: "Review",
    },
    {
      driverId: "KH4235",
      driverName: "Cooper, Kristin",
      totalLoad: "4",
      loadedMiles: "123",
      rpm: "$20.25",
      totalRevenue: "$10,157.75",
      totalOtherRevenue: "$229.00    •    2",
      totalDeduction: "$0.00    •    0",
      netPay: "$0.0",
      status: "Review",
    },
    {
      driverId: "KH4235",
      driverName: "Nguyen, Shane",
      totalLoad: "2",
      loadedMiles: "321",
      rpm: "$12.00",
      totalRevenue: "$10,157.75",
      totalOtherRevenue: "$0.00    •    0",
      totalDeduction: "$48.00    •    1",
      netPay: "$48.00 ",
      status: "Pushed",
    },
    {
      driverId: "KH4235",
      driverName: "Henry, Arthur",
      totalLoad: "1",
      loadedMiles: "231",
      rpm: "$20.50",
      totalRevenue: "$10,879.91",
      totalOtherRevenue: "$48.00    •    1",
      totalDeduction: "$110.00    •    2",
      netPay: "$110.00",
      status: "Pushed",
    },
    {
      driverId: "KH4235",
      driverName: "Miles, Esther",
      totalLoad: "5",
      loadedMiles: "43",
      rpm: "$15.75",
      totalRevenue: "$11,076.90",
      totalOtherRevenue: "$48.00    •    4",
      totalDeduction: "$0.00    •    0",
      netPay: "$0.00 ",
      status: "Pushed",
    },
    {
      driverId: "KH4235",
      driverName: "Flores, Juanita",
      totalLoad: "3",
      loadedMiles: "213",
      rpm: "$13.75",
      totalRevenue: "$9,945.04",
      totalOtherRevenue: "$0.00    •    0",
      totalDeduction: "$0.00    •    0",
      netPay: "$0.00",
      status: "Pushed",
    },
  ];
  const settlementsTableDataAPI =
    settelmentsDriverDetailsOriginalData &&
    settelmentsDriverDetailsOriginalData.map((item, index) => {
      return {
        driverId: item.driver_id,
        driverName: item.driver_name,
        totalLoad: item.loads_delivered
          ? item.loads_delivered.toLocaleString()
          : "0",
        milesLoaded: item.loaded_miles
          ? item.loaded_miles
          : "0"
          ? item.loaded_miles.toLocaleString()
          : "0",
        rpm: item.rpm
          ? `$${item.rpm.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        totalRevenue: item.total_revenue
          ? `$${item.total_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        totalOtherRevenue: item.other_revenue_amount
          ? `$${item.other_revenue_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}  •  ${item.other_revenue_count.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        totalDeduction: item.deduction_amount
          ? `$${item.deduction_amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} •  ${item.deduction_count.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        netPay: item.net_pay
          ? `$${item.net_pay.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        status: item.status ? capitalizeFirstLetter(item.status) : "", // Assuming you have a function to determine the status
      };
    });
  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const loadsTableHeaders = {
    loadID: "Load Id.",
    date: "Date",
    originDestination: "Origin & Destination",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    itemisedTotal: "Itemized Total",
    gross: "%Gross",
    contractorAmount: "Contractor Amount",
  };
  const loadsOriginalArrays = settelmentsDriverDetailsOriginalData.map((item) =>
    item.loads ? item.loads : ""
  );

  console.log("loadsOriginalArrays", loadsOriginalArrays);
  const loadsTableData = loadsOriginalArrays
    .map((loadArray) => {
      return loadArray.map((item, index) => {
        //console.log("item.pay_description", item.pay_description);
        const loadedMilesWithCommas = parseInt(
          item.loaded_miles
        ).toLocaleString("en");
        const originalDateString = item.date;
        let originalDate = moment.utc(originalDateString);
        const month = moment(originalDate).format("MMM");
        const day = moment(originalDate).format("DD");
        const year = moment(originalDate).year();
        const formattedDate = `${month} ${day}, ${year}`;
        return {
          loadNo: item.load_id,
          date: formattedDate,
          originDestination: `${item.origin} - ${item.destination}`,
          milesLoaded: loadedMilesWithCommas,
          // payDescriptions: item.pay_description,
          rpm: item.rpm
            ? `$${item.rpm.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : "$0.00",
          itemisedTotal: `$${item.total_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          gross: grossArrowDown, // You need to specify how to calculate this
          contractorAmount: `$${item.contractor_amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        };
      });
    })
    .flat();

  const loadDetailsTableHeaders = {
    loadID: "Load Id.",
    date: "Date",
    originDestination: "Origin & Destination",
    payDescription: "Pay Description",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    itemisedTotal: "Itemized Total",
    gross: "%Gross",
    contractorAmount: "Contractor Amount",
  };
  const loadsObjects = loadsOriginalArrays.flat().map((loadArray, index) => ({
    load_id: index == 0 ? enterArrow : "",
    date: "",
    originDestination: loadArray.description,
    loaded_miles: "",
    rpm: "",
    total_revenue: loadArray.total_revenue,
    gross: loadArray.gross_percentage,
    contractor_amount: loadArray.driver_total_revenue,
    pay_description: loadArray.pay_description,
  }));
  console.log("loadsOriginalArrays2", loadsObjects);
  const loadSubTableData = loadsObjects.map((item) => item.pay_description);
  // Extract each array of objects
  for (let i = 0; i < loadSubTableData.length; i++) {
    console.log(`loadDetailsArray2 ${i}:`, loadSubTableData[i]);
  }
  console.log("loadsTableData", loadsTableData);
  const subtotalLoads = loadsOriginalArrays
    .flatMap((loadArray) => loadArray.map((item) => item.contractor_amount))
    .reduce((acc, curr) => acc + curr, 0);
  loadsTableData.push({
    loadID: "",
    date: "",
    originDestination: "",
    milesLoaded: "",
    rpm: "", // Assuming you want 2 decimal places for RPM
    itemisedTotal: "", // Assuming this field is not provided in the data
    grossPercenatge: "Sub Total", // Assuming gross_percentage is a percentage
    contractorAmount: `$${subtotalLoads.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })}`,
  });
  ////////////////
  const additionalArrays = settelmentsDriverDetailsOriginalData.map((item) =>
    item.other_income_list ? item.other_income_list : ""
  );
  const additionalPaymentTableHeaders = {
    additionalPaymentDescriptions: "Additional Payment Descriptions",
    date: "Date",
    amount: "Amount",
    grossPercenatge: "%Gross",
    contractorAmount: "Contractor Amount",
  };
  const additionalPaymentTableData = additionalArrays.flatMap(
    (innerArray, indexArray) =>
      innerArray.map((item, index) => {
        console.log("itemArray", item);
        const originalDateString = item.statement_date;
        let originalDate = moment.utc(originalDateString);
        const month = moment(originalDate).format("MM");
        const day = moment(originalDate).date();
        const year = moment(originalDate).year();
        const formattedDate = `${month}-${day}-${year}`;
        // if (
        //   indexArray === additionalArrays.length - 1 &&
        //   index === innerArray.length - 1
        // ) {
        //   // Only show contractorAmount for the last row
        //   return {
        //     additionalPaymentDescriptions: "",
        //     date: "", // Use the formatted date here
        //     amount: "",
        //     grossPercenatge: `Sub Total`,
        //     contractorAmount: `$${item.contractor_amount.toLocaleString(
        //       "en-US",
        //       {
        //         maximumFractionDigits: 2,
        //       }
        //     )}`,
        //   };
        // } else {
        return {
          additionalPaymentDescriptions: item.description,
          //date: formattedDate, // Use the formatted date here
          amount: `${item.amount.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`,
          //grossPercenatge: `${item.gross_percentage}%`,
          // contractorAmount: `$${item.contractor_amount.toLocaleString("en-US", {
          //   maximumFractionDigits: 2,
          // })}`,
        };
        // }
      })
  );
  const additionalPaymentDescriptionTableData = additionalArrays.flatMap(
    (innerArray, indexArray) =>
      innerArray.map((item, index) => {
        console.log("itemArray", item);
        const originalDateString = item.statement_date;
        let originalDate = moment.utc(originalDateString);
        const month = moment(originalDate).format("MM");
        const day = moment(originalDate).date();
        const year = moment(originalDate).year();
        const formattedDate = `${month}-${day}-${year}`;
        return {
          id: item._id,
          additionalPaymentDescriptions: item.description,
          amount: `${item.contractor_amount.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`,
        };
        // }
      })
  );
  // Create the subtotal row
  const subtotalAdditionalRow = {
    additionalPaymentDescriptions: "",
    date: "", // Use the formatted date here
    amount: "",
    grossPercenatge: "Sub Total",
    contractorAmount: `$${additionalArrays
      .flat()
      .reduce((total, item) => total + item.contractor_amount, 0)
      .toLocaleString("en-US", { maximumFractionDigits: 2 })}`,
  };
  // Add the subtotal row to the end of the table data
  additionalPaymentTableData.push(subtotalAdditionalRow);
  const deductionArrays = settelmentsDriverDetailsOriginalData.map((item) =>
    item.deductions_list ? item.deductions_list : ""
  );
  const deductionDescriptionTableHeaders = {
    deductionDescription: "Deduction Descriptions",
    date: "Date",
    amount: "Amount",
    gross: "%Gross",
    contractorAmount: "Contractor Amount",
  };
  const deductionTableData = deductionArrays.flatMap((innerArray) =>
    innerArray.map((item) => ({
      deductionDescription: item.description,
      date: item.statement_date,
      amount: `$${item.amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      gross: `${item.gross_percentage}%`,
      contractorAmount: `$${item.contractor_amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    }))
  );
  const deductionDescriptionTableData = deductionArrays.flatMap((innerArray) =>
    innerArray.map((item) => ({
      id: item._id,
      deductionDescription: item.description,
      amount: `${item.contractor_amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    }))
  );
  // Calculate the subtotal
  const subtotalDeductions = deductionArrays
    .flat()
    .reduce((total, item) => total + item.contractor_amount, 0);

  // Append the "Sub Total" row
  deductionDescriptionTableData.push({
    deductionDescription: "",
    date: "", // Use the formatted date here
    amount: "",
    grossPercenatge: "Sub Total",
    contractorAmount: `$${subtotalDeductions.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })}`,
  });
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const handleRowClick = (rowIndex, driverId) => {
    console.log("ClickedrowIndex");
    console.log("ClickedrowIndex1", rowIndex);
    const clickedRowData = loadSubTableData[rowIndex];
    console.log("ClickedrowIndex2", rowIndex);
    setLoadDetailsTableDataNew(clickedRowData);
  };
  useEffect(() => {
    console.log("uploadIdDriver", uploadIdDriver);
    // Find the index of the driver object with matching driver_id
    const uploadIdIndex = settlementsTableDataSettelmentDate.findIndex(
      (uploadId) => {
        // console.log("uploadIdObj", uploadId);
        // console.log("uploadIdObj2", typeof uploadId.uploadIdDriver);
        // console.log("uploadIdObj3", typeof parseFloat(uploadIdDriver));
        return uploadId.uploadIdDriver === parseFloat(uploadIdDriver);
      }
    );
    console.log("uploadIdIndex", uploadIdIndex);

    // Check if the driver was found
    if (uploadIdIndex !== -1) {
      // Driver found, use the index
      console.log("Index of the driver:", uploadIdIndex);
      setCurrentUploadIndex(uploadIdIndex);
    } else {
      // Driver not found
      console.log("Driver not found.");
    }
  }, [uploadIdDriver, settlementsTableDataSettelmentDate]);
  // Function to handle click on next arrow
  const handleNextArrowClick = () => {
    if (currentUploadIndex < settlementsTableDataALL.length - 1) {
      setCurrentUploadIndex((prevIndex) => prevIndex + 1);
      const nextUploadIdDriver =
        settlementsTableDataSettelmentDate[currentUploadIndex + 1]
          .uploadIdDriver;
      const nextDatesDriver =
        settlementsTableDataSettelmentDate[currentUploadIndex + 1].datesDriver;
      console.log("datesDriver1", nextDatesDriver);
      getSettelmentDriverDetailsDataAPI(nextUploadIdDriver);
      history.push(
        `/drivers/${driverId}/${assignedTruck}/${nextDatesDriver}/${nextUploadIdDriver}`
      );
    }
  };
  // Function to handle click on previous arrow
  const handlePreviousArrowClick = () => {
    if (currentUploadIndex > 0) {
      setCurrentUploadIndex((prevIndex) => prevIndex - 1);
      const previousUploadIdDriver =
        settlementsTableDataSettelmentDate[currentUploadIndex - 1]
          .uploadIdDriver;
      const previousDatesDriver =
        settlementsTableDataSettelmentDate[currentUploadIndex - 1].datesDriver;
      console.log("datesDriver2", previousDatesDriver);
      getSettelmentDriverDetailsDataAPI(previousUploadIdDriver);
      history.push(
        `/drivers/${driverId}/${assignedTruck}/${previousDatesDriver}/${previousUploadIdDriver}`
      );
    }
  };
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 332; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    handleReturnDriverSettelmentsPOC();
    const originalDateString = datesDriver ? datesDriver : "";
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).format("DD");
    const year = moment(originalDate).year();
    const formattedDate = `${month} ${day}, ${year}`;
    console.log("setStatementDate1", formattedDate);
    setStatementDate(formattedDate);
    if (uploadIdDriver > 0) {
      getSettlementsListDataAPI();
      getSettelmentDriverDetailsDataAPI();
      getAllDriverSettlementsData();
    }
  }, [uploadIdDriver]);
  const getAllDriverSettlementsData = () => {
    const storedToken = localStorage.getItem("token");
    getDriverDetailsSettlementsData(driverId)
      .then((response) => {
        //console.log("insideif", response);
        console.log(
          "getAllDriverSettlementsData",
          response.data ? response.data : ""
        );
        setDriverSettlementsOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getSettlementsListDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getDriverSettlementsListData(uploadIdDriver)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getSettlementsListDataAPI", response.data);
        setSettlementsListOriginalData(response.data ? response.data : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getSettelmentDriverDetailsDataAPI = (id = uploadIdDriver) => {
    const storedToken = localStorage.getItem("token");
    getDriverSettelmentDetailsData(id, driverId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getSettelmentDriverDetailsDataAPI", response.data);
        setSettelmentsDriverDetailsOriginalData(
          response.data ? response.data : ""
        );
        const name = response.data[0].driver_name
          ? response.data[0].driver_name
          : "";
        const newName = name.split(", ").reverse().join(" ");
        setDriverName(newName);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageDriverSettelmentsPOC, setCurrentPageDriverSettelmentsPOC] =
    useState(1);
  const [
    itemsPerPageDriverSettelmentsPOC,
    setItemsPerPageDriverSettelmentsPOC,
  ] = useState(50);
  const handlePageSettelmentsChange = (pageNumber) => {
    setCurrentPageDriverSettelmentsPOC(pageNumber);
  };
  const handleItemsPerPageSettelmentsChange = (perPage) => {
    setItemsPerPageDriverSettelmentsPOC(perPage);
    setCurrentPageDriverSettelmentsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateSettelmentsAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageDriverSettelmentsPOC",
      currentPageDriverSettelmentsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageDriverSettelmentsPOC",
    //   itemsPerPageDriverSettelmentsPOC
    // );
  };
  const handleReturnDriverSettelmentsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverSettelmentsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageDriverSettelmentsPOC"
    // );
    setCurrentPageDriverSettelmentsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverSettelmentsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="details-page-design">
              <div className="detailsStickyHeaderDriverSettlementsDetails">
                <Breadcrumbs items={breadcrumbItems} />
                <div style={{ marginTop: "8px" }}>
                  <Title name={driverName} type={"page"} color={"#fff"} />
                </div>
                <div className="serialNumbersSettlements">
                  <div className="settlementIdDriverSettlementsDetails">
                    Settlement Id:
                  </div>
                  <div className="settlementValueDriverSettlementsDetails">
                    {uploadIdDriver ? uploadIdDriver : ""}
                  </div>
                </div>
                <div className="tableTitleContainerSettlementsDriver">
                  {" "}
                  <div style={{ flex: "1" }}>
                    <Title
                      name={statementDate ? statementDate : ""}
                      type={"table"}
                    />
                  </div>
                  <div style={{ flex: " " }} className="nextPreviousContainer">
                    <Icon
                      size="regular"
                      buttonType="secondary"
                      image={
                        currentUploadIndex == 0
                          ? previousGreyArrow
                          : previousBlackArrow
                      }
                      onClick={handlePreviousArrowClick}
                      disabled={currentUploadIndex == 0}
                      imageReplace={true}
                      hover={false}
                    ></Icon>
                    <Icon
                      size="regular"
                      buttonType="secondary"
                      image={
                        currentUploadIndex ==
                        settlementsTableDataSettelmentDate.length - 1
                          ? nextGreyArrow
                          : nextBlackArrow
                      }
                      onClick={handleNextArrowClick}
                      disabled={
                        currentUploadIndex ==
                        settlementsTableDataSettelmentDate.length - 1
                      }
                      imageReplace={true}
                      hover={false}
                    ></Icon>
                  </div>
                </div>
              </div>
              <div className="tableContainerSettlementsDriver">
                <div className="driversTableContainerSettlementsDriver">
                  <DriversTablePOC
                    headers={settlementsTableHeaders}
                    data={settlementsTableDataAPI}
                    activeTab={activeTab}
                    tableName={"driversSettelmentsTablePOC"}
                    rowClick={true}
                    currentPage={currentPageDriverSettelmentsPOC}
                    itemsPerPage={itemsPerPageDriverSettelmentsPOC}
                    onPageChange={handlePageSettelmentsChange}
                    onItemsPerPageChange={handleItemsPerPageSettelmentsChange}
                    onNavigateAway={handleNavigateSettelmentsAway}
                  />
                </div>
                <div
                  className="settlementsTableDetailsConatainer"
                  style={{ maxHeight: `${maxTableHeight}px` }}
                >
                  <div style={{ background: "#ffffff" }} className="loadTable">
                    <DriversTablePOC
                      headers={loadsTableHeaders}
                      data={loadsTableData}
                      subHeaders={loadDetailsTableHeaders}
                      subData={loadDetailsTableDataNew}
                      activeTab={activeTab}
                      tableName={"loadsDriversTable"}
                      rowClick={true}
                      rowClickHandler={handleRowClick}
                      currentPage={currentPageDriverSettelmentsPOC}
                      itemsPerPage={itemsPerPageDriverSettelmentsPOC}
                      onPageChange={handlePageSettelmentsChange}
                      onItemsPerPageChange={handleItemsPerPageSettelmentsChange}
                      onNavigateAway={handleNavigateSettelmentsAway}
                    />
                  </div>
                  <div style={{ background: "#ffffff" }} className="loadTable">
                    {/* <DriversTablePOC
                        headers={additionalPaymentTableHeaders}
                        data={additionalPaymentTableData}
                        activeTab={activeTab}
                        tableName={"additionalPaymentTable"}
                      /> */}
                    <AdditionalPaymentTable
                      headers={additionalPaymentTableHeaders}
                      data={additionalPaymentDescriptionTableData}
                      tableName={"additionalPaymentTable"}
                      uploadId={uploadIdDriver}
                      driverId={driverId}
                    />
                  </div>
                  <div style={{ background: "#ffffff" }} className="loadTable">
                    {/* <DriversTablePOC
                        headers={deductionDescriptionTableHeaders}
                        data={deductionDescriptionTableData}
                        activeTab={activeTab}
                        tableName={"deductionDescriptionTable"}
                      /> */}
                    <AdditionalPaymentTable
                      headers={deductionDescriptionTableHeaders}
                      data={deductionDescriptionTableData}
                      tableName={"deductionDescriptionTable"}
                      uploadId={uploadIdDriver}
                      driverId={driverId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DriversSettelmentsPOC;
