import React, { useState } from "react";
import { Button } from "reactstrap";
import whiteCrossIcon from "../../../../assets/images/buttons/whiteCrossIcon.svg";
import blackCrossIcon from "../../../../assets/images/buttons/blackCrossIcon.svg";
import disabledCrossIcon from "../../../../assets/images/buttons/disabledCrossIcon.svg";
import "./Icon.scss"; // Use a common SCSS file for styling

const Icon = ({
  color,
  onClick,
  disabled,
  buttonType, // New prop to handle button type (e.g., "secondary", "ghost","primary")
  size, //regular and small
  image,
  onMouseEnterImage,
  onMouseLeaveImage,
  style, // New prop for custom styles
  hover,
  imageReplace,
  ...rest
}) => {
  const [imageHovered, setImageHovered] = useState(false);
  const handleMouseEnter = (event) => {
    setImageHovered(true);
    event.target.style.backgroundImage = onMouseEnterImage;
  };
  const handleMouseLeave = (event) => {
    setImageHovered(false);
    event.target.style.backgroundImage = onMouseLeaveImage;
  };
  const renderIcon = () => {
    const icon = disabled
      ? disabledCrossIcon
      : buttonType == "primary"
      ? whiteCrossIcon
      : blackCrossIcon;
    //const marginRight = iconPosition === "left" ? { marginRight: "8px" } : {};
    //const marginLeft = iconPosition === "right" ? { marginLeft: "8px" } : {};
    const width = size == "small" ? { width: "16px" } : {};
    const height = size == "small" ? { height: "16px" } : {};
    return (
      <img
        src={
          hover == false
            ? image
            : imageHovered
            ? onMouseEnterImage
            : onMouseLeaveImage || image || icon
        }
        style={{
          ...width,
          ...height,
          ...style, // Merge custom styles
        }}
        alt="Icon"
      />
    );
  };
  // const renderIcon = () => {
  //   const icon = disabled
  //     ? disabledCrossIcon
  //     : buttonType === "primary"
  //     ? whiteCrossIcon
  //     : blackCrossIcon;
  //   const iconSrc = onMouseLeaveImage || image || icon; // Use onMouseLeaveImage if available, else fallback to image, else to icon
  //   const width = size === "small" ? { width: "16px" } : {};
  //   const height = size === "small" ? { height: "16px" } : {};
  //   return (
  //     <img
  //       src={iconSrc}
  //       style={{
  //         ...width,
  //         ...height,
  //         ...style, // Merge custom styles
  //       }}
  //       onMouseEnter={
  //         onMouseEnterImage ? () => onMouseEnterImage(iconSrc) : undefined
  //       } // Pass the iconSrc as an argument
  //       onMouseLeave={
  //         onMouseLeaveImage ? () => onMouseLeaveImage(iconSrc) : undefined
  //       } // Pass the iconSrc as an argument
  //       alt="Icon"
  //     />
  //   );
  // };
  return (
    <Button
      //className={`genericButton ${buttonType}`}
      className={`${
        size == "regular"
          ? buttonType == "primary"
            ? "regularPrimaryCrossIcon"
            : buttonType == "secondary"
            ? "regularSecondaryCrossIcon"
            : "regularGhostCrossIcon"
          : size == "small"
          ? buttonType == "primary"
            ? "smallPrimaryCrossIcon"
            : buttonType == "secondary"
            ? "smallSecondaryCrossIcon"
            : "smallGhostCrossIcon"
          : ""
      }`} // Apply a common class for styling
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        background:
          imageReplace && imageHovered
            ? "#ffffff"
            : hover == true
            ? imageHovered == false
              ? "#424242"
              : "#000000"
            : color,
      }}
      {...rest}
    >
      {renderIcon()}
    </Button>
  );
};

export default Icon;
