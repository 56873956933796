import message from 'antd/es/message';

const reconfigureAntMessagePosition = () => {
  message.config({
    top: 100,
  });
};

export const configureAntUIElements = () => {
  reconfigureAntMessagePosition();
};
