import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Modal,
  TablePagination,
  Snackbar,
} from "@material-ui/core";
import "./AdditionalPaymentLoadsTable.scss";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import addRowIcon from "../../../../../../assets/images/poc/addRowIcon.svg";
import deleteRow from "../../../../../../assets/images/poc/deleteRow.svg";
import submitPayment from "../../../../../../assets/images/poc/submitPayment.svg";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import {
  addLoadPayDescription,
  addOtherRevenue,
  addDeduction,
  deleteOtherRevenue,
  deleteDeduction,
} from "app/apiServices/apiServices";
const AdditionalPaymentLoadsTable = ({
  tableName,
  uploadId,
  driverId,
  loadId,
  headers,
  data,
}) => {
  console.log("Tabledata", data);
  console.log("Tableheaders", headers);
  console.log("TableloadId", loadId);
  const [rows, setRows] = useState(data || []);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isNewRowAdding, setIsNewRowAdding] = useState(false);
  // Calculate total amount whenever rows changes
  useEffect(() => {
    // Calculate total amount from rows
    const total = rows.reduce((acc, curr) => acc + (curr.amount || 0), 0);
    setTotalAmount(total);
  }, [rows]);
  useEffect(() => {
    if (Array.isArray(data)) {
      if (tableName == "additionalPaymentTable") {
        // Convert the received data into an array of objects
        const rowData = data.map((item) => {
          console.log("dataPayItem", item.additionalPaymentDescriptions);
          console.log("dataPayItem2", item.amount);
          return {
            id: item.id,
            description: item.additionalPaymentDescriptions || "", // Add default value for description
            amount: parseFloat(item.amount || null), // Add default value for amount
          };
        });
        console.log("rowData", rowData);
        setRows(rowData);
      } else {
        // Convert the received data into an array of objects
        const rowData = data.map((item) => {
          console.log("dataPayItem", item.deductionDescription);
          console.log("dataPayAmount", item.amount);
          return {
            id: item.id,
            description: item.deductionDescription || "", // Add default value for description
            amount: parseFloat(item.amount), // Add default value for amount
          };
        });
        console.log("rowData1", rowData);
        console.log("rowData2", rowData[0]);
        setRows(rowData);
      }
    }
  }, [data]);
  const addRow = () => {
    // Set isNewRowAdding to true when adding a new row
    setIsNewRowAdding(true);
    setRows([...rows, { name: "", email: "" }]);
  };
  const deleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
    const storedToken = localStorage.getItem("token");
    if (tableName == "additionalPaymentTable") {
      deleteOtherRevenue(index)
        .then((response) => {
          //console.log("insideif", response);
          console.log("addVehicleData", response.data);
          setTimeout(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      deleteDeduction(index)
        .then((response) => {
          //console.log("insideif", response);
          console.log("addVehicleData", response.data);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };
  return (
    <div>
      <InputTable
        rows={rows}
        headers={headers}
        loadId={loadId}
        onDelete={deleteRow}
        tableName={tableName}
        uploadId={uploadId}
        driverId={driverId}
        isNewRowAdding={isNewRowAdding}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <div style={{ flex: "1" }}>
          {" "}
          <Buttons
            size={"regular"}
            dimensions={"normal"}
            buttonType={"secondary"}
            iconPosition={"left"}
            image={addRowIcon}
            width={"auto"}
            onClick={addRow}
            hover={false}
            color={"#000000"}
          >
            {"Add Pay Description"}
          </Buttons>
        </div>
        <>
          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: "",
          }}
        >
          <div className="subTotal" style={{ flex: "", float: "right" }}>
            Sub Total
          </div>
          <div
            className={
              tableName == "additionalPaymentTable"
                ? "subTotalAmountAdd"
                : tableName == "deductionDescriptionTable"
                ? "subTotalAmountDeduction"
                : ""
            }
            style={{ marginLeft: "20px" }}
          >
            $
            {totalAmount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div> */}
        </>
      </div>
    </div>
  );
};

function InputTable({
  headers,
  rows,
  loadId,
  onDelete,
  tableName,
  uploadId,
  driverId,
  isNewRowAdding,
}) {
  console.log("Tableheaders2", headers);
  const headerKeys = Object.keys(headers);
  const headerValues = Object.values(headers);
  const headerEntries = Object.entries(headers);
  console.log("headerKeys", headerKeys);
  console.log("headerValues", headerValues);
  console.log("headerEntries", headerEntries);
  return (
    <Table
      style={{
        width: "100%",
      }}
    >
      <TableHead className={"tableStickyHeader"} style={{ width: "100%" }}>
        <TableRow style={{}}>
          {/* {headerValues.map((header, index) => (
            <>
              <TableData
                key={index}
                cellType={"headerCell"}
                size={"regular"}
                align={index > 0 ? "right" : "left"}
                style={{
                  cursor: "pointer",
                  borderTopLeftRadius: "8px",
                }}
              >
                {header}
              </TableData>
            </>
          ))} */}
          <TableData
            cellType={"headerCell"}
            size={"regular"}
            align={"left"}
            style={{
              cursor: "pointer",
              borderTopRightRadius: "8px",
            }}
          >
            Additional Payment Descriptions
          </TableData>
          <TableData
            cellType={"headerCell"}
            size={"regular"}
            align={"right"}
            style={{
              cursor: "pointer",
              borderTopRightRadius: "8px",
            }}
          >
            Itemised Total
          </TableData>
          <TableData
            cellType={"headerCell"}
            size={"regular"}
            align={"right"}
            style={{
              cursor: "pointer",
              borderTopRightRadius: "8px",
            }}
          >
            %Gross
          </TableData>
          <TableData
            cellType={"headerCell"}
            size={"regular"}
            align={"right"}
            style={{
              cursor: "pointer",
              borderTopRightRadius: "8px",
            }}
          >
            Contractor Amount
          </TableData>
          <TableData
            cellType={"headerCell"}
            size={"regular"}
            align={"right"}
            style={{
              cursor: "pointer",
              borderTopRightRadius: "8px",
            }}
          ></TableData>
          <>
            {/*   <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"left"}
              style={{
                cursor: "pointer",
                borderTopLeftRadius: "8px",
              }}
            >
              {"Additional Payment Description"}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
              }}
            >
              {"Amount"}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
              }}
            >
              {"Amount"}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
              }}
            >
              {"Amount"}
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
              }}
            ></TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              align={"right"}
              style={{
                cursor: "pointer",
                borderTopRightRadius: "8px",
              }}
            ></TableData>
          */}{" "}
          </>
        </TableRow>
      </TableHead>
      <tbody>
        {rows.map((row, index) => (
          <InputRow
            key={index}
            index={index}
            name={row.name}
            email={row.email}
            row={row}
            loadId={loadId}
            onDelete={onDelete}
            tableName={tableName}
            uploadId={uploadId}
            driverId={driverId}
            isNewRowAdding={isNewRowAdding && index === rows.length - 1}
          />
        ))}
      </tbody>
    </Table>
  );
}

function InputRow({
  index,
  row,
  loadId,
  onDelete,
  tableName,
  uploadId,
  driverId,
  isNewRowAdding,
}) {
  const [payDescription, setPayDescription] = useState("");
  const [amountRevenue, setAmountRevenue] = useState();
  const [hasBlurred, setHasBlurred] = useState(false);
  const { description, revenue } = row;
  const [payDescriptionLoadData, setDescriptionData] = useState(
    description || ""
  );
  const [amountRevenueLoadData, setAmountRevenueData] = useState(revenue);
  // Check if the row is newly added
  const isNewRow = isNewRowAdding;
  console.log("isNewRow", isNewRow);
  // console.log("dataPay1", description);
  console.log("dataPay2", revenue);
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    console.log("isNewRowvalue", value);
    setPayDescription(value);
  };
  const handleAmountChange = (e) => {
    const amount = e.target.value;
    console.log("isNewRowamount", amount);
    setAmountRevenue(parseFloat(amount));
  };
  const handleBlur = () => {
    setHasBlurred(true);
  };
  useEffect(() => {
    if (hasBlurred) {
      if (amountRevenue != null && payDescription !== "") {
        // AddOtherRevenueAPI();
      }
    }
  }, [hasBlurred]);
  const AddLoadPayDescriptionAPI = () => {
    const loadPayDescriptionData = {
      description: payDescription,
      revenue: parseFloat(amountRevenue),
      load_id: loadId,
    };
    addLoadPayDescription(loadPayDescriptionData)
      .then((response) => {
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
        setTimeout(() => {
          //window.location.reload();
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <TableRow style={{ width: "100%" }}>
      <>
        {/* Conditionally render submit button for newly added rows */}
        {isNewRow ? (
          <>
            <TableCell
              className={"inputCellStyles"}
              align="right"
              style={{
                cursor: "pointer",
                background: "#F4F5F8",
                width: "100%",
              }}
            >
              <input
                type="text"
                value={payDescription}
                onChange={handleDescriptionChange}
                placeholder="Add Description"
                className="inputStyle"
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#F4F5F8",
                  border: "none",
                }}
              />
            </TableCell>
            <TableCell
              className={"inputCellStyles"}
              colSpan={2}
              style={{
                cursor: "pointer",
                background: "#F4F5F8",
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                $
                <input
                  type="number"
                  value={amountRevenue}
                  min="-999999" // Set minimum value
                  max="-1" // Set maximum value
                  step="any" // Allow any step
                  onChange={handleAmountChange}
                  onBlur={handleBlur}
                  placeholder="Amount"
                  className="inputStyle"
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#F4F5F8",
                    border: "none",
                  }}
                />
              </div>
            </TableCell>
            <TableCell
              align="right"
              style={{
                cursor: "pointer",
              }}
              onClick={AddLoadPayDescriptionAPI}
            >
              <img src={submitPayment} />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              className={"dataCellLoadStyles"}
              style={{
                cursor: "pointer",
                background: "#FFFFFF",
                width: "80%",
              }}
            >
              {payDescriptionLoadData}
            </TableCell>
            <TableCell
              className={"dataCellLoadStyles"}
              align="right"
              style={{
                cursor: "pointer",
                background: "#FFFFFF",
                width: "80%",
                textAlign: "right",
              }}
            >
              value
            </TableCell>
            <TableCell
              className={"dataCellLoadStyles"}
              align="right"
              style={{
                cursor: "pointer",
                background: "#FFFFFF",
                width: "80%",
              }}
            >
              value
            </TableCell>
            <TableCell
              className={"dataCellLoadStyles"}
              align="right"
              style={{
                cursor: "pointer",
                background: "#FFFFFF",
                width: "80%",
              }}
            >
              value
            </TableCell>
          </>
        )}
        <TableCell
          align="right"
          style={{
            cursor: "pointer",
          }}
          //onClick={() => onDelete(loadId)}
        >
          <img src={deleteRow} />
        </TableCell>
      </>
    </TableRow>
  );
}
export default AdditionalPaymentLoadsTable;
