import React, { useState } from "react";
import "./ToggleSwitch.scss";
function ToggleSwitch({ onToggle }) {
  const [toggled, setToggled] = useState(false);

  const toggleSwitch = () => {
    setToggled(!toggled);
    onToggle(!toggled);
  };

  return (
    <div className={"toggleButtonContainer"}>
      <button
        className={`toggle-btn ${toggled ? "toggled" : ""}`}
        onClick={toggleSwitch}
      >
        <div className="thumb"></div>
      </button>
    </div>
  );
}

export default ToggleSwitch;
