import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Container,
} from "reactstrap";
import "./AddLoad.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import { HashSpinner } from "app/views/commonComponents/Spinners/Spinners";
import {
  addLoadData,
  addLoadStop,
  getAvailableVehiclesLoad,
  updateLoadStop,
  addLoadAdvancedDetails,
} from "../../../../../../apiServices/apiServices";
import Title from "app/views/commonComponents/Title/Title";
import saveButton from "assets/images/addLoad/saveButton.svg";
import DetailsHeader from "app/views/commonComponents/DetailsHeader/DetailsHeader";
import addStop from "assets/images/addLoad/addStop.svg";
import LocationSection from "../LocationSection/LocationSection";
import RecommendedVehicle from "../RecommendedVehicle/RecommendedVehicle";
import RoadMap from "../RoadMap/RoadMap";
import AdvancedDetails from "../AdvancedDetails/AdvancedDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastIcon from "assets/images/poc/toastIcon.svg";
import toastCross from "assets/images/poc/toastCross.svg";
import moment from "moment";
const AddLoad = ({ onClose, onLoadAdd }) => {
  const [validContactNo, setValidContactNo] = useState(false);
  const [isLoadAdded, setIsLoadAdded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedZipAddress, setSelectedZipAddress] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [locationType, setLocationType] = useState("");
  const [editableValues, setEditableValues] = useState({
    billNumber: null,
    statementId: null,
    practicalMile: null,
    revenue: null,
    pay: null,
    netProfit: null,
    rpm: null,
  });
  const locationItems = [
    // "Drop",
    "Drop-Pickup",
    "Gas Stop",
    //"Pickup",
    "Safe Haven",
  ];
  const [stopsData, setStopsData] = useState([
    {
      locationType: "Pickup",
      arrivalDate: null,
      etaSelection: "",
      etaEntered: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      latitude: 0,
      longitude: 0,
      instructions: "",
      shipperName: "",
      contactName: "",
      contactNo: "",
    },
    {
      locationType: "Drop",
      arrivalDate: null,
      etaSelection: "",
      etaEntered: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      latitude: 0,
      longitude: 0,
      instructions: "",
      shipperName: "",
      contactName: "",
      contactNo: "",
    },
  ]);
  const load_stops = [
    {
      shipper_name: "",
      address_line_1: "",
      city_state: "",
      arrival_date: "",
      contact_person: "",
      contact_number: "",
      postal_code: "",
      location: { lat: "", lng: "" },
      instructions: "",
      location_type: "",
    },
  ];
  const [selectedLatitude, setSelectedLatitude] = useState(32.779167);
  const [selectedLongitude, setSelectedLongitude] = useState(-96.808891);
  const [distanceAndTime, setDistanceAndTime] = useState([]);
  const [visibleStops, setVisibleStops] = useState([true, true]);
  const [showAdvancedDetails, setShowAdvancedDetails] = useState(true);
  const [billLandingNo, setBillLandingNo] = useState("");
  const [sealNo, setSealNo] = useState("");
  const [authorisation, setAuthorisation] = useState("");
  const [piecesNo, setPiecesNo] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [dhMiles, setDHMiles] = useState(0);
  const [scaleItems, setScaleItems] = useState(["F", "C"]);
  const [selectedScale, setSelectedScale] = useState("");
  const [selectedTemperature, setSelectedTemperature] = useState("");
  const [enterdedTemperature, setEnteredTemperature] = useState("");
  const [point, setPoint] = useState(0);
  const [speedToggleValue, setSpeedToggleValue] = useState(Boolean);
  const [loadBoardToggleValue, setLoadBoardToggleValue] = useState(Boolean);
  const [hazmatToggleValue, setHazmatToggleValue] = useState(Boolean);
  const [refrigerantToggleValue, setRefrigerantToggleValue] = useState(Boolean);
  const temperaturesCelsius = [
    "-20°C",
    "-15°C",
    "-10°C",
    "-5°C",
    "0°C",
    "5°C",
    "10°C",
    "15°C",
    "20°C",
    // Add more temperatures as needed
  ];
  const temperaturesFahrenheit = [
    "-4°F",
    "14°F",
    "32°F",
    "50°F",
    "68°F",
    "86°F",
    "104°F",
    // Add more temperatures as needed
  ];
  const addLoadsData = [
    {
      icon: "",
      values: editableValues.billNumber,
      title: "Bill Number",
      key: "billNumber",
      editable: true, // Make this value editable
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: editableValues.statementId,
      title: "Statement ID",
      key: "statementId",
      editable: true, // Make this value editable
      placeholder: "eg. XYZ122432",
    },
    {
      icon: "",
      values: editableValues.practicalMile,
      title: "Practical Mile",
      key: "practicalMile",
      editable: true, // Make this value editable
      placeholder: "eg. 1,234",
    },
    {
      icon: "",
      values: editableValues.revenue,
      title: "Revenue",
      key: "revenue",
      editable: true, // Make this value editable
      placeholder: "eg. $1,234",
    },
    {
      icon: "",
      values: editableValues.pay,
      title: "Pay",
      key: "pay",
      editable: true, // Make this value editable
      placeholder: "eg. $1,234",
    },
    {
      icon: "",
      values: editableValues.netProfit,
      title: "Net Profit",
      key: "netProfit",
      editable: false, // Make this value non-editable
      placeholder: "--",
    },
    {
      icon: "",
      values: editableValues.rpm,
      title: "RPM",
      key: "rpm",
      editable: false, // Make this value non-editable
      placeholder: "--",
    },
  ];
  const timeOptions = [
    "00:00am",
    "00:15am",
    "00:30am",
    "00:45am",
    "01:00am",
    "01:15am",
    "01:30am",
    "01:45am",
    "02:00am",
    "02:15am",
    "02:30am",
    "02:45am",
    "03:00am",
    "03:15am",
    "03:30am",
    "03:45am",
    "04:00am",
    "04:15am",
    "04:30am",
    "04:45am",
    "05:00am",
    "05:15am",
    "05:30am",
    "05:45am",
    "06:00am",
    "06:15am",
    "06:30am",
    "06:45am",
    "07:00am",
    "07:15am",
    "07:30am",
    "07:45am",
    "08:00am",
    "08:15am",
    "08:30am",
    "08:45am",
    "09:00am",
    "09:15am",
    "09:30am",
    "09:45am",
    "10:00am",
    "10:15am",
    "10:30am",
    "10:45am",
    "11:00am",
    "11:15am",
    "11:30am",
    "11:45am",
    "12:00pm",
    "12:15pm",
    "12:30pm",
    "12:45pm",
    "01:00pm",
    "01:15pm",
    "01:30pm",
    "01:45pm",
    "02:00pm",
    "02:15pm",
    "02:30pm",
    "02:45pm",
    "03:00pm",
    "03:15pm",
    "03:30pm",
    "03:45pm",
    "04:00pm",
    "04:15pm",
    "04:30pm",
    "04:45pm",
    "05:00pm",
    "05:15pm",
    "05:30pm",
    "05:45pm",
    "06:00pm",
    "06:15pm",
    "06:30pm",
    "06:45pm",
    "07:00pm",
    "07:15pm",
    "07:30pm",
    "07:45pm",
    "08:00pm",
    "08:15pm",
    "08:30pm",
    "08:45pm",
    "09:00pm",
    "09:15pm",
    "09:30pm",
    "09:45pm",
    "10:00pm",
    "10:15pm",
    "10:30pm",
    "10:45pm",
    "11:00pm",
    "11:15pm",
    "11:30pm",
    "11:45pm",
  ];
  // Define state for time options
  const [timeDropdown, setTimeDropdown] = useState();
  // Initialize time options for the first stop
  useEffect(() => {
    setTimeDropdown(timeOptions);
  }, []);
  const handleValueChange = (keyName, value) => {
    console.log("Detailsvalue1", keyName);
    console.log("Detailsvalue2", value);
    setEditableValues((prevValues) => ({
      ...prevValues,
      [keyName]: value,
    }));
  };
  console.log("selectedAddress2", selectedAddress);
  console.log("selectedCity2", selectedCity);
  console.log("selectedState2", selectedState);
  // Function to add a new stop
  const handleAddStop = () => {
    const newStop = {
      locationType: "",
      arrivalDate: null,
      etaSelection: "",
      etaEntered: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      latitude: 0,
      longitude: 0,
      instructions: "",
      shipperName: "",
      contactName: "",
      contactNo: "",
    };

    setStopsData((prevStopsData) => {
      const updatedStopsData = [...prevStopsData];
      updatedStopsData.splice(stopsData.length - 1, 0, newStop); // Insert new stop at index 1
      return updatedStopsData;
    });

    setVisibleStops((prevVisibleStops) => {
      const updatedVisibleStops = new Array(prevVisibleStops.length + 1).fill(
        false
      );
      updatedVisibleStops[stopsData.length - 1] = true; // Only the newly added stop is visible
      return updatedVisibleStops;
    });
  };
  // Function to handle deletion of a stop
  const handleDeleteStop = (index) => {
    if (stopsData.length <= 2) {
      return; // Prevent deletion if there is only one stop
    }
    // Remove the stop at the specified index from stopsData
    setStopsData((prevStopsData) => {
      const updatedStopsData = [...prevStopsData];
      updatedStopsData.splice(index, 1);
      return updatedStopsData;
    });

    // Update visibility of stops
    setVisibleStops((prevVisibleStops) => {
      const updatedVisibleStops = [...prevVisibleStops];
      updatedVisibleStops.splice(index, 1);
      return updatedVisibleStops;
    });
  };
  // Function to generate time options based on the selected ETA of the previous stop
  const generatePreviousNextTimeOptions = (previousETA, nextETA) => {
    console.log("etaTimeOptionsNP1", previousETA);
    console.log("etaTimeOptionsNP1", nextETA);
    if (!previousETA || !nextETA) return timeOptions;

    const parsedPreviousETA = moment(previousETA, "hh:mma");
    const parsedNextETA = moment(nextETA, "hh:mma");

    return timeOptions.filter((option) => {
      const parsedOption = moment(option, "hh:mma");
      return (
        parsedOption.isAfter(parsedPreviousETA) &&
        parsedOption.isBefore(parsedNextETA)
      );
    });
  };
  // Function to generate time options based on the selected ETA of the previous stop
  const generatePreviousTimeOptions = (previousETA) => {
    // If there's no previous ETA, return all time options
    if (!previousETA) return timeOptions;
    // Parse the previous ETA using moment.js
    const parsedPreviousETA = moment(previousETA, "hh:mma");
    // Filter out the time options that are greater than the previous ETA
    return timeOptions.filter((option) => {
      const parsedOption = moment(option, "hh:mma");
      return parsedOption.isAfter(parsedPreviousETA);
    });
  };
  const generateNextTimeOptions = (nextETA) => {
    // If there's no previous ETA, return all time options
    console.log("etaTimeOptionsN1", nextETA);
    if (!nextETA) return timeOptions;
    // Parse the next ETA using moment.js
    const parsedNextETA = moment(nextETA, "hh:mma");
    // Filter out the time options that are lesser than the next ETA
    return timeOptions.filter((option) => {
      const parsedOption = moment(option, "hh:mma");
      return parsedOption.isBefore(parsedNextETA);
    });
  };
  // Function to handle arrival date change for a specific stop
  const handleArrivalDateChange = (date, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].arrivalDate = date;
    setStopsData(updatedStopsData);
    // If there are previous and next stops with the same date, update time options for the current stop
    if (
      index > 0 &&
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate,
        "day"
      ) &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index + 1].arrivalDate,
        "day"
      )
    ) {
      const previousETA = updatedStopsData[index - 1].etaSelection;
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredTimeOptions = generatePreviousNextTimeOptions(
        previousETA,
        nextETA
      );
      console.log("etaTimeOptionsNP", filteredTimeOptions);
      setTimeDropdown(filteredTimeOptions);
    }
    // If there's a previous stop with the same date, update time options for the current stop
    if (
      index > 0 &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate
      )
    ) {
      // Get the ETA of the previous stop
      const previousETA = updatedStopsData[index - 1].etaSelection;
      // Generate time options based on the previous ETA
      const filteredPreviousTimeOptions =
        generatePreviousTimeOptions(previousETA);
      // Update time options for the current stop
      setTimeDropdown((prevTimeOptions) => {
        return filteredPreviousTimeOptions;
      });
    }
    // Update time options for the next stop with the same date
    else if (
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index + 1].arrivalDate).isSame(
        updatedStopsData[index].arrivalDate,
        "day"
      )
    ) {
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredNextTimeOptions = generateNextTimeOptions(nextETA);
      setTimeDropdown(filteredNextTimeOptions);
    } else {
      setTimeDropdown(timeOptions);
    }
  };
  // Function to handle ETA selection for a specific stop
  const handleETASelection = (etaSelection, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].etaSelection = etaSelection;
    setStopsData(updatedStopsData);
    // If there are previous and next stops with the same date, update time options for the current stop
    if (
      index > 0 &&
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate,
        "day"
      ) &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index + 1].arrivalDate,
        "day"
      )
    ) {
      const previousETA = updatedStopsData[index - 1].etaSelection;
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredTimeOptions = generatePreviousNextTimeOptions(
        previousETA,
        nextETA
      );
      setTimeDropdown(filteredTimeOptions);
    }
    // If there's a previous stop with the same date, update time options for the current stop
    if (
      index > 0 &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate
      )
    ) {
      // Get the ETA of the previous stop
      const previousETA = updatedStopsData[index - 1].etaSelection;
      // Generate time options based on the previous ETA
      const filteredPreviousTimeOptions =
        generatePreviousTimeOptions(previousETA);
      // Update time options for the current stop
      console.log("etaTime5", filteredPreviousTimeOptions);
      setTimeDropdown((prevTimeOptions) => {
        return filteredPreviousTimeOptions;
      });
    }
    // Update time options for the next stop with the same date
    else if (
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index + 1].arrivalDate).isSame(
        updatedStopsData[index].arrivalDate,
        "day"
      )
    ) {
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredNextTimeOptions = generateNextTimeOptions(nextETA);
      setTimeDropdown(filteredNextTimeOptions);
    } else {
      setTimeDropdown(timeOptions);
    }
  };
  // Function to handle ETA entered for a specific stop
  const handleETAEntered = (etaEntered, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].etaEntered = etaEntered;
    setStopsData(updatedStopsData);
    // If there are previous and next stops with the same date, update time options for the current stop

    if (
      index > 0 &&
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate,
        "day"
      ) &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index + 1].arrivalDate,
        "day"
      )
    ) {
      const previousETA = updatedStopsData[index - 1].etaSelection;
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredTimeOptions = generatePreviousNextTimeOptions(
        previousETA,
        nextETA
      );
      setTimeDropdown(filteredTimeOptions);
    }
    // If there's a previous stop with the same date, update time options for the current stop
    if (
      index > 0 &&
      moment(updatedStopsData[index].arrivalDate).isSame(
        updatedStopsData[index - 1].arrivalDate
      )
    ) {
      // Get the ETA of the previous stop
      const previousETA = updatedStopsData[index - 1].etaSelection;
      // Generate time options based on the previous ETA
      const filteredPreviousTimeOptions =
        generatePreviousTimeOptions(previousETA);
      // Update time options for the current stop
      console.log("etaTime5", filteredPreviousTimeOptions);
      setTimeDropdown((prevTimeOptions) => {
        return filteredPreviousTimeOptions;
      });
    }
    // Update time options for the next stop with the same date
    else if (
      index + 1 < updatedStopsData.length &&
      moment(updatedStopsData[index + 1].arrivalDate).isSame(
        updatedStopsData[index].arrivalDate,
        "day"
      )
    ) {
      const nextETA = updatedStopsData[index + 1].etaSelection;
      const filteredNextTimeOptions = generateNextTimeOptions(nextETA);
      setTimeDropdown(filteredNextTimeOptions);
    } else {
      setTimeDropdown(timeOptions);
    }
  };
  // Function to handle address change for a specific stop
  const handleAddressChange = (address, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].address = address;
    setStopsData(updatedStopsData);
  };
  // Function to handle city change for a specific stop
  const handleCityChange = (city, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].city = city;
    setStopsData(updatedStopsData);
  };
  // Function to handle state change for a specific stop
  const handleStateChange = (state, index) => {
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].state = state;
    setStopsData(updatedStopsData);
  };
  // Function to handle ZIP address change for a specific stop
  const handleZipAddressChange = (zip, index) => {
    const updatedStopsData = [...stopsData];
    // Assuming your stop data structure contains a zip property
    updatedStopsData[index].zip = zip;
    setStopsData(updatedStopsData);
  };
  // Function to handle Latitude address change for a specific stop
  const handleLatitudeChange = (latitude, index) => {
    setSelectedLatitude(latitude);
    const updatedStopsData = [...stopsData];
    // Assuming your stop data structure contains a zip property
    updatedStopsData[index].latitude = latitude;
    setStopsData(updatedStopsData);
  };
  // Function to handle Longitude address change for a specific stop
  const handleLongitudeChange = (longitude, index) => {
    setSelectedLongitude(longitude);
    const updatedStopsData = [...stopsData];
    // Assuming your stop data structure contains a zip property
    updatedStopsData[index].longitude = longitude;
    setStopsData(updatedStopsData);
  };
  // Function to handle Location Type change for a specific stop
  const handleLocationType = (locationType, index) => {
    console.log("locationType2", locationType);
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].locationType = locationType;
    setStopsData(updatedStopsData);
  };
  // Function to handle shipper name change for a specific stop
  const handleShipperNameChange = (e, index) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      const updatedStopsData = [...stopsData];
      updatedStopsData[index].shipperName = formattedValue;
      setStopsData(updatedStopsData);
    }
  };
  // Function to handle contact name change for a specific stop
  const handleContactNameChange = (e, index) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      const updatedStopsData = [...stopsData];
      updatedStopsData[index].contactName = formattedValue;
      setStopsData(updatedStopsData);
    }
  };
  // Function to handle contact number change for a specific stop
  const handleContactNoChange = (e, index) => {
    const rawValue = e.target.value.replace(/[^\d]/g, "");
    const limitedValue = rawValue.slice(0, 10);
    const validContactNo = limitedValue.length === 10;
    const formattedValue = limitedValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    const updatedStopsData = [...stopsData];
    updatedStopsData[index].contactNo = formattedValue;
    setValidContactNo(validContactNo);
    setStopsData(updatedStopsData);
  };
  // Function to toggle visibility of a specific stop
  const handleToggleStop = (index) => {
    setVisibleStops((prevVisibleStops) =>
      prevVisibleStops.map((visible, i) => (i == index ? !visible : visible))
    );
  };
  const handleDistanceAndTime = (data) => {
    console.log("distanceAndTime3", data);
    setDistanceAndTime(data);
  };
  const handleBillLandingNo = (e) => {
    const value = e.target.value;
    setBillLandingNo(value);
  };
  const handleSealNo = (e) => {
    const value = e.target.value;
    setSealNo(value);
  };
  const handleAuthorisation = (e) => {
    const value = e.target.value;
    setAuthorisation(value);
  };
  const handlePiecesNo = (e) => {
    const value = e.target.value;
    setPiecesNo(value);
  };
  const handleDHMiles = (e) => {
    const value = e.target.value;
    setDHMiles(value);
  };
  const handleGrossWeight = (e) => {
    const value = e.target.value;
    setGrossWeight(value);
    // onGrossWeight(value);
  };
  const handleSpeedToggleValue = (value) => {
    console.log("toggleValueParent", value);
    setSpeedToggleValue(value);
  };
  const handleLoadBoardToggleValue = (value) => {
    console.log("toggleValueParent", value);
    setLoadBoardToggleValue(value);
  };
  const handleHazmatToggleValue = (value) => {
    console.log("toggleValueParent", value);
    setHazmatToggleValue(value);
    //onHazmatValue(value);
  };
  const handleRefrigerantToggleValue = (value) => {
    console.log("toggleValueParent", value);
    setRefrigerantToggleValue(value);
  };
  const handleTemperatureScaleType = (value) => {
    console.log("toggleValueParent", value);
    setSelectedScale(value);
  };
  const handleTemperatureSelection = (selectedTemperature) => {
    console.log("selectedTemperature", selectedTemperature);
    setSelectedTemperature(selectedTemperature);
    // onTemperatureValue(selectedTemperature);
  };
  const handleTemperatureEntered = (enterdedTemperature) => {
    setEnteredTemperature(enterdedTemperature);
  };
  const handlePoint = (e) => {
    const value = e.target.value;
    setPoint(value);
  };
  const addLoadDataAPI = () => {
    const { billNumber, statementId, practicalMile, revenue } = editableValues;
    const loadData = {
      bill_number: billNumber,
      statement_id: statementId,
      miles: parseFloat(practicalMile),
      revenue: parseFloat(revenue),
      gross_weight: parseFloat(grossWeight),
      is_hazmat: hazmatToggleValue,
      temp_scale: selectedScale,
      temp_range: selectedTemperature,
    };
    addLoadData(loadData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // setIsLoadAdded(true);
          // handleAddLoadStatus(true);
          toast("Load is added.", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            //window.location.reload();
          }, 1000);
        } else {
        }
        console.log("isLoadAdded0", isLoadAdded);
        console.log("addLoadData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleSaveButton = () => {
    addLoadStopDataAPI();
    addLoadAdvancedDetailsAPI();
  };
  const addLoadStopDataAPI = () => {
    const { billNumber } = editableValues;
    const load_stops = stopsData.map((stop) => ({
      shipper_name: stop.shipperName,
      address_line_1: stop.address,
      city_state: `${stop.city}, ${stop.state}`,
      arrival_date: stop.arrivalDate,
      contact_person: stop.contactName,
      contact_number: stop.contactNo,
      postal_code: stop.zip,
      location: { lat: stop.latitude, lng: stop.longitude },
      //instructions: stop.instructions,
      location_type: stop.locationType,
    }));
    const loadStopData = {
      load_id: billNumber,
      load_stops: load_stops,
    };
    addLoadStop(loadStopData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
        } else {
        }
        console.log("isVehicleAdded0", isLoadAdded);
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const updateLoadStopDataAPI = () => {
    const { billNumber } = editableValues;
    const load_stops = stopsData.map((stop) => ({
      shipper_name: stop.shipperName,
      address_line_1: stop.address,
      city_state: `${stop.city}, ${stop.state}`,
      arrival_date: stop.arrivalDate,
      contact_person: stop.contactName,
      contact_number: stop.contactNo,
      postal_code: stop.zip,
      location: { lat: stop.latitude, lng: stop.longitude },
      //instructions: stop.instructions,
      location_type: stop.locationType,
    }));
    const loadStopData = {
      load_id: billNumber,
      load_stops: load_stops,
    };
    updateLoadStop(loadStopData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
        } else {
        }
        console.log("isVehicleAdded0", isLoadAdded);
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const addLoadAdvancedDetailsAPI = () => {
    const advancedDetailsData = {
      bill_of_lading_no: billLandingNo,
      seal_no: sealNo,
      pickup_or_authorization: authorisation,
      no_of_pieces: parseFloat(piecesNo),
      gross_weight: parseFloat(grossWeight),
      dh_miles: parseFloat(dhMiles),
      less_75_speed: speedToggleValue,
      load_board: loadBoardToggleValue,
      is_hazmat: hazmatToggleValue,
      refrigerant: refrigerantToggleValue,
      temp_scale: selectedScale,
      temp_range: selectedTemperature,
      set_point: point,
    };
    const loadId = billNumber;
    addLoadAdvancedDetails(advancedDetailsData, loadId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setTimeout(() => {
            //window.location.reload();
          }, 1000);
        } else {
        }
        console.log("addVehicleData", response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const { billNumber, pay, practicalMile } = editableValues;
  console.log("editableValues", editableValues);
  console.log("editableValues1", billNumber);
  console.log("editableValues2", practicalMile);
  console.log("stopsDataTime", stopsData);
  console.log("etaTime6", timeDropdown);
  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="details-page-design">
              <div className="addLoadPage">
                <div className="titleButtonSection">
                  <div className="titleContainer">
                    <Title name={"Add Load"} type={"page"} color={"#fff"} />
                  </div>
                  <div className="buttonsContainer">
                    <Buttons
                      size={"small"}
                      dimensions={"normal"}
                      buttonType={"secondary"}
                      imageReplace={false}
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      dimensions={"normal"}
                      iconPosition={"left"}
                      buttonType="secondary"
                      size="small"
                      image={saveButton}
                      hover={false}
                      color={"#000000"}
                      onClick={handleSaveButton}
                    >
                      Save
                    </Buttons>
                    <Buttons
                      size={"small"}
                      dimensions={"normal"}
                      buttonType={"primary"}
                      imageReplace={true}
                      onClick={() => {
                        addLoadDataAPI();
                      }}
                    >
                      Add
                    </Buttons>
                  </div>
                </div>
                <div
                  className="addLoadDetailsHeaders"
                  style={{ width: "100%" }}
                >
                  <DetailsHeader
                    data={addLoadsData}
                    onValueChange={handleValueChange}
                  />
                </div>
                <div className="detailsConatiner">
                  <div className="loadDetailsContainer">
                    {stopsData.map((stop, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="addLocationDetails"
                          style={{ paddingTop: index === 0 ? "32px" : "" }}
                        >
                          <LocationSection
                            locationTypeName={
                              index == stopsData.length - 1 ? "Destination" : ""
                            }
                            stopsData={stopsData}
                            setStopsData={setStopsData}
                            locationType={stop.locationType}
                            arrivalDate={stop.arrivalDate}
                            etaSelection={stop.etaSelection}
                            etaEntered={stop.etaEntered}
                            selectedAddress={stop.address}
                            selectedCity={stop.city}
                            selectedState={stop.state}
                            instructions={stop.instructions}
                            shipperName={stop.shipperName}
                            contactName={stop.contactName}
                            contactNo={stop.contactNo}
                            handleArrivalDateChange={(date) =>
                              handleArrivalDateChange(date, index)
                            }
                            handleETASelection={(etaSelection) =>
                              handleETASelection(etaSelection, index)
                            }
                            handleETAEntered={(etaEntered) =>
                              handleETAEntered(etaEntered, index)
                            }
                            handleAddressChange={(address) =>
                              handleAddressChange(address, index)
                            }
                            handleCityChange={(city) =>
                              handleCityChange(city, index)
                            }
                            handleStateChange={(state) =>
                              handleStateChange(state, index)
                            }
                            handleZipAddressChange={(zip) =>
                              handleZipAddressChange(zip, index)
                            }
                            handleLatitudeChange={(latitude) =>
                              handleLatitudeChange(latitude, index)
                            }
                            handleLongitudeChange={(longitude) =>
                              handleLongitudeChange(longitude, index)
                            }
                            handleLocationType={(locationType) =>
                              handleLocationType(locationType, index)
                            }
                            handleShipperNameChange={(e) =>
                              handleShipperNameChange(e, index)
                            }
                            handleContactNameChange={(e) =>
                              handleContactNameChange(e, index)
                            }
                            handleContactNoChange={(e) =>
                              handleContactNoChange(e, index)
                            }
                            updateLoadStopDataAPI={updateLoadStopDataAPI}
                            locationItems={locationItems}
                            timeOptions={timeDropdown}
                            isVisible={visibleStops[index]}
                            handleToggleStop={() => handleToggleStop(index)}
                            index={index}
                            handleDeleteStop={() => handleDeleteStop(index)}
                            isDeleteDisabled={stopsData.length <= 2}
                            distanceAndTime={distanceAndTime}
                          />
                        </div>
                        {index == stopsData.length - 2 && (
                          // <React.Fragment key={"add-button"}>
                          <div
                            className="addStopContainer"
                            onClick={handleAddStop}
                          >
                            <Buttons
                              dimensions={"responsive"}
                              iconPosition={"left"}
                              buttonType="secondary"
                              size="regular"
                              image={addStop}
                              hover={false}
                              color={"#000000"}
                            >
                              Add Stop
                            </Buttons>
                          </div>
                          // </React.Fragment>
                        )}
                        {index == stopsData.length - 2 && (
                          <div class="vertical-line-destination">
                            <div></div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                    {/* <div className="addStopContainer" onClick={handleAddStop}>
                      <Buttons
                        dimensions={"responsive"}
                        iconPosition={"left"}
                        buttonType="secondary"
                        size="regular"
                        image={addStop}
                        hover={false}
                        color={"#000000"}
                      >
                        Add Stop
                      </Buttons>
                    </div> */}
                  </div>
                  <div className="mapRecommendationContainer">
                    <RoadMap
                      stopsData={stopsData}
                      latitude={selectedLatitude}
                      longitude={selectedLongitude}
                      onDistanceAndTime={handleDistanceAndTime}
                    />
                    <RecommendedVehicle billNumber={billNumber} />
                    <AdvancedDetails
                      billLandingNo={billLandingNo}
                      sealNo={sealNo}
                      authorisation={authorisation}
                      piecesNo={piecesNo}
                      grossWeight={grossWeight}
                      dhMiles={dhMiles}
                      speedToggleValue={speedToggleValue}
                      loadBoardToggleValue={loadBoardToggleValue}
                      hazmatToggleValue={hazmatToggleValue}
                      refrigerantToggleValue={refrigerantToggleValue}
                      scaleItems={scaleItems}
                      selectedScale={selectedScale}
                      selectedTemperature={selectedTemperature}
                      enterdedTemperature={enterdedTemperature}
                      point={point}
                      temperaturesCelsius={temperaturesCelsius}
                      temperaturesFahrenheit={temperaturesFahrenheit}
                      handleBillLandingNo={handleBillLandingNo}
                      handleSealNo={handleSealNo}
                      handleAuthorisation={handleAuthorisation}
                      handlePiecesNo={handlePiecesNo}
                      handleDHMiles={handleDHMiles}
                      handleGrossWeight={handleGrossWeight}
                      handleTemperatureScaleType={handleTemperatureScaleType}
                      handleSpeedToggleValue={handleSpeedToggleValue}
                      handleLoadBoardToggleValue={handleLoadBoardToggleValue}
                      handleHazmatToggleValue={handleHazmatToggleValue}
                      handleRefrigerantToggleValue={
                        handleRefrigerantToggleValue
                      }
                      handleTemperatureSelection={handleTemperatureSelection}
                      handleTemperatureEntered={handleTemperatureEntered}
                      handlePoint={handlePoint}
                      billNumber={billNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer
              className={"toast-message"}
              toastStyle={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "8px 12px 8px 8px",
                background: "rgba(0, 188, 249, 0.1)",
                borderRadius: "54px",
                color: "#00bfff",
                fontFamily: "Red Hat Display",
                fontSize: "14px",
                fontWeight: "600",
                //height: "36px",
                minHeight: "none",
              }}
              hideProgressBar={true}
              closeButton={<img src={toastCross} />}
              icon={<img src={toastIcon} />}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AddLoad;
