import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import closeAddDriver from "../../../../../../assets/images/addDriver/closeAddDiver.svg";
import "./UpdateVehicleInformation.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import { updateVehicleDetails } from "app/apiServices/apiServices";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
const UpdateVehicleInformation = ({
  onClose,
  vehicleId,
  make,
  model,
  type,
  year,
  vinNumber,
  colour,
  licensePlateNumber,
  stateRegistration,
  onVehicleUpdated,
}) => {
  const [vinNumberEdit, setVINNumberEdit] = useState(vinNumber || "");
  const [makeEdit, setMakeEdit] = useState(make || "");
  const [modelEdit, setModelEdit] = useState(model || "");
  const [typeEdit, setTypeEdit] = useState(type || "");
  const [yearEdit, setYearEdit] = useState(year || 0);
  const [licencePlateNumberEdit, setLicencePlateNoEdit] = useState(
    licensePlateNumber || ""
  );
  const [stateRegistrationEdit, setStateRegistrationEdit] = useState(
    stateRegistration || ""
  );
  const [colourEdit, setColourEdit] = useState(colour || "");
  //const [vehicleId, setVehicleId] = useState("");
  const [vinNumberUpdate, setVINNumberUpdate] = useState("");
  const [makeUpdate, setMakeUpdate] = useState("");
  const [modelUpdate, setModelUpdate] = useState("");
  const [typeUpdate, setTypeUpdate] = useState("");
  const [yearUpdate, setYearUpdate] = useState();
  const [licencePlateNumberUpdate, setLicencePlateNoUpdate] = useState("");
  const [stateRegistrationUpdate, setStateRegistrationUpdate] = useState("");
  const [colourUpdate, setColourUpdate] = useState("");
  const [validVINNumber, setValidVINNumber] = useState(false);
  const [validVehicleId, setValidVehicleId] = useState(false);
  const [validLicencePlateNo, setValidLicencePlateNo] = useState(false);
  const [isVehicleUpdated, setIsVehicleUpdated] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedState, setSelectedState] = useState();
  console.log("vehiclId3", vehicleId);
  const yearList = [
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];
  const fordModels = ["F-150", "Mustang", "Explorer", "Escape", "Focus"];
  const chevroletModels = ["Silverado", "Malibu", "Equinox", "Tahoe", "Camaro"];
  const dodgeModels = [
    "Challenger",
    "Charger",
    "Durango",
    "Grand Caravan",
    "Journey",
  ];
  const jeepModels = [
    "Wrangler",
    "Grand Cherokee",
    "Cherokee",
    "Compass",
    "Renegade",
  ];
  const chryslerModels = ["300", "Pacifica"];
  const gmcModels = ["Sierra", "Yukon", "Acadia", "Terrain", "Canyon"];
  const teslaModels = ["Model S", "Model 3", "Model X", "Model Y"];
  const cadillacModels = ["Escalade", "XT5", "CT4", "CT5"];
  const buickModels = ["Encore", "Enclave", "Regal", "Envision"];
  const lincolnModels = ["Navigator", "Aviator", "Continental", "Nautilus"];
  const ramModels = ["1500", "2500", "ProMaster"];
  const toyotaModels = ["Camry", "Corolla", "RAV4", "Tacoma", "Highlander"];
  const hondaModels = ["Civic", "Accord", "CR-V", "Pilot", "Odyssey"];
  const nissanModels = ["Altima", "Sentra", "Rogue", "Pathfinder", "Frontier"];
  const subaruModels = [
    "Outback",
    "Forester",
    "Impreza",
    "Legacy",
    "Crosstrek",
  ];
  const volkswagenModels = ["Jetta", "Passat", "Tiguan", "Atlas", "Golf"];
  const bmwModels = ["3 Series", "5 Series", "X3", "X5", "7 Series"];
  const mercedesModels = ["C-Class", "E-Class", "GLE", "GLC", "S-Class"];
  const audiModels = ["A4", "A6", "Q5", "Q7", "A8"];
  const hyundaiModels = ["Sonata", "Elantra", "Tucson", "Santa Fe", "Palisade"];
  const kiaModels = ["Optima", "Sorento", "Sportage", "Soul", "Telluride"];
  const lexusModels = ["ES", "RX", "IS", "NX", "GX"];
  const infinitiModels = ["Q50", "QX60", "QX80", "QX50"];
  const acuraModels = ["TLX", "MDX", "RDX", "ILX"];
  const mazdaModels = ["Mazda3", "Mazda6", "CX-5", "CX-9"];
  const volvoModels = ["S60", "XC60", "XC90", "V60"];
  const porscheModels = ["911", "Cayenne", "Macan", "Panamera"];
  const jaguarModels = ["XE", "XF", "F-Pace", "E-Pace"];
  const landRoverModels = [
    "Range Rover",
    "Discovery",
    "Defender",
    "Range Rover Sport",
  ];
  const miniModels = ["Cooper", "Countryman", "Clubman"];
  const mitsubishiModels = ["Outlander", "Eclipse Cross", "Mirage"];
  const fiatModels = ["500", "500X", "500L"];
  const fordColors = [
    "Oxford White",
    "Shadow Black",
    "Magnetic Metallic",
    "Ingot Silver",
    "Race Red",
  ];
  const chevroletColors = [
    "Summit White",
    "Black",
    "Silver Ice Metallic",
    "Satin Steel Metallic",
    "Red Hot",
  ];
  const dodgeColors = [
    "White Knuckle Clearcoat",
    "Pitch Black Clearcoat",
    "Granite Crystal Metallic Clearcoat",
    "Billet Clearcoat",
    "TorRed Clearcoat",
  ];
  const jeepColors = [
    "Bright White Clearcoat",
    "Black Clearcoat",
    "Granite Crystal Metallic Clearcoat",
    "Billet Silver Metallic Clearcoat",
    "Firecracker Red Clearcoat",
  ];
  const chryslerColors = [
    "Bright White Clearcoat",
    "Gloss Black",
    "Velvet Red Pearlcoat",
    "Ceramic Grey Clearcoat",
    "Ocean Blue Metallic Clearcoat",
  ];
  const gmcColors = [
    "Summit White",
    "Onyx Black",
    "Quicksilver Metallic",
    "Carbon Black Metallic",
    "Cayenne Red Tintcoat",
  ];
  const teslaColors = [
    "Solid Black",
    "Midnight Silver Metallic",
    "Deep Blue Metallic",
    "Pearl White Multi-Coat",
    "Red Multi-Coat",
  ];
  const cadillacColors = [
    "Crystal White Tricoat",
    "Black Raven",
    "Radiant Silver Metallic",
    "Stellar Black Metallic",
    "Red Passion Tintcoat",
  ];
  const buickColors = [
    "Summit White",
    "Ebony Twilight Metallic",
    "Quicksilver Metallic",
    "White Frost Tricoat",
    "Satin Steel Metallic",
  ];
  const lincolnColors = [
    "Infinite Black Metallic",
    "White Platinum Metallic Tri-Coat",
    "Ingot Silver Metallic",
    "Burgundy Velvet Metallic Tinted Clearcoat",
    "Rhapsody Blue",
  ];
  const ramColors = [
    "Bright White Clearcoat",
    "Diamond Black Crystal Pearlcoat",
    "Maximum Steel Metallic Clearcoat",
    "Billet Silver Metallic Clearcoat",
    "Granite Crystal Metallic Clearcoat",
  ];
  const toyotaColors = [
    "Super White",
    "Midnight Black Metallic",
    "Celestial Silver Metallic",
    "Blizzard Pearl",
    "Ruby Flare Pearl",
  ];
  const hondaColors = [
    "Platinum White Pearl",
    "Crystal Black Pearl",
    "Lunar Silver Metallic",
    "Modern Steel Metallic",
    "Radiant Red Metallic",
  ];
  const nissanColors = [
    "Glacier White",
    "Super Black",
    "Gun Metallic",
    "Brilliant Silver Metallic",
    "Scarlet Ember Tintcoat",
  ];
  const subaruColors = [
    "Crystal White Pearl",
    "Crystal Black Silica",
    "Ice Silver Metallic",
    "Magnetite Gray Metallic",
    "Crimson Red Pearl",
  ];
  const volkswagenColors = [
    "Pure White",
    "Deep Black Pearl",
    "Platinum Gray Metallic",
    "Silk Blue Metallic",
    "Habanero Orange Metallic",
  ];
  const bmwColors = [
    "Alpine White",
    "Jet Black",
    "Mineral White Metallic",
    "Black Sapphire Metallic",
    "Melbourne Red Metallic",
  ];
  const mercedesColors = [
    "Polar White",
    "Obsidian Black Metallic",
    "Selenite Grey Metallic",
    "Iridium Silver Metallic",
    "Lunar Blue Metallic",
  ];
  const audiColors = [
    "Ibis White",
    "Mythos Black Metallic",
    "Glacier White Metallic",
    "Florett Silver Metallic",
    "Tango Red Metallic",
  ];
  const hyundaiColors = [
    "Polar White",
    "Phantom Black",
    "Symphony Silver",
    "Stellar Silver",
    "Scarlet Red",
  ];
  const kiaColors = [
    "Snow White Pearl",
    "Aurora Black Pearl",
    "Gravity Grey",
    "Everlasting Silver",
    "Passion Red",
  ];
  const lexusColors = [
    "Eminent White Pearl",
    "Obsidian",
    "Caviar",
    "Atomic Silver",
    "Nebula Gray Pearl",
  ];
  const infinitiColors = [
    "Pure White",
    "Graphite Shadow",
    "Liquid Platinum",
    "Hermosa Blue",
    "Dynamic Sunstone Red",
  ];
  const acuraColors = [
    "Platinum White Pearl",
    "Modern Steel Metallic",
    "Majestic Black Pearl",
    "Performance Red Pearl",
    "Apex Blue Pearl",
  ];
  const mazdaColors = [
    "Snowflake White Pearl Mica",
    "Jet Black Mica",
    "Machine Gray Metallic",
    "Soul Red Crystal Metallic",
    "Deep Crystal Blue Mica",
  ];
  const volvoColors = [
    "Ice White",
    "Black Stone",
    "Onyx Black Metallic",
    "Osmium Grey Metallic",
    "Fusion Red Metallic",
  ];
  const porscheColors = [
    "White",
    "Black",
    "Guards Red",
    "Carrara White Metallic",
    "Night Blue Metallic",
  ];
  const jaguarColors = [
    "Yulong White Metallic",
    "Santorini Black Metallic",
    "Fuji White",
    "Corris Grey Metallic",
    "Firenze Red Metallic",
  ];
  const landRoverColors = [
    "Fuji White",
    "Santorini Black Metallic",
    "Firenze Red Metallic",
    "Corris Grey Metallic",
    "Loire Blue Metallic",
  ];
  const miniColors = [
    "Pepper White",
    "Midnight Black Metallic",
    "Melting Silver Metallic",
    "Electric Blue Metallic",
    "Chili Red",
  ];
  const mitsubishiColors = [
    "Pearl White",
    "Labrador Black Metallic",
    "Mercury Gray Metallic",
    "Alloy Silver Metallic",
    "Red Diamond",
  ];
  const fiatColors = [
    "Bianco White Ice",
    "Nero Puro (Straight Black)",
    "Rosso (Red)",
    "Grigio (Gray)",
    "Bianco Gelato (White Clear Coat)",
  ];
  const statesRegistrationList = [
    "AL - Alabama",
    "AK - Alaska",
    "AZ - Arizona",
    "AR - Arkansas",
    "CA - California",
    "CO - Colorado",
    "CT - Connecticut",
    "DE - Delaware",
    "FL - Florida",
    "GA - Georgia",
    "HI - Hawaii",
    "ID - Idaho",
    "IL - Illinois",
    "IN - Indiana",
    "IA - Iowa",
    "KS - Kansas",
    "KY - Kentucky",
    "LA - Louisiana",
    "ME - Maine",
    "MD - Maryland",
    "MA - Massachusetts",
    "MI - Michigan",
    "MN - Minnesota",
    "MS - Mississippi",
    "MO - Missouri",
    "MT - Montana",
    "NE - Nebraska",
    "NV - Nevada",
    "NH - New Hampshire",
    "NJ - New Jersey",
    "NM - New Mexico",
    "NY - New York",
    "NC - North Carolina",
    "ND - North Dakota",
    "OH - Ohio",
    "OK - Oklahoma",
    "OR - Oregon",
    "PA - Pennsylvania",
    "RI - Rhode Island",
    "SC - South Carolina",
    "SD - South Dakota",
    "TN - Tennessee",
    "TX - Texas",
    "UT - Utah",
    "VT - Vermont",
    "VA - Virginia",
    "WA - Washington",
    "WV - West Virginia",
    "WI - Wisconsin",
    "WY - Wyoming",
  ];

  const fordVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const chevroletVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const dodgeVehicleTypes = ["Sedan", "SUV", "Truck", "Van"];
  const jeepVehicleTypes = ["SUV"];
  const chryslerVehicleTypes = ["Sedan", "Van"];
  const gmcVehicleTypes = ["Truck", "SUV", "Van"];
  const teslaVehicleTypes = ["Sedan", "SUV"];
  const cadillacVehicleTypes = ["Sedan", "SUV"];
  const buickVehicleTypes = ["Sedan", "SUV"];
  const lincolnVehicleTypes = ["Sedan", "SUV"];
  const ramVehicleTypes = ["Truck", "Van"];
  const toyotaVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const hondaVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const nissanVehicleTypes = ["Sedan", "SUV", "Truck", "Hatchback", "Van"];
  const subaruVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const volkswagenVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const bmwVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const mercedesVehicleTypes = ["Sedan", "SUV"];
  const audiVehicleTypes = ["Sedan", "SUV"];
  const hyundaiVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const kiaVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const lexusVehicleTypes = ["Sedan", "SUV"];
  const infinitiVehicleTypes = ["Sedan", "SUV"];
  const acuraVehicleTypes = ["Sedan", "SUV"];
  const mazdaVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const volvoVehicleTypes = ["Sedan", "SUV"];
  const porscheVehicleTypes = ["Sedan", "SUV"];
  const jaguarVehicleTypes = ["Sedan", "SUV"];
  const landRoverVehicleTypes = ["SUV"];
  const miniVehicleTypes = ["Sedan", "SUV", "Hatchback"];
  const mitsubishiVehicleTypes = ["Sedan", "SUV"];
  const fiatVehicleTypes = ["Sedan", "Hatchback"];

  const makeList = [
    {
      name: "Ford",
      models: fordModels,
      types: fordVehicleTypes,
      colors: fordColors,
    },
    {
      name: "Chevrolet",
      models: chevroletModels,
      types: chevroletVehicleTypes,
      colors: chevroletColors,
    },
    {
      name: "Dodge",
      models: dodgeModels,
      types: dodgeVehicleTypes,
      colors: dodgeColors,
    },
    {
      name: "Jeep",
      models: jeepModels,
      types: jeepVehicleTypes,
      colors: jeepColors,
    },
    {
      name: "Chrysler",
      models: chryslerModels,
      types: chryslerVehicleTypes,
      colors: chryslerColors,
    },
    {
      name: "GMC",
      models: gmcModels,
      types: gmcVehicleTypes,
      colors: gmcColors,
    },
    {
      name: "Tesla",
      models: teslaModels,
      types: teslaVehicleTypes,
      colors: teslaColors,
    },
    {
      name: "Cadillac",
      models: cadillacModels,
      types: cadillacVehicleTypes,
      colors: cadillacColors,
    },
    {
      name: "Buick",
      models: buickModels,
      types: buickVehicleTypes,
      colors: buickColors,
    },
    {
      name: "Lincoln",
      models: lincolnModels,
      types: lincolnVehicleTypes,
      colors: lincolnColors,
    },
    {
      name: "Ram",
      models: ramModels,
      types: ramVehicleTypes,
      colors: ramColors,
    },
    {
      name: "Toyota",
      models: toyotaModels,
      types: toyotaVehicleTypes,
      colors: toyotaColors,
    },
    {
      name: "Honda",
      models: hondaModels,
      types: hondaVehicleTypes,
      colors: hondaColors,
    },
    {
      name: "Nissan",
      models: nissanModels,
      types: nissanVehicleTypes,
      colors: nissanColors,
    },
    {
      name: "Subaru",
      models: subaruModels,
      types: subaruVehicleTypes,
      colors: subaruColors,
    },
    {
      name: "Volkswagen",
      models: volkswagenModels,
      types: volkswagenVehicleTypes,
      colors: volkswagenColors,
    },
    {
      name: "BMW",
      models: bmwModels,
      types: bmwVehicleTypes,
      colors: bmwColors,
    },
    {
      name: "Mercedes-Benz",
      models: mercedesModels,
      types: mercedesVehicleTypes,
      colors: mercedesColors,
    },
    {
      name: "Audi",
      models: audiModels,
      types: audiVehicleTypes,
      colors: audiColors,
    },
    {
      name: "Hyundai",
      models: hyundaiModels,
      types: hyundaiVehicleTypes,
      colors: hyundaiColors,
    },
    {
      name: "Kia",
      models: kiaModels,
      types: kiaVehicleTypes,
      colors: kiaColors,
    },
    {
      name: "Lexus",
      models: lexusModels,
      types: lexusVehicleTypes,
      colors: lexusColors,
    },
    {
      name: "Infiniti",
      models: infinitiModels,
      types: infinitiVehicleTypes,
      colors: infinitiColors,
    },
    {
      name: "Acura",
      models: acuraModels,
      types: acuraVehicleTypes,
      colors: acuraColors,
    },
    {
      name: "Mazda",
      models: mazdaModels,
      types: mazdaVehicleTypes,
      colors: mazdaColors,
    },
    {
      name: "Volvo",
      models: volvoModels,
      types: volvoVehicleTypes,
      colors: volvoColors,
    },
    {
      name: "Porsche",
      models: porscheModels,
      types: porscheVehicleTypes,
      colors: porscheColors,
    },
    {
      name: "Jaguar",
      models: jaguarModels,
      types: jaguarVehicleTypes,
      colors: jaguarColors,
    },
    {
      name: "Land Rover",
      models: landRoverModels,
      types: landRoverVehicleTypes,
      colors: landRoverColors,
    },
    {
      name: "Mini",
      models: miniModels,
      types: miniVehicleTypes,
      colors: miniColors,
    },
    {
      name: "Mitsubishi",
      models: mitsubishiModels,
      types: mitsubishiVehicleTypes,
      colors: mitsubishiColors,
    },
    {
      name: "Fiat",
      models: fiatModels,
      types: fiatVehicleTypes,
      colors: fiatColors,
    },
  ];
  const allModels = [
    "F-150",
    "Mustang",
    "Explorer",
    "Escape",
    "Focus",
    "Silverado",
    "Malibu",
    "Equinox",
    "Tahoe",
    "Camaro",
    "Challenger",
    "Charger",
    "Durango",
    "Grand Caravan",
    "Journey",
    "Wrangler",
    "Grand Cherokee",
    "Cherokee",
    "Compass",
    "Renegade",
    "300",
    "Pacifica",
    "Sierra",
    "Yukon",
    "Acadia",
    "Terrain",
    "Canyon",
    "Model S",
    "Model 3",
    "Model X",
    "Model Y",
    "Escalade",
    "XT5",
    "CT4",
    "CT5",
    "Encore",
    "Enclave",
    "Regal",
    "Envision",
    "Navigator",
    "Aviator",
    "Continental",
    "Nautilus",
    "1500",
    "2500",
    "ProMaster",
    "Camry",
    "Corolla",
    "RAV4",
    "Tacoma",
    "Highlander",
    "Civic",
    "Accord",
    "CR-V",
    "Pilot",
    "Odyssey",
    "Altima",
    "Sentra",
    "Rogue",
    "Pathfinder",
    "Frontier",
    "Outback",
    "Forester",
    "Impreza",
    "Legacy",
    "Crosstrek",
    "Jetta",
    "Passat",
    "Tiguan",
    "Atlas",
    "Golf",
    "3 Series",
    "5 Series",
    "X3",
    "X5",
    "7 Series",
    "C-Class",
    "E-Class",
    "GLE",
    "GLC",
    "S-Class",
    "A4",
    "A6",
    "Q5",
    "Q7",
    "A8",
    "Sonata",
    "Elantra",
    "Tucson",
    "Santa Fe",
    "Palisade",
    "Optima",
    "Sorento",
    "Sportage",
    "Soul",
    "Telluride",
    "ES",
    "RX",
    "IS",
    "NX",
    "GX",
    "Q50",
    "QX60",
    "QX80",
    "QX50",
    "TLX",
    "MDX",
    "RDX",
    "ILX",
    "Mazda3",
    "Mazda6",
    "CX-5",
    "CX-9",
    "S60",
    "XC60",
    "XC90",
    "V60",
    "911",
    "Cayenne",
    "Macan",
    "Panamera",
    "XE",
    "XF",
    "F-Pace",
    "E-Pace",
    "Range Rover",
    "Discovery",
    "Defender",
    "Range Rover Sport",
    "Cooper",
    "Countryman",
    "Clubman",
    "Outlander",
    "Eclipse Cross",
    "Mirage",
    "500",
    "500X",
    "500L",
  ];
  const vehicleTypes = [
    ...fordVehicleTypes,
    ...chevroletVehicleTypes,
    ...dodgeVehicleTypes,
    ...jeepVehicleTypes,
    ...chryslerVehicleTypes,
    ...gmcVehicleTypes,
    ...teslaVehicleTypes,
    ...cadillacVehicleTypes,
    ...buickVehicleTypes,
    ...lincolnVehicleTypes,
    ...ramVehicleTypes,
    ...toyotaVehicleTypes,
    ...hondaVehicleTypes,
    ...nissanVehicleTypes,
    ...subaruVehicleTypes,
    ...volkswagenVehicleTypes,
    ...bmwVehicleTypes,
    ...mercedesVehicleTypes,
    ...audiVehicleTypes,
    ...hyundaiVehicleTypes,
    ...kiaVehicleTypes,
    ...lexusVehicleTypes,
    ...infinitiVehicleTypes,
    ...acuraVehicleTypes,
    ...mazdaVehicleTypes,
    ...volvoVehicleTypes,
    ...porscheVehicleTypes,
    ...jaguarVehicleTypes,
    ...landRoverVehicleTypes,
    ...miniVehicleTypes,
    ...mitsubishiVehicleTypes,
    ...fiatVehicleTypes,
  ];
  const allVehicleTypes = [...new Set(vehicleTypes)];
  console.log("allVehicleTypes", allVehicleTypes);
  const allColors = [
    ...fordColors,
    ...chevroletColors,
    ...dodgeColors,
    ...jeepColors,
    ...chryslerColors,
    ...gmcColors,
    ...teslaColors,
    ...cadillacColors,
    ...buickColors,
    ...lincolnColors,
    ...ramColors,
    ...toyotaColors,
    ...hondaColors,
    ...nissanColors,
    ...subaruColors,
    ...volkswagenColors,
    ...bmwColors,
    ...mercedesColors,
    ...audiColors,
    ...hyundaiColors,
    ...kiaColors,
    ...lexusColors,
    ...infinitiColors,
    ...acuraColors,
    ...mazdaColors,
    ...volvoColors,
    ...porscheColors,
    ...jaguarColors,
    ...landRoverColors,
    ...miniColors,
    ...mitsubishiColors,
    ...fiatColors,
  ];
  const updateVehicleDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    const updateVehicleData = {
      vin_number: vinNumberUpdate ? vinNumberUpdate : vinNumberEdit,
      make: selectedMake ? selectedMake : makeEdit,
      type: selectedType ? selectedType : typeEdit,
      model: selectedModel ? selectedModel : modelEdit,
      year: yearUpdate ? yearUpdate : yearEdit,
      license_plate_number: licencePlateNumberUpdate
        ? licencePlateNumberUpdate
        : licencePlateNumberEdit,
      state_registration: selectedState ? selectedState : stateRegistrationEdit,
      colour: selectedColor ? selectedColor : colourEdit,
    };
    updateVehicleDetails(vehicleId, updateVehicleData)
      .then((response) => {
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
        setIsVehicleUpdated(true);
        handleVehicleUpdated(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleVehicleUpdated = (value) => {
    onVehicleUpdated(value);
  };
  const updateVinNumber = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    const trimmedValue = inputValue.slice(0, 17);
    const validVINNumber = trimmedValue.length == 17;
    setValidVINNumber(validVINNumber);
    const regex = /^[0-9A-Z]{0,17}$/;
    if (regex.test(inputValue)) {
      if (vinNumber) {
        setVINNumberEdit(inputValue);
      } else {
        setVINNumberUpdate(inputValue);
      }
    }
  };
  const updateMake = (e) => {
    if (make != "") {
      setMakeEdit(e.target.value);
    } else {
      setMakeUpdate(e.target.value);
    }
  };
  const updateModel = (e) => {
    if (model != "") {
      setModelEdit(e.target.value);
    } else {
      setModelUpdate(e.target.value);
    }
  };
  const updateType = (e) => {
    if (type != "") {
      setTypeEdit(e.target.value);
    } else {
      setTypeUpdate(e.target.value);
    }
  };
  const updateYear = (e) => {
    if (year != "") {
      setYearEdit(e.target.value);
    } else {
      setYearUpdate(e.target.value);
    }
  };
  const updateLicencePlateNumber = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    // Regular expression to match only alphanumeric characters
    // const trimmedValue = inputValue.slice(0, 15); // Limit to 5 digits
    // const validVehicleId = trimmedValue.length === 15; // Check if it contains exactly 10 digits
    //setValidVehicleId(validVehicleId);
    const regex = /^[0-9A-Z-]{0,9}$/;
    if (regex.test(inputValue)) {
      if (licensePlateNumber != "") {
        setLicencePlateNoEdit(inputValue);
      } else {
        setLicencePlateNoUpdate(inputValue);
      }
    }
  };
  const updateStateRegistration = (e) => {
    if (stateRegistration != "") {
      setStateRegistrationEdit(e.target.value);
    } else {
      setStateRegistrationUpdate(e.target.value);
    }
  };
  const updateColour = (e) => {
    if (colour != "") {
      setColourEdit(e.target.value);
    } else {
      setColourUpdate(e.target.value);
    }
  };
  // Event handler for opening a dropdown
  const handleDropdownOpen = () => {
    setShowDropdown(true);
  };
  const handleYearSelection = (selectedYear) => {
    console.log("year2", selectedYear);
    setSelectedYear(selectedYear);
    handleDropdownOpen();
  };
  // Event handler for selecting a make
  const handleMakeSelection = (selectedMake) => {
    console.log("make2", selectedMake);
    if (selectedMake != "") {
      console.log("selectedMake", false);
    }
    setSelectedMake(selectedMake);
    // Reset selected model, type, and color when a new make is selected
    setSelectedModel("");
    setSelectedType("");
    setSelectedColor("");
    handleDropdownOpen();
  };
  // Event handler for selecting a model
  const handleModelSelection = (selectedModel) => {
    //setShowMake(true);
    setSelectedModel(selectedModel);
    handleDropdownOpen();
  };
  // Event handler for selecting a type
  const handleTypeSelection = (selectedType) => {
    setSelectedType(selectedType);
    handleDropdownOpen();
  };
  // Event handler for selecting a color
  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };
  const handleStateSelection = (selectedValue) => {
    setSelectedState(selectedValue);
  };
  console.log("year1", yearEdit);
  console.log("year3", selectedYear);
  console.log("selectedMake2", makeEdit);
  console.log("selectedMak3", selectedMake);
  console.log("ValidVIN0", vinNumber);
  console.log("ValidVIN1", validVINNumber);
  console.log("ValidVIN2", vinNumberEdit);
  console.log("ValidVIN3", vinNumberUpdate);
  if (vinNumber == vinNumberEdit) {
    console.log("ValidVIN5", true);
  }
  return (
    <Card className={"updateVehicleCard"}>
      <CardBody>
        <div className="updateVehicleContainer">
          <div className="updateVehicleTitle">
            <div className="updateVehicleStyle">Update Information</div>
            <Icon
              size="regular"
              buttonType="secondary"
              image={closeAddDriver}
              onClick={onClose}
              imageReplace={true}
              hover={false}
            ></Icon>
          </div>
          <div className="inputSectionVehicle">
            <div className="inputRowVehicle">
              {/* <AddInputField
                fieldName="Vehicle Id"
                fieldValue={vehicleId}
                onChange={(e) => setVehicleId(e.target.value)}
                style={{ width: "100%" }}
              /> */}
              <InputDropdown
                fieldName="Year"
                dropdownOptions={yearList}
                initialValue={""}
                selectedValue={selectedYear || yearEdit}
                onValueSelection={handleYearSelection}
                inputType="number"
                //setShowDropdownParent={handleShowDropdownChange}
                style={{ width: "100%" }}
              />
              {/* <AddInputField
                fieldName="Year"
                fieldValue={yearUpdate ? yearUpdate : yearEdit}
                inputType="number"
                onChange={updateYear}
                style={{ width: "100%" }}
              /> */}
              {/* <AddInputField
                fieldName="Make"
                fieldValue={makeUpdate ? makeUpdate : makeEdit}
                onChange={updateMake}
                style={{ width: "100%" }}
              /> */}
              <InputDropdown
                fieldName="Make"
                dropdownOptions={makeList.map((make) => make.name)}
                initialValue={""}
                selectedValue={selectedMake || makeEdit}
                onValueSelection={handleMakeSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                inputType="text"
                style={{ width: "100%" }}
              />
              <InputDropdown
                fieldName="Model"
                dropdownOptions={
                  selectedMake != ""
                    ? makeList.find((make) => make.name === selectedMake)
                        ?.models
                    : allModels.sort()
                }
                initialValue={""}
                selectedValue={selectedModel || modelEdit}
                onValueSelection={handleModelSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                inputType="textNumber"
                style={{ width: "100%" }}
              />
              {/* <AddInputField
                fieldName="Model"
                fieldValue={modelUpdate ? modelUpdate : modelEdit}
                onChange={updateModel}
                style={{ width: "100%" }}
              /> */}
              <InputDropdown
                fieldName="Type"
                dropdownOptions={
                  selectedMake != ""
                    ? makeList.find((make) => make.name === selectedMake).types
                    : allVehicleTypes.sort()
                }
                initialValue={""}
                selectedValue={selectedType || typeEdit}
                onValueSelection={handleTypeSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                inputType="text"
                style={{ width: "100%" }}
              />
              {/* <AddInputField
                fieldName="Type"
                fieldValue={typeUpdate ? typeUpdate : typeEdit}
                onChange={updateType}
                style={{ width: "100%" }}
              /> */}
            </div>
            <div className="inputRowVehicle">
              <InputDropdown
                fieldName="Color"
                dropdownOptions={
                  selectedMake
                    ? makeList.find((make) => make.name === selectedMake).colors
                    : allColors.sort()
                }
                initialValue={""}
                selectedValue={selectedColor || colourEdit}
                onValueSelection={handleColorSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                //zIndex={showDropdown == true && "-1"}
                inputType="text"
                style={{ width: "100%" }}
              />
              {/* <AddInputField
                fieldName="Color"
                fieldValue={colourUpdate ? colourUpdate : colourEdit}
                onChange={updateColour}
                style={{ width: "100%" }}
              /> */}
              <InputDropdown
                fieldName="State Registration"
                dropdownOptions={statesRegistrationList}
                initialValue={""}
                selectedValue={selectedState || stateRegistrationEdit}
                onValueSelection={handleStateSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                //zIndex={showDropdown == true && "-1"}
                inputType="text"
                style={{ width: "100%" }}
              />
              {/* <AddInputField
                fieldName="State Registration"
                fieldValue={
                  stateRegistrationUpdate
                    ? stateRegistrationUpdate
                    : stateRegistrationEdit
                }
                onChange={updateStateRegistration}
                style={{ width: "100%" }}
              /> */}
              <AddInputField
                fieldName="License Plate No."
                fieldValue={
                  licencePlateNumberUpdate
                    ? licencePlateNumberUpdate
                    : licencePlateNumberEdit
                }
                onChange={updateLicencePlateNumber}
                style={{ width: "100%" }}
              />
              <AddInputField
                fieldName="VIN Number"
                fieldValue={vinNumberUpdate ? vinNumberUpdate : vinNumberEdit}
                onChange={updateVinNumber}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="submitButtonVehicle">
            <Buttons
              size={"regular"}
              dimensions={"normal"}
              buttonType={"primary"}
              disabled={
                (selectedMake == "" && makeEdit == "") || // Both makeAuto and make are empty
                (selectedModel == "" && modelEdit == "") ||
                (selectedType == "" && typeEdit == "") ||
                (selectedYear == "" && yearEdit == "") ||
                // validVehicleId == false ||
                // validVINNumber == false ||
                // (licencePlateNumberUpdate == "" &&
                //   licencePlateNumberEdit == "") ||
                (selectedState == "" && stateRegistrationEdit == "") ||
                (selectedColor == "" && colourEdit == "")
              }
              onClick={() => {
                updateVehicleDataAPI();
                onClose();
              }}
              imageReplace={true}
            >
              Save Changes
            </Buttons>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UpdateVehicleInformation;
