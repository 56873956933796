import React, { useState, useRef, useEffect } from "react";
import "./InputDropdown.scss";
import dropdownUpArrow from "../../../../assets/images/poc/dropdownUpArrow.svg";
import dropdownDownArrow from "../../../../assets/images/poc/dropdownDownArrow.svg";
import selectedDropdownValue from "../../../../assets/images/poc/selectedDropdownValue.svg";
import clock from "assets/images/addLoad/clock.svg";
const InputDropdown = (props) => {
  const {
    initialValue,
    selectedValue,
    placeholder,
    maxHeight,
    onValueSelection,
    fieldName,
    fieldValue,
    inputType,
    onChange,
    onBlur,
    style,
    dropdownOptions,
    onValueEnter,
    //setShowDropdownParent,
    zIndex,
    showArrow,
    background,
  } = props;
  console.log("initialValue", initialValue);
  console.log("selectedValue", selectedValue);
  const [selectedValueEdit, setSelectedValueEdit] = useState(
    selectedValue || ""
  );
  const [initialValueEdit, setInitilValueEdit] = useState(initialValue || "");
  const [matchingOptions, setMatchingOptions] = useState([]);
  const [dropdownWidth, setDropdownWidth] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownBelow, setIsDropdownBelow] = useState(false);
  const inputRef = useRef();
  const dropdownRef = useRef(null);
  const handleDropdownOptionChange = (event) => {
    const value = event.target.value;
    console.log("eventvalue", value);
    if (inputType == "text") {
      console.log("eventvalue1", value);
      // Regular expression to match only alphabetic characters and spaces
      const regex = /^[A-Za-z-\s]*$/;
      if (regex.test(value)) {
        const lowercaseValue = value.toLowerCase(); // Convert input value to lowercase
        const matchingOptions = dropdownOptions.filter(
          (option) => option.toLowerCase().includes(lowercaseValue) // Convert options to lowercase for case-insensitive comparison
        );
        const formattedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Format input value for display
        //if (matchingOptions.includes(formattedValue) == false) {
        onValueEnter(formattedValue);
        //}
        if (initialValue != "") {
          setInitilValueEdit(formattedValue);
        } else {
          setSelectedValueEdit(formattedValue);
        }
        setMatchingOptions(matchingOptions);
        setShowDropdown(true);
      }
    } else if (inputType == "number" || inputType == "textNumber") {
      console.log("eventvalue2", value);
      // Handle logic specific to number input type
      const lowercaseValue = value.toLowerCase(); // Convert input value to lowercase
      const matchingOptions = dropdownOptions.filter(
        (option) => option.toLowerCase().includes(lowercaseValue) // Convert options to lowercase for case-insensitive comparison
      );
      const formattedValue =
        value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Format input value for display
      //if (matchingOptions.includes(formattedValue) == false) {
      onValueEnter(formattedValue);
      //}
      if (initialValue != "") {
        setInitilValueEdit(formattedValue);
      } else {
        setSelectedValueEdit(formattedValue);
      }
      setMatchingOptions(matchingOptions);
      setShowDropdown(true);
    }
  };
  const handleOptionClick = (option) => {
    setSelectedValueEdit(option);
    setShowDropdown(false);
    onValueSelection(option);
    //setShowDropdownParent(false);
  };
  const handleInputClick = () => {
    const matchingOptions = dropdownOptions;
    setMatchingOptions(matchingOptions);
    setShowDropdown(!showDropdown);
    //setShowDropdownParent(!showDropdown);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    // if (showDropdown == false) {
    //   //setShowDropdownParent(false);
    // }
    const inputWidth = inputRef.current;
    setDropdownWidth(inputWidth);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showDropdown && inputRef.current && dropdownRef.current) {
      const fieldRect = inputRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const isDropdownBelow = fieldRect.bottom + dropdownHeight <= windowHeight;
      setIsDropdownBelow(isDropdownBelow);
      if (isDropdownBelow) {
        dropdownRef.current.style.top = `${fieldRect.height}px`;
        dropdownRef.current.style.bottom = "auto";
      } else {
        dropdownRef.current.style.top = "auto";
        //dropdownRef.current.style.bottom = `${fieldRect.height}px`;
        dropdownRef.current.style.bottom = `45.5px`;
      }
    }
  }, [showDropdown]);

  console.log("selectedValueEdit", selectedValueEdit);
  return (
    <div className="inputDropdownFieldConatinerInputDropdown" style={style}>
      <p className="inputDropdownFieldNameInputDropdown">{fieldName}</p>{" "}
      <div
        className="inputDropdownFieldStyleInputDropdown"
        style={{ zIndex: zIndex }}
      >
        <div
          className="inputDropdown"
          style={{ position: "relative", background: background }}
        >
          <div
            style={{
              display: "inline-block",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              ref={inputRef}
            >
              <input
                type={
                  inputType == "number"
                    ? "number"
                    : inputType == "textNumber"
                    ? "text"
                    : "text"
                }
                className="inputBox"
                placeholder={placeholder || ""}
                value={initialValue != "" ? initialValue : selectedValueEdit}
                onChange={handleDropdownOptionChange}
                onClick={handleInputClick}
                //ref={inputRef}
                style={{ background: background }}
              />
              <div
                style={{
                  marginRight: "16px",
                  cursor: "default",
                  background: background,
                  cursor: "pointer",
                }}
                onClick={handleInputClick}
                ref={inputRef}
              >
                {fieldName == "Temperature" ? (
                  showDropdown ? (
                    <img src={dropdownUpArrow} />
                  ) : (
                    <img src={dropdownDownArrow} />
                  )
                ) : (
                  ""
                )}
                {fieldName == "ETA" ? <img src={clock} /> : ""}
              </div>
            </div>
            {showDropdown && matchingOptions.length > 0 && (
              <div
                className="dropdownPickerInputDropdown"
                ref={dropdownRef}
                // style={{
                //   top: !isDropdownBelow ? "auto" : "100%",
                //   bottom: !isDropdownBelow ? "45.5px" : "",
                // }}
              >
                <div className="dropdownOptionsConatiner">
                  {matchingOptions.map((value, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            flex: "1",
                          }}
                          onClick={() => handleOptionClick(value)}
                        >
                          {value}
                        </div>
                        {selectedValueEdit == value && (
                          <div>
                            <img src={selectedDropdownValue} />
                          </div>
                        )}
                      </div>
                      {index == matchingOptions.length - 1 ? (
                        ""
                      ) : (
                        <div
                          key={index}
                          style={{
                            background: "#ECEEF1",
                            height: "1px",
                          }}
                        ></div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputDropdown;
