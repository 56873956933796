import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./ScrollAnimation.scss";

const ScrollAnimation = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false);
  const elementRef = useRef(null);

  const checkVisibility = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      if (rect.bottom < 0) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    checkVisibility(); // Check visibility on mount

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={classNames("scroll-element", { "is-hidden": isHidden })}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
