import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Modal,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./SettlementsDetailsPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import backRightArrow from "../../../../../../assets/images/poc/backRightArrow.svg";
import userIcon from "../../../../../../assets/images/poc/userIcon.svg";
import { Link } from "react-router-dom";
import QuickActionsDropdown from "app/views/commonComponents/QuickActionsDropdown/QuickActionsDropdown";
import driverProfile from "../../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../../assets/images/drivers/accidents.svg";
import calendarRemove from "../../../../../../assets/images/drivers/calendarRemove.svg";
import moneys from "../../../../../../assets/images/drivers/moneys.svg";
import presentionChart from "../../../../../../assets/images/drivers/presentionChart.svg";
import star from "../../../../../../assets/images/drivers/star.svg";
import userTag from "../../../../../../assets/images/drivers/userTag.svg";
import ProfilePOC from "../../ProfilePOC/ProfilePOC";
import searchIcon from "../../../../../../assets/images/poc/searchIcon.svg";
import DriversTablePOC from "../../TablePOC/TablePOC";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import AddOtherRevenue from "../AddOtherRevenue/AddOtherRevenue";
import optionsMenu from "../../../../../../assets/images/optionsMenu/optionsMenu.svg";
import grossRevenueGradient from "../../../../../../assets/images/poc/grossRevenueGradient.svg";
import grossMarginGradient from "../../../../../../assets/images/poc/grossMarginGradient.svg";
import rpmGradient from "../../../../../../assets/images/poc/rpmGradient.svg";
import driverPaymentsGradient from "../../../../../../assets/images/poc/driverPaymentsGradient.svg";
import milesLoadedGradient from "../../../../../../assets/images/poc/milesLoadedGradient.svg";
import loadsDeliveredGradient from "../../../../../../assets/images/poc/loadsDeliveredGradient.svg";
import {
  getSettelmentsDetailsHeaderKPIData,
  getSettlementsListData,
} from "app/apiServices/apiServices";
const SettlementsDetailsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [settelmentsListOriginalData, setSettlementsListOriginalData] =
    useState([]);
  const [newSettlementDate, setNewSettelmentDate] = useState("");
  const [newUploadId, setNewUploadId] = useState();
  const [statementDate, setStatementDate] = useState("");
  const { settlementDate, uploadId } = useParams();
  const [showAddOtherRevenue, setShowAddOtherRevenue] = useState(false);
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);

  // Use the settlementDate and settelmentsUploadId as needed
  console.log("Settlement1", settlementDate);
  console.log("settelmentsUploadId", uploadId);
  const breadcrumbItems = [
    { title: "Settlements", link: "/settlement" },
    {
      title: statementDate ? `${statementDate}` : `${statementDate}`,
      link: `/settlement/${settlementDate}/${uploadId}`,
    },
  ];
  const driverData = [
    {
      icon: grossRevenueGradient,
      values: `$${Math.round(grossRevenue).toLocaleString("en-US")}`,
      title: "Gross Revenue",
    },
    {
      icon: driverPaymentsGradient,
      values: `$${Math.round(driverPayments).toLocaleString("en-US")}`,
      title: "Driver Payments",
    },
    {
      icon: grossMarginGradient,
      values: `$${Math.round(grossMargin).toLocaleString("en-US")}`,
      title: "Gross Margin",
    },
    {
      icon: rpmGradient,
      values: `$${headerRPM.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      title: "RPM",
    },
    {
      icon: milesLoadedGradient,
      values: milesLoaded.toLocaleString("en-US"),
      title: "Loaded Miles",
    },
    {
      icon: loadsDeliveredGradient,
      values: loadsDelivered.toLocaleString("en-US"),
      title: "Delivered Loads",
    },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const tabsData = [
    { id: 0, label: "Profile" },
    { id: 1, label: "Loads" },
    { id: 2, label: "Driver History" },

    // Add more tabs as needed
  ];
  const settlementsTableHeaders = {
    driverID: "Driver Id.",
    driverName: "Driver Name",
    loadsDelivered: "Loads",
    MilesLoaded: "Loaded Miles",
    rpm: "RPM",
    totalRevenue: "Load Revenue",
    totalOtherRevenue: "Other Revenue",
    totalDeduction: "Deductions",
    netPay: "Net Pay",
    status: "Status",
  };
  const settlementsTableData = settelmentsListOriginalData.map((driver) => ({
    driverID: driver.driver_id,
    driverName: driver.driver_name,
    loadsDelivered: driver.loads_delivered.toLocaleString(),
    milesLoaded: driver.loaded_miles.toLocaleString(),
    rpm: driver.rpm
      ? `$${driver.rpm.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : "$0.00", // Formatting rpm with 2 decimal places
    totalRevenue: `$${driver.total_revenue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    totalOtherRevenue: `$${driver.other_revenue_amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} •  ${driver.other_revenue_count.toLocaleString()}`,
    totalDeduction: `$${driver.deduction_amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} • ${driver.deduction_count.toLocaleString()}`,
    netPay: `$${driver.net_pay.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    status: capitalizeFirstLetter(driver.status),
    settlementDate: settlementDate,
    uploadId: uploadId,
  }));
  const handleOpenAddOtherRevenue = () => {
    setShowAddOtherRevenue(true);
  };
  const handleCloseAddOtherRevenue = () => {
    setShowAddOtherRevenue(false);
  };
  function handleButtonClick(driver) {
    // Handle button click here, you can access the driver data
    console.log("Button clicked for driver:", driver);
  }
  // Function to capitalize the first letter of a string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  useEffect(() => {
    handleReturnSettlementsDetailsPOC();
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 332; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    const originalDateString = settlementDate ? settlementDate : "";
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).format("DD");
    const year = moment(originalDate).year();
    const formattedDate = `${month} ${day}, ${year}`;
    console.log("setStatementDate1", formattedDate);
    setStatementDate(formattedDate);
    setNewUploadId(uploadId);
    setNewSettelmentDate(settlementDate);

    if (uploadId != 0) {
      getSettlementsListDataAPI();
      getDetailsHeaderKPIData();
    }
  }, [uploadId]);
  const getDetailsHeaderKPIData = (selectedDate) => {
    console.log("currentDate", selectedDate);
    //const selectedDate = selectedMonth;
    //let { startDate, endDate } = convertStartDate(selectedDate);
    // console.log("currentDate2", startDate);
    // console.log("currentDate3", endDate);
    getSettelmentsDetailsHeaderKPIData(uploadId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("DetailsHeaderKPIData", response.data);
        console.log("DetailsHeaderKPIData1", response.data.gross_revenue);
        setGrossRevenue(response.data.gross_revenue);
        setDriverPayments(response.data.driver_payments);
        setGrossMargin(response.data.gross_margin);
        setHeaderRPM(response.data.rpm);
        setMilesLoaded(response.data.miles_loaded);
        setLoadsDelivered(response.data.loads_delivered);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getSettlementsListDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getSettlementsListData(uploadId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getSettlementsListData", response.data);
        setSettlementsListOriginalData(response.data ? response.data : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [
    currentPageSettlementsDetailsPOC,
    setCurrentPageSettlementsDetailsPOC,
  ] = useState(1);
  const [
    itemsPerPageSettlementsDetailsPOC,
    setItemsPerPageSettlementsDetailsPOC,
  ] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageSettlementsDetailsPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageSettlementsDetailsPOC(perPage);
    setCurrentPageSettlementsDetailsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageSettlementsDetailsPOC",
      currentPageSettlementsDetailsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageSettlementsDetailsPOC",
    //   itemsPerPageSettlementsDetailsPOC
    // );
  };
  const handleReturnSettlementsDetailsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageSettlementsDetailsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageSettlementsDetailsPOC"
    // );
    setCurrentPageSettlementsDetailsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageSettlementsDetailsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="details-page-design">
              <div className="stickyRowSettlementsDetails">
                <div className="headerSettlementsDetails">
                  <Breadcrumbs items={breadcrumbItems} />
                  {/* <div
                      className="backToDrivers"
                      style={{ display: "flex", width: "100%" }}
                    >
                      {" "}
                      <img
                        src={backRightArrow}
                        className="backRightArrow"
                      ></img>
                      <Link to="/settlement" className="backLink">
                        Weekly Settlements
                      </Link>
                    </div> */}
                  <div style={{ marginTop: "8px" }}>
                    <Title name={statementDate} type={"page"} color={"#fff"} />
                  </div>
                  <div className="serialNumbersSettlements">
                    <div className="settlementIdSettlementsDetails">
                      Upload Id:
                    </div>
                    <div className="settlementValueSettlementsDetails">
                      {uploadId}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "16px" }}>
                  <DetailsHeader data={driverData} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px",
                  }}
                  className="tableTitleContainerSettlementsDetails"
                >
                  {" "}
                  <div style={{ flex: "1" }}>
                    <Title
                      name={`Settlements (${settelmentsListOriginalData.length})`}
                      type={"table"}
                    />
                  </div>
                  {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search settlement id or driver name"
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                </div>
              </div>
              <div className="tableContainerSettlementsDetails">
                <div
                  className="driversTableContainerSettlementsDetails"
                  style={{
                    maxHeight: `${maxTableHeight}px`,
                    padding: "0 16px 0 16px",
                    marginBottom: "16px",
                  }}
                >
                  <div className="recentSettelmentsTableContainer">
                    <DriversTablePOC
                      headers={settlementsTableHeaders}
                      data={settlementsTableData}
                      activeTab={activeTab}
                      rowClick={true}
                      tableName={"settelmentsDetailsPOC"}
                      settelmentDate={settlementDate}
                      uploadId={uploadId}
                      paginationEnabled={true}
                      currentPage={currentPageSettlementsDetailsPOC}
                      itemsPerPage={itemsPerPageSettlementsDetailsPOC}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onNavigateAway={handleNavigateAway}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SettlementsDetailsPOC;
