import React, { useState, useEffect, useRef } from "react";
import { Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datePicker from "assets/images/poc/datePicker.svg";
import "./CustomDatePicker.scss"; // Use a common SCSS file for styling
import moment from "moment";

const CustomDatePicker = ({
  color,
  onClick,
  disabled,
  size, //regular and small
  style, // New prop for custom styles
  selectedDate,
  setSelectedDate,
  fieldName,
  width,
  dateFormat,
  background,
  startDate,
  endDate,
  validateRange,
  disablePastDates = true,
  ...rest
}) => {
  const datePickerRef = useRef(null);
  // const handleDateChange = (date) => {
  //   console.log("selectedDate", date);
  //   // Format the date to the desired format using moment
  //   const formattedDate = moment(date).format("MMM DD, YYYY");
  //   console.log("selectedDate2", formattedDate);
  //   setSelectedDate(formattedDate);
  // };
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    // Set minDate and maxDate based on the arrival dates of the first and last stops
    if (moment().isBetween(startDate, endDate)) {
      // If today's date is between the arrival dates of the first and last stops
      setMinDate(moment().toDate()); // Set minDate to today's date
      setMaxDate(endDate ? moment(endDate).toDate() : null); // Set maxDate to the end date
    } else {
      // If today's date is not between the arrival dates of the first and last stops
      setMinDate(startDate ? moment(startDate).toDate() : null); // Set minDate to the start date
      setMaxDate(endDate ? moment(endDate).toDate() : null); // Set maxDate to the end date
    }
  }, [startDate, endDate]);

  // Handler for date change
  const handleDateChange = (date) => {
    if (fieldName == "Date Of Birth") {
      // Convert the date to moment object
      const momentDate = moment(date);
      // Format the selected date using moment
      const formattedDate = momentDate.format("MM/DD/YYYY");
      console.log("dateOfBirth1", formattedDate);
      setSelectedDate(formattedDate);
    } else {
      // Check if the selected date falls between the start and end dates
      if (
        (!startDate || moment(date).isSameOrAfter(startDate, "day")) &&
        (!endDate || moment(date).isSameOrBefore(endDate, "day"))
      ) {
        // Format the selected date using moment
        const formattedDate = moment(date).format("MMM DD, YYYY");
        setSelectedDate(formattedDate);
      }
    }
  };
  useEffect(() => {
    // Ensure the ref is assigned only after the component has mounted
    if (datePickerRef.current) {
      datePickerRef.current.setFocus = (focus) => {
        if (focus && datePickerRef.current.input) {
          datePickerRef.current.setOpen(true);
        }
      };
    }
  }, []);
  const handleImgClick = () => {
    // Focus the DatePicker component when the image is clicked
    datePickerRef.current.setFocus(true);
  };
  return (
    <div className="datePickerContainer">
      <p className="datePickerFieldName">{fieldName}</p>
      <div className="datePickerInputField" style={{ background: background }}>
        {fieldName == "Date Of Birth" ? (
          <DatePicker
            selected={selectedDate}
            ref={datePickerRef}
            onChange={handleDateChange}
            style={{
              width: width,
              background: "#f4f5f8",
            }}
            placeholderText="" // Placeholder text for user input
            dateFormat={"MM/dd/YYYY"} // Date format for display
            className="datePicker"
            showYearDropdown // Show year dropdown in the calendar
            yearDropdownItemNumber={100} // Show 100 years back from the current year
            maxDate={moment().toDate()} // Maximum date allowed (today)
            peekNextMonth={false}
            showMonthDropdown={true} // Hide month dropdown to display only years
            scrollableYearDropdown // Allow scrolling in the year dropdown
            // Add more date restrictions if needed
          />
        ) : (
          <DatePicker
            selected={selectedDate} // Use moment to convert to a Date object
            ref={(ref) => {
              datePickerRef.current = ref;
            }} // Assign ref to the DatePicker component
            onChange={handleDateChange}
            style={{
              width: width,
              background: background ? background : "#f4f5f8",
            }}
            minDate={disablePastDates ? moment().toDate() : minDate}
            maxDate={maxDate}
            placeholderText={""} // Placeholder text
            dateFormat={"MMM dd, YYYY"} // Date format changed here
            className="datePicker"
            // filterDate={(date) => {
            //   // Disable dates greater than the current date using moment
            //   return moment(date).isSameOrBefore(moment(), "day");
            // }}
          />
        )}
        <img src={datePicker} alt="Date" onClick={handleImgClick} />
      </div>
    </div>
  );
};

export default CustomDatePicker;
