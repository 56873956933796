import config from "./config";

export default {
  BASE: `${config.BASE}`,
  SOCKETIO_SERVICE: `/socketio-service/socket.io`,
  // Graphql
  USER_SERVICE_GRAPHQL: `${config.USER_SERVICE}/graphql`,
  // FMCSA Graphql
  FMCSA_GRAPHQL: `${config.FMCSA_SERVICE}/graphql`,
  // User Service
  APPLICANTS: `${config.USER_SERVICE}/api/admin/applicants`,
};
