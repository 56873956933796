import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Modal,
} from "@material-ui/core";
import "./SubTablePOC.scss";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import { useHistory } from "react-router-dom";
import enterArrow from "../../../../../assets/images/poc/enterArrow.svg";
const SubTablePOC = ({
  headers,
  data,
  activeTab,
  tableName,
  subHeaders,
  subData,
  settlementsUploadId,
  rowClick,
  rowClickHandler,
  settlementDate,
  uploadId,
  driverId,
  vehicleId,
  fullDriverName,
  // handleMenuClick,
  // optionsMenuOpen,
}) => {
  const history = useHistory(); // Initialize useHistory hook
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [showAssignTruck, setShowAssignTruck] = useState(false);
  const [showUnassignTruck, setShowUnassignTruck] = useState(false);
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [selectedOptionsMenuItem, setSelectedOptionsMenuItem] = useState(null);
  const menuList = [
    { item: "Review", icon: "" },
    { item: "Paid", icon: "" },
  ];
  const [sortBy, setSortBy] = useState(""); // Initialize sortBy to the default column name
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedDriverData, setSortedDriverData] = useState(null);
  const handleSort = (columnName, data) => {
    // if (tableName == "driverLeaderboard" || tableName == "vehicleLeaderboard") {
    console.log("helloSorting1", data);
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }

    const sortedData = [...data].sort((a, b) => {
      console.log("helloSorting3");
      if (sortBy === null) return 0; // No sorting if sortBy is null
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      // Helper function to convert string values containing "$" to numeric values
      const parseNumericValue = (value) => {
        // Remove "$" and commas, then parse as float
        return parseFloat(value.replace(/[$,]/g, ""));
      };

      // Custom comparison logic based on column headers
      switch (sortBy) {
        case "driverName":
          // Alphabetical sorting for driverName
          return sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        case "grossMargin":
        case "rpm":
          // Numeric sorting for grossMargin and rpm (remove "$" and compare)
          return sortOrder === "asc"
            ? parseNumericValue(valueA) - parseNumericValue(valueB)
            : parseNumericValue(valueB) - parseNumericValue(valueA);
        case "milesLoaded":
        case "loadsDelivered":
          // Numeric sorting for milesLoaded and loadsDelivered
          return sortOrder === "asc"
            ? parseInt(valueA.replace(/,/g, "")) -
                parseInt(valueB.replace(/,/g, ""))
            : parseInt(valueB.replace(/,/g, "")) -
                parseInt(valueA.replace(/,/g, ""));
        default:
          // Default sorting (no transformation)
          return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        //return 0;
      }
    });

    console.log("helloSorting2", sortedData);
    setSortedDriverData(sortedData); // Move the setSortedDriverData call here
    // }
  };
  const handleRowClick = (
    rowData,
    index,
    event,
    settlementsUploadId,
    tableName,
    rowClick //true or false
  ) => {
    if (!rowClick) return;
    // Check if the first row is clicked and toggle expansion state
    if (
      rowClick &&
      tableName == "loadsTable" &&
      (index === 0 || index <= data.length - 1)
    ) {
      console.log("expanded1", isExpanded);
      setIsExpanded(!isExpanded);
    }
    // Toggle expandedRowIndex to show/hide expanded table
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
    // Define the function to handle row click
    if (rowClick && tableName == "vehicleLeaderBoard") {
      history.push(`/vehicle`);
    }
    // Navigate to the new page using history.push()
    if (
      rowClick &&
      rowData.vehicleId &&
      rowData.make &&
      tableName == "vehicleDataDetailsTable"
    ) {
      history.push(`/vehicle/${rowData.make}/${rowData.vehicleId}`);
    }
    if (!event.target.closest("button")) {
      event.stopPropagation(); // Stop event propagation to prevent button click
      // Rest of your code to handle row click

      if (
        rowClick &&
        rowData.driverId &&
        rowData.assignedTruck &&
        rowData.fullDriverName
      ) {
        history.push(
          `/drivers/${rowData.driverId}/${rowData.assignedTruck}/${rowData.fullDriverName}`
        );
      }
    }
    if (rowClick && rowData.driverName && tableName == "driverLeaderBoard") {
      history.push(`/drivers`);
    }
    if (rowClick && rowData.datesDriver && tableName != "loadsTable") {
      history.push(`/drivers/${rowData.datesDriver}`);
    }

    if (
      rowClick &&
      rowData.settlementDate &&
      tableName == "weeklySettelmentsTableDashboard"
    ) {
      history.push(`/settlement`);
    }
    if (rowClick && rowData.uploadId) {
      history.push(`/settlement/${rowData.settlementDate}/${rowData.uploadId}`);
    }
    if (rowClick && rowData.driverID) {
      history.push(
        `/settlement/${rowData.newSettlementDate}/${rowData.newUploadId}/${rowData.driverID}`
      );
    }
    //settelmentRowClickHandler(index, rowData.driverID);
    if (tableName == "settelmentsDetailsPOC") {
      rowClickHandler(index, rowData.driverID);
    }
    rowClickHandler(index);
  };
  const loads = [
    {
      load_id: "71847710",
      date: "2023-12-06",
      origin: "Brooks, KY",
      destination: "Dothan, AL",
      loaded_miles: "543",
      rpm: 4.05,
      total_revenue: 2198.56,
      contractor_amount: 460.19,
      pay_description: [
        {
          revenue: 5085.4,
          gross_percentage: "21",
          driver_revenue: 266.98,
          description: "Load Base Pay",
          total_revenue: 5085.4,
          driver_total_revenue: 1067.92,
          total_records_merged: 4,
        },
        {
          revenue: 82.4,
          gross_percentage: "21",
          driver_revenue: 4.33,
          description: "Deadhead - Dispatch To Pickup",
          total_revenue: 82.4,
          driver_total_revenue: 17.32,
          total_records_merged: 4,
        },
      ],
      status: "review",
    },
    {
      load_id: "71856371",
      date: "2023-11-29",
      origin: "Cincinnati, OH",
      destination: "Cincinnati, OH",
      loaded_miles: "62",
      rpm: 5.69,
      total_revenue: 353.03000000000003,
      contractor_amount: 74.14,
      pay_description: [
        {
          revenue: 1118,
          gross_percentage: "21",
          driver_revenue: 58.7,
          description: "Load Base Pay",
          total_revenue: 1118,
          driver_total_revenue: 234.8,
          total_records_merged: 4,
        },
        {
          revenue: 186,
          gross_percentage: "21",
          driver_revenue: 9.76,
          description: "Deadhead - Dispatch To Pickup",
          total_revenue: 186,
          driver_total_revenue: 39.04,
          total_records_merged: 4,
        },
      ],
      status: "review",
    },
    {
      load_id: "71858014",
      date: "2023-12-06",
      origin: "Columbus, OH",
      destination: "Haverhill, MA",
      loaded_miles: "771",
      rpm: 3.11,
      total_revenue: 2397.43,
      contractor_amount: 457.9,
      pay_description: [
        {
          revenue: 6362.44,
          gross_percentage: "21",
          driver_revenue: 334.03,
          description: "Load Base Pay",
          total_revenue: 6362.44,
          driver_total_revenue: 1336.12,
          total_records_merged: 4,
        },
        {
          revenue: 252,
          gross_percentage: "21",
          driver_revenue: 13.23,
          description: "Deadhead - Dispatch To Pickup",
          total_revenue: 252,
          driver_total_revenue: 52.92,
          total_records_merged: 4,
        },
      ],
      status: "review",
    },
  ];
  const loadDetailsTableHeaders = {
    loadID: "Load Id.",
    date: "Date",
    originDestination: "Origin & Destination",
    payDescription: "Pay Description",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    itemisedTotal: "Itemized Total",
    gross: "%Gross",
    contractorAmount: "Contractor Amount",
  };
  const payDescription = [
    {
      revenue: 6362.44,
      gross_percentage: "21",
      driver_revenue: 334.03,
      description: "Load Base Pay",
      total_revenue: 6362.44,
      driver_total_revenue: 1336.12,
      total_records_merged: 4,
    },
    {
      revenue: 252,
      gross_percentage: "21",
      driver_revenue: 13.23,
      description: "Deadhead - Dispatch To Pickup",
      total_revenue: 252,
      driver_total_revenue: 52.92,
      total_records_merged: 4,
    },
  ];
  const convertRowToLoadObject = (row) => {
    if (tableName == "loadsDetailsTable") {
      return {
        loadID: "",
        payDescription: row.description,
        revenue: row.revenue
          ? `$${row.revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
      };
    } else {
      return {
        load_id: "",
        date: "",
        //payDescription: "",
        milesLoaded: "",
        rpm: "",
        originDestination: row.description,
        loaded_miles: "",
        loaded_miles: "",
        loaded_miles: "",
        payDescription: "",
        date: "",
        rpm: "",
        milesLoaded: "",
        itemisedTotal: row.revenue
          ? `$${row.revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
        //rpm: "",
        gross: `${row.gross_percentage}%`,
        //rpm2: "",
        contractor_amount: row.driver_revenue
          ? `$${row.driver_revenue.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "$0.00",
      };
    }
  };
  return (
    <>
      <Table
        style={{
          width: "100%",
        }}
      >
        <>
          {console.log("loadsDetailsTable", tableName)}
          {tableName == "loadsDetailsTable" && (
            <TableHead className={"tableStickyHeader"} style={{}}>
              <TableRow
                style={{
                  borderBottom: isExpanded ? "2px solid #B8BEC7" : "",
                  width: "100%",
                }}
              >
                {Object.values(headers).map((header, index) =>
                  header == "Origin & Destination" || header == "Date" ? (
                    ""
                  ) : (
                    <>
                      <TableData
                        key={index}
                        cellType={"headerCell"}
                        size={"regular"}
                        align={
                          header == "Loaded Miles" ||
                          header == "Pay Description"
                            ? "left"
                            : index <= 1
                            ? "left"
                            : "right"
                        }
                        style={{
                          cursor: "pointer",
                          background: isExpanded ? "#ffffff" : "#F4F5F8",
                          // borderTopLeftRadius: index == 0 ? "8px" : "",
                          // borderTopRightRadius:
                          //   index == headers.length ? "8px" : "",
                        }}
                        onClick={() =>
                          handleSort(Object.keys(headers)[index], data)
                        }
                      >
                        {header == "Loaded Miles" || header == "RPM" ? (
                          ""
                        ) : // )
                        header == enterArrow ? (
                          <img src={enterArrow} />
                        ) : (
                          header
                        )}
                      </TableData>
                    </>
                  )
                )}
              </TableRow>
            </TableHead>
          )}
        </>
        <TableBody style={{ overflowY: "auto" }}>
          {(sortedDriverData || data).map((row, rowIndex) => {
            const convertedRow = convertRowToLoadObject(row, tableName);
            console.log("convertedRow", convertedRow);
            return (
              <>
                <TableRow
                  key={rowIndex}
                  onClick={(event) =>
                    handleRowClick(
                      row,
                      rowIndex,
                      event,
                      settlementsUploadId,
                      tableName,
                      rowClick
                    )
                  }
                  style={{
                    borderBottom:
                      rowIndex === data.length - 1
                        ? "2px solid rgb(184, 190, 199)"
                        : "1px solid var(--T---LT-Gray, #ECEEF1)",
                    //height: tableName == "driversTable" ? "80px" : "",
                    width: "100%",
                  }}
                  className="driver-table"
                >
                  {console.log("payrow", row)}
                  {Object.entries(convertedRow).map(([key, cell], cellIndex) =>
                    key == "sort_order" ||
                    key == "total_records_merged" ||
                    key == "total_revenue" ||
                    key == "driver_total_revenue" ? (
                      ""
                    ) : (
                      <>
                        {console.log("payKey", key)}

                        <TableData
                          key={cellIndex}
                          cellType={"bodyCell"}
                          size={"large"}
                          align={
                            (tableName == "loadsDetailsTable" &&
                              (key == "payDescription" || key == "loadID")) ||
                            key == "originDestination"
                              ? "left"
                              : "right"
                          }
                          style={{
                            fontWeight:
                              key == "netPay" ||
                              key == "driverPayments" ||
                              key == "contractorAmount"
                                ? "500"
                                : "400",
                            color:
                              tableName == "loadsTable" ||
                              tableName == "additionalPaymentTable"
                                ? rowIndex == data.length - 1 &&
                                  key == "contractorAmount"
                                  ? "#6F5FED"
                                  : "#393A3D"
                                : tableName == "deductionDescriptionTable" &&
                                  key == "contractorAmount"
                                ? "#F35631"
                                : "#393A3D",
                            width:
                              (key == "milesLoaded" && "7%") ||
                              (key == "contractor_amount" && "14%") ||
                              (key == "gross" && "10%"),
                          }}
                        >
                          {console.log("payHeaders", headers.loadID)}

                          {console.log("payIndex", cell)}
                          {headers.loadID == "Load Id." &&
                          rowIndex == 0 &&
                          cellIndex == 0 ? (
                            <img src={enterArrow} />
                          ) : rowIndex == 7 && key == "gross_percentage" ? (
                            cell[key]
                          ) : cell === null || cell === 0 ? (
                            ""
                          ) : (
                            cell
                          )}
                        </TableData>
                      </>
                    )
                  )}
                </TableRow>

                <></>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
export default SubTablePOC;
