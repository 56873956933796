import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./UpdateDriverInformation.scss";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import closeAddDriver from "../../../../../../../assets/images/addDriver/closeAddDiver.svg";
import RadioButtonGroup from "app/views/commonComponents/RadioButtonGroup/RadioButtonGroup";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import toastIcon from "../../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateDriverDetails } from "app/apiServices/apiServices";
import { Address } from "app/views/commonComponents/Address/Address";
import InputDropdown from "app/views/commonComponents/InputDropdown/InputDropdown";
import CustomDatePicker from "app/views/commonComponents/CustomDatePicker/CustomDatePicker";
import CustomDropdown from "app/views/commonComponents/CustomDropdown/CustomDropdown";
const UpdateDriverInformation = ({
  onClose,
  driverId,
  fullName,
  firstName,
  lastName,
  middleName,
  suffix,
  preferrdName,
  phoneNumber,
  planId,
  email,
  homeAddress,
  city,
  state,
  zip,
  dateOfBirth,
  driverClassification,
  vehicleNo,
  onDriverUpdated,
}) => {
  const [firstNameEdit, setFirstNameEdit] = useState(firstName || "");
  const [lastNameEdit, setLastNameEdit] = useState(lastName || "");
  const [middleNameEdit, setMiddleNameEdit] = useState(middleName || "");
  const [suffixEdit, setSuffixEdit] = useState(suffix || "");
  const [dateOfBirthEdit, setDateOfBirthEdit] = useState(dateOfBirth);
  const [preferredNameEdit, setPreferredNameEdit] = useState(
    preferrdName || ""
  );
  const [mobileNumberEdit, setMobileNumberEdit] = useState(phoneNumber);
  const [emailAddressEdit, setEmailAddressEdit] = useState(email || "");
  const [homeAddressEdit, setHomeAddressEdit] = useState(homeAddress || "");
  const [cityEdit, setCityEdit] = useState(city || "");
  const [stateEdit, setStateEdit] = useState(state || "");
  const [zipEdit, setZipEdit] = useState(zip);
  const [planEdit, setPlanEdit] = useState(planId || "A");
  const [driverClassificationEdit, setDriverClassificationEdit] = useState("");
  const [initalHireDateEdit, setInitalHireDateEdit] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [middleName, setMiddleName] = useState("");
  // const [lastName, setLastName] = useState("");
  //const [driverId, setDriverId] = useState("");
  const [firstNameUpdate, setFirstNameUpdate] = useState("");
  const [lastNameUpdate, setLastNameUpdate] = useState("");
  const [middleNameUpdate, setMiddleNameUpdate] = useState("");
  const [suffixUpdate, setSuffixUpdate] = useState("");
  const [dateOfBirthUpdate, setDateOfBirthUpdate] = useState("");
  const [preferredNameUpdate, setPreferredNameUpdate] = useState("");
  const [mobileNumberUpdate, setMobileNumberUpdate] = useState();
  const [emailAddressUpdate, setEmailAddressUpdate] = useState("");
  const [homeAddressUpdate, setHomeAddressUpdate] = useState("");
  const [cityUpdate, setCityUpdate] = useState("");
  const [stateUpdate, setStateUpdate] = useState("");
  const [selectedState, setSelectedState] = useState();
  const [zipUpdate, setZipUpdate] = useState();
  const [planUpdate, setPlanUpdate] = useState(planId || "A");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [driverClassificationUpdate, setDriverClassificationUpdate] =
    useState("");
  const [initalHireDateUpdate, setInitalHireDateUpdate] = useState("");
  const [addStatus, setAddStatus] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validMobileNumber, setValidMobileNumber] = useState(true);
  const [validZipNumber, setValidZipNumber] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [isDriverUpdated, setIsDriverUpdated] = useState(false);
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [selectedCityAddress, setSelectedCityAddress] = useState("");
  const [selectedStateAddress, setSelectedStateAddress] = useState("");
  const [selectedZipAddress, setSelectedZipAddress] = useState("");
  const planOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    // { value: "C", label: "C" },
  ];
  const suffixItems = ["Sr.", "Jr."];
  const statesList = [
    "AL - Alabama",
    "AK - Alaska",
    "AZ - Arizona",
    "AR - Arkansas",
    "CA - California",
    "CO - Colorado",
    "CT - Connecticut",
    "DE - Delaware",
    "FL - Florida",
    "GA - Georgia",
    "HI - Hawaii",
    "ID - Idaho",
    "IL - Illinois",
    "IN - Indiana",
    "IA - Iowa",
    "KS - Kansas",
    "KY - Kentucky",
    "LA - Louisiana",
    "ME - Maine",
    "MD - Maryland",
    "MA - Massachusetts",
    "MI - Michigan",
    "MN - Minnesota",
    "MS - Mississippi",
    "MO - Missouri",
    "MT - Montana",
    "NE - Nebraska",
    "NV - Nevada",
    "NH - New Hampshire",
    "NJ - New Jersey",
    "NM - New Mexico",
    "NY - New York",
    "NC - North Carolina",
    "ND - North Dakota",
    "OH - Ohio",
    "OK - Oklahoma",
    "OR - Oregon",
    "PA - Pennsylvania",
    "RI - Rhode Island",
    "SC - South Carolina",
    "SD - South Dakota",
    "TN - Tennessee",
    "TX - Texas",
    "UT - Utah",
    "VT - Vermont",
    "VA - Virginia",
    "WA - Washington",
    "WV - West Virginia",
    "WI - Wisconsin",
    "WY - Wyoming",
  ];
  console.log("city", city);
  console.log("city1", state);
  console.log("city2", zip);
  useEffect(() => {
    // const nameParts = extractNameParts(fullName);
    // setFirstName(nameParts.firstName);
    // setFirstNameEdit(nameParts.firstName);
    // setMiddleName(nameParts.middleName);
    // setMiddleNameEdit(nameParts.middleName);
    // setLastName(nameParts.lastName);
    // setLastNameEdit(nameParts.lastName);
  }, []);
  function extractNameParts(fullName) {
    const parts = fullName.split(" ");
    let firstName = "";
    let middleName = "";
    let lastName = "";

    if (parts.length >= 1) {
      firstName = parts[0];
    }
    if (parts.length >= 2) {
      lastName = parts[parts.length - 1];
      middleName = parts.slice(1, -1).join(" ");
    }

    return {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
    };
  }
  const updateDriverDataAPI = () => {
    console.log("helloUpdate", driverId);
    const storedToken = localStorage.getItem("token");
    const updateDriverData = {
      firstName: firstNameUpdate ? firstNameUpdate : firstNameEdit,
      middleName: middleNameUpdate ? middleNameUpdate : middleNameEdit,
      lastName: lastNameUpdate ? lastNameUpdate : lastNameEdit,
      preferred_name: preferredNameUpdate
        ? preferredNameUpdate
        : preferredNameEdit,
      email: emailAddressUpdate ? emailAddressUpdate : emailAddressEdit,
      phone_number: mobileNumberUpdate ? mobileNumberUpdate : mobileNumberEdit,
      plan_id: planUpdate ? planUpdate : planEdit,
      home_address: selectedHomeAddress ? selectedHomeAddress : homeAddressEdit,
      suffix: suffixUpdate ? suffixUpdate : suffixEdit,
      city: selectedCityAddress ? selectedCityAddress : cityEdit,
      state: selectedStateAddress ? selectedStateAddress : stateEdit,
      zip: selectedZipAddress ? selectedZipAddress : zipEdit,
      date_of_birth: dateOfBirthUpdate ? dateOfBirthUpdate : dateOfBirthEdit,
      // driver_classification: driverClassificationUpdate,
      // inital_hire_date: initalHireDateUpdate,
    };
    updateDriverDetails(driverId, updateDriverData)
      .then((response) => {
        //console.log("insideif", response);
        if (response.status === 200 || response.status == 201) {
          setIsDriverUpdated(true);
          handleDriverUpdated(true);
        }
        console.log("addVehicleData", response.data);
        // toast("Driver information is updated.", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const updateFirstName = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      if (firstName != "") {
        setFirstNameEdit(formattedValue);
      } else {
        setFirstNameUpdate(formattedValue);
      }
    }
  };
  const handleDriverUpdated = (value) => {
    onDriverUpdated(value);
  };
  const updateMiddleName = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      if (middleName != "") {
        setMiddleNameEdit(formattedValue);
      } else {
        setMiddleNameUpdate(formattedValue);
      }
    }
  };
  const updateLastName = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      if (lastName != "") {
        setLastNameEdit(formattedValue);
      } else {
        setLastNameUpdate(formattedValue);
      }
    }
  };
  // const updateSuffix = (e) => {
  //   const inputValue = e.target.value;
  //   const formattedValue = inputValue
  //     .split(/\s+/) // Split input into words by spaces
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //     .join(" "); // Join words back with a space
  //   // Regular expression to match only alphabetic characters
  //   const regex = /^[A-Za-z]*$/;
  //   if (regex.test(formattedValue)) {
  // if (suffix != "") {
  //   setSuffixEdit(formattedValue);
  // } else {
  //   setSuffixUpdate(formattedValue);
  // }
  //   }
  // };
  const updateSuffix = (value) => {
    console.log("toggleValueParent", value);
    if (suffix != "") {
      setSuffixEdit(value);
    } else {
      setSuffixUpdate(value);
    }
  };

  const updateDateOfBirth1 = (e) => {
    let inputValue = e.target.value;
    // Check if the user pressed the backspace key
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      // Remove the last character if it's not a slash
      if (inputValue.charAt(inputValue.length - 1) !== "/") {
        inputValue = inputValue.slice(0);
      }
    } else {
      // Remove any non-digit characters and slashes
      inputValue = inputValue.replace(/[^\d/]/g, "");

      // Insert slash after the month (mm) and day (dd) if not already present
      if (
        (inputValue.length === 2 || inputValue.length === 5) &&
        inputValue.charAt(inputValue.length - 1) !== "/"
      ) {
        inputValue += "/";
      }

      // Format the date as mm/dd/yyyy
      if (inputValue.length > 2 && inputValue.length <= 4) {
        inputValue = inputValue.replace(/(\d{2})(\d{2})/, "$1/$2");

        // Validate month
        const mm = inputValue.substring(0, 2);
        // if (parseInt(mm) > 12) {
        //   return; // Return without making any changes if month is invalid
        // }
      } else if (inputValue.length > 4 && inputValue.length <= 10) {
        //inputValue = inputValue.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");

        // Validate month and day
        const mm = inputValue.substring(0, 2);
        const dd = inputValue.substring(3, 5);
        // if (parseInt(mm) > 12 || parseInt(dd) > 31) {
        //   return; // Return without making any changes if month or day is invalid
        // }
      } else if (inputValue.length > 10) {
        // If input exceeds 10 characters (including slashes), return without making any changes
        return;
      }
    }
    if (dateOfBirth != "") {
      setDateOfBirthEdit(inputValue);
    } else {
      setDateOfBirthUpdate(inputValue);
    }
  };
  const updateDateOfBirth = (date) => {
    console.log("dateOfBirth2", date);
    if (dateOfBirth != "") {
      setDateOfBirthEdit(date);
    } else {
      setDateOfBirthUpdate(date);
    }
  };
  const updatePreferredName = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(formattedValue)) {
      if (preferrdName != "") {
        setPreferredNameEdit(formattedValue);
      } else {
        setPreferredNameUpdate(formattedValue);
      }
    }
  };
  const updateMobileNumber = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const limitedValue = rawValue.slice(0, 10); // Limit to 10 digits
    const validMobileNumber = limitedValue.length === 10; // Check if it contains exactly 10 digits
    setValidMobileNumber(validMobileNumber);
    const formattedValue = limitedValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    ); // Format as (555) 222-3333
    const value = e.target.value;
    // Validate if the value contains up to 10 digits
    // if (/^\d{0,10}$/.test(value)) {
    if (phoneNumber != "") {
      setMobileNumberEdit(formattedValue);
    } else {
      setMobileNumberUpdate(formattedValue);
    }
    // }
  };
  const updateEmailAddress = (e) => {
    const inputValue = e.target.value;
    if (email != "") {
      setEmailAddressEdit(inputValue);
    } else {
      setEmailAddressUpdate(inputValue);
    }
  };
  // Function to handle email address change
  const handleEmailAddressValid = (emailAddress) => {
    // Regular expression for email validation
    //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //const emailAddress = event.target.value;
    if (!emailRegex.test(emailAddress)) {
      // Invalid email address format
      console.log("EmailAddress1");
      setValidEmail(false);
    } else {
      // Valid email address format
      console.log("EmailAddress2", emailAddress);
      setValidEmail(true);
    }
  };
  useEffect(() => {
    if (emailAddressEdit != "") {
      handleEmailAddressValid(emailAddressEdit);
    } else if (emailAddressUpdate != "") {
      handleEmailAddressValid(emailAddressUpdate);
    } // Call validation function on component mount and whenever emailAddress changes
  }, [emailAddressUpdate, emailAddressEdit]);
  const updateHomeAddress = (e) => {
    if (homeAddress != "") {
      setHomeAddressEdit(e.target.value);
    } else {
      setHomeAddressUpdate(e.target.value);
    }
  };
  const updateCity = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(formattedValue)) {
      if (city != "") {
        setCityEdit(formattedValue);
      } else {
        setCityUpdate(formattedValue);
      }
    }
  };
  const updateState = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(inputValue)) {
      if (state != "") {
        setStateEdit(inputValue);
      } else {
        setStateUpdate(inputValue);
      }
    }
  };
  const handleStateSelection = (selectedValue) => {
    setSelectedState(selectedValue);
  };
  const updateZip = (e) => {
    const inputValue = e.target.value;
    console.log("zipInput", zipEdit);
    const trimmedValue = inputValue.slice(0, 5);
    const validZipNumber = trimmedValue.length === 5; // Check if it contains exactly 10 digits
    setValidZipNumber(validZipNumber);
    if (zip != "") {
      setZipEdit(trimmedValue);
    } else {
      setZipUpdate(trimmedValue);
    }
  };
  const updatePlan = (e) => {
    if (planId != "") {
      setPlanEdit(planEdit);
    } else {
      setPlanUpdate(planUpdate);
    }
  };
  const handleHomeAddressChange = (homeAddress) => {
    setSelectedHomeAddress(homeAddress);
  };
  const handleCityAddressChange = (city) => {
    setSelectedCityAddress(city);
  };
  const handleStateAddressChange = (state) => {
    setSelectedStateAddress(state);
  };
  const handleZipAddressChange = (zip) => {
    setSelectedZipAddress(zip);
  };
  console.log("selectedAddress2", selectedHomeAddress);
  console.log("selectedCity2", selectedCityAddress);
  console.log("selectedState2", selectedStateAddress);
  console.log("selectedZip2", selectedZipAddress);
  return (
    <>
      <Card className={"updateDriverInputCard"}>
        <CardBody>
          <div className="updateDriverContainer">
            <div className="updateDriverTitle">
              <div className="updateDriverStyle">Update Information</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Icon
                  size="regular"
                  buttonType="secondary"
                  image={closeAddDriver}
                  onClick={onClose}
                  imageReplace={true}
                  hover={false}
                ></Icon>
              </div>
            </div>
            <div className="inputSectionDriver">
              <div className="inputRowDriver">
                <AddInputField
                  fieldName="First Name"
                  fieldValue={firstNameUpdate ? firstNameUpdate : firstNameEdit}
                  inputType="text"
                  onChange={updateFirstName}
                  style={{ width: "100%" }}
                />
                <AddInputField
                  fieldName="Middle Name"
                  fieldValue={
                    middleNameUpdate ? middleNameUpdate : middleNameEdit
                  }
                  inputType="text"
                  onChange={updateMiddleName}
                  style={{ width: "100%" }}
                />
                <AddInputField
                  fieldName="Last Name"
                  fieldValue={lastNameUpdate ? lastNameUpdate : lastNameEdit}
                  inputType="text"
                  onChange={updateLastName}
                  style={{ width: "100%" }}
                />
                {/* <AddInputField
                  fieldName="Suffix"
                  fieldValue={suffixUpdate ? suffixUpdate : suffixEdit}
                  inputType="text"
                  onChange={updateSuffix}
                  style={{ width: "100%" }}
                /> */}
                <CustomDropdown
                  fieldName="Scale"
                  items={suffixItems}
                  selectedItem={suffixUpdate ? suffixUpdate : suffixEdit}
                  onSelect={updateSuffix}
                  //background={"#ffffff"}
                />
              </div>
              <div
                className="inputRowDriver"
                style={{
                  display: "flex",
                }}
              >
                <div style={{ flex: "1" }}>
                  {/* <AddInputField
                    fieldName="Home Address"
                    fieldValue={
                      homeAddressUpdate ? homeAddressUpdate : homeAddressEdit
                    }
                    inputType="textarea"
                    onChange={updateHomeAddress}
                  /> */}
                  <Address
                    addressCountryFilters={["us"]}
                    initialAddress={homeAddressEdit}
                    handleAddressChange={handleHomeAddressChange}
                    handleCityChange={handleCityAddressChange}
                    handleStateChange={handleStateAddressChange}
                    handleZipChange={handleZipAddressChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: "1",
                    gap: "24px",
                  }}
                >
                  <div style={{ flex: "1" }}>
                    <AddInputField
                      fieldName="City"
                      fieldValue={selectedCityAddress || cityEdit}
                      inputType="text"
                      onChange={updateCity}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "24px",
                      flex: "1",
                    }}
                  >
                    {/* <AddInputField
                      fieldName="State"
                      fieldValue={selectedStateAddress || stateEdit}
                      inputType="text"
                      onChange={updateState}
                    /> */}
                    <InputDropdown
                      fieldName="State"
                      dropdownOptions={statesList}
                      initialValue={selectedStateAddress || ""}
                      selectedValue={
                        selectedStateAddress ? selectedStateAddress : stateEdit
                      }
                      onValueSelection={handleStateSelection}
                      //setShowDropdownParent={handleShowDropdownChange}
                      showArrow={false}
                      inputType="text"
                    />
                    <AddInputField
                      fieldName="Zip"
                      fieldValue={selectedZipAddress || zipEdit}
                      inputType={"number"}
                      onChange={updateZip}
                    />
                  </div>
                </div>
              </div>
              <div className="inputRowDriver" style={{ width: "100%" }}>
                {/* <AddInputField
                  fieldName="Date Of Birth"
                  fieldValue={
                    dateOfBirthUpdate ? dateOfBirthUpdate : dateOfBirthEdit
                  }
                  inputType="text"
                  onChange={updateDateOfBirth}
                  placeholder="mm/dd/yyyy"
                  style={{ width: "100%" }}
                /> */}
                <div style={{ width: "100%" }}>
                  <CustomDatePicker
                    fieldName="Date Of Birth"
                    selectedDate={
                      dateOfBirthUpdate ? dateOfBirthUpdate : dateOfBirthEdit
                    } // Pass down the selectedDate state
                    setSelectedDate={updateDateOfBirth}
                    dateFormat="MM-dd-YYYY" // Pass down the function to update selectedDate
                    //background="#f4f5f8"
                  />
                </div>
                <AddInputField
                  fieldName="Preferred Name"
                  fieldValue={
                    preferredNameUpdate
                      ? preferredNameUpdate
                      : preferredNameEdit
                  }
                  inputType="text"
                  onChange={updatePreferredName}
                  style={{ width: "100%" }}
                />
                <AddInputField
                  fieldName="Mobile Phone Number"
                  fieldValue={
                    mobileNumberUpdate ? mobileNumberUpdate : mobileNumberEdit
                  }
                  inputType="text"
                  onChange={updateMobileNumber}
                  style={{ width: "100%" }}
                />
                <AddInputField
                  fieldName="Email Address"
                  fieldValue={
                    emailAddressUpdate ? emailAddressUpdate : emailAddressEdit
                  }
                  inputType="email"
                  onChange={updateEmailAddress}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="loginCTABox">
              <div className="payPlan">
                <RadioButtonGroup
                  fieldName="Pay Plan"
                  options={planOptions}
                  selectedOption={planUpdate ? planUpdate : planEdit}
                  onChange={setPlanUpdate}
                />
              </div>
              <Buttons
                size={"regular"}
                dimensions={"normal"}
                buttonType={"primary"}
                disabled={
                  ((firstNameUpdate == "" && firstNameEdit == "") ||
                    (lastNameUpdate == "" && lastNameEdit == "") ||
                    (selectedHomeAddress == "" && homeAddressEdit == "") ||
                    (selectedCityAddress == "" && cityEdit == "") ||
                    (selectedStateAddress == "" && stateEdit == "") ||
                    //(zipUpdate == "" && zipEdit == "") ||
                    (dateOfBirthUpdate == "" && dateOfBirthEdit == "") ||
                    //(mobileNumberUpdate == "" && mobileNumberEdit == "") ||
                    // (emailAddressUpdate == "" && emailAddressEdit == "") ||
                    validEmail == false ||
                    validMobileNumber == false ||
                    validZipNumber == false) &&
                  true
                }
                imageReplace={true}
                onClick={() => {
                  updateDriverDataAPI();
                  onClose();
                }}
              >
                Save Changes
              </Buttons>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UpdateDriverInformation;
