import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import closeAddDriver from "../../../../../../../assets/images/addDriver/closeAddDiver.svg";
import addDrivingLicense from "../../../../../../../assets/images/addDriver/addDrivingLicense.svg";
import "./AddDriverModal.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import AddDriverLicense from "../AddDriverLicense/AddDriverLicense";
import AddDriverInput from "../AddDriverInput/AddDriverInput";
import Loader from "react-js-loader";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import ImageCropper from "../ImageCropper/ImageCropper";
import FileDropZone from "../FileDropZone/FileDropZone";
import AppSlider from "../AppSlider/AppSlider";
import CropEasy from "../CropEasy/CropEasy";
import { addDriverData } from "../../../../../../apiServices/apiServices";

const AddDriverModal = ({ onClose, sendDriverAdded, onDriverAdd }) => {
  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const fileFrontInputRef = useRef(null);
  const fileBackInputRef = useRef(null);
  const [licencePlateNo, setLicencePlateNo] = useState("");
  const [driverClass, setDriverClass] = useState("");
  const [isDriverAdded, setIsDriverAdded] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [frontOpenCrop, setFrontOpenCrop] = useState(false);
  const [backOpenCrop, setBackOpenCrop] = useState(false);
  const [remoteImage, setRemoteImage] = useState("");
  const [localImage, setLocalImage] = useState("");
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const isImageSelected = remoteImage || localImage ? true : false;
  const [file, setFile] = useState(null);
  const [frontPhotoURL, setFrontPhotoURL] = useState();
  const [backPhotoURL, setBackPhotoURL] = useState();
  const [photoURL, setPhotoURL] = useState();
  const [driverId, setDriverId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [suffixItems, setSuffixItems] = useState(["Sr.", "Jr."]);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [preferredName, setPreferredName] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [emailAddress, setEmailAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setSate] = useState("");
  const [zip, setzip] = useState();
  const [plan, setPlan] = useState("A");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [driverClassification, setDriverClassification] = useState("");
  const [initalHireDate, setInitalHireDate] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validMobileNumber, setValidMobileNumber] = useState(false);
  const [validZipNumber, setValidZipNumber] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedZip, setSelectedZip] = useState("");
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedLongitude, setSelectedLongitude] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const planOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    // { value: "C", label: "C" },
  ];
  const onDrop = useCallback((acceptedFiles) => {
    setRemoteImage("");
    setLocalImage(URL.createObjectURL(acceptedFiles[0]));
  }, []);
  const handleOnZoom = useCallback((zoomValue) => {
    setZoom(zoomValue);
  }, []);
  const handleOnRotation = useCallback((rotationValue) => {
    setRotation(rotationValue);
  }, []);
  const downloadImage = async () => {
    if (!croppedImage) return;
    const link = document.createElement("a");
    const name = `${Date.now()}_wallpaper`;
    link.download = name;
    link.href = URL.createObjectURL(croppedImage);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const fileFrontSelectHandler = (event) => {
    const file = event.target.files[0];
    setSelectedFrontImage(file);
    setFrontPhotoURL(URL.createObjectURL(file));
    setFrontOpenCrop(true);
    setBackOpenCrop(false);
    // setLocalImage("");
    // setRemoteImage(event.target.value);
    //onFrontImageSelect(file); // Pass selected front image to parent component
    setOpenCrop(true); // Open the crop editor
    //onOpenCrop(true);
    event.target.value = null;
  };
  const fileBackSelectHandler = (event) => {
    const file = event.target.files[0];
    setSelectedBackImage(file);
    setBackPhotoURL(URL.createObjectURL(file));
    setFrontOpenCrop(false);
    setBackOpenCrop(true);
    //onBackImageSelect(file); // Pass selected back image to parent component
    setOpenCrop(true); // Open the crop editor
    //onOpenCrop(true);
    event.target.value = null;
    // Handle file upload
  };
  const handleButtonFrontClick = () => {
    fileFrontInputRef.current.click();
  };
  const handleButtonBackClick = () => {
    fileBackInputRef.current.click();
  };
  const handleLicencePlateNoChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    const regex = /^[0-9A-Z-]{0,9}$/;
    if (regex.test(inputValue)) {
      setLicencePlateNo(inputValue);
    }
  };
  const handleDriverClassChange = (e) => {
    let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
    const regex = /^[A-Z]{0,1}$/;
    if (regex.test(inputValue)) {
      setDriverClass(inputValue);
    }
  };
  // const handleDriverAdded = (value) => {
  //   console.log("isDriverAdded3", value);
  //   setIsDriverAdded(value);
  //   sendDriverAdded(value);
  // };
  const handleFirstNameChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      setFirstName(formattedValue);
    }
  };
  const handleLastNameChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;
    if (regex.test(formattedValue)) {
      setLastName(formattedValue);
    }
  };
  const handleMiddleNameChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]*$/;

    if (regex.test(formattedValue)) {
      setMiddleName(formattedValue);
    }
  };
  // const handleSuffixChange = (e) => {
  //   const inputValue = e.target.value;
  //   const formattedValue = inputValue
  //     .split(/\s+/) // Split input into words by spaces
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  //     .join(" "); // Join words back with a space
  //   // Regular expression to match only alphabetic characters
  //   const regex = /^[A-Za-z]*$/;

  //   if (regex.test(formattedValue)) {
  //     setSuffix(formattedValue);
  //   }
  // };
  const handleSuffixChange = (value) => {
    console.log("toggleValueParent", value);
    setSuffix(value);
  };
  const handlePreferredNameChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(formattedValue)) {
      setPreferredName(formattedValue);
    }
  };
  const handleCityChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue
      .split(/\s+/) // Split input into words by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join words back with a space
    // Regular expression to match only alphabetic characters
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(formattedValue)) {
      if (selectedCity != "") {
        setSelectedCity(formattedValue);
      } else {
        setCity(formattedValue);
      }
    }
  };
  const handleDateOfBirthChange = (date) => {
    console.log("dateOfBirth2", date);
    setDateOfBirth(date);
  };
  // const handleDateOfBirthChange = (e) => {
  //   let inputValue = e.target.value;

  //   // Check if the user pressed the backspace key
  //   if (e.nativeEvent.inputType === "deleteContentBackward") {
  //     // Remove the last character if it's not a slash
  //     if (inputValue.charAt(inputValue.length - 1) !== "/") {
  //       inputValue = inputValue.slice(0);
  //     }
  //   } else {
  //     // Remove any non-digit characters and slashes
  //     inputValue = inputValue.replace(/[^\d/]/g, "");

  //     // Insert slash after the month (mm) and day (dd) if not already present
  //     if (
  //       (inputValue.length === 2 || inputValue.length === 5) &&
  //       inputValue.charAt(inputValue.length - 1) !== "/"
  //     ) {
  //       inputValue += "/";
  //     }

  //     // Format the date as mm/dd/yyyy
  //     if (inputValue.length > 2 && inputValue.length <= 4) {
  //       inputValue = inputValue.replace(/(\d{2})(\d{2})/, "$1/$2");

  //       // Validate month
  //       const mm = inputValue.substring(0, 2);
  //       // if (parseInt(mm) > 12) {
  //       //   return; // Return without making any changes if month is invalid
  //       // }
  //     } else if (inputValue.length > 4 && inputValue.length <= 10) {
  //       //inputValue = inputValue.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");

  //       // Validate month and day
  //       const mm = inputValue.substring(0, 2);
  //       const dd = inputValue.substring(3, 5);
  //       // if (parseInt(mm) > 12 || parseInt(dd) > 31) {
  //       //   return; // Return without making any changes if month or day is invalid
  //       // }
  //     } else if (inputValue.length > 10) {
  //       // If input exceeds 10 characters (including slashes), return without making any changes
  //       return;
  //     }
  //   }

  //   // Update the state with the formatted value
  //   setDateOfBirth(inputValue);
  // };
  const handleMobileNumberChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const limitedValue = rawValue.slice(0, 10); // Limit to 10 digits
    console.log("limitedValue", limitedValue);
    const validMobileNumber = limitedValue.length === 10; // Check if it contains exactly 10 digits
    setValidMobileNumber(validMobileNumber);
    const formattedValue = limitedValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    ); // Format as (555) 222-3333
    setMobileNumber(formattedValue); // Update the state with the formatted value
  };
  // Function to handle email address change
  const handleEmailAddressValid = () => {
    // Regular expression for email validation
    //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //const emailAddress = event.target.value;
    if (!emailRegex.test(emailAddress)) {
      // Invalid email address format
      console.log("EmailAddress1");
      setValidEmail(false);
    } else {
      // Valid email address format
      console.log("EmailAddress2", emailAddress);
      setValidEmail(true);
    }
  };
  const handleEmailAddressChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression for validating email addresses
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setEmailAddress(inputValue);
    // handleEmailAddressValid();
  };
  useEffect(() => {
    handleEmailAddressValid(); // Call validation function on component mount and whenever emailAddress changes
  }, [emailAddress]);
  const handleHomeAddressChange = (homeAddress) => {
    setSelectedHomeAddress(homeAddress);
  };
  const handleCityAddressChange = (city) => {
    setSelectedCity(city);
  };
  const handleStateAddressChange = (state) => {
    setSelectedState(state);
  };
  const handleZipAddressChange = (zip) => {
    setSelectedZip(zip);
  };
  const handleLatitudeChange = (latitude) => {
    setSelectedLatitude(latitude);
  };
  const handleLongitudeChange = (longitude) => {
    setSelectedLongitude(longitude);
  };
  const addDriverDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    const driverData = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      preferred_name: preferredName,
      email: emailAddress,
      phone_number: mobileNumber,
      plan_id: plan,
      home_address: selectedHomeAddress,
      suffix: suffix,
      city: selectedCity || city,
      state: selectedState || state,
      zip: selectedZip || zip,
      date_of_birth: dateOfBirth,
      driver_classification: driverClassification,
      inital_hire_date: initalHireDate,
    };
    addDriverData(driverData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("isDriverAdded0", isDriverAdded);
          handleAddDriverStatus(true);
          setIsDriverAdded(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          //return result.json();
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleAddDriverStatus = (value) => {
    console.log("isDriverAdded1", value);
    onDriverAdd(value);
  };
  const cityStateZip = `${selectedCity}${
    selectedState || selectedZip ? "," : ""
  } ${selectedState}${selectedZip ? "," : ""} ${selectedZip}`;
  return (
    <Card
      // className={
      //   showInputSection ? "addDriverInputCard" : "addDriverLicenseCard"
      // }
      className={"addDriverInputCard"}
    >
      <CardBody>
        <div className="addDriverContainer">
          <div className="addDriverTitleSection">
            <div className="addDriverTitleMessageSection">
              <div className="addDriverTitleStyle">
                {openCrop ? "Adjust Image" : "Add Driver"}
              </div>
              <div className="addDriverMessageStyle">
                {openCrop
                  ? "Align all four corners of the snapshot for easier processing."
                  : "Upload a snapshot of your driver's license for onboarding."}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Icon
                size="regular"
                buttonType="secondary"
                image={closeAddDriver}
                onClick={onClose}
                imageReplace={true}
                hover={false}
              ></Icon>
            </div>
          </div>

          {openCrop ? (
            // <ImageCropper
            //   zoom={zoom}
            //   onZoomChange={handleOnZoom}
            //   rotation={rotation}
            //   onRotationChange={setRotation}
            //   source={remoteImage || localImage}
            //   onCrop={setCroppedImage}
            //   width={1080}
            //   height={1920}
            // />
            <div style={{ width: "100%" }}>
              {frontOpenCrop && (
                <CropEasy
                  photoURL={frontPhotoURL}
                  setOpenCrop={setOpenCrop}
                  setPhotoURL={setFrontPhotoURL}
                  setSelectedImage={setSelectedFrontImage}
                />
              )}
              {backOpenCrop && (
                <CropEasy
                  photoURL={backPhotoURL}
                  setOpenCrop={setOpenCrop}
                  setPhotoURL={setBackPhotoURL}
                  setSelectedImage={setSelectedBackImage}
                />
              )}
            </div>
          ) : (
            <div className="licenseDetailsSection">
              <div className="uploadImageSection">
                <div
                  className="frontSideUploadSection"
                  style={{ width: "100%" }}
                >
                  <div className="frontSideTitle">
                    {/* <div className="dlStyle">Driving License</div> */}
                    <div className="fsStyle"> Front Side</div>
                  </div>
                  <div className="frontSideUploadInput">
                    {selectedFrontImage ? (
                      <>
                        <div className="imageWrapper">
                          <img
                            src={frontPhotoURL}
                            alt="Selected"
                            style={{ maxWidth: "100%", maxHeight: "200px" }}
                          />
                          {/* <ImageCropper
                          zoom={zoom}
                          onZoomChange={handleOnZoom}
                          rotation={rotation}
                          onRotationChange={setRotation}
                          source={remoteImage || localImage}
                          onCrop={setCroppedImage}
                          width={1080}
                          height={1920}
                        /> */}
                          <Icon
                            size="regular"
                            buttonType="secondary"
                            image={addDrivingLicense}
                            onClick={handleButtonFrontClick}
                            className="uploadIcon"
                            imageReplace={true}
                            hover={false}
                          ></Icon>
                        </div>
                      </>
                    ) : (
                      <div className="chooseFileContainer">
                        <Icon
                          size="regular"
                          buttonType="secondary"
                          image={addDrivingLicense}
                          onClick={handleButtonFrontClick}
                          imageReplace={true}
                          hover={false}
                        ></Icon>
                        <div>
                          <div className="dragDrop">
                            Drag and drop your image or
                          </div>
                          <div
                            className="chooseFile"
                            onClick={handleButtonFrontClick}
                          >
                            Choose File
                          </div>
                        </div>
                      </div>
                    )}
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      //value={remoteImage}
                      // onChange={({ target }) => {
                      //   setLocalImage("");
                      //   setRemoteImage(target.value);
                      // }}
                      onChange={fileFrontSelectHandler}
                      ref={fileFrontInputRef}
                    />
                  </div>
                </div>
                <div
                  className="backSideUploadSection"
                  style={{ width: "100%" }}
                >
                  <div className="frontSideTitle">
                    {/* <div className="dlStyle">Driving License</div> */}
                    <div className="fsStyle"> Back Side</div>
                  </div>
                  <div className="frontSideUploadInput">
                    {selectedBackImage ? (
                      <div className="imageWrapper">
                        <img
                          src={backPhotoURL}
                          alt="Selected"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                        <Icon
                          size="regular"
                          buttonType="secondary"
                          image={addDrivingLicense}
                          onClick={handleButtonBackClick}
                          className="uploadIcon"
                          imageReplace={true}
                          hover={false}
                        ></Icon>
                      </div>
                    ) : (
                      <div className="chooseFileContainer">
                        <Icon
                          size="regular"
                          buttonType="secondary"
                          image={addDrivingLicense}
                          onClick={handleButtonBackClick}
                          imageReplace={true}
                          hover={false}
                        ></Icon>
                        <div>
                          <div className="dragDrop">
                            Drag and drop your image or
                          </div>
                          <div
                            className="chooseFile"
                            onClick={handleButtonBackClick}
                          >
                            Choose File
                          </div>
                        </div>
                      </div>
                    )}
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={fileBackSelectHandler}
                      ref={fileBackInputRef}
                    />
                  </div>
                </div>
                <div className="licenseCLassSection">
                  <div style={{ flex: 2 }}>
                    <AddInputField
                      fieldName="Driver’s License No."
                      fieldValue={licencePlateNo}
                      onChange={handleLicencePlateNoChange}
                      inputType="text"
                      background="#ffffff"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <AddInputField
                      fieldName="Class"
                      fieldValue={driverClass}
                      onChange={handleDriverClassChange}
                      inputType="text"
                      background="#ffffff"
                    />
                  </div>
                </div>
              </div>
              <AddDriverInput
                firstName={firstName}
                lastName={lastName}
                middleName={middleName}
                mobileNumber={mobileNumber}
                emailAddress={emailAddress}
                suffix={suffix}
                suffixItems={suffixItems}
                preferredName={preferredName}
                dateOfBirth={dateOfBirth}
                city={city}
                selectedHomeAddress={selectedHomeAddress}
                selectedCity={selectedCity}
                selectedState={selectedState}
                selectedZip={selectedZip}
                selectedLatitude={selectedLatitude}
                selectedLongitude={selectedLongitude}
                handleFirstNameChange={handleFirstNameChange}
                handleLastNameChange={handleLastNameChange}
                handleMiddleNameChange={handleMiddleNameChange}
                handleSuffixChange={handleSuffixChange}
                handlePreferredNameChange={handlePreferredNameChange}
                handleCityChange={handleCityChange}
                handleDateOfBirthChange={handleDateOfBirthChange}
                handleMobileNumberChange={handleMobileNumberChange}
                handleEmailAddressValid={handleEmailAddressValid}
                handleEmailAddressChange={handleEmailAddressChange}
                handleHomeAddressChange={handleHomeAddressChange}
                handleCityAddressChange={handleCityAddressChange}
                handleStateAddressChange={handleStateAddressChange}
                handleZipAddressChange={handleZipAddressChange}
                handleLatitudeChange={handleLatitudeChange}
                handleLongitudeChange={handleLongitudeChange}
                addDriverDataAPI={addDriverDataAPI}
                cityStateZip={cityStateZip}
                onClose={onClose}
              />
            </div>
          )}

          {!openCrop && (
            <div className="addDriverButtonContainer">
              <Buttons
                size={"small"}
                dimensions={"normal"}
                buttonType={"primary"}
                disabled={
                  (firstName == "" ||
                    lastName == "" ||
                    // dateOfBirth == null ||
                    validMobileNumber == false ||
                    validEmail == false ||
                    selectedHomeAddress == "" ||
                    (selectedCity == "" && city == "") ||
                    (selectedState == "" && state == "") ||
                    (selectedZip == "" && validZipNumber == false)) &&
                  true
                }
                imageReplace={true}
                onClick={() => {
                  addDriverDataAPI();
                  onClose();
                }}
              >
                Submit
              </Buttons>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default AddDriverModal;
