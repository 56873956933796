import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import { Modal } from "@material-ui/core";
import "./AccountSettings.scss";
import Title from "app/views/commonComponents/Title/Title";
import FieldNameValue from "../../../commonComponents/FieldNameValue/FieldNameValue";
import avatarProfile from "assets/images/poc/avatarProfile.svg";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import toastIcon from "assets/images/poc/toastIcon.svg";
import toastCross from "assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userProfileImage from "assets/images/sidebar/userProfileImage.jpeg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import logout from "assets/images/sidebar/logout.svg";
import changePasswordIcon from "assets/images/sidebar/changePasswordIcon.svg";
import editPassword from "assets/images/sidebar/editPassword.svg";
import verifyIcon from "assets/images/sidebar/verifyIcon.svg";
import Logout from "../AdministratorLogin/Logout/Logout";
import { loginAPI, changePassword } from "app/apiServices/apiServices";
const AccountSettings = ({}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [userName, setUserName] = useState("");
  const [preferrdName, setPreferrdName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const tabsData = [
    { id: 0, label: "Profile" },
    { id: 1, label: "Settings" },
  ];
  const handlePageTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleShowLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    const authUserData = localStorage.getItem("authUser");
    console.log("authUserData", authUserData);
    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
      const userName = `${userData.first_name} ${userData.last_name}`;
      console.log("userData4", userName);
      setUserName(userName);
      const preferrdName = `${userData.first_name.charAt(
        0
      )} ${userData.last_name.charAt(0)}`;
      console.log("userData5", preferrdName);
      setPreferrdName(preferrdName);
      console.log("userData6", userData._id);
      setUserId(userData._id);
      console.log("userData7", userData.email);
      setEmail(userData.email);
      console.log("userData7", userData.phone_number);
      setPhoneNumber(userData.phone_number);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
  }, []);
  const formatPhoneNumber = (data) => {
    // Remove all non-digit characters
    const cleaned = data.replace(/\D/g, "");
    // Apply formatting
    const formatted = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
    return formatted;
  };
  // Use replace method to format the phone number
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  console.log("formattedPhoneNumber", formattedPhoneNumber);
  const handleChangePassword = () => {
    console.log("Change password clicked!");
    const changePasswordDetails = {
      token: token,
      user_id: userId,
      password: password,
    };
    changePassword(changePasswordDetails)
      .then((response) => {
        console.log("forgotPasswordDetails", response);
        const token = response.data.token; // Assuming the token is returned in the response
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <React.Fragment>
        <div className="">
          <Container fluid>
            <div className="">
              <Row xl={12} md={12} sm={12} xs={12}>
                <Col xl={12} md={12} sm={12} xs={12}>
                  <div className="accountSettingsPage">
                    <div className="titleContainer">
                      <Title name={"Accout Settings"} type={"page"} />
                    </div>
                    <div style={{ width: "100%" }}>
                      {/* <div
                        className="accountTabButtons"
                        style={{ marginBottom: "16px" }}
                      >
                        <TabButtons
                          tabs={tabsData}
                          onTabChange={handlePageTabChange}
                        />
                      </div> */}
                      <div className="accountInformationConatiner">
                        <div className="profile-title">
                          <div style={{ flex: "1" }}>
                            <Title name={"Basic Information"} type={"card"} />
                          </div>
                        </div>
                        <div className="informationDetails">
                          <div className="profileImage">
                            <img
                              src={userProfileImage}
                              alt="Profile Image"
                              style={{
                                height: "112px",
                                width: "112px",
                                borderRadius: "104px",
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <div className="detailsSectionAccount">
                              <div className="valueConatinerPOC">
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Full Name"}
                                    fileldValue={userName || "--"}
                                  />
                                </div>
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Preferred Name"}
                                    fileldValue={preferrdName || "--"}
                                  />
                                </div>
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Role"}
                                    fileldValue={"Fleet Manager"}
                                  />
                                </div>
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Joined"}
                                    fileldValue={"Dec 12,1997"}
                                  />
                                </div>
                              </div>
                              <div className="valueConatinerPOC">
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Mobile Phone Number"}
                                    fileldValue={formattedPhoneNumber || "--"}
                                    image={verifyIcon}
                                  />
                                </div>
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Email Address"}
                                    fileldValue={email || "--"}
                                    image={verifyIcon}
                                  />
                                </div>
                              </div>
                              <div className="valueConatinerPOC">
                                <div style={{ flex: "" }}>
                                  <FieldNameValue
                                    fieldName={"Password"}
                                    fileldValue={"**************"}
                                    image={editPassword}
                                  />
                                </div>
                              </div>
                              <hr className="horizontalLine" />
                              <div className="buttonsSection">
                                <div>
                                  <Buttons
                                    dimensions={"responsive"}
                                    iconPosition="left"
                                    buttonType="secondary"
                                    size="regular"
                                    image={logout}
                                    hover={false}
                                    onClick={handleShowLogoutModal}
                                    color={"#000000"}
                                  >
                                    Logout
                                  </Buttons>
                                </div>
                                <div>
                                  <Buttons
                                    dimensions={"responsive"}
                                    iconPosition="left"
                                    buttonType="secondary"
                                    size="regular"
                                    image={changePasswordIcon}
                                    hover={false}
                                    //onClick={handleShowLogoutModal}
                                    color={"#000000"}
                                  >
                                    Change Password
                                  </Buttons>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    open={isLogoutModalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ overflowY: "scroll" }}
                  >
                    <Logout onClose={handleCloseLogoutModal} />
                  </Modal>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default AccountSettings;
