import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Modal } from "@material-ui/core";
import "./Sidebar.scss";
import Cookies from "js-cookie";
import dashboard from "../../../../assets/images/sidebar/dashboard.svg";
import fleet from "../../../../assets/images/sidebar/fleet.svg";
import drivers from "../../../../assets/images/sidebar/drivers.svg";
import truck from "../../../../assets/images/sidebar/truck.svg";
import shippers from "../../../../assets/images/sidebar/shippers.svg";
import jobs from "../../../../assets/images/sidebar/jobs.svg";
import settlement from "../../../../assets/images/sidebar/settlement.svg";
import openProfileArrow from "../../../../assets/images/sidebar/openProfileArrow.svg";
import addNewWhite from "../../../../assets/images/sidebar/addNewWhite.svg";
import addNewBlack from "../../../../assets/images/sidebar/addNewBlack.svg";
import addDriverWhite from "../../../../assets/images/sidebar/addDriverWhite.svg";
import addDriverBlack from "../../../../assets/images/sidebar/addDriverBlack.svg";
import addVehicleWhite from "../../../../assets/images/sidebar/addVehicleWhite.svg";
import addVehicleBlack from "../../../../assets/images/sidebar/addVehicleBlack.svg";
import addCSVWhite from "../../../../assets/images/sidebar/addCSVWhite.svg";
import addCSVBlack from "../../../../assets/images/sidebar/addCSVBlack.svg";
import addLoadWhite from "../../../../assets/images/sidebar/addLoadWhite.svg";
import addLoadBlack from "../../../../assets/images/sidebar/addLoadBlack.svg";
import closeAddOptions from "../../../../assets/images/sidebar/closeAddOptions.svg";
import Buttons from "../Buttons/Buttons";
import Icon from "../Icon/Icon";
import { Squash as Hamburger } from "hamburger-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AddDriverModal from "app/views/pages/POC/DriversDetails/AddDriver/AddDriverModal/AddDriverModal";
import AddVehicle from "app/views/pages/POC/VehicleDetails/AddVehicle/AddVehicle";
import axios from "axios";
import Logout from "app/views/pages/POC/AdministratorLogin/Logout/Logout";
import avatarProfile from "../../../../assets/images/poc/avatarProfile.svg";
import userProfileImage from "assets/images/sidebar/userProfileImage.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastIcon from "../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../assets/images/poc/toastCross.svg";
import Loader from "react-js-loader";
import { HashSpinner } from "app/views/commonComponents/Spinners/Spinners";
import { getPresignedURL, submitCSV } from "../../../apiServices/apiServices";
import AddAsset from "app/views/pages/POC/AddAsset/AddAsset";
import AddLoad from "../../pages/POC/LoadsDetails/AddLoadDetails/AddLoad/AddLoad";
import EPlus from "assets/images/sidebar/EPlus.svg";
import MilePlus from "assets/images/sidebar/MilePlus.svg";
import gradientBlackSection from "assets/images/commonTopSection/gradientBlackSection.png";
const SideMenu = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [isLogoCollapseHovered, setIsLogoCollapseHovered] = useState(false);
  const [isLogoExpandHovered, setIsLogoExpandHovered] = useState(false);
  const [isLogoTransportHovered, setIsLogoTransportHovered] = useState(false);
  const [isHamburgerHovered, setIsHamburgerHovered] = useState(false);
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false);
  const [isHamburgerFlag, setIsHamburgerFlag] = useState(false);
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
  const [isAddLoadModalOpen, setIsAddLoadModalOpen] = useState(false);
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [presignedUrl, setPredesignedUrl] = useState("");
  const [selectedCSV, setSelectedCSV] = useState(null);
  const fileInputRef = useRef(null);
  const [submitCSVResponse, setSubmitCSVResponse] = useState([]);
  const [successStatus, setSuccessStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const toggleSidebar = () => {
    if (isHamburgerFlag === true && isHamburgerClicked == true) {
      setIsHamburgerFlag(false);
    }
    setIsSidebarOpen(!isSidebarOpen);
    setIsLogoTransportHovered(false);
    if (isSidebarOpen) {
      setIsHamburgerFlag(false);
      setIsHamburgerClicked(false);
      setIsHamburgerHovered(false);
    }
    console.log("HamburgerFlag3", isHamburgerFlag);
  };
  const subOptions = [
    {
      path: "/",
      name: "Active",
    },
    {
      path: "/",
      name: "Prospective",
    },
    {
      path: "/",
      name: "Previous",
    },
    {
      path: "/",
      name: "Option 4",
    },
    // Add more suboptions if needed
  ];
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: dashboard,
      //options: subOptions,
    },
    {
      path: "/fleet",
      name: "Fleets",
      icon: fleet,
      options: subOptions,
    },
    {
      path: "/drivers",
      name: "Drivers",
      icon: drivers,
      options: subOptions,
    },
    {
      path: "/trucks",
      name: "Trucks",
      icon: truck,
      options: subOptions,
    },
    {
      path: "/shippers",
      name: "Shippers",
      icon: shippers,
      options: subOptions,
    },
    {
      path: "/jobs",
      name: "Jobs",
      icon: jobs,
      options: subOptions,
    },
    {
      path: "/settlement",
      name: "Settlement",
      icon: settlement,
      options: subOptions,
    },
  ];
  const menuItemPOC = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: dashboard,
      //options: subOptions,
    },
    {
      path: "/settlement",
      name: "Settlements",
      icon: settlement,
      options: subOptions,
    },
    {
      path: "/drivers",
      name: "Drivers",
      icon: drivers,
      options: subOptions,
    },
    {
      path: "/loads",
      name: "Loads",
      icon: fleet,
      options: subOptions,
    },
    {
      path: "/vehicle",
      name: "Vehicles",
      icon: truck,
      options: subOptions,
    },

    // {
    //   path: "/shippers",
    //   name: "Shippers",
    //   icon: shippers,
    //   options: subOptions,
    // },
    // {
    //   path: "/jobs",
    //   name: "Jobs",
    //   icon: jobs,
    //   options: subOptions,
    // },
  ];
  const clearCookies = () => {
    // Use js-cookie to clear specific cookies
    Cookies.remove("yourCookieName");

    // Alternatively, to clear all cookies, you can iterate over them
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });

    // Add more logic as needed for clearing cookies
  };
  useEffect(() => {
    // This effect will run when the component is mounted
    clearCookies();
  }, []);
  const handleItemClick = (index) => {
    setSelectedItem((prevSelectedItem) => {
      // Toggle selected item if it's already selected, else select the new one
      return prevSelectedItem === index ? null : index;
    });
  };
  const handleMouseLeaveSidebar = () => {
    setShowAddOptions(false);
  };
  const handleMouseEnter = () => {
    setIsHamburgerFlag(true);
    setIsLogoHovered(true);
    if (isLogoCollapseHovered == false) {
      setIsLogoExpandHovered(false);
    }
    if (isLogoHovered && isSidebarOpen == false) {
      setIsLogoCollapseHovered(false);
    }
  };
  const handleMouseLeave = () => {
    setIsHamburgerHovered(true);
    setIsLogoHovered(false);
    if (isSidebarOpen === false) {
      setIsLogoCollapseHovered(false);
    } else {
      setIsLogoCollapseHovered(true);
    }
  };
  const handleTransportMouseEnter = () => {
    setIsLogoTransportHovered(true);
    setIsLogoHovered(true);
    setIsLogoExpandHovered(true);
  };
  const handleTransportMouseLeave = () => {
    //setIsLogoHovered(false);
    //setIsLogoTransportHovered(false);
    //setIsLogoCollapseHovered(false);
  };
  const handleHamburgerClick = () => {
    setIsHamburgerClicked(!isHamburgerClicked);
    setIsHamburgerFlag(true);
    console.log("HamburgerFlag2", isHamburgerFlag);
  };
  const handleHamburgerMouseEnter = () => {
    setIsHamburgerHovered(true);
  };
  const handleHamburgerMouseLeave = () => {
    setIsHamburgerHovered(false);
  };
  const handleShowAddOptions = () => {
    setShowAddOptions(!showAddOptions);
  };
  const handleShowAddDriverModal = () => {
    setIsAddDriverModalOpen(true);
  };
  const handleCloseAddDriverModal = () => {
    setIsAddDriverModalOpen(false);
  };
  const handleShowAddVehicleModal = () => {
    setIsAddVehicleModalOpen(true);
  };
  const handleCloseAddVehicleModal = () => {
    setIsAddVehicleModalOpen(false);
  };
  const handleShowAddLoadModal = () => {
    setIsAddLoadModalOpen(true);
  };
  const handleCloseAddLoadModal = () => {
    setIsAddLoadModalOpen(false);
  };
  const handleShowAddAssetModal = () => {
    setIsAddAssetModalOpen(true);
  };
  const handleCloseAddAssetModal = () => {
    setIsAddAssetModalOpen(false);
  };
  const handleShowLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };
  const csvFileSelectHandler = (event) => {
    console.log("CSVUploadData0", event);
    const file = event.target.files[0];
    setSelectedCSV(file);
    //event.target.value = null;
  };
  const handleButtonFileUpload = () => {
    getCSVUploadUrlAPI();
    fileInputRef.current.click();
  };
  useEffect(() => {
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");
    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
    console.log("CSVUploadData0");
    //csvFileSelectHandler();
    if (presignedUrl == "") {
      console.log("CSVUploadDataUrl11", presignedUrl);
      // getCSVUploadUrlAPI();
    }
    if (presignedUrl && selectedCSV) {
      handleCSVUpload();
    }
  }, [presignedUrl, selectedCSV]);
  const getCSVUploadUrlAPI = () => {
    setIsLoading(true); // Set loading to true when fetching data
    console.log("HelloCSVUrl");
    const storedToken = localStorage.getItem("token");
    getPresignedURL()
      .then((response) => {
        console.log("CSVUploadUrl", response);
        setPredesignedUrl(response.presigned_url);
      })
      .catch((error) => {
        console.log("Error", error);
      });
    // handleCSVUpload();
  };
  console.log("CSVUploadData1", presignedUrl);
  console.log("CSVUploadDataType", typeof presignedUrl);
  console.log("CSVUploadDataCSV2", selectedCSV);
  const handleCSVUpload = () => {
    console.log("HelloCSVUpload");
    console.log("CSVUploadDataType", typeof presignedUrl);
    console.log("helloCSV");
    console.log("CSVUploadDataCSV1", selectedCSV);
    // if (!selectedCSV) {
    //   console.error("CSVUploadDataUrlFetchNo");
    //   return;
    // }
    console.log("CSVUploadDataUrlFetch", presignedUrl);
    // const formData = new FormData();
    // formData.append("file", selectedCSV);
    console.log("CSVUploadDataUrl2", presignedUrl);
    const formData = new FormData();
    formData.append("file", selectedCSV);
    // axios
    //   .put(presignedUrl, formData, {
    axios
      .put(presignedUrl, selectedCSV, {
        headers: {
          "Content-Type": "text/csv",
          //"Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        console.log("CSVstatus", response.status);
        console.log(JSON.stringify(response.data));
        submitCSVAPI(presignedUrl);
        // Handle success
      })
      .catch((error) => {
        console.log(error);
        // Handle error
      });
  };
  const submitCSVAPI = (presignedUrl) => {
    console.log("HelloCSVSubmit");
    const storedToken = localStorage.getItem("token");
    const presignedUrlData = { presigned_url: presignedUrl };
    submitCSV(presignedUrlData)
      .then((response) => {
        console.log("submitCSV", response);
        setSuccessStatus(response.status);
        setSubmitCSVResponse(response.data);
        setIsLoading(false);
        toast("CSV is uploaded", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        console.log("CSVError", error);
      });
  };
  console.log("CSVUploadDataCSV2", selectedCSV);
  console.log("CSVsuccessStatus", successStatus);
  const [vehicleAdded, setVehicleAdded] = useState(false);
  const handleVehicleAdded = (value) => {
    setVehicleAdded(value);
    toast("Vehicle is added.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [driverAdded, setDriverAdded] = useState(false);
  const handleDriverAdded = (value) => {
    setDriverAdded(value);
    toast("Driver is added.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [loadAdded, setLoadAdded] = useState(false);
  const handleLoadAdded = (value) => {
    console.log("isLoadAdded2", value);
    setLoadAdded(value);
    toast("Load is added.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <>
      <div className="container">
        <div
          style={{ width: isSidebarOpen ? "216px" : "72px" }}
          className="sidebarContainer"
          id="sidebar-container"
          // onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeaveSidebar}
        >
          <div
            className="logoContainer"
            style={{
              display: "flex",
              alignItems: "center",
              padding: isSidebarOpen ? "20px" : "8px",
              //padding: "20px 8px 20px 20px",
            }}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            // onClick={toggleSidebar}
          >
            {isSidebarOpen ? (
              <div
                className="logoImage"
                onClick={toggleSidebar}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <img
                  src={MilePlus}
                  style={{ height: "", cursor: "pointer" }}
                  alt="Transport Grey Logo"
                  className={`collapseSibebar ${
                    isLogoHovered && isSidebarOpen ? "stableLogo" : ""
                  }`}
                  onClick={toggleSidebar}
                  // onMouseEnter={handleTransportMouseEnter}
                  // onMouseLeave={handleTransportMouseLeave}
                />
                {/* <img
                  src={PlusBlackLogo}
                  className={`collapseSibebar ${
                    isLogoHovered && isSidebarOpen ? "rotateLogo" : "stableLogo"
                  }`}
                  style={{
                    marginLeft: "10px",
                    height: "34px",
                    transform:
                      isLogoCollapseHovered == false
                        ? isHamburgerFlag
                          ? isHamburgerClicked == false && isLogoHovered
                            ? "rotate(90deg)"
                            : isHamburgerClicked && isLogoHovered
                            ? "rotate(-90deg)"
                            : "rotate(0deg)"
                          : isHamburgerFlag == false
                          ? isHamburgerClicked == false && isLogoHovered
                            ? "rotate(0deg)"
                            : "rotate(90deg)"
                          : isLogoTransportHovered
                          ? "rotate(-90deg)"
                          : isSidebarOpen === true && isLogoHovered
                          ? "rotate(90deg)"
                          : "rotate(0deg)"
                        : isSidebarOpen === true && isLogoHovered
                        ? "rotate(-90deg)"
                        : "rotate(0deg)",
                  }}
                  onClick={toggleSidebar}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                /> */}
              </div>
            ) : (
              <div className="logoImage">
                <img
                  src={EPlus}
                  className={`expandSidebar ${
                    isLogoHovered && isSidebarOpen == false
                      ? "rotateLogoCW"
                      : "stableLogo"
                  }`}
                  style={{
                    height: "",
                    // transform:
                    //   isLogoTransportHovered == false &&
                    //   isLogoExpandHovered &&
                    //   isLogoHovered
                    //     ? "rotate(0deg)"
                    //     : isLogoCollapseHovered == true
                    //     ? isLogoHovered
                    //       ? "rotate(-90deg)"
                    //       : "rotate(0deg)"
                    //     : isHamburgerFlag == false
                    //     ? isHamburgerClicked && isLogoHovered
                    //       ? "rotate(90deg)"
                    //       : isLogoHovered && isHamburgerHovered
                    //       ? "rotate(-90deg)"
                    //       : "rotate(0deg)"
                    //     : isSidebarOpen == false && isLogoHovered
                    //     ? "rotate(90deg)"
                    //     : "rotate(0deg)",
                    cursor: "pointer",
                  }}
                  onClick={toggleSidebar}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                />
              </div>
            )}
          </div>
          <div className="hamburgerContainer">
            <div
              className={isSidebarOpen ? "hamburgerShrink" : "hamburgerExpand"}
              onClick={() => {
                handleHamburgerClick();
                toggleSidebar();
              }}
              onMouseEnter={handleHamburgerMouseEnter}
              onMouseLeave={handleHamburgerMouseLeave}
            >
              {isSidebarOpen ? (
                <Hamburger
                  toggled={isSidebarOpen}
                  toggle={setIsSidebarOpen}
                  size={16}
                  onClick={() => {
                    handleHamburgerClick();
                    toggleSidebar();
                  }}
                  color="#fff"
                  // border="rgba(212, 215, 220, 0.40)"
                />
              ) : (
                <Hamburger
                  toggled={isSidebarOpen}
                  toggle={setIsSidebarOpen}
                  size={16}
                  onClick={() => {
                    handleHamburgerClick();
                    toggleSidebar();
                  }}
                  color="#fff"
                />
              )}
            </div>
          </div>
          <div className="sidebarContent">
            {menuItemPOC.map((item, index) => (
              <div
                key={index}
                className="menuItem"
                // onClick={() => handleItemClick(index)}
              >
                <NavLink
                  to={item.path}
                  className={"sidebarLink"}
                  activeClassName="sidebarLinkActive"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    //justifyContent: isSidebarOpen ? "" : "center",
                  }}
                >
                  {/* Render main menu item */}
                  <div className="sidebarIcon">
                    <img src={item.icon} alt={item.name} />
                  </div>
                  <div
                    className="sidebarItems"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                  >
                    {item.name}
                  </div>
                </NavLink>
                <>
                  {/* {(item.options || selectedItem === index) && (
                <div
                  //className="subOptionsPopup"
                  className={
                    selectedItem === index
                      ? "subOptionsPopupClicked"
                      : "subOptionsPopup"
                  }
                >
                  {item.options.map((subItem, subIndex) => (
                    <div
                      to={subItem.path}
                      className="subOptionLink"
                      activeClassName="activeSubOption"
                      key={subIndex}
                      style={{
                        borderTopLeftRadius:
                          subItem.name == "Active" ? "12px" : "none",
                        borderTopRightRadius:
                          subItem.name == "Active" ? "12px" : "none",
                        borderBottomLeftRadius:
                          subItem.name == "Option 4" ? "12px" : "none",
                        borderBottomRightRadius:
                          subItem.name == "Option 4" ? "12px" : "none",
                      }}
                    >
                      {subItem.name}
                    </div>
                  ))}
                </div>
              )} */}
                </>
              </div>
            ))}
            <div className="bottomOptions">
              <>
                {/* <div className="searchContainerSidebar">
              <div className="sidebarIcon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div
                className="sidebarItems"
                style={{ display: isSidebarOpen ? "block" : "none" }}
              >
                {"Search"}
              </div>{" "}
            </div>
            <div className="notificationContainer">
              {" "}
              <div className="sidebarIcon">
                <img src={notificationIcon} alt="Search" />
              </div>
              <div
                className="sidebarItems"
                style={{ display: isSidebarOpen ? "block" : "none" }}
              >
                {"Notification"}
              </div>{" "}
            </div> */}
              </>
              {showAddOptions && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "16px 0",
                    marginLeft: isSidebarOpen ? "462px" : "216px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="closeOptions"
                      onClick={handleShowAddOptions}
                    >
                      <img src={closeAddOptions} />
                    </div>
                  </div>
                  <div className="addOptionsConatiner">
                    <div
                      className="addNewDriver"
                      onClick={handleShowAddDriverModal}
                    >
                      {isSidebarOpen ? (
                        <Buttons
                          dimensions={"responsive"}
                          iconPosition="left"
                          buttonType="primary"
                          size="regular"
                          image={addDriverWhite}
                          hover={true}
                          onMouseEnterImage={addDriverWhite}
                          onMouseLeaveImage={addDriverWhite}
                          //onClick={handleShowAddOptions}
                        >
                          Add Driver
                        </Buttons>
                      ) : (
                        <Icon
                          size="regular"
                          buttonType="primary"
                          image={addDriverWhite}
                          hover={true}
                          onMouseEnterImage={addDriverWhite}
                          onMouseLeaveImage={addDriverWhite}
                        ></Icon>
                      )}
                    </div>
                    <div
                      className="addNewDriver"
                      onClick={handleShowAddVehicleModal}
                    >
                      {isSidebarOpen ? (
                        <Buttons
                          dimensions={"responsive"}
                          iconPosition="left"
                          buttonType="primary"
                          size="regular"
                          image={addVehicleWhite}
                          hover={true}
                          onMouseEnterImage={addVehicleWhite}
                          onMouseLeaveImage={addVehicleWhite}
                          //onClick={handleShowAddOptions}
                        >
                          Add Vehicle
                        </Buttons>
                      ) : (
                        <Icon
                          size="regular"
                          buttonType="primary"
                          image={addVehicleWhite}
                          hover={true}
                          onMouseEnterImage={addVehicleWhite}
                          onMouseLeaveImage={addVehicleWhite}
                        ></Icon>
                      )}
                    </div>
                    <div className="addNewDriver">
                      {isSidebarOpen ? (
                        <Buttons
                          dimensions={"responsive"}
                          iconPosition="left"
                          buttonType="primary"
                          size="regular"
                          image={addCSVWhite}
                          hover={true}
                          onMouseEnterImage={addCSVWhite}
                          onMouseLeaveImage={addCSVWhite}
                          onClick={handleButtonFileUpload}
                        >
                          Upload CSV
                        </Buttons>
                      ) : (
                        <Icon
                          size="regular"
                          buttonType="primary"
                          onClick={handleButtonFileUpload}
                          image={addCSVWhite}
                          onMouseEnterImage={addCSVWhite}
                          onMouseLeaveImage={addCSVWhite}
                          hover={true}
                        ></Icon>
                      )}
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv"
                        //accept="*.csv, text/csv"
                        //accept=".xlsx, .xls, .csv"
                        //accept=".csv, text/csv, text/,"
                        //accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={csvFileSelectHandler}
                        ref={fileInputRef}
                      />
                    </div>
                    <NavLink to="/addLoad">
                      {" "}
                      <div
                        className="addNewDriver"
                        //onClick={handleShowAddLoadModal}
                      >
                        {isSidebarOpen ? (
                          <Buttons
                            dimensions={"responsive"}
                            iconPosition="left"
                            buttonType="primary"
                            size="regular"
                            image={addLoadWhite}
                            hover={true}
                            onMouseEnterImage={addLoadWhite}
                            onMouseLeaveImage={addLoadWhite}
                            //color={"#000000"}
                          >
                            Add Load
                          </Buttons>
                        ) : (
                          <Icon
                            size="regular"
                            buttonType="primary"
                            image={addLoadWhite}
                            hover={true}
                            onMouseEnterImage={addLoadWhite}
                            onMouseLeaveImage={addLoadWhite}
                          ></Icon>
                        )}
                      </div>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            {showAddOptions == false && (
              <div className="addNewButton">
                {isSidebarOpen ? (
                  <Buttons
                    dimensions={"responsive"}
                    iconPosition="left"
                    buttonType="primary"
                    size="regular"
                    imageReplace={true}
                    onMouseEnterImage={addNewBlack}
                    onMouseLeaveImage={addNewWhite}
                    onClick={handleShowAddOptions}
                  >
                    Add New
                  </Buttons>
                ) : (
                  <Icon
                    size="regular"
                    buttonType="primary"
                    //hover={true}
                    imageReplace={true}
                    onMouseEnterImage={addNewBlack}
                    onMouseLeaveImage={addNewWhite}
                    onClick={handleShowAddOptions}
                  ></Icon>
                )}
              </div>
            )}
            <div
              className="profileSection"
              //style={{ width: isSidebarOpen && "212px" }}
            >
              <>
                {/* {isSidebarOpen ? (
                  <>
                    <Buttons
                      dimensions={"responsive"}
                      iconPosition="left"
                      buttonType="secondary"
                      size="regular"
                      image={logout}
                      hover={false}
                      onClick={handleShowLogoutModal}
                      color={"#000000"}
                    >
                      Logout
                    </Buttons>
                  </>
                ) : (
                  <Icon
                    size="regular"
                    buttonType="secondary"
                    image={logout}
                    hover={false}
                    onClick={handleShowLogoutModal}
                  ></Icon>
                )} */}
              </>
              <NavLink to="/accountSettings">
                <div className="sidebarProfile">
                  <img
                    src={userProfileImage}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "20px",
                    }}
                    //onClick={handleShowLogoutModal}
                  />
                  <div
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                      width: "103px",
                    }}
                  >
                    <p className="adminProfile">
                      {`${firstNameUser} ${lastNameUser}`.length > 15
                        ? `${firstNameUser} ${lastNameUser}`.substring(0, 15) +
                          "..."
                        : `${firstNameUser} ${lastNameUser}`}
                    </p>
                    {/* <p className="adminProfile">
                  {`Eric Nivelle111112222222`.length > 20
                    ? `Eric Nivelle11111222`.substring(0, 15) + "..."
                    : `Eric Nivelle`}
                </p> */}
                    <p className="adminDesignation">
                      {"Fleet Manager".length > 20
                        ? "Fleet Manager".substring(0, 15) + "..."
                        : "Fleet Manager"}
                    </p>
                  </div>
                  {isSidebarOpen && <img src={openProfileArrow} />}
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <Modal
          open={isAddDriverModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <AddDriverModal
            onClose={handleCloseAddDriverModal}
            onDriverAdd={handleDriverAdded}
          />
        </Modal>
        <Modal
          open={isAddVehicleModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <AddVehicle
            onClose={handleCloseAddVehicleModal}
            onVehicleAdd={handleVehicleAdded}
          />
        </Modal>
        <Modal
          open={isAddAssetModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <AddAsset
            onClose={handleCloseAddAssetModal}
            onVehicleAdd={handleVehicleAdded}
          />
        </Modal>
        <Modal
          open={isAddLoadModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <AddLoad
            onClose={handleCloseAddLoadModal}
            onLoadAdd={handleLoadAdded}
          />
        </Modal>
        <Modal
          open={isLogoutModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <Logout onClose={handleCloseLogoutModal} />
        </Modal>
        <main style={{ padding: "0", background: "#F9FAFB" }}>
          <div className="gradientBackground">
            <img
              src={gradientBlackSection}
              style={{ width: "100dvw", height: "300px" }}
            />
          </div>
          <div className={isSidebarOpen ? "childrenExpand" : "childrenShrink"}>
            {children}
          </div>
        </main>
        {isLoading && (
          <div
            className="hashLoader"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1000",
            }}
          >
            <HashSpinner color={"#393A3D"} />
          </div>
        )}
        <ToastContainer
          className={"toast-message"}
          toastStyle={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 12px 8px 8px",
            background: "rgba(0, 188, 249, 0.1)",
            borderRadius: "54px",
            color: "#00bfff",
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            fontWeight: "600",
            //height: "36px",
            minHeight: "none",
          }}
          hideProgressBar={true}
          closeButton={<img src={toastCross} />}
          icon={<img src={toastIcon} />}
        />
      </div>
    </>
  );
};
const Sidebar = React.memo(SideMenu);
export default Sidebar;
