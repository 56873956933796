import React from "react";
import { Button, Dropdown } from "reactstrap";
import whiteCrossIcon from "../../../../assets/images/buttons/whiteCrossIcon.svg";
import blackCrossIcon from "../../../../assets/images/buttons/blackCrossIcon.svg";
import disabledCrossIcon from "../../../../assets/images/buttons/disabledCrossIcon.svg";
import optionsMenu from "../../../../assets/images/optionsMenu/optionsMenu.svg";
import "./OptionsMenu.scss"; // Use a common SCSS file for styling
const OptionsMenu = ({
  children,
  color,
  onClick,
  iconPosition,
  disabled,
  size, //regular and small
  image,
  dimensions, //normal or responsive
  menuList,
  optionsMenuOpen,
  statusPopupOpen,
  onClickMenuItem,
  // Other props as needed
  ...rest
}) => {
  console.log("MenuList", menuList);
  console.log("statusPopupOpen", statusPopupOpen);
  // Function to handle menu item click
  const handleMenuItemClick = (item, event) => {
    // Call the onClick function with the selected item as an argument
    onClickMenuItem(item);
  };
  return (
    <div className="optionsMenuContainer">
      {" "}
      {!statusPopupOpen && (
        <div
          className="optionsMenu"
          onClick={onClick}
          {...rest}
          style={{
            borderColor: optionsMenuOpen
              ? "color(display-p3 0.4196 0.4235 0.4471)"
              : "color(display-p3 0.8314 0.8431 0.8627)",
          }}
        >
          <img src={optionsMenu} alt="Options" />
        </div>
      )}
      {optionsMenuOpen && (
        <div className="optionsPopup">
          {menuList.map((listItem, index) => (
            <div
              className="listOptions"
              key={index}
              onClick={(event) => {
                event.stopPropagation();
                handleMenuItemClick(listItem.item);
              }}
            >
              <img src={listItem.icon} />
              <p className="optionsStyle" style={{ marginBottom: "0" }}>
                {listItem.item}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionsMenu;
