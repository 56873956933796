const BASE = 'https://ms-dev.withtransport.com';

const config = {
  BASE:`${BASE}`,
  USER_SERVICE: `${BASE}/user-service`,
  FMCSA_SERVICE: `${BASE}/fmcsa-service`,
  TENANT_SERVICE: '',
  WORKFLOW_SERVICE: '',
};

export default config;
