import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Modal,
  //TablePagination,
  Snackbar,
} from "@material-ui/core";

import { TablePagination } from "@mui/material";
import "./TablePOC.scss";
//import Toaster from "app/views/commonComponents/Toaster/Toaster";
import CustomSnackbar from "app/views/commonComponents/Toaster/Toaster";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import Expirations from "app/views/commonComponents/Expirations/Expirations";
import Labels from "app/views/commonComponents/Labels/Labels";
import reviewArrow from "../../../../../assets/images/poc/reviewArrow.svg";
import assignTruck from "../../../../../assets/images/poc/assignTruck.svg";
import unassignVehicle from "../../../../../assets/images/poc/unassignVehicle.svg";
import { useHistory } from "react-router-dom";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import AssignVehicle from "../DriversDetails/AssignVehicle/AssignVehicle/AssignVehicle";
import UnassignVehicle from "../DriversDetails/UnassignVehicle/UnassignVehicle";
import OptionsMenu from "app/views/commonComponents/OptionsMenu/OptionsMenu";
import StatusPopup from "app/views/commonComponents/StatusPopup/StatusPopup";
import SubTablePOC from "../SubTablePOC/SubTablePOC";
import AssignDriver from "../VehicleDetails/AssignDriver/AssignDriver/AssignDriver";
import assignDriver from "../../../../../assets/images/poc/assignDriver.svg";
import UnassignDriver from "../VehicleDetails/UnassignDriver/UnassignDriver";
import optionsMenu from "../../../../../assets/images/optionsMenu/optionsMenu.svg";
import AddOtherRevenue from "../SettlementsDetails/AddOtherRevenue/AddOtherRevenue";
import ReactPaginate from "react-paginate";
import Pagination from "app/views/commonComponents/Pagination/Pagination";
import toastIcon from "../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { assignVehicle, settlementStatus } from "app/apiServices/apiServices";
import AdditionalPaymentLoadsTable from "../LoadsDetails/AdditionalPaymentLoadsTable/AdditionalPaymentLoadsTable";
//import SubTablePOC from "../SubTablePOC/SubTablePOC";
const TablePOC = ({
  headers,
  data,
  activeTab,
  tableName,
  subHeaders,
  subData,
  onClose,
  settlementsUploadId,
  rowClick,
  rowClickHandler,
  settlementDate,
  uploadId,
  driverId,
  driverIdArray,
  vehicleId,
  fullDriverName,
  driverName,
  pagination,
  unassignCheck,
  paginationEnabled,
  onVehicleAssign,
  // handleMenuClick,
  // optionsMenuOpen,
  currentPage,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
  onNavigateAway,
  sendUnassignDriverStatus,
  sendUnassignVehicleStatus,
}) => {
  const history = useHistory(); // Initialize useHistory hook
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [showAssignTruck, setShowAssignTruck] = useState(false);
  const [showUnassignTruck, setShowUnassignTruck] = useState(false);
  const [statusPopupOpenLabel, setStatusPopupOpenLabel] = useState({});
  const [selectedOptionsMenuItemLabel, setSelectedOptionsMenuItemLabel] =
    useState({});
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [selectedOptionsMenuItem, setSelectedOptionsMenuItem] = useState(null);
  const [rowPopupOpen, setRowPopupOpen] = useState({});
  const menuList = [{ item: "Delete", icon: "" }];
  const menuListLabel = [
    { item: "Review", icon: "" },
    { item: "Paid", icon: "" },
  ];
  const [sortBy, setSortBy] = useState(""); // Initialize sortBy to the default column name
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedDriverData, setSortedDriverData] = useState(null);
  const [showAssignDriver, setShowAssignDriver] = useState(false);
  const [showUnassignDriver, setShowUnassignDriver] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [vehicleIdData, setVehicleIdData] = useState(0);
  const [rowHovered, setRowHovered] = useState(false);
  const [isVehicleAssigned, setIsVehicleAssigned] = useState(false);
  const [unassignDriverId, setUnassignedDriverId] = useState("");
  const handleSort = (columnName, data) => {
    // if (tableName == "driverLeaderboard" || tableName == "vehicleLeaderboard") {
    console.log("helloSorting1", data);
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }

    const sortedData = [...data].sort((a, b) => {
      console.log("helloSorting3");
      if (sortBy === null) return 0; // No sorting if sortBy is null
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      // Helper function to convert string values containing "$" to numeric values
      const parseNumericValue = (value) => {
        // Remove "$" and commas, then parse as float
        return parseFloat(value.replace(/[$,]/g, ""));
      };

      // Custom comparison logic based on column headers
      switch (sortBy) {
        case "driverName":
          // Alphabetical sorting for driverName
          return sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        case "grossMargin":
        case "rpm":
          // Numeric sorting for grossMargin and rpm (remove "$" and compare)
          return sortOrder === "asc"
            ? parseNumericValue(valueA) - parseNumericValue(valueB)
            : parseNumericValue(valueB) - parseNumericValue(valueA);
        case "milesLoaded":
        case "loadsDelivered":
          // Numeric sorting for milesLoaded and loadsDelivered
          return sortOrder === "asc"
            ? parseInt(valueA.replace(/,/g, "")) -
                parseInt(valueB.replace(/,/g, ""))
            : parseInt(valueB.replace(/,/g, "")) -
                parseInt(valueA.replace(/,/g, ""));
        default:
          // Default sorting (no transformation)
          return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        //return 0;
      }
    });

    console.log("helloSorting2", sortedData);
    setSortedDriverData(sortedData); // Move the setSortedDriverData call here
    // }
  };
  const handleStatusPopupOpenLabel = (event, rowIndex) => {
    event.stopPropagation();
    setStatusPopupOpenLabel((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex], // Toggle the state for the current row
    }));
  };
  const handleOptionsMenuLabel = (
    event,
    rowIndex,
    selectedItem,
    driverID,
    uploadId
  ) => {
    console.log("SelectedItem", selectedItem.toLowerCase());
    console.log("SelectedItemDiD", driverID);
    console.log("SelectedItemUiD", uploadId);
    handleSettlementStatusChange(selectedItem, driverID, uploadId);
    setSelectedOptionsMenuItemLabel((prevState) => ({
      ...prevState,
      [rowIndex]: selectedItem, // Update the selected item for the current row
    }));
  };
  const handleSettlementStatusChange = (selectedItem, driverID, uploadId) => {
    console.log("SelectedItem2", selectedItem.toLowerCase());
    console.log("SelectedItemDiD2", driverID);
    console.log("SelectedItemUiD2", uploadId);
    const storedToken = localStorage.getItem("token");
    const statusData = {
      driver_id: driverID,
      upload_id: parseInt(uploadId),
      status: selectedItem.toLowerCase(),
    };
    settlementStatus(statusData)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          onClose();
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleRowPopupOpen = (rowIndex) => {
    setRowPopupOpen((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };
  const handleStatusPopupOpen = (event, rowIndex) => {
    event.stopPropagation();
    setStatusPopupOpen(!statusPopupOpen);
    handleRowPopupOpen(rowIndex);
    setOptionsMenuOpen(!statusPopupOpen);
  };
  const handleOptionsMenu = (selectedItem, event) => {
    //setOptionsMenuOpen(!optionsMenuOpen);
    event.stopPropagation();
    console.log("SelectedItem", selectedItem);
    setSelectedOptionsMenuItem(selectedItem);
    setOptionsMenuOpen(false);
  };
  const handleMenuClick = (index, event) => {
    // event.stopPropagation();
    setOptionsMenuOpen(!optionsMenuOpen);
  };
  const handleOpenAssignDriver = () => {
    setShowAssignTruck(true);
  };
  const handleCloseAssignDriver = () => {
    setShowAssignTruck(false);
  };
  const handleOpenUnassignVehicle = () => {
    console.log("hellotruck");
    setShowUnassignTruck(true);
  };
  const handleCloseUnassignVehicle = () => {
    setShowUnassignTruck(false);
  };
  const handleOpenUnassignDriver = (value) => {
    setShowUnassignDriver(true);
    console.log("unassignDriverId1", value);
    setUnassignedDriverId(value);
  };
  const handleCloseUnassignDriver = () => {
    setShowUnassignDriver(false);
  };
  const handleAssignVehicle = (
    rowData,
    index,
    event,
    settlementsUploadId,
    tableName,
    rowClick //true or false
  ) => {
    console.log("params", rowData);
    assignVehicleService(rowData);

    if (!event.target.closest("button")) {
      event.stopPropagation(); // Stop event propagation to prevent button click
      // Rest of your code to handle row click
    }
  };
  const assignVehicleService = (rowData) => {
    const storedToken = localStorage.getItem("token");

    let d_id = null;
    let v_id = null;
    if (vehicleId) {
      d_id = rowData.driver_id;
      v_id = vehicleId;
      console.log("vid", d_id, v_id);
    }
    if (driverId) {
      d_id = driverId;
      v_id = rowData.vehicleId;
      console.log("did", d_id, v_id);
    }
    const assignVehicleData = {
      driver_id: d_id,
      vehicle_id: v_id,
    };
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/vehicle_drivers/assign",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       driver_id: d_id,
    //       vehicle_id: v_id,
    //       // start_date: rowData.initial_hire_date,
    //     }),
    //   }
    // )
    //   .then((result) => {
    //     if (result.status == 200 || result.status == 201) {
    //       setIsVehicleAssigned(true);
    //       onClose();
    //       return result.json();
    //     } else {
    //       alert("Please check login credentials!");
    //       setTimeout(() => {
    //         // Close the alert
    //         document.querySelector(".alert").style.display = "none";
    //       }, 3000);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //     }
    //   })
    assignVehicle(assignVehicleData)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          setIsVehicleAssigned(true);
          onClose();
        }
        // alert(response.message);
        console.log("AssignDriverTable1");
        // Automatically close the alert after 3 seconds
        setTimeout(() => {
          // Close the alert
          document.querySelector(".alert").style.display = "none";
        }, 3000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleunassignRowClick = (
    rowData,
    index,
    event,
    settlementsUploadId,
    tableName,
    rowClick //true or false
  ) => {
    console.log("hiirow3", rowData);
    setVehicleIdData(rowData.vehicleNo);
    console.log("hiirow2", vehicleIdData);
  };
  const handleRowClick = (
    rowData,
    index,
    event,
    settlementsUploadId,
    tableName,
    rowClick, //true or false
    paginationEnabled //true or false
  ) => {
    // Call the onNavigateAway function before navigating away
    if (paginationEnabled) {
      onNavigateAway();
    }
    if (rowData.vehicleNo) {
      setVehicleIdData(rowData.vehicleNo);
    }
    console.log("hiirow", rowData);
    if (!rowClick) return;
    // Check if the first row is clicked and toggle expansion state
    if (
      (rowClick && tableName == "loadsSettelmentsTable") ||
      (tableName == "loadsDriversTable" &&
        (index === 0 || index <= data.length - 1))
    ) {
      console.log("expanded1", isExpanded);
      setIsExpanded(!isExpanded);
    }
    // Toggle expandedRowIndex to show/hide expanded table
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
    // Define the function to handle row click
    if (rowClick && tableName == "vehicleLeaderBoard") {
      history.push(`/vehicle`);
    }
    // Navigate to the new page using history.push()
    if (
      rowClick &&
      rowData.vehicleId &&
      tableName == "vehicleDataDetailsTable"
    ) {
      history.push(`/vehicle/${rowData.vehicleId}`);
    }
    if (!event.target.closest("button")) {
      event.stopPropagation(); // Stop event propagation to prevent button click
      // Rest of your code to handle row click

      if (rowClick && rowData.driverId) {
        history.push(`/drivers/${rowData.driverId}/${rowData.assignedTruck}`);
      }
      // if (
      //   rowClick &&
      //   rowData.settlementDate &&
      //   rowData.uploadId &&
      //   rowData.driverID
      // ) {
      //   history.push(
      //     `/settlement/${rowData.settlementDate}/${rowData.uploadId}/${rowData.driverID}`
      //   );
      // }
    }
    if (rowClick && rowData.driverName && tableName == "driverLeaderBoard") {
      history.push(`/drivers`);
    }
    if (
      rowClick &&
      rowData.driverId &&
      rowData.assignedTruck &&
      rowData.datesDriver &&
      rowData.uploadIdDriver &&
      tableName != "loadsTable"
    ) {
      history.push(
        `/drivers/${rowData.driverId}/${rowData.assignedTruck}/${rowData.datesDriver}/${rowData.uploadIdDriver}`
      );
    }
    if (rowClick && tableName == "weeklySettelmentsTableDashboard") {
      history.push(`/settlement`);
    }
    if (rowClick && rowData.settlementDate && rowData.uploadId) {
      history.push(`/settlement/${rowData.settlementDate}/${rowData.uploadId}`);
    }
    if (
      rowClick &&
      rowData.settlementDate &&
      rowData.uploadId &&
      rowData.driverID
    ) {
      history.push(
        `/settlement/${rowData.settlementDate}/${rowData.uploadId}/${rowData.driverID}`
      );
    }
    //settelmentRowClickHandler(index, rowData.driverID);
    if (tableName == "settelmentsDetailsPOC") {
      rowClickHandler(index, rowData.driverID);
    }
    rowClickHandler(index);
  };
  // Logic to calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // // Logic to calculate the current items to display based on pagination
  // const indexOfLastItem = currentPageTable * itemsPerPageTable;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPageTable;
  const currentItems =
    sortedDriverData || data.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    if (isVehicleAssigned) {
      // Call the callback function passed from the parent
      console.log("isVehicleAssigned3", isVehicleAssigned);
      onVehicleAssign(isVehicleAssigned);
    }
  }, [isVehicleAssigned, onVehicleAssign]);
  console.log("isVehicleAssigned", isVehicleAssigned);
  const handleUnassignDriverStatus = (value) => {
    sendUnassignDriverStatus(value);
  };
  const handleUnassignVehicleStatus = (value) => {
    sendUnassignVehicleStatus(value);
  };
  console.log("unassignDriverId", unassignDriverId);
  return (
    <>
      <div>
        <Table
          style={{
            width: "100%",
          }}
        >
          <TableHead
            className={tableName == "loadsTable" ? "" : "tableStickyHeader"}
            style={{ width: "100%" }}
          >
            <TableRow
              style={{
                borderTop:
                  tableName == "loadsDetailsTable" ||
                  tableName == "settelmentsPOC"
                    ? "1px solid var(--Gray-06, #E3E5E8)"
                    : "",
                borderBottom: isExpanded
                  ? "2px solid #B8BEC7"
                  : tableName == "loadsDetailsTable" ||
                    tableName == "settelmentsPOC"
                  ? "1px solid var(--Gray-06, #E3E5E8)"
                  : "",
              }}
            >
              {Object.values(headers).map((header, index) => (
                //header == "Options Menu" ? null :
                <>
                  {console.log("headersSettels", headers.length)}
                  <TableData
                    key={index}
                    cellType={"headerCell"}
                    size={"regular"}
                    align={
                      header == "Driver Name" ||
                      header == "Make" ||
                      header == "Type" ||
                      header == "Dates" ||
                      header == "Action" ||
                      header == "Full Name" ||
                      header == "Origin" ||
                      header == "Destination" ||
                      header == "Driver(s)" ||
                      header == "Driver" ||
                      header == "Date" ||
                      header == "Model" ||
                      header == "Origin & Destination" ||
                      header == "Co-Driver" ||
                      header == "Year" ||
                      header == "Status" ||
                      header == "Phone No." ||
                      header == "Assigned Truck" ||
                      header == "Vehicle Id." ||
                      header == "Vehicle"
                        ? "left"
                        : index > 0
                        ? "right"
                        : ""
                    }
                    onClick={() =>
                      handleSort(Object.keys(headers)[index], data)
                    }
                    style={{
                      cursor: "pointer",
                      background: isExpanded
                        ? "#ffffff"
                        : tableName == "loadsDetailsTable" ||
                          tableName == "settelmentsPOC"
                        ? "#F4F5F8"
                        : "#F9FAFB",
                      borderTopLeftRadius:
                        tableName == "loadsDetailsTable" ||
                        tableName == "settelmentsPOC"
                          ? ""
                          : index == 0
                          ? "10px"
                          : "",
                      borderTopRightRadius:
                        tableName != "loadsDetailsTable" ||
                        tableName != "settelmentsPOC"
                          ? ""
                          : index === Object.keys(headers).length - 1
                          ? "10px"
                          : "",
                    }}
                  >
                    {header == "Options Menu" ? "" : header}
                  </TableData>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: "auto", width: "100%" }}>
            {currentItems.length > 0 ? (
              currentItems.map((row, rowIndex) => (
                <>
                  <TableRow
                    key={rowIndex}
                    onClick={(event) =>
                      handleRowClick(
                        row,
                        rowIndex,
                        event,
                        settlementsUploadId,
                        tableName,
                        rowClick,
                        paginationEnabled
                      )
                    }
                    style={{
                      borderBottom:
                        rowIndex === data.length - 1
                          ? "0"
                          : "1px solid var(--T---LT-Gray, #ECEEF1)",
                      //height: tableName == "driversTable" ? "80px" : "",
                      borderTop:
                        expandedRowIndex == rowIndex ? "2px solid #B8BEC7" : "",
                    }}
                    className="driver-table"
                  >
                    {Object.entries(row).map(([key, cell], cellIndex) =>
                      ((tableName == "driversHistoryVehicles" ||
                        tableName == "vehicleHistoryDrivers") &&
                        key == "endDate") ||
                      (tableName == "driversSettelmentsTable" &&
                        (key == "driverId" ||
                          key == "assignedTruck")) ? null : key == "uploadId" ||
                        key == "newSettlementDate" ||
                        key == "newUploadId" ||
                        key == "uploadIdDriver" ||
                        (tableName == "settelmentsDetailsPOC" &&
                          key == "settlementDate") ? null : (
                        <>
                          <TableData
                            key={cellIndex}
                            cellType={"bodyCell"}
                            size={"large"}
                            align={
                              key == "contractorAmount" ||
                              key == "action" ||
                              key == "optionsMenu"
                                ? "right"
                                : key == "" ||
                                  key == "driverName" ||
                                  key == "fullName" ||
                                  key == "make" ||
                                  key == "model" ||
                                  key == "type" ||
                                  key == "action" ||
                                  key == "dates" ||
                                  key == "origin" ||
                                  key == "destination" ||
                                  key == "driver" ||
                                  key == "date" ||
                                  key == "fullDriverName" ||
                                  key == "originDestination" ||
                                  key == "dates" ||
                                  key == "coDriver" ||
                                  key == "year" ||
                                  key == "status" ||
                                  key == "phoneNo" ||
                                  key == "assignedTruck" ||
                                  key == "vehicleId"
                                ? "left"
                                : cellIndex > 0
                                ? "right"
                                : rowIndex == data.length - 1 &&
                                  cell == "Sub Total"
                                ? "right"
                                : ""
                            }
                            style={{
                              fontWeight:
                                key == "netPay" ||
                                key == "driverPayments" ||
                                key == "contractorAmount"
                                  ? "500"
                                  : "400",
                              color:
                                tableName == "loadsTable" ||
                                tableName == "additionalPaymentTable"
                                  ? rowIndex == data.length - 1 &&
                                    key == "contractorAmount"
                                    ? "#6F5FED"
                                    : "#393A3D"
                                  : tableName == "deductionDescriptionTable" &&
                                    key == "contractorAmount"
                                  ? "#F35631"
                                  : "#393A3D",
                            }}
                          >
                            {tableName == "loadsDetailsTable" &&
                              cellIndex == 0 && (
                                <>
                                  {" "}
                                  {console.log("rowKey1", key)}
                                  {console.log("rowKey2", cell)}
                                  {console.log("rowKey", row)}
                                </>
                              )}
                            {tableName == "driverDataTable" &&
                            cell == "Options Menu" ? (
                              <>
                                <img
                                  src={optionsMenu}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleStatusPopupOpen(event, rowIndex);
                                  }}
                                />
                                {rowPopupOpen[rowIndex] && (
                                  <div className="optionsMenuPopup">
                                    <OptionsMenu
                                      menuList={menuList}
                                      onClickMenuItem={handleOptionsMenu}
                                      optionsMenuOpen={optionsMenuOpen}
                                      statusPopupOpen={statusPopupOpen}
                                    />
                                  </div>
                                )}
                              </>
                            ) : key == "phoneNo" ? (
                              cell.replace(
                                /^(\d{3})(\d{3})(\d{4})$/,
                                "($1) $2-$3"
                              )
                            ) : key == "totalOtherRevenue" ||
                              key == "totalDeduction" ? (
                              (() => {
                                const stringValue = cell;
                                const [part1, part2] = stringValue
                                  .split("•")
                                  .map((part) => part.trim());
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: "15px",
                                    }}
                                  >
                                    <div
                                      style={{ textAlign: "right", flex: "3" }}
                                    >
                                      {part1}
                                    </div>
                                    {tableName ==
                                      "weeklySettelmentsTableDashboard" ||
                                    tableName == "settelmentsPOC" ||
                                    tableName == "driversSettelments" ||
                                    tableName == "driversSettelmentsTable" ||
                                    tableName == "driversSettelmentsTablePOC" ||
                                    tableName == "settelmentsDetailsPOC" ||
                                    tableName == "settelmentsDriverTablePOC" ? (
                                      ""
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          gap: "10px",
                                          flex: "1",
                                        }}
                                      >
                                        <div style={{ textAlign: "" }}>•</div>
                                        <div style={{ textAlign: "" }}>
                                          {part2}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })()
                            ) : key === "gross" ? (
                              <img src={cell} alt="Image" />
                            ) : tableName == "vehicleHistoryDrivers" &&
                              row.endDate == null &&
                              key == "action" ? (
                              //Unassign Vehicle
                              <Buttons
                                size={"regular"}
                                dimensions={"normal"}
                                buttonType={"secondary"}
                                iconPosition={"left"}
                                image={assignTruck}
                                width={"auto"}
                                onClick={handleOpenUnassignVehicle}
                                hover={false}
                                color={"#000000"}
                              >
                                Unassign
                              </Buttons>
                            ) : tableName == "driversHistoryVehicles" &&
                              row.endDate == null &&
                              row.driverId &&
                              key == "action" ? (
                              //Unassign Driver
                              <Buttons
                                size={"small"}
                                dimensions={"normal"}
                                buttonType={"secondary"}
                                iconPosition={"left"}
                                image={assignDriver}
                                width={"auto"}
                                onClick={(event) => {
                                  handleOpenUnassignDriver(row.driverId);
                                }}
                                hover={false}
                                color={"#000000"}
                              >
                                Unassign
                              </Buttons>
                            ) : cell == "Assign" ? (
                              <Buttons
                                size={"small"}
                                dimensions={"normal"}
                                buttonType={"secondary"}
                                iconPosition={"left"}
                                image={assignTruck}
                                hover={false}
                                color={"#000000"}
                              >
                                Assign
                              </Buttons>
                            ) : cell == "Assign Vehicle" ? (
                              <Buttons
                                size={"regular"}
                                dimensions={"responsive"}
                                buttonType={"secondary"}
                                iconPosition={"left"}
                                image={assignTruck}
                                onClick={(event) =>
                                  handleAssignVehicle(
                                    row,
                                    rowIndex,
                                    event,
                                    settlementsUploadId,
                                    tableName,
                                    rowClick
                                  )
                                }
                                hover={false}
                                color={"#000000"}
                              >
                                Assign Vehicle
                              </Buttons>
                            ) : cell == "Assign Truck" ? (
                              <Buttons
                                size={"regular"}
                                dimensions={"normal"}
                                buttonType={"secondary"}
                                iconPosition={"left"}
                                image={assignTruck}
                                width={"auto"}
                                onClick={handleOpenAssignDriver}
                                hover={false}
                                color={"#000000"}
                              >
                                Assign Truck
                              </Buttons>
                            ) : (tableName == "driversSettelmentsTable" ||
                                tableName == "driversSettelmentsTablePOC" ||
                                tableName == "settelmentsDetailsPOC" ||
                                tableName == "settelmentsDriverTablePOC") &&
                              cell === "Review" ? (
                              <>
                                {" "}
                                <Labels
                                  labelType="light"
                                  labelTag={
                                    selectedOptionsMenuItemLabel[rowIndex] ==
                                    "Paid"
                                      ? "green"
                                      : "purple"
                                  }
                                  // arrow={
                                  //   selectedOptionsMenuItem == "Paid" ? false : true
                                  // }
                                  iconPosition={"right"}
                                  image={
                                    selectedOptionsMenuItemLabel[rowIndex] ==
                                    "Paid"
                                      ? ""
                                      : reviewArrow
                                  }
                                  onClick={(event) =>
                                    handleStatusPopupOpenLabel(event, rowIndex)
                                  }
                                >
                                  {console.log(
                                    "selectedOptionsMenuItemLabel[rowIndex]",
                                    selectedOptionsMenuItemLabel[rowIndex]
                                  )}
                                  {selectedOptionsMenuItemLabel[rowIndex] ||
                                    cell}
                                </Labels>
                                {statusPopupOpenLabel[rowIndex] && (
                                  <div className="optionsMenuPopupLabel">
                                    <StatusPopup
                                      menuList={menuListLabel}
                                      onClickMenuItem={(
                                        selectedItem,
                                        event
                                      ) => {
                                        handleOptionsMenuLabel(
                                          event,
                                          rowIndex,
                                          selectedItem,
                                          row.driverID || row.driverId,
                                          row.uploadId || row.uploadIdDriver
                                        );
                                      }}
                                      statusPopupOpen={
                                        statusPopupOpenLabel[rowIndex]
                                      }
                                    />
                                  </div>
                                )}
                              </>
                            ) : cell === "On Load" ? (
                              <Labels
                                labelType="light"
                                labelTag="purple"
                                arrow={false}
                              >
                                {cell}
                              </Labels>
                            ) : cell === "Cancelled" ||
                              cell === "Suspended" ||
                              cell === "Maintenance" ? (
                              <Labels
                                labelType="light"
                                labelTag="red"
                                arrow={false}
                              >
                                {cell}
                              </Labels>
                            ) : cell === "Available" ||
                              cell === "Completed" ||
                              cell === "Pushed" ||
                              cell === "Paid" ? (
                              <Labels
                                labelType="light"
                                labelTag="green"
                                arrow={false}
                              >
                                {cell}
                              </Labels>
                            ) : cell === "Resting" ||
                              cell === "Not Assigned" ? (
                              <Labels
                                labelType="light"
                                labelTag="yellow"
                                arrow={false}
                              >
                                {cell}
                              </Labels>
                            ) : (
                              cell
                            )}
                          </TableData>
                        </>
                      )
                    )}
                  </TableRow>
                  {expandedRowIndex == rowIndex && (
                    <TableRow>
                      <TableCell
                        colSpan={Object.keys(row).length}
                        style={{ borderBottom: "0", padding: "0" }}
                      >
                        {/* {tableName == "loadsDetailsTable" ? (
                          <div
                            style={{
                              margin: "16px 0",
                              borderBottom:
                                expandedRowIndex == rowIndex
                                  ? "2px solid #B8BEC7"
                                  : "",
                            }}
                          >
                            <AdditionalPaymentLoadsTable
                              loadId={row.loadId}
                              data={subData}
                              headers={subHeaders}
                            />
                          </div>
                        ) : ( */}
                        <SubTablePOC
                          data={subData}
                          headers={subHeaders}
                          tableName={tableName}
                        />
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{ borderBottom: "0", padding: "0" }}
                >
                  <TableData
                    colSpan={1}
                    size="large"
                    style={{ textAlign: "center", border: "0" }}
                    //className="noRecordsFound"
                    cellType={"bodyCell"}
                  >
                    No records found
                  </TableData>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {paginationEnabled && (
        <div className="stickyPagination">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            onPageChange={onPageChange}
            onItemsPerPageChange={onItemsPerPageChange}
          />
          {/* <Pagination
            currentPage={currentPageTable}
            itemsPerPage={itemsPerPageTable}
            totalItems={data.length}
            onPageChange={handlePageChangeTable}
            onItemsPerPageChange={handleItemsPerPageChangeTable}
          /> */}
        </div>
      )}
      {console.log("unassignDriverId2", unassignDriverId)}
      <Modal
        open={showUnassignTruck}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //disableBackdropClick
      >
        <UnassignVehicle
          onClose={handleCloseUnassignVehicle}
          driverId={driverId}
          vehicleId={vehicleIdData}
          fullDriverName={fullDriverName}
          onUnassignVehicle={handleUnassignVehicleStatus}
        />
      </Modal>
      <Modal
        open={showUnassignDriver}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        //disableBackdropClick
      >
        <UnassignDriver
          onClose={handleCloseUnassignDriver}
          driverName={driverName}
          driverId={driverId}
          unassignDriverId={unassignDriverId}
          driverIdArray={driverIdArray}
          vehicleId={vehicleId}
          onUnassignDriver={handleUnassignDriverStatus}
        />
      </Modal>
    </>
  );
};
export default TablePOC;
