import React from "react";
import "./AddInputField.scss"; // Use a common SCSS file for styling
import PropTypes from "prop-types";
const AddInputField = ({
  fieldName,
  fieldValue,
  inputType,
  onChange,
  onBlur,
  style,
  placeholder,
  readOnly,
  autoComplete,
  zIndex,
  background,
}) => {
  // Truncate the fieldValue if it exceeds 20 characters
  const truncatedValue =
    fieldValue && fieldValue.length > 20
      ? fieldValue.substring(0, 20) + "..."
      : fieldValue;
  return (
    <>
      {" "}
      <div className="inputFieldConatiner" style={style}>
        <p className="inputFieldName">{fieldName}</p>
        <input
          type={inputType}
          value={fieldValue}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className="inputFieldStyle"
          style={{
            width: "100%",
            zIndex: zIndex,
            background: background,
          }}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
      </div>
    </>
  );
};
// Define prop types for type validation
AddInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  //fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldValue: PropTypes.string,
  inputType: PropTypes.oneOf([
    "text",
    "number",
    "email",
    "password",
    "textarea",
  ]), // Add "textarea" to supported input types
  onChange: PropTypes.func,
  style: PropTypes.object, // Accept a style object
};
// // Set default prop values
// AddInputField.defaultProps = {
//   inputType: "text",
// };
export default AddInputField;
