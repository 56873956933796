import axios from "axios";
import api from "app/routes/api";
import { print } from "graphql";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import i18n from "i18next";
import message from "antd/es/message";
import { store } from "app/store";
import { appOperations } from "app/store/state/app";
import { UNAUTHORIZED_ERROR_CODE } from "app/constants/keys";

const GraphQLService = async (
  url,
  logoutOnExpiry,
  query,
  variables,
  showErrorToasts = true,
  showSessionExpiryToasts = true
) => {
  try {
    const response = await axios.post(url, {
      query: print(query),
      variables,
    });

    let errors = get(
      response,
      `data.error.errors`,
      get(response, `data.errors`, [])
    );

    if (!isEmpty(errors)) {
      /**
       * Failed GraphQL Requests Handling
       * Covers:
       * 1. GraphQL validation errors
       */
      // console.warn('GQL: GraphQL request has failed');
      errors = errors.map((error) => {
        if (!error.code) {
          error.code = "error";
        }
        if (logoutOnExpiry && error.code === UNAUTHORIZED_ERROR_CODE) {
          showSessionExpiryToasts && message.info("Session expired!");
          store.dispatch(appOperations.logout());
        }
        showErrorToasts && message.error(i18n.t(`error:${error.code}`));
        return error;
      });
      return Promise.reject(errors);
    } else {
      const data = get(response, `data.data`, {});
      return Promise.resolve(data);
    }
  } catch (error) {
    /**
     * Failed API Requests Handling
     * Covers:
     * 1. Invalid mutation name
     * 2. Invalid field name
     */
    console.warn("GQL: API request has failed");
    let errors = get(
      error,
      `response.data.error.errors`,
      get(error, `response.data.errors`, [])
    );
    if (!isEmpty(errors)) {
      errors = errors.map((error) => {
        if (!error.code) {
          error.code = "error";
        }
        if (logoutOnExpiry && error.code === UNAUTHORIZED_ERROR_CODE) {
          showSessionExpiryToasts && message.info("Session expired!");
          store.dispatch(appOperations.logout());
        }
        showErrorToasts && message.error(i18n.t(`error:${error.code}`));
        return error;
      });
    }
    return Promise.reject(errors);
  }
};

export const UserGraphQLService = function () {
  return GraphQLService(api.USER_SERVICE_GRAPHQL, true, ...arguments);
};

export const FMCSAGraphQLService = function () {
  return GraphQLService(api.FMCSA_GRAPHQL, true, ...arguments);
};

export const LogoutGraphQLService = function () {
  return GraphQLService(api.USER_SERVICE_GRAPHQL, false, ...arguments);
};
