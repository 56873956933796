import React, { useState } from "react";
import { Button } from "reactstrap";
import whiteCrossIcon from "../../../../assets/images/buttons/whiteCrossIcon.svg";
import blackCrossIcon from "../../../../assets/images/buttons/blackCrossIcon.svg";
import disabledCrossIcon from "../../../../assets/images/buttons/disabledCrossIcon.svg";
import "./Buttons.scss"; // Use a common SCSS file for styling
const Buttons = ({
  children,
  color,
  bgcolor,
  onClick,
  iconPosition,
  disabled,
  buttonType, // New prop to handle button type (e.g., "secondary", "ghost","primary")
  size, //regular and small
  image,
  onMouseEnterImage,
  onMouseLeaveImage,
  dimensions, //normal or responsive
  width,
  hover,
  imageReplace,
  style,
  fontSize,
  // Other props as needed
  ...rest
}) => {
  const [imageHovered, setImageHovered] = useState(false);
  const handleMouseEnter = (event) => {
    setImageHovered(true);
    event.target.style.backgroundImage = onMouseEnterImage;
  };
  const handleMouseLeave = (event) => {
    setImageHovered(false);
    event.target.style.backgroundImage = onMouseLeaveImage;
  };
  const renderIcon = () => {
    const icon = disabled
      ? disabledCrossIcon
      : buttonType == "primary"
      ? whiteCrossIcon
      : blackCrossIcon;
    const marginRight = iconPosition === "left" ? { marginRight: "8px" } : {};
    const marginLeft = iconPosition === "right" ? { marginLeft: "8px" } : {};
    const width = size == "small" ? { width: "16px" } : {};
    const height = size == "small" ? { height: "16px" } : {};
    return (
      <img
        src={
          hover == false
            ? image
            : imageHovered
            ? onMouseEnterImage
            : onMouseLeaveImage || image
        }
        style={{
          ...marginRight,
          ...marginLeft,
          ...width,
          ...height,
          ...style, // Merge custom styles
        }}
        alt="Icon"
      />
    );
  };
  // const renderIcon = () => {
  //   const icon = disabled
  //     ? disabledCrossIcon
  //     : buttonType == "primary"
  //     ? whiteCrossIcon
  //     : blackCrossIcon;
  //   const marginRight = iconPosition === "left" ? { marginRight: "8px" } : {};
  //   const marginLeft = iconPosition === "right" ? { marginLeft: "8px" } : {};
  //   const width = size == "small" ? { width: "16px" } : {};
  //   const height = size == "small" ? { height: "16px" } : {};
  //   return (
  //     <img
  //       src={image || icon}
  //       style={{
  //         ...marginRight,
  //         ...marginLeft,
  //         ...width,
  //         ...height,
  //       }}
  //       alt="Cross Icon"
  //     />
  //   );
  // };
  return (
    <Button
      //className={`genericButton ${buttonType}`} // Apply a common class for styling
      className={`${
        dimensions === "normal"
          ? size == "regular"
            ? buttonType == "primary"
              ? "regularPrimaryButton"
              : buttonType == "secondary"
              ? "regularSecondaryButton"
              : "regularGhostButton"
            : size == "small"
            ? buttonType == "primary"
              ? "smallPrimaryButton"
              : buttonType == "secondary"
              ? "smallSecondaryButton"
              : "smallGhostButton"
            : ""
          : dimensions === "responsive"
          ? size === "small"
            ? buttonType == "primary"
              ? "responsiveSmallPrimaryButton"
              : buttonType == "secondary"
              ? "responsiveSmallSecondaryButton"
              : "responsiveSmallGhostButton"
            : size === "regular"
            ? buttonType == "primary"
              ? "responsiveRegularPrimaryButton"
              : buttonType == "secondary"
              ? "responsiveRegularSecondaryButton"
              : "responsiveRegularGhostButton"
            : ""
          : ""
      }`} // Apply a common class for styling
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        color:
          imageReplace == false
            ? "#000000"
            : color
            ? color
            : imageReplace && imageHovered
            ? "#000000"
            : "#ffffff",
        width: width,
        background:
          imageReplace && imageHovered
            ? "#ffffff"
            : hover == true
            ? imageHovered == false
              ? "#424242"
              : "#000000"
            : bgcolor,
        fontSize: fontSize || "14px",
      }}
      {...rest}
    >
      {iconPosition === "left" && renderIcon()}
      {children}
      {iconPosition === "right" && renderIcon()}
    </Button>
  );
};

export default Buttons;
