import React, { useRef, useState, useEffect } from "react";
import "./Address.scss";
import usePlacesAutocomplete from "use-places-autocomplete";
import { usePlaceDetails } from "app/utils/hooks";
import dropdownUpArrow from "../../../../assets/images/poc/dropdownUpArrow.svg";
import dropdownDownArrow from "../../../../assets/images/poc/dropdownDownArrow.svg";
export const Address = ({
  fieldName,
  initialAddress,
  handleAddressChange,
  handleCityChange,
  handleStateChange,
  handleZipChange,
  handleLatitudeChange,
  handleLongitudeChange,
  showArrow,
  style,
  background,
}) => {
  const addressInputRef = useRef();
  const [selectedAddress, setSelectedAddress] = useState(initialAddress || "");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCityState, setSelectedCityState] = useState("");
  const [selectedZip, setSelectedZip] = useState();
  const [selectedLatitude, setSelectedLatitude] = useState();
  const [selectedLongitude, setSelectedLongitude] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    suggestions: { status, data },
    setValue: setPlaceSearchValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "us",
      },
    },
    debounce: 300,
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSelectedAddress(value);
    setPlaceSearchValue(value);
  };
  const [getPlaceDetails] = usePlaceDetails();
  const handleSelectSuggestion = (suggestion, data) => {
    console.log("selectedSuggestion", suggestion);
    setSelectedAddress(suggestion);
    getPlaceDetails(data.place_id, (res) => {
      console.log("getPlaceDetails", res);
      //setSelectedAddress(res.street_address);
      const cityState = `${res.city}, ${res.state}`;
      setSelectedAddress(res.street_address);
      setSelectedCity(res.city);
      setSelectedState(res.state);
      setSelectedZip(res.postal_code);
      console.log("getPlaceDetails1", res.location.coordinates[0]);
      console.log("getPlaceDetails2", res.location.coordinates[1]);
      setSelectedLatitude(res.location.coordinates[0]);
      setSelectedLongitude(res.location.coordinates[1]);
      handleAddressChange(res.street_address);
      handleCityChange(res.city);
      handleStateChange(res.state);
      handleZipChange(res.postal_code);
      handleLatitudeChange(res.location.coordinates[0]);
      handleLongitudeChange(res.location.coordinates[1]);
      clearSuggestions();
    });
  };

  console.log("selectedInitial", initialAddress);
  console.log("selectedAddress", selectedAddress);
  console.log("selectedCity", selectedCity);
  console.log("selectedState", selectedState);
  console.log("selectedZip", selectedZip);
  console.log("selectedLatitude", selectedLatitude);
  console.log("selectedLongitude", selectedLongitude);
  useEffect(() => {
    if (status === "OK") {
      console.log("AutocompleteSuggestionsStatus:", status);
      console.log("AutocompleteSuggestionsData:", data);
      setSuggestions(data.map((suggestion) => suggestion.description));
    } else {
      setSuggestions([]);
    }
  }, [status, data]);
  useEffect(() => {
    setSelectedAddress(initialAddress || "");
  }, [initialAddress]);
  console.log("AutocompleteSuggestions:", suggestions);
  console.log("AutocompleteBackground:", background);
  return (
    <div
      className="autoCompleteInputFieldConatiner"
      //style={{ background: background }}
    >
      <p className="autoCompleteInputFieldName">{fieldName}</p>
      {console.log("AutocompleteBackground2:", background)}
      <input
        style={{ background: background ? background : "#f4f5f8" }}
        ref={addressInputRef}
        value={selectedAddress}
        onChange={handleInputChange}
        placeholder={""}
        autoComplete="new-password"
        className="autoCompleteInput"
      />
      {/* {showArrow == false ? (
        ""
      ) : showDropdown ? (
        <img src={dropdownUpArrow} />
      ) : (
        <img src={dropdownDownArrow} />
      )} */}
      {suggestions.length > 0 && (
        <div className="autocompleteDropdown">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="autocompleteSuggestions"
              onClick={() => handleSelectSuggestion(suggestion, data[index])}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
