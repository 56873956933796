import Login from "../../views/pages/AdministratorLogin/Login/Login";
import Drivers from "app/views/pages/Drivers/Drivers/Drivers";
import DriversDetailsPOC from "../../views/pages/POC/DriversDetails/DriversDetailsPOC/DriversDetailsPOC";
import DriversPOC from "../../views/pages/POC/DriversDetails/DriversPOC/DriversPOC";
import VehicleDetailsPOC from "../../views/pages/POC/VehicleDetails/VehicleDetailsPOC/VehicleDetailsPOC";
const APP_BASE = `${process.env.PUBLIC_URL}/`;

console.log("APP BASE", APP_BASE);

export const PAGE_URLS = {
  LANDING: APP_BASE,
  DRIVERS: `${APP_BASE}drivers`,
  PAGE_NOT_FOUND: `${APP_BASE}404`,
  DriversDetailsPOC: APP_BASE,
};
export const publicRoutes = [
  // { path: "/logout", component: Logout },
  // { path: "/", component: Login },
  // { path: "/drivers", component: Drivers },
  // { path: "/drivers", component: DriversPOC },
  // { path: "/driversDetails/:driverNo", component: DriversDetailsPOC },
  //{ path: "/vehicleDetails/:vehicleNo", component: VehicleDetailsPOC },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/lock-screen", component: AuthLockScreen },
];
