import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Alert,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import loginBlock from "../../../../../../assets/images/login/loginBlock.jpg";
import MilePlusBlackLogo from "assets/images/MilePlusBlackLogo.svg";
import PlusWhiteLogo from "assets/images/PlusWhiteLogo.svg";
import MilePlusWhiteLogo from "assets/images/MilePlusWhiteLogo.svg";
import MilePlusLogo from "assets/images/MilePlusLogo.svg";
import "./Login.scss";
import OTPInputs from "../OTPInputs/OTPInputs";
import LoginForm from "../LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  loginAPI,
  forgotPassword,
} from "../../../../../apiServices/apiServices";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import BackgroundContainer from "app/views/commonComponents/BackgroundContainer/BackgroundContainer";
const Login = ({ onLogin, isUserLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showForgotPassword, setForgotPassword] = useState(false);
  const history = useHistory();
  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername); // Update the local state directly
    // Update the stored username in localStorage
    localStorage.setItem("username", newUsername);
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Update the stored password in localStorage
    localStorage.setItem("password", newPassword);
  };
  const handleKeepLoggedInChange = (e) => {
    console.log("keep1", e.target.checked);
    const isChecked = e.target.checked;
    setKeepLoggedIn(isChecked); // Update the state when the checkbox changes

    // Update the local storage based on the checkbox status
    if (isChecked) {
      localStorage.setItem("keepLoggedIn", "true");
      // localStorage.setItem("username", username);
      // localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("keepLoggedIn");
      // localStorage.removeItem("username");
      // localStorage.removeItem("password");
    }
  };
  const handleForgotPassword = () => {
    localStorage.setItem("forgotPassword", "true");
    console.log("Forgot password clicked!");
    setForgotPassword(true);
    // Redirect to '/forgotPassword'
    history.push("/forgotPassword");
  };
  const handleToggleForgotPassword = (value) => {
    setForgotPassword(value);
  };
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      onLogin(false);
      history.push(`/`);
    }
  }, []);
  const handleLogin = () => {
    console.log("hello Login");
    const loginDetails = {
      email: username,
      password: password,
    };
    // fetch(
    //   "https://b2g3qcf43gczrgsrfuiqanyzzi0pgaaa.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/client/login",
    //   {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       email: username,
    //       password: password,
    //     }),
    //   }
    // )
    //   .then((result) => {
    //     if (result.status === 200) {
    //       return result.json();
    //     } else {
    //       //alert("Please check login credentials!");
    //     }
    //   })
    loginAPI(loginDetails)
      .then((response) => {
        console.log("insideif", response);
        const token = response.data.token; // Assuming the token is returned in the response
        // Save token to localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("authUser", JSON.stringify(response.data.user));
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        const firstName = response.data.user.first_name;
        setFirstName(firstName);
        const lastName = response.data.user.last_name;
        setLastName(lastName);
        onLogin(true);
        // history.push(`/dashboard/${firstName}/${lastName}`);
        setTimeout(() => {
          window.location.reload();
        });
        history.push(`/dashboard`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <React.Fragment>
      <BackgroundContainer>
        <Container fluid className="p-0">
          <Row xl={12} md={12} sm={6} xs={6}>
            <Col xl={12} md={12} sm={6} xs={6}>
              <>
                <div className="login-page">
                  <div className="header"></div>

                  {/* <div className="loginBlockImage"> */}
                  {/* <img
                      src={loginBlock}
                      //src={LoginBlock}
                      alt="Login Block"
                      className="loginBlockOverlay"
                    /> */}
                  {/* </div> */}
                  {!showForgotPassword ? (
                    <div className="loginForm">
                      <>
                        {" "}
                        <div className="login-header">
                          <img
                            src={MilePlusLogo}
                            alt="MileLogo"
                            style={{
                              height: "38px",
                            }}
                          />
                          {/* <img
                          src={PlusLogo}
                          alt="PlusLogo"
                          style={{
                            height: "50px",
                            marginLeft: "10px",
                          }}
                        /> */}
                        </div>
                        {showOTP ? (
                          <>
                            <OTPInputs
                              // handleResendCode={handleResendCode}
                              // handleNoAccess={handleNoAccess}
                              expectedOTP={"111111"}
                            />
                          </>
                        ) : (
                          <LoginForm
                            username={username}
                            password={password}
                            keepLoggedIn={keepLoggedIn}
                            handleUsernameChange={handleUsernameChange}
                            handlePasswordChange={handlePasswordChange}
                            handleKeepLoggedInChange={handleKeepLoggedInChange}
                            handleForgotPassword={handleForgotPassword}
                            handleLogin={handleLogin}
                          />
                        )}
                      </>
                    </div>
                  ) : (
                    <ForgotPassword
                      toggleForgotPassword={handleToggleForgotPassword}
                    />
                    // <Switch>
                    //   {" "}
                    //   <Route
                    //     path="/forgotPassword"
                    //     component={() => <ForgotPassword />}
                    //   />{" "}
                    // </Switch>
                  )}
                </div>
              </>
            </Col>
          </Row>
        </Container>
      </BackgroundContainer>
    </React.Fragment>
  );
};
export default Login;
