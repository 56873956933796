import React from "react";
import { Button, Dropdown } from "reactstrap";
import whiteCrossIcon from "../../../../assets/images/buttons/whiteCrossIcon.svg";
import blackCrossIcon from "../../../../assets/images/buttons/blackCrossIcon.svg";
import disabledCrossIcon from "../../../../assets/images/buttons/disabledCrossIcon.svg";
import optionsMenu from "../../../../assets/images/optionsMenu/optionsMenu.svg";
import "./StatusPopup.scss"; // Use a common SCSS file for styling
const StatusPopup = ({
  children,
  color,
  onClick,
  iconPosition,
  disabled,
  size, //regular and small
  image,
  dimensions, //normal or responsive
  menuList,
  statusPopupOpen,
  onClickMenuItem,
  // Other props as needed
  ...rest
}) => {
  console.log("MenuList", menuList);
  // Function to handle menu item click
  const handleMenuItemClick = (item, event) => {
    // Call the onClick function with the selected item as an argument
    onClickMenuItem(item);
  };
  return (
    <div className="optionsMenuContainer">
      {" "}
      {statusPopupOpen && (
        <div className="optionsPopup">
          {menuList.map((listItem, index) => (
            <div
              className="listOptions"
              key={index}
              onClick={(event) => {
                event.stopPropagation();
                handleMenuItemClick(listItem.item);
              }}
            >
              <img src={listItem.icon} />
              <p className="optionsStyle" style={{ marginBottom: "0" }}>
                {listItem.item}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusPopup;
