import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./UnassignVehicle.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import toastIcon from "../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { unassignVehicle } from "../../../../../apiServices/apiServices";
const UnassignVehicle = ({
  onClose,
  driverId,
  vehicleId,
  fullDriverName,
  onUnassignVehicle,
}) => {
  console.log("vehicleIdUN1", vehicleId);
  console.log("vehicleIdUN2", driverId);
  console.log("vehicleIdUN3", fullDriverName);
  const [isUnassignedVehicle, setIsUnassignedVehicle] = useState(false);
  const removeVehicle = () => {
    const storedToken = localStorage.getItem("token");
    const unassignVehicleData = { driver_id: driverId, vehicle_id: vehicleId };
    console.log("vid", vehicleId);
    unassignVehicle(unassignVehicleData)
      .then((response) => {
        //alert(response.message);
        setIsUnassignedVehicle(true);
        handleUnassignedVehicle(true);
        // Automatically close the alert after 3 seconds
        setTimeout(() => {
          // Close the alert
          document.querySelector(".alert").style.display = "none";
        }, 1000);
        //console.log("insideif", response);
        console.log("addVehicleData", response.data);
        // toast("Vehicle is unassigned.", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleUnassignedVehicle = (value) => {
    onUnassignVehicle(value);
  };
  return (
    <Card className={"unassignVehicleCard"}>
      <CardBody>
        <div className="unassignVehicleContainer">
          <div className="unassignVehicleTitleMessage">
            <div className="unassignVehicleTitle">
              <div className="unassignVehicleStyle">Are you sure?</div>
            </div>
            <div className="unassignVehicleMessage">
              You want to unassign vehicle{" "}
              <p className="unassignVehicleNumber">{vehicleId}</p>with{" "}
              {fullDriverName.replace(",", "").split(" ").reverse().join(" ")} ?
            </div>
          </div>
          <div className="removeSection">
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              onClick={onClose}
              imageReplace={false}
            >
              No, Don’t Remove
            </Buttons>
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              onClick={() => {
                removeVehicle();
                onClose();
              }}
              imageReplace={false}
            >
              Yes Remove
            </Buttons>
          </div>
        </div>
        {/* <ToastContainer
          className={"toast-message"}
          toastStyle={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 12px 8px 8px",
            background: "rgba(0, 188, 249, 0.1)",
            borderRadius: "54px",
            color: "#00bfff",
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            fontWeight: "600",
            //height: "36px",
            minHeight: "none",
          }}
          hideProgressBar={true}
          closeButton={<img src={toastCross} />}
          icon={<img src={toastIcon} />}
        /> */}
      </CardBody>
    </Card>
  );
};

export default UnassignVehicle;
