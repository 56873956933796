import React, { useState } from "react";
import "./Pagination.scss";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
import previousArrow from "../../../../assets/images/poc/previousArrow.svg";
import nextArrow from "../../../../assets/images/poc/nextArrow.svg";
import previousBlackArrow from "../../../../assets/images/poc/previousBlackArrow.svg";
import previousGreyArrow from "../../../../assets/images/poc/previousGreyArrow.svg";
import nextGreyArrow from "../../../../assets/images/poc/nextGreyArrow.svg";
import nextBlackArrow from "../../../../assets/images/poc/nextBlackArrow.svg";
import dropdownPaginationArrow from "../../../../assets/images/poc/dropdownPaginationArrow.svg";
import arrowLeftFirstItem from "../../../../assets/images/poc/arrowLeftFirstItem.svg";
import arrowRightLastItem from "../../../../assets/images/poc/arrowRightLastItem.svg";
import arrowLeftFirstItemGrey from "../../../../assets/images/poc/arrowLeftFirstItemGrey.svg";
import arrowRightLastItemGrey from "../../../../assets/images/poc/arrowRightLastItemGrey.svg";
// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";
// import Select from "react-select";
import Dropdown from "../CustomDropdown/CustomDropdown";
const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const itemsPerPageOptions = [5, 10, 20];
  // const itemsPerPageOptions = [
  //   { value: 5, label: "5 per page" },
  //   { value: 10, label: "10 per page" },
  //   { value: 20, label: "20 per page" },
  // ];
  // const itemsPerPageOptions = ["5 per page", "10 per page", "20 per page"];
  //const [dropdownOpen, setDropdownOpen] = useState(false);
  // Function to handle page navigation
  const handleClick = (page) => {
    onPageChange(page);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    onItemsPerPageChange(newItemsPerPage);
  };
  // // Function to handle items per page change
  // const handleItemsPerPageChange = (newItemsPerPage) => {
  //   onItemsPerPageChange(newItemsPerPage);
  // };
  // // Function to handle items per page change
  // const handleItemsPerPageChange = (selectedOption) => {
  //   onItemsPerPageChange(selectedOption.value);
  // };
  // // Function to handle items per page change
  // const handleItemsPerPageChange = (option) => {
  //   const value = parseInt(option.value.split(" ")[0]); // Extract the number from the selected option
  //   onItemsPerPageChange(value);
  //   setDropdownOpen(false); // Close dropdown after selection
  // };

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div className="rowPerPage">Result per page</div>
          <select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="form-select select-dropdown"
          >
            <option value="20">20</option>
            <option value="50" defaultValue>
              50
            </option>
            <option value="100">100</option>
          </select>
        </div>
        <>
          {/* <Select
          options={itemsPerPageOptions}
          value={itemsPerPageOptions.find(
            (option) => option.value === itemsPerPage
          )}
          onChange={handleItemsPerPageChange}
        /> */}
          {/* <Dropdown
          options={itemsPerPageOptions}
          value={`${itemsPerPage} per page`}
          onChange={handleItemsPerPageChange}
          className={`form-select ${dropdownOpen ? "open" : ""}`}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
          menuClassName="myMenuClassName"
        /> */}
          {/* <Dropdown
          items={itemsPerPageOptions}
          selectedItem={`${itemsPerPage} per page`}
          onSelect={handleItemsPerPageChange}
        /> */}
          {/* <Dropdown isOpen={false} toggle={() => {}}>
            <DropdownToggle caret>{itemsPerPage} per page</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleItemsPerPageChange(5)}>
                5 per page
              </DropdownItem>
              <DropdownItem onClick={() => handleItemsPerPageChange(10)}>
                10 per page
              </DropdownItem>
              <DropdownItem onClick={() => handleItemsPerPageChange(20)}>
                20 per page
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          {" "}
          <li className="page-item">
            <div
              onClick={() => handleClick(1)}
              className="arrowContainerPagination"
              style={
                {
                  //background: currentPage === 1 ? "#F4F5F8" : "#FFFFFF",
                }
              }
              disabled={currentPage === 1}
            >
              {currentPage === 1 ? (
                <img src={arrowLeftFirstItemGrey} />
              ) : (
                <img src={arrowLeftFirstItem} />
              )}
            </div>
          </li>
          <li className="page-item">
            <div
              onClick={() => handleClick(currentPage - 1)}
              className="arrowContainerPagination"
              style={
                {
                  //background: currentPage === 1 ? "#F4F5F8" : "#FFFFFF",
                }
              }
              disabled={currentPage === 1}
            >
              {currentPage === 1 ? (
                <img src={previousGreyArrow} />
              ) : (
                <img src={previousBlackArrow} />
              )}
            </div>
          </li>
          <>
            {/* {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <a
                onClick={() => handleClick(number)}
                href="#"
                className={
                  number === currentPage ? "page-link active" : "page-link"
                }
              >
                {number}
              </a>
            </li>
          ))} */}
          </>
          <li className="page-item">
            <div className="page-link">{`Page`}</div>
            {/* <div className="page-link active">{`${currentPage}`}</div> */}
            <input
              type="text"
              className="page-link-input active"
              value={currentPage}
              onChange={(e) => {
                const value = e.target.value;
                handleClick(value);
              }}
            />
            <div className="page-link">{`of`}</div>
            <div className="page-link">{`${totalPages}`}</div>
          </li>
          <li className="page-item">
            <div
              onClick={() => handleClick(currentPage + 1)}
              className="arrowContainerPagination"
              style={
                {
                  //background: currentPage === totalPages ? "#F4F5F8" : "#FFFFFF",
                }
              }
              disabled={currentPage === totalPages}
            >
              {currentPage === totalPages ? (
                <img src={nextGreyArrow} />
              ) : (
                <img src={nextBlackArrow} />
              )}
            </div>
          </li>
          <li className="page-item">
            <div
              onClick={() => handleClick(totalPages)}
              className="arrowContainerPagination"
              style={
                {
                  //background: currentPage === totalPages ? "#F4F5F8" : "#FFFFFF",
                }
              }
              disabled={currentPage === totalPages}
            >
              {currentPage === totalPages ? (
                <img src={arrowRightLastItemGrey} />
              ) : (
                <img src={arrowRightLastItem} />
              )}
            </div>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;
