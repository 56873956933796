import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
//import { useAuth } from "../../context/AuthContext";
import getCroppedImg from "./CropImage";
import { ResizableBox } from "react-resizable";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
const CropEasy = ({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setSelectedImage,
  onFrontImageSelect,
}) => {
  // const { setAlert, setLoading } = useAuth();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCropSizeChange = (cropSize) => {
    // Increase crop area by multiplying width and height with a scaling factor
    const scaleFactor = 10; // Adjust this value as needed
    setCropSize({
      width: cropSize.width * scaleFactor,
      height: cropSize.height * scaleFactor,
    });
  };
  const cropImage = async () => {
    //setLoading(true);
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      setSelectedImage(file);
      //onFrontImageSelect(file);
      setOpenCrop(false);
    } catch (error) {
      //   setAlert({
      //     isAlert: true,
      //     severity: "error",
      //     message: error.message,
      //     timeout: 5000,
      //     location: "modal",
      //   });
      console.log(error);
    }

    //setLoading(false);
  };
  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: "rgba(93, 93, 93, 0.5)",
          position: "relative",
          height: 400,
          width: "auto",
          minWidth: { sm: 500 },
        }}
      >
        <ResizableBox
          width={200} // Initial width of the crop cutter
          height={200} // Initial height of the crop cutter
          minConstraints={[100, 100]} // Minimum width and height of the crop cutter
          maxConstraints={[500, 500]} // Maximum width and height of the crop cutter
          onResize={(e, { size }) => {
            setCropSize(size); // Update crop size when resizing
          }}
        >
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1.55}
            showGrid={false}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
            onCropSizeChange={onCropSizeChange}
            objectFit="contain"
          />
        </ResizableBox>
      </DialogContent>
      <div className="addDriverButtonContainer">
        <Buttons
          size={"small"}
          dimensions={"normal"}
          buttonType={"primary"}
          imageReplace={true}
          onClick={cropImage}
        >
          Crop & Add
        </Buttons>
      </div>
      {/* <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            //startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Crop
          </Button>
        </Box>
      </DialogActions> */}
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
