import moment from "moment";

// const BASE_URL =
//   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1";

const BASE_URL =
  "https://b2g3qcf43gczrgsrfuiqanyzzi0pgaaa.lambda-url.us-east-1.on.aws/fdcc-settlements/v1";

const storedToken = localStorage.getItem("token");

const handleAPIRequest = async (url, method, body) => {
  const requestOptions = {
    method: method,
    headers: {
      Authorization: `Bearer ${storedToken}`,
      "Content-Type": "application/json",
    },
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("apiResponse1");
    return response.json();
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return null;
  }
};
//Login API
export const loginAPI = async (loginDetails) => {
  const url = `${BASE_URL}/client/login`;
  return handleAPIRequest(url, "POST", loginDetails);
};
//Verify OTP API
export const verifyOTP = async (otpDetails) => {
  const url = `${BASE_URL}/client/verify/otp`;
  return handleAPIRequest(url, "POST", otpDetails);
};
//Forgot Password API
export const forgotPassword = async (forgotPasswordDetails) => {
  const url = `${BASE_URL}/client/forgot/password`;
  return handleAPIRequest(url, "POST", forgotPasswordDetails);
};
//Change Password API
export const changePassword = async (changePasswordDetails) => {
  const url = `${BASE_URL}/client/reset/password`;
  return handleAPIRequest(url, "POST", changePasswordDetails);
};
//Dashboard APIs
export const getNextSettlementsData = async () => {
  console.log("apiResponse2");
  const url = `${BASE_URL}/admin/next/settlements`;
  return handleAPIRequest(url, "GET");
};

export const getWeeklySettlementsData = async (limit) => {
  const url = `${BASE_URL}/admin/weekly/settlements?limit=${limit}`;
  return handleAPIRequest(url, "GET");
};

export const getDriversLeaderBoardSettlementsData = async () => {
  const url = `${BASE_URL}/admin/leaderboard/drivers`;
  return handleAPIRequest(url, "GET");
};

export const getVehiclesLeaderBoardSettlementsData = async () => {
  const url = `${BASE_URL}/admin/leaderboard/vehicles`;
  return handleAPIRequest(url, "GET");
};

export const getDashboardDetailsHeaderKPIData = async (
  finalStartDate,
  finalEndDate
) => {
  // const { startDate, endDate } = convertStartDate(selectedDate);
  const url = `${BASE_URL}/admin/dashboard/kpi?start_date=${finalStartDate}&end_date=${finalEndDate}`;
  return handleAPIRequest(url, "GET");
};
//Drivers Table Data
export const getAllDriversData = async () => {
  const url = `${BASE_URL}/admin/drivers`;
  return handleAPIRequest(url, "GET");
};
export const getDriversHeaderKPIData = async (finalStartDate, finalEndDate) => {
  const url = `${BASE_URL}/admin/driver/kpi?start_date=${finalStartDate}&end_date=${finalEndDate}`;
  return handleAPIRequest(url, "GET");
};

//Driver Details
export const getDriverDetailsHeaderKPIData = async (
  finalStartDate,
  finalEndDate,
  driverId
) => {
  const url = `${BASE_URL}/admin/driver/detail/kpi?start_date=${finalStartDate}&end_date=${finalEndDate}&driver_id=${driverId}`;
  return handleAPIRequest(url, "GET");
};
export const getDriverDetailsProfileData = async (driverId) => {
  const url = `${BASE_URL}/admin/drivers/${driverId}`;
  return handleAPIRequest(url, "GET");
};
export const getDriverDetailsLoadsData = async (driverId) => {
  const url = `${BASE_URL}/admin/drivers/${driverId}/loads`;
  return handleAPIRequest(url, "GET");
};
export const getDriverDetailsSettlementsData = async (driverId) => {
  const url = `${BASE_URL}/admin/drivers/${driverId}/settlements`;
  return handleAPIRequest(url, "GET");
};
export const getDriverSettlementsListData = async (uploadIdDriver) => {
  const url = `${BASE_URL}/admin/settlements/${uploadIdDriver}`;
  return handleAPIRequest(url, "GET");
};
export const getDriverSettelmentDetailsData = async (id, driverId) => {
  const url = `${BASE_URL}/admin/settlements/${id}/${driverId}`;
  return handleAPIRequest(url, "GET");
};

//Vehicle Table Data
export const getAllVehiclesData = async () => {
  const url = `${BASE_URL}/admin/vehicles`;
  return handleAPIRequest(url, "GET");
};
export const getVehiclesHeaderKPIData = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/driver/kpi?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};

//Vehicle Details
export const getVehicleDetailsHeaderKPIData = async (
  startDate,
  endDate,
  vehicleId
) => {
  const url = `${BASE_URL}/admin/vehicles/kpi?start_date=${startDate}&end_date=${endDate}&vehicle_id=${vehicleId}`;
  return handleAPIRequest(url, "GET");
};
export const getVehicleDetailsProfileData = async (vehicleId) => {
  const url = `${BASE_URL}/admin/vehicle/detail/${vehicleId}`;
  return handleAPIRequest(url, "GET");
};
export const getVehicleDetailsLoadsData = async (vehicleId) => {
  const url = `${BASE_URL}/admin/vehicle/loads/${vehicleId}`;
  return handleAPIRequest(url, "GET");
};

//Loads Details
export const getAllLoadsData = async () => {
  //const url = `${BASE_URL}/admin/load`;
  const url = `${BASE_URL}/admin/loads/list`;
  return handleAPIRequest(url, "GET");
};
export const getAllLoadsNewData = async () => {
  const url = `${BASE_URL}/admin/load`;
  return handleAPIRequest(url, "GET");
};
export const getLoadsHeaderKPIData = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/loads/kpi?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};

// Settelments Details
export const getSettelmentsDetailsHeaderKPIData = async (uploadId) => {
  const url = `${BASE_URL}/admin/dashboard/kpi?upload_id=${uploadId}`;
  return handleAPIRequest(url, "GET");
};
export const getSettlementsListData = async (uploadId) => {
  const url = `${BASE_URL}/admin/settlements/${uploadId}`;
  return handleAPIRequest(url, "GET");
};
// Settelments Driver Details
export const getSettelmentDriverDetailsData = async (uploadId, id) => {
  const url = `${BASE_URL}/admin/settlements/${uploadId}/${id}`;
  return handleAPIRequest(url, "GET");
};
//Unassign Driver
export const unassignDriver = async (unassignDriverData) => {
  const url = `${BASE_URL}/vehicle_drivers/remove`;
  return handleAPIRequest(url, "POST", unassignDriverData);
};

//Unassign Vehicle
export const unassignVehicle = async (unassignVehicleData) => {
  const url = `${BASE_URL}/vehicle_drivers/remove`;
  return handleAPIRequest(url, "POST", unassignVehicleData);
};

//Available Drivers
export const availableDrivers = async () => {
  const url = `${BASE_URL}/drivers/available`;
  return handleAPIRequest(url, "GET");
};
//Assign Driver
export const assignDriver = async (assignDriverData) => {
  const url = `${BASE_URL}/vehicle_drivers/assign`;
  return handleAPIRequest(url, "POST", assignDriverData);
};

//Available Vehicles
export const availableVehicles = async () => {
  const url = `${BASE_URL}/available/vehicles`;
  return handleAPIRequest(url, "GET");
};
//Assign Vehicle
export const assignVehicle = async (assignVehicleData) => {
  const url = `${BASE_URL}/vehicle_drivers/assign`;
  return handleAPIRequest(url, "POST", assignVehicleData);
};
//Add Driver Data
export const addDriverData = async (driverData) => {
  const url = `${BASE_URL}/drivers`;
  return handleAPIRequest(url, "POST", driverData);
};
//Add Vehicle Data
export const addVehicleData = async (vehicleData) => {
  const url = `${BASE_URL}/vehicles`;
  return handleAPIRequest(url, "POST", vehicleData);
};
//Add VIN Number
export const addVinNumber = async (vinNumber) => {
  const url = `${BASE_URL}/admin/decode/vin?VIN=${vinNumber}`;
  return handleAPIRequest(url, "GET");
};
//Get Vehicle Types
export const getVehiclesTypes = async () => {
  const url = `${BASE_URL}/vehicles/types?make=Volvo&model=S90&type=Y-3000`;
  return handleAPIRequest(url, "GET");
};
//Add Vehicle Types
export const addVehiclesTypes = async (vehicleTypeData) => {
  const url = `${BASE_URL}/vehicles/add-types`;
  return handleAPIRequest(url, "POST", vehicleTypeData);
};
//Add CSV
//Get Presigned URL for CSV
export const getPresignedURL = async () => {
  const url = `${BASE_URL}/generate/presignedurl`;
  return handleAPIRequest(url, "GET");
};
//Upload CSV
//Submit CSV
export const submitCSV = async (presignedUrlData) => {
  const url = `${BASE_URL}/submit/document`;
  return handleAPIRequest(url, "POST", presignedUrlData);
};
//Add Load Data
export const addLoadData = async (laodData) => {
  const url = `${BASE_URL}/admin/create/load`;
  return handleAPIRequest(url, "POST", laodData);
};
//Add Load Stop
export const addLoadStop = async (laodStopData) => {
  const url = `${BASE_URL}/admin/load/stops`;
  return handleAPIRequest(url, "POST", laodStopData);
};
//Update Load Stop
export const updateLoadStop = async (updatedLaodStopData) => {
  const url = `${BASE_URL}/admin/load/stops`;
  return handleAPIRequest(url, "PUT", updatedLaodStopData);
};
//Add Load Advanced Details
export const addLoadAdvancedDetails = async (advancedDetailsData, loadId) => {
  const url = `${BASE_URL}/admin/load/advanced/${loadId}`;
  return handleAPIRequest(url, "POST", advancedDetailsData);
};
//Get Available Vehicles for Load
export const getAvailableVehiclesLoad = async (loadId) => {
  const url = `${BASE_URL}/admin/load/availableVehicle/${loadId}`;
  return handleAPIRequest(url, "GET");
};
//Add Load Pay Description
export const addLoadPayDescription = async (loadPayDescriptionData) => {
  const url = `${BASE_URL}/create/load/description`;
  return handleAPIRequest(url, "POST", loadPayDescriptionData);
};
//Update Driver Details
export const updateDriverDetails = async (driverId, updateDriverData) => {
  const url = `${BASE_URL}/drivers/${driverId}`;
  return handleAPIRequest(url, "PUT", updateDriverData);
};

//Update Vehicle Details
export const updateVehicleDetails = async (vehicleId, updateVehicleData) => {
  const url = `${BASE_URL}/vehicles/${vehicleId}`;
  return handleAPIRequest(url, "PUT", updateVehicleData);
};

//Add Other Revenue
export const addOtherRevenue = async (otherRevenueData) => {
  const url = `${BASE_URL}/admin/other-incomes`;
  return handleAPIRequest(url, "POST", otherRevenueData);
};

//Add Deduction
export const addDeduction = async (deductionData) => {
  const url = `${BASE_URL}/admin/deductions`;
  return handleAPIRequest(url, "POST", deductionData);
};

//Delete Other Revenue
export const deleteOtherRevenue = async (index) => {
  const url = `${BASE_URL}/admin/other-incomes/${index}`;
  return handleAPIRequest(url, "DELETE");
};

//Delete Deduction
export const deleteDeduction = async (index) => {
  const url = `${BASE_URL}/admin/deductions/${index}`;
  return handleAPIRequest(url, "DELETE");
};

//Settelments Status
export const settlementStatus = async (statusData) => {
  const url = `${BASE_URL}/admin/settlements/status`;
  return handleAPIRequest(url, "POST", statusData);
};

//Chart APIs
//Loads Weekly Chart API
export const getLoadsWeeklyChart = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/dashboard/chart?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};
//Loads Month Chart API
export const getLoadsMonthlyChart = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/dashboard/chart/monthly?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};
//Loads Yearly Chart API
export const getLoadsQuarterlyChart = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/dashboard/chart/quarterly?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};
//Loads Yearly Chart API
export const getLoadsYearlyChart = async (startDate, endDate) => {
  const url = `${BASE_URL}/admin/dashboard/chart/yearly?start_date=${startDate}&end_date=${endDate}`;
  return handleAPIRequest(url, "GET");
};
