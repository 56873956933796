import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./DriversFilterButton.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import driversFilter from "../../../../../assets/images/filter/driversFilter.svg";
import searchCity from "../../../../../assets/images/filter/searchCity.svg";
import { UsaStates } from "usa-states";
import { Country, State, City } from "country-state-city";

const DriversFilterButton = ({
  isDriversFilterOpen,
  handleOpenDriversFilter,
  handleCancelDriversFilter,
  handleApplyDriversFilter,
}) => {
  const [milesCovered, setMilesCovered] = useState(false);
  const [totalLoads, setTotalLoads] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [expiry, setExpiry] = useState(false);

  const handleMilesCovered = () => {
    setMilesCovered(true);
    setTotalLoads(false);
    setCity(false);
    setState(false);
    setExpiry(false);
  };
  const handleTotalLoads = () => {
    setMilesCovered(false);
    setTotalLoads(true);
    setCity(false);
    setState(false);
    setExpiry(false);
  };
  const handleCity = () => {
    setMilesCovered(false);
    setTotalLoads(false);
    setCity(true);
    setState(false);
    setExpiry(false);
  };
  const handleState = () => {
    setMilesCovered(false);
    setTotalLoads(false);
    setCity(false);
    setState(true);
    setExpiry(false);
  };
  const handleExpiry = () => {
    setMilesCovered(false);
    setTotalLoads(false);
    setCity(false);
    setState(false);
    setExpiry(true);
  };
  const countryCode = "US";
  const country = Country.getCountryByCode(countryCode);
  const states = State.getStatesOfCountry(country.isoCode);
  const citiesFromFirstState = City.getCitiesOfState(
    countryCode,
    states[0].isoCode
  );
  console.log("Cities", {
    state: states[0],
    cities: citiesFromFirstState,
  });
  return (
    <>
      <div className="toolbar" style={{}}>
        <Buttons
          buttonType={"secondary"}
          iconPosition={"left"}
          image={driversFilter}
          dimensions={"responsive"}
          size={"small"}
          onClick={handleOpenDriversFilter}
        >
          Filter
        </Buttons>
        {isDriversFilterOpen && (
          <div className="driversFilterBar">
            <div className="filterTitle">Filter</div>
            <div className="driverFilter">
              <div className="driversFilterOptions">
                <div
                  className={"milesCovered"}
                  style={{ background: milesCovered ? "#f4f5f8" : "#ffffff" }}
                  onClick={handleMilesCovered}
                >
                  Miles Covered
                  {/* {Object.values(subscriptionChecked).some(Boolean) && ( // Conditionally render the tick image
                    <div className="activeTick">
                      <img
                        src={activeTick}
                        style={{ height: "15px", width: "15px" }}
                      />
                    </div>
                  )} */}
                </div>
                <div
                  className={"milesCovered"}
                  style={{ background: totalLoads ? "#f4f5f8" : "#ffffff" }}
                  onClick={handleTotalLoads}
                >
                  Total Loads
                  {/* {Object.values(quatersChecked).some(Boolean) && ( // Conditionally render the tick image
                    <div className="activeTick">
                      <img
                        src={activeTick}
                        style={{
                          height: "15px",
                          width: "15px",
                        }}
                      />
                    </div>
                  )} */}
                </div>
                <div
                  className={"milesCovered"}
                  style={{ background: city ? "#f4f5f8" : "#ffffff" }}
                  onClick={handleCity}
                >
                  City
                  {/* {Object.values(moodChecked).some(Boolean) && ( // Conditionally render the tick image
                    <div className="activeTick">
                      <img
                        src={activeTick}
                        style={{ height: "15px", width: "15px" }}
                      />
                    </div>
                  )} */}
                </div>
                <div
                  className={"milesCovered"}
                  style={{ background: state ? "#f4f5f8" : "#ffffff" }}
                  onClick={handleState}
                >
                  State
                  {/* {Object.values(moodChecked).some(Boolean) && ( // Conditionally render the tick image
                    <div className="activeTick">
                      <img
                        src={activeTick}
                        style={{ height: "15px", width: "15px" }}
                      />
                    </div>
                  )} */}
                </div>
                <div
                  className={"milesCovered"}
                  style={{ background: expiry ? "#f4f5f8" : "#ffffff" }}
                  onClick={handleExpiry}
                >
                  Expiry
                  {/* {Object.values(moodChecked).some(Boolean) && ( // Conditionally render the tick image
                    <div className="activeTick">
                      <img
                        src={activeTick}
                        style={{ height: "15px", width: "15px" }}
                      />
                    </div>
                  )} */}
                </div>
              </div>
              <div className="driversFilterValues">
                {city && (
                  <div className="cityConatiner">
                    {" "}
                    <div className="valuesTitleConatiner">
                      <div className="filterValuesTitle">City</div>
                      <div className="seperatorDot">.</div>
                      <div className="selectedFilters">5</div>
                    </div>
                    <div className="searchContainerFilter">
                      <div className="searchBox">
                        <img src={searchCity} />
                        <div className="searchCity">Search City</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="filterActions">
              {" "}
              <div className="driverCountLabel">
                {" "}
                <div className="driversCount">21,343</div>
                <div className="driversLabel">Drivers</div>
              </div>
              <Buttons
                onClick={handleCancelDriversFilter}
                buttonType={"secondary"}
                dimensions={"normal"}
                size={"small"}
                color="#F35631"
              >
                Reset
              </Buttons>
              <Buttons
                onClick={handleApplyDriversFilter}
                dimensions={"normal"}
                buttonType="primary"
                size="small"
              >
                Apply
              </Buttons>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DriversFilterButton;
