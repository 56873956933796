// LoginForm.js

import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Label, Button } from "reactstrap";
import forgotPasswordWhite from "../../../../../../assets/images/login/forgotPasswordWhite.svg";
import Buttons from "../../../../commonComponents/Buttons/Buttons";
import "./LoginForm.scss";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { useHistory, Redirect, NavLink, Route, Switch } from "react-router-dom";
const LoginForm = ({
  username,
  password,
  keepLoggedIn,
  handleUsernameChange,
  handlePasswordChange,
  handleKeepLoggedInChange,
  handleForgotPassword,
  handleLogin,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  // const [username, setUsername] = useState(initialUsername || "");
  // const [password, setPassword] = useState(initialPassword || "");
  // const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  console.log("keep2", keepLoggedIn);
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("keepLoggedIn") === "true") {
      const storedUsername = localStorage.getItem("username");
      const storedPassword = localStorage.getItem("password");

      if (storedUsername && storedPassword) {
        handleUsernameChange({ target: { value: storedUsername } });
        handlePasswordChange({ target: { value: storedPassword } });
        handleKeepLoggedInChange({ target: { checked: true } });
      }
    }
  }, [handleUsernameChange, handlePasswordChange, handleKeepLoggedInChange]);

  const handleValidSubmit = () => {
    // This function will be called only when the form is valid
    handleLogin();
  };

  return (
    <div className="login-details">
      <AvForm
        id="loginForm"
        className="form-horizontal"
        //onValidSubmit={handleValidSubmit}
      >
        <div className="login-form-group">
          <Label htmlFor="username" className="login-form-label">
            Username
          </Label>
          <AvField
            name="username"
            value={username}
            onChange={handleUsernameChange}
            type="text"
            className="login-form-input"
            id="username"
            validate={{
              required: {
                value: true,
                errorMessage: "This field is required.",
              },
              minLength: {
                value: 6,
                errorMessage: "Username must be at least 6 characters.",
              },
              maxLength: {
                value: 50,
                errorMessage: "Username cannot exceed 50 characters.",
              },
            }}
          />
        </div>
        <div className="login-form-group">
          <Label htmlFor="userpassword" className="login-form-label">
            Password
          </Label>
          <AvField
            name="password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
            className="login-form-input"
            id="userpassword"
            validate={{
              required: {
                value: true,
                errorMessage: "This field is required.",
              },
              minLength: {
                value: 8,
                errorMessage: "Password must be at least 8 characters.",
              },
              maxLength: {
                value: 50,
                errorMessage: "Password cannot exceed 50 characters.",
              },
              // pattern: {
              //   value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
              //   errorMessage: "Invalid password.",
              // },
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              id="keepLoggedIn"
              checked={keepLoggedIn}
              onChange={handleKeepLoggedInChange}
              style={{ marginRight: "5px" }}
              className="keepCheckbox"
            />
            <label htmlFor="keepLoggedIn" className="keepCheckbox-label">
              Keep me logged in
            </label>
          </div>
          <div style={{ marginRight: "5px", cursor: "pointer" }}>
            <img src={forgotPasswordWhite} style={{ marginRight: "5px" }} />
            <Label onClick={handleForgotPassword} className="forgotPassword">
              Forgot Password?
            </Label>
          </div>
        </div>
        <div className="text-center" style={{ paddingTop: "40px" }}>
          <Button
            className="login-button"
            onClick={handleValidSubmit}
            type="submit"
            //disabled={!isFormValid}
          >
            Login
          </Button>
        </div>
      </AvForm>
    </div>
  );
};

export default LoginForm;
