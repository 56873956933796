import React, { useEffect, useState } from "react";
import ConfigProvider from "antd/es/config-provider";
import { defaultValidationMessagesWithTranslation } from "app/config/antd-validations";
import moment from "moment";
// Import Routes
import { publicRoutes } from "app/routes/app";
import { PAGE_URLS } from "app/routes/app";
import { useTranslation } from "react-i18next";
import LOCALE_MAP from "app/config/i18n-map";
import get from "lodash/get";
import { EN } from "app/constants/keys";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import history from "app/utils/history";
import PublicRoute from "app/views/common/components/PublicRoute";
import { useSelector } from "react-redux";
import PAGES from "app/views/pages";
import "./App.scss";
import DefaultLayout from "app/views/layout";
import Login from "app/views/pages/POC/AdministratorLogin/Login/Login";
import ForgotPassword from "app/views/pages/POC/AdministratorLogin/ForgotPassword/ForgotPassword";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import Drivers from "app/views/pages/Drivers/Drivers/Drivers";
import { Helmet } from "react-helmet";
import Breadcrumb from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import DriverDetails from "app/views/commonComponents/DetailsHeader/DetailsHeader";
import DriverDetailsPage from "app/views/pages/DriverDetails/DriverDetailsPage/DriverDetailsPage";
import DriversPOC from "app/views/pages/POC/DriversDetails/DriversPOC/DriversPOC";
import DriversDetailsPOC from "app/views/pages/POC/DriversDetails/DriversDetailsPOC/DriversDetailsPOC";
import VehicleDetailsPOC from "app/views/pages/POC/VehicleDetails/VehicleDetailsPOC/VehicleDetailsPOC";
import VehiclePOC from "app/views/pages/POC/VehicleDetails/VehiclePOC/VehiclePOC";
import LoadsPOC from "app/views/pages/POC/LoadsDetails/LoadsPOC/LoadsPOC";
import SettlementsPOC from "app/views/pages/POC/SettlementsDetails/SettlementsPOC/SettlementsPOC";
import SettlementsDetailsPOC from "app/views/pages/POC/SettlementsDetails/SettlementsDetailsPOC/SettlementsDetailsPOC";
import SettlementsDriverPOC from "app/views/pages/POC/SettlementsDetails/SettlementsDriverPOC/SettlementsDriverPOC";
import DashboardPOC from "app/views/pages/POC/DashboardPOC/DashboardPOC";
import DriversSettelmentsPOC from "app/views/pages/POC/DriversDetails/DriversSettelmentsPOC/DriversSettelmentsPOC";
import { useHistory } from "react-router-dom";
import AccountSettings from "app/views/pages/POC/AccountSettings/AccountSettings";
import ChangePassword from "app/views/pages/POC/AdministratorLogin/ChangePassword/ChangePassword";
import AddLoad from "app/views/pages/POC/LoadsDetails/AddLoadDetails/AddLoad/AddLoad";
function App() {
  const [t, i18n] = useTranslation();
  const [tokenCheck, setTokenCheck] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(null);
  const langPref = useSelector((state) => state.app.lang);
  const [locale, setLocale] = useState(
    get(LOCALE_MAP, `${langPref}.antd`, LOCALE_MAP[EN].antd)
  );
  const history = useHistory();
  useEffect(() => {
    i18n.changeLanguage(langPref);
    setLocale(LOCALE_MAP[langPref].antd);
    moment.locale(LOCALE_MAP[langPref].moment);
    const storedToken = localStorage.getItem("token");
    setTokenCheck(storedToken);
    const forgotPassword = localStorage.getItem("token");
    setForgotPassword(forgotPassword);
  }, [langPref, i18n, tokenCheck]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const handleLoginSuccess = (isUserLoggedIn, status) => {
    console.log("login", isUserLoggedIn);
    setIsUserLoggedIn(true);
    console.log("Status", status);
  };
  return (
    <ConfigProvider
      locale={locale}
      form={{ validateMessages: defaultValidationMessagesWithTranslation(t) }}
    >
      <Router>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>

        {tokenCheck == null ? (
          // Render login page if not logged in
          <>
            <PublicRoute
              path={"/"}
              exact={true}
              component={() => (
                <Login
                  onLogin={handleLoginSuccess} // Pass the onLogin prop here
                  isUserLoggedIn={isUserLoggedIn}
                />
              )}
            />
            <Route
              path="/forgotPassword"
              exact={true}
              component={ForgotPassword}
            />
            <Route
              path="/passwordReset"
              exact={true}
              component={ChangePassword}
            />
          </>
        ) : (
          <>
            <Sidebar>
              <Switch>
                <Route path="/dashboard" component={DashboardPOC} />
                <Route path="/drivers" exact={true} component={DriversPOC} />
                <Route
                  path="/drivers/:driverId/:assignedTruck"
                  exact
                  component={DriversDetailsPOC}
                />
                <Route
                  path="/drivers/:driverId/:assignedTruck/:datesDriver/:uploadIdDriver"
                  component={DriversSettelmentsPOC}
                />
                <Route path="/vehicle" exact={true} component={VehiclePOC} />
                <Route
                  path="/vehicle/:vehicleId"
                  exact={true}
                  component={VehicleDetailsPOC}
                />
                <Route path="/loads" exact component={LoadsPOC} />
                <Route path="/settlement" exact component={SettlementsPOC} />
                <Route
                  path="/settlement/:settlementDate/:uploadId"
                  exact
                  component={SettlementsDetailsPOC}
                />
                <Route
                  path="/settlement/:settlementDate/:uploadId/:driverID"
                  component={SettlementsDriverPOC}
                />
                <Route path="/accountSettings" component={AccountSettings} />
                <Route path="/addLoad" component={AddLoad} />
                {tokenCheck != null ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <>
                    <Redirect to="/" exact />
                  </>
                )}
              </Switch>
            </Sidebar>
            <>
              {" "}
              <Switch>
                {/* <PublicRoute path={"/"} exact={true} component={Login} /> */}
                <Switch>
                  {/* <Route path="/" exact component={DriversPOC} />
              <Route
                path="/driversDetails/:driverNo"
                component={DriversDetailsPOC}
              />
              <Route
                path="/vehicleDetails/:vehicleNo"
                component={VehicleDetailsPOC}
              /> */}
                  {/* <Route path="/" component={Login} /> */}
                </Switch>
                <Route>
                  {/* <DefaultLayout /> */}
                  {/* <Login /> */}
                  {/* <Sidebar /> */}
                  {/* <DriversDetailsPOC />
              <DriversPOC></DriversPOC> */}
                </Route>
              </Switch>
            </>
          </>
        )}
      </Router>
    </ConfigProvider>
  );
}

export default App;
