import React, { useRef, useState, useEffect } from "react";
import "./AddDriverInput.scss";
import AddInputField from "app/views/commonComponents/AddInputField/AddInputField";
import toastIcon from "../../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Address } from "app/views/commonComponents/Address/Address";
import CustomDatePicker from "app/views/commonComponents/CustomDatePicker/CustomDatePicker";
import CustomDropdown from "app/views/commonComponents/CustomDropdown/CustomDropdown";
const AddDriverInput = ({
  firstName,
  lastName,
  middleName,
  mobileNumber,
  emailAddress,
  suffix,
  suffixItems,
  preferredName,
  dateOfBirth,
  city,
  selectedHomeAddress,
  selectedCity,
  selectedState,
  selectedZip,
  selectedLatitude,
  selectedLongitude,
  handleFirstNameChange,
  handleLastNameChange,
  handleMiddleNameChange,
  handleSuffixChange,
  handlePreferredNameChange,
  handleCityChange,
  handleDateOfBirthChange,
  handleMobileNumberChange,
  handleEmailAddressValid,
  handleEmailAddressChange,
  handleHomeAddressChange,
  handleCityAddressChange,
  handleStateAddressChange,
  handleZipAddressChange,
  handleLatitudeChange,
  handleLongitudeChange,
  cityStateZip,
}) => {
  // const [dateOfBirth, setDateOfBirth] = useState(null);
  // const handleDateOfBirthChange = (date) => {
  //   console.log("dateOfBirth2", date);
  //   setDateOfBirth(date);
  // };
  return (
    <>
      <div className="inputSectionAddDriverContainer">
        <div className="inputSectionAddDriver">
          <div className="inputSectionTitleDriver">Personal Details</div>
          <div className="inputRowDriver">
            {/* <AddInputField
            fieldName="Driver Id"
            fieldValue={driverId}
            onChange={(e) => setDriverId(e.target.value)}
            style={{ width: "100%" }}
          /> */}
            <AddInputField
              fieldName="First Name"
              fieldValue={firstName}
              inputType="text"
              //onChange={(e) => setFirstName(e.target.value)}
              onChange={handleFirstNameChange}
              style={{ width: "100%" }}
              autoComplete="given-name"
              background="#ffffff"
            />
            <AddInputField
              fieldName="Middle Name"
              fieldValue={middleName}
              inputType="text"
              //onChange={(e) => setMiddleName(e.target.value)}
              onChange={handleMiddleNameChange}
              style={{ width: "100%" }}
              background="#ffffff"
            />
            <AddInputField
              fieldName="Last Name"
              fieldValue={lastName}
              inputType="text"
              // onChange={(e) => setLastName(e.target.value)}
              onChange={handleLastNameChange}
              style={{ width: "100%" }}
              autoComplete="family-name"
              background="#ffffff"
            />
          </div>
          <div className="inputRowDriver" style={{ width: "100%" }}>
            {/* <AddInputField
              fieldName="Suffix"
              fieldValue={suffix}
              inputType="text"
              //onChange={(e) => setSuffix(e.target.value)}
              onChange={handleSuffixChange}
              style={{ width: "100%" }}
              background="#ffffff"
            /> */}
            <CustomDropdown
              fieldName="Suffix"
              items={suffixItems}
              selectedItem={suffix}
              onSelect={handleSuffixChange}
              background={"#ffffff"}
            />
            <AddInputField
              fieldName="Preferred Name"
              fieldValue={preferredName}
              inputType="text"
              //onChange={(e) => setPreferredName(e.target.value)}
              onChange={handlePreferredNameChange}
              style={{ width: "100%" }}
              background="#ffffff"
            />
            {/* <AddInputField
              fieldName="Date Of Birth"
              fieldValue={dateOfBirth}
              inputType="text"
              onChange={handleDateOfBirthChange}
              placeholder="mm/dd/yyyy"
              style={{ width: "100%" }}
              autoComplete="bday"
              background="#ffffff"
            /> */}
            <div style={{ width: "100%" }}>
              <CustomDatePicker
                fieldName="Date Of Birth"
                selectedDate={dateOfBirth} // Pass down the selectedDate state
                setSelectedDate={handleDateOfBirthChange}
                dateFormat="MM-dd-YYYY" // Pass down the function to update selectedDate
                background="#ffffff"
              />
            </div>
          </div>
        </div>
        {/* <hr className="horizontalLine" /> */}
        <div className="inputSectionAddDriver">
          <div className="inputSectionTitleDriver">Contact Information</div>
          <div className="inputRowDriver" style={{ width: "100%" }}>
            <AddInputField
              fieldName="Mobile Phone Number"
              fieldValue={mobileNumber}
              inputType="text"
              onChange={handleMobileNumberChange}
              style={{ width: "100%" }}
              autoComplete="tel"
              background="#ffffff"
            />
            <AddInputField
              fieldName="Email Address"
              fieldValue={emailAddress}
              inputType="email"
              onChange={handleEmailAddressChange}
              style={{ width: "100%" }}
              autoComplete="email"
              background="#ffffff"
            />
          </div>
        </div>
        {/* <hr className="horizontalLine" /> */}
        <div className="inputSectionAddDriver">
          <div className="inputSectionTitleDriver">Address</div>
          <div
            className="inputRowDriver"
            style={{
              display: "flex",
            }}
          >
            <div style={{ flex: "1" }}>
              <Address
                fieldName={"Home Address"}
                addressCountryFilters={["us"]}
                handleAddressChange={handleHomeAddressChange}
                handleCityChange={handleCityAddressChange}
                handleStateChange={handleStateAddressChange}
                handleZipChange={handleZipAddressChange}
                handleLatitudeChange={handleLatitudeChange}
                handleLongitudeChange={handleLongitudeChange}
                background="#ffffff"
              />
            </div>
            <div style={{ flex: "1" }}>
              <AddInputField
                fieldName="City, State, ZIP"
                fieldValue={cityStateZip || city}
                inputType="text"
                //onChange={(e) => setCity(e.target.value)}
                onChange={handleCityChange}
                autoComplete="address-level2"
                background="#ffffff"
              />
              {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "24px",
                flex: "1",
              }}
            >
              <InputDropdown
                fieldName="State"
                dropdownOptions={statesList}
                initialValue={selectedState || ""}
                selectedValue={selectedState || state}
                onValueSelection={handleStateSelection}
                //setShowDropdownParent={handleShowDropdownChange}
                showArrow={false}
                //zIndex={showDropdown == true && "-1"}
                inputType="text"
              />
              <AddInputField
                fieldName="Zip"
                fieldValue={selectedZip || zip}
                inputType={"number"}
                onChange={handleZipChange}
                autoComplete="postal-code"
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="loginCTABox">
        <Buttons
          size={"small"}
          dimensions={"normal"}
          buttonType={"primary"}
          disabled={
            (firstName == "" ||
              lastName == "" ||
              // dateOfBirth == null ||
              validMobileNumber == false ||
              validEmail == false ||
              selectedHomeAddress == "" ||
              (selectedCity == "" && city == "") ||
              (selectedState == "" && state == "") ||
              (selectedZip == "" && validZipNumber == false)) &&
            true
          }
          imageReplace={true}
          onClick={() => {
            addDriverDataAPI();
            onClose();
          }}
        >
          Submit
        </Buttons>
      </div> */}
      <ToastContainer
        className={"toast-message"}
        toastStyle={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 12px 8px 8px",
          background: "rgba(0, 188, 249, 0.1)",
          borderRadius: "54px",
          color: "#00bfff",
          fontFamily: "Red Hat Display",
          fontSize: "14px",
          fontWeight: "600",
          //height: "36px",
          minHeight: "none",
        }}
        hideProgressBar={true}
        closeButton={<img src={toastCross} />}
        icon={<img src={toastIcon} />}
      />
    </>
  );
};

export default AddDriverInput;
