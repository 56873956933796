import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./DashboardPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../commonComponents/DetailsHeader/DetailsHeader";
import driverProfile from "../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../assets/images/drivers/accidents.svg";
import star from "../../../../../assets/images/drivers/star.svg";
import userTag from "../../../../../assets/images/drivers/userTag.svg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTablePOC from "../TablePOC/TablePOC";
//import DriversFilterButton from "../DriversFilterButton/DriversFilterButton";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import addIcon from "../../../../../assets/images/poc/addIcon.svg";
import searchIcon from "../../../../../assets/images/poc/searchIcon.svg";
import helloImage from "../../../../../assets/images/poc/helloImage.png";
import leftDecrementArrow from "../../../../../assets/images/poc/leftDecrementArrow.svg";
import rightIncrementArrow from "../../../../../assets/images/poc/rightIncrementArrow.svg";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import axios from "axios";
import { useParams } from "react-router-dom";
import SearchBox from "app/views/commonComponents/SearchBox/SearchBox";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastIcon from "../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../assets/images/poc/toastCross.svg";
import grossRevenueGradient from "../../../../../assets/images/poc/grossRevenueGradient.svg";
import grossMarginGradient from "../../../../../assets/images/poc/grossMarginGradient.svg";
import rpmGradient from "../../../../../assets/images/poc/rpmGradient.svg";
import driverPaymentsGradient from "../../../../../assets/images/poc/driverPaymentsGradient.svg";
import milesLoadedGradient from "../../../../../assets/images/poc/milesLoadedGradient.svg";
import loadsDeliveredGradient from "../../../../../assets/images/poc/loadsDeliveredGradient.svg";
import {
  getNextSettlementsData,
  getWeeklySettlementsData,
  getDriversLeaderBoardSettlementsData,
  getVehiclesLeaderBoardSettlementsData,
  getDashboardDetailsHeaderKPIData,
} from "../../../../apiServices/apiServices";
const DashboardPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTabId, setActiveCalendarTabId] = useState(0);
  const [activeCalendarTabLabel, setActiveCalendarTabLabel] = useState("Month");
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  // const [selectedMonth, setSelectedMonth] = useState("Jan 2024");
  // const [startDate, setStartDate] = useState("2024-01-01");
  // const [endDate, setEndDate] = useState("2024-01-31");
  // const [years, setYears] = useState([2024, 2023]);
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [loadCount, setLoadCount] = useState(0);
  const [totalMile, setTotalMile] = useState(0);
  const [rpm, setRPM] = useState(0);
  const [totalOtherIncome, setTotalOtherIncome] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [netPay, setNetPay] = useState(0);
  const [statementDate, setStatementDate] = useState("");
  const [settlementsOriginalData, setSettlementsOriginalData] = useState([]);
  const [driversOriginalData, setDriversOriginalData] = useState([]);
  const [vehiclesOriginalData, setVehiclesOriginalData] = useState([]);
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [userDetails, setUserDetails] = useState({});
  //const { firstName, lastName } = useParams();
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const handleSearch = (searchData) => {
    let fromattedFilteredData = null;
    const filteredData = settlementsOriginalData.find(
      (item) => item._id == searchData
    );

    if (filteredData != undefined) {
      const filteredDataArray = Array.isArray(filteredData)
        ? filteredData
        : [filteredData];

      fromattedFilteredData = filteredDataArray.map((item, index) => {
        const originalDateString = item.statement_date;
        let originalDate = moment.utc(originalDateString);
        const month = moment(originalDate).format("MM");
        const day = moment(originalDate).date();
        const year = moment(originalDate).year();

        const formattedDate = `${month}-${day}-${year}`;

        return {
          settlementDate: `${formattedDate}`,
          totalLoad: item.total_load_count.toLocaleString("en-US"),
          totalDriver: item.total_drivers.toLocaleString("en-US"),
          totalRevenue: `$${item.total_revenue.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`,
          totalOtherRevenue: `$${item.total_other_revenue.toLocaleString(
            "en-US",
            {
              maximumFractionDigits: 2,
            }
          )}          •             ${item.total_other_income_count.toLocaleString(
            "en-US"
          )}`,
          totalDeduction: `$${item.total_deduction.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })} • ${item.total_deduction_count.toLocaleString("en-US")}`,
          driverPayments: `$${item.driver_payment.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`,
        };
      });
    } else {
      fromattedFilteredData = [];
    }

    //setSearchData(fromattedFilteredData);
    setTableData(fromattedFilteredData);
  };
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const driverData = [
    {
      icon: grossRevenueGradient,
      values: `$${Math.round(grossRevenue).toLocaleString("en-US")}` || "$0.00",
      title: "Gross Revenue",
    },
    {
      icon: driverPaymentsGradient,
      values:
        `$${Math.round(driverPayments).toLocaleString("en-US")}` || "$0.00",
      title: "Driver Payments",
    },
    {
      icon: grossMarginGradient,
      values: `$${Math.round(grossMargin).toLocaleString("en-US")}` || "$0.00",
      title: "Gross Margin",
    },
    {
      icon: rpmGradient,
      values:
        `$${headerRPM.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}` || "$0.00",
      title: "RPM",
    },
    {
      icon: milesLoadedGradient,
      values: milesLoaded.toLocaleString("en-US") || "0.00",
      title: "Loaded Miles",
    },
    {
      icon: loadsDeliveredGradient,
      values: loadsDelivered.toLocaleString("en-US") || "0.00",
      title: "Delivered Loads",
    },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const nextSettlementsData = [
    {
      icon: "",
      values: loadCount ? loadCount : 0,
      title: "Load Count",
      key: "loadCount",
      editable: false,
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: totalMile ? totalMile : 0,
      title: "Total Miles",
      key: "totalMile",
      editable: false,
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: rpm
        ? `$${rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      title: "Rate Per Mile",
      key: "rpm",
      editable: false,
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: totalOtherIncome
        ? `$${totalOtherIncome.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      title: "Total Other Income",
      key: "totalOtherIncome",
      editable: false,
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: `$${totalDeductions.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      title: "Total Deductions",
      key: "totalDeductions",
      editable: false,
      placeholder: "eg. LA12345678",
    },
    {
      icon: "",
      values: totalDrivers ? totalDrivers.toLocaleString() : 0,
      title: "Total Drivers",
      key: "totalDrivers",
      editable: false,
      placeholder: "eg. LA12345678",
    },
  ];
  const calendarTabsData = [
    { id: 0, label: "Month" },
    { id: 1, label: "Quarter" },
    { id: 2, label: "Year" },
    // Add more tabs as needed
  ];
  const driversTableHeaders = {
    driverName: "Driver Name",
    grossRevenue: "Gross Revenue",
    rpm: "RPM",
    milesLoaded: "Loaded Miles",
    loadsDelivered: "Delivered Loads",
  };
  const driversTableData = driversOriginalData.map((item, index) => {
    const truncatedDriverName =
      item.driver_name && item.driver_name.length > 20
        ? item.driver_name.substring(0, 20) + "..."
        : item.driver_name;
    return {
      driverName: truncatedDriverName,
      grossRevenue: item.load_revenue
        ? `$${item.load_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      rpm: item.rpm
        ? `$${item.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      milesLoaded: item.loaded_miles
        ? item.loaded_miles.toLocaleString("en-US")
        : "0",
      loadsDelivered: item.loads_delivered
        ? item.loads_delivered.toLocaleString("en-US")
        : "0",
    };
  });
  const vehicleTableHeaders = {
    vehicleId: "Vehicle Id.",
    grossRevenue: "Gross Revenue",
    rpm: "RPM",
    milesLoaded: "Loaded Miles",
    loadsDelivered: "Delivered Loads",
  };
  const vehicleTableData = vehiclesOriginalData.map((item, index) => {
    return {
      vehicleId: item.vehicle_id
        ? item.vehicle_id.toLocaleString("en-US")
        : "0",
      grossRevenue: item.load_revenue
        ? `$${item.load_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      rpm: item.rpm
        ? `$${item.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      milesLoaded: item.loaded_miles
        ? item.loaded_miles.toLocaleString("en-US")
        : "0",
      loadsDelivered: item.loads_delivered
        ? item.loads_delivered.toLocaleString("en-US")
        : "0",
    };
  });
  const settlementsTableHeaders = {
    settlementDate: "Settlement Date",
    totalLoad: "Total Loads",
    totalDriver: "Total Drivers",
    totalRevenue: "Total Revenue",
    totalOtherRevenue: "Total Other Revenue",
    totalDeduction: "Total Deductions",
    driverPayments: "Driver Payments",
  };
  const settlementsTableData = settlementsOriginalData.map((item, index) => {
    const originalDateString = item.statement_date;
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).format("DD");
    const year = moment(originalDate).year();
    const formattedDate = `${month} ${day}, ${year}`;
    return {
      settlementDate: `${formattedDate}`,
      totalLoad: item.total_load_count.toLocaleString("en-US"),
      totalDriver: item.total_drivers.toLocaleString("en-US"),
      totalRevenue: `$${item.total_revenue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      totalOtherRevenue: `$${item.total_other_revenue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} • ${item.total_other_income_count.toLocaleString("en-US")}`,
      totalDeduction: `$${item.total_deduction.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} • ${item.total_deduction_count.toLocaleString("en-US")}`,
      driverPayments: `$${item.driver_payment.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    };
  });
  const handleTabChange = (tabId, tabLabel) => {
    setActiveCalendarTabId(tabId);
    setActiveCalendarTabLabel(tabLabel);
  };
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 280; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };
    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  // Function to handle month change
  const handleDateChange = (startDate, endDate) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    // Call getDetailsHeaderKPIDataAPI with final start and end dates
    getDetailsHeaderKPIDataAPI(startDate, endDate);
  };
  const [currentPageDriverPOC, setCurrentPageDriverPOC] = useState(1);
  const [itemsPerPageDriverPOC, setItemsPerPageDriverPOC] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageDriverPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageDriverPOC(perPage);
    setCurrentPageDriverPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem("currentPageDriverPOC", currentPageDriverPOC);
    //localStorage.setItem("itemsPerPageDriverPOC", itemsPerPageDriverPOC);
  };
  const handleReturnDriversPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageDriverPOC");
    //const savedItemsPerPage = localStorage.getItem("itemsPerPageDriverPOC");
    setCurrentPageDriverPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageDriverPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  useEffect(() => {
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");
    console.log("authUserData", authUserData);
    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
    if (
      activeCalendarTabLabel == "Month" ||
      activeCalendarTabLabel == "Quarter" ||
      activeCalendarTabLabel == "Year"
    ) {
      getDetailsHeaderKPIDataAPI();
    }
    getNextSettlementsDataAPI();
    getWeeklySettlementsDataAPI();
    getDriversLeaderBoardSettlementsDataAPI();
    getVehiclesLeaderBoardSettlementsDataAPI();
  }, [activeCalendarTabLabel, firstNameUser]);
  const getDetailsHeaderKPIDataAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    let currentStartDate = null;
    let currentEndDate = null;

    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0

    switch (activeCalendarTabLabel) {
      case "Month": // Current month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Quarter": // Current quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "Year": // Current year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    const storedToken = localStorage.getItem("token");
    getDashboardDetailsHeaderKPIData(finalStartDate, finalEndDate)
      .then((response) => {
        //console.log("insideif", response);
        console.log("DetailsHeaderKPIData", response.data);
        console.log("DetailsHeaderKPIData1", response.data.gross_revenue);
        setGrossRevenue(response.data.gross_revenue);
        setDriverPayments(response.data.driver_payments);
        setGrossMargin(response.data.gross_margin);
        setHeaderRPM(response.data.rpm);
        setMilesLoaded(response.data.miles_loaded);
        setLoadsDelivered(response.data.loads_delivered);
        console.log("authUser2", loadCount);
        // toast("This is a custom toast", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getNextSettlementsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getNextSettlementsData()
      .then((response) => {
        console.log("apiResponse4");
        console.log("NextSettlementsData", response.data);
        console.log("authUser1", response.data.total_load_count);
        setLoadCount(
          response.data.total_load_count ? response.data.total_load_count : ""
        );
        setTotalMile(
          response.data.total_miles ? response.data.total_miles : ""
        );
        setRPM(response.data.rpm ? response.data.rpm : "");
        setTotalOtherIncome(
          response.data.total_other_revenue
            ? response.data.total_other_revenue
            : ""
        );
        setTotalDeductions(
          response.data.total_deduction ? response.data.total_deduction : ""
        );
        setTotalDrivers(
          response.data.total_drivers ? response.data.total_drivers : ""
        );
        setNetPay(response.data.net_pay ? response.data.net_pay : "");
        console.log("setStatementDate0", response.data.statement_date);
        const originalDateString = response.data.statement_date
          ? response.data.statement_date
          : "";
        let originalDate = moment.utc(originalDateString);
        console.log("monthCheck", originalDate);
        const month = originalDate ? moment(originalDate).format("MMMM") : "";
        console.log("monthCheck1", month);
        const day = originalDate ? moment(originalDate).format("DD") : "";
        const year = originalDate ? moment(originalDate).year() : "";
        const formattedDate = `${month == "Invalid date" ? "Date:" : month} ${
          day == "Invalid date" ? "" : day
        }${year ? "," : ""} ${year ? year : ""}`;
        console.log("setStatementDate1", formattedDate);
        setStatementDate(formattedDate);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getWeeklySettlementsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    const limit = 5;
    getWeeklySettlementsData(limit)
      .then((response) => {
        console.log("WeeklySettlementsResponse", response);
        console.log("WeeklySettlementsData1", response.data);
        setSettlementsOriginalData(response.data ? response.data : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDriversLeaderBoardSettlementsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getDriversLeaderBoardSettlementsData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("DriversLeaderBoardSettlementsData1", response.data);
        setDriversOriginalData(response.data ? response.data : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getVehiclesLeaderBoardSettlementsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getVehiclesLeaderBoardSettlementsData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("VehiclesLeaderBoardSettlementsData1", response.data);
        setVehiclesOriginalData(response.data ? response.data : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  // Splitting the number into two parts before and after the decimal point
  const parts = netPay.toString().split(".");
  const beforeDecimalNetPay = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const afterDecimalNetPay = parts[1] || "00"; // If there's no decimal part, default to "00"

  console.log("nextSettelmemt1", loadCount);
  console.log("nextSettelmemt2", totalMile);
  console.log("nextSettelmemt3", rpm);
  console.log("nextSettelmemt4", totalDeductions);
  console.log("nextSettelmemt5", totalOtherIncome);
  console.log("nextSettelmemt6", totalDrivers);
  console.log("nextSettelmemt7", netPay);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="main-page-design">
          <Row xl={12} md={12} sm={12} xs={12}>
            <Col xl={12} md={12} sm={12} xs={12}>
              <div className="driverStickyHeaderDriver">
                {/* <Breadcrumbs items={breadcrumbItems} /> */}
                <div className="titleContainer">
                  <div className="greeting">High Roller Expediting</div>
                  <div style={{ marginTop: "8px", display: "flex" }}>
                    {/* <img
                        src={helloImage}
                        style={{
                          width: "34px",
                          height: "34px",
                          marginRight: "5px",
                        }}
                      /> */}
                    <Title
                      // name={`${firstNameUser} ${lastNameUser}`}
                      name={"Fleet Finance Dashboard"}
                      type={"page"}
                      color="#fff"
                    />
                  </div>
                </div>
                <div className="calenderChangeButtonContainerDashboard">
                  <CalenderChanger
                    activeTabLabel={activeCalendarTabLabel}
                    activeTabId={activeCalendarTabId}
                    onMonthChange={handleDateChange}
                    onQuarterChange={handleDateChange}
                    onYearChange={handleDateChange}
                  />
                </div>
                <div className="stickyTabButtonsDashboard">
                  <TabButtons
                    tabs={calendarTabsData}
                    onTabChange={handleTabChange}
                  />
                </div>
              </div>
              {/* <div className="stickyTabButtons">
                  <TabButtons tabs={tabsData} onTabChange={handleTabChange} />
                </div> */}
              <div style={{ marginTop: "24px" }}>
                <DetailsHeader data={driverData} />
              </div>
              <div className="nextSettlements">
                <div className="nextSettlementsTitle">
                  <div className="nextSettlementsHeadingDateSection">
                    {" "}
                    <div className="nextSettlementsHeading">
                      Next Settlement:
                    </div>
                    <div style={{ display: "flex" }}>
                      {/* <div className="currentWeek">W5</div>
                        <div className="dotSeperater">•</div> */}
                      <div className="currentWeek">{statementDate}</div>
                    </div>
                  </div>
                  <div className="netPaySection">
                    <div style={{ display: "flex" }}>
                      {" "}
                      <div className="neyPayValue">
                        {`$${beforeDecimalNetPay || 0}.`}
                      </div>
                      <div className="neyPayDecimalValue">
                        {afterDecimalNetPay.slice(0, 2)}
                      </div>
                    </div>
                    <div className="neyPayField">Net Pay</div>
                  </div>
                </div>
                <div style={{ marginTop: "", width: "100%" }}>
                  <DetailsHeader
                    data={nextSettlementsData}
                    background={"#FCFCFC"}
                    inputBackground={"#FCFCFC"}
                  />
                </div>
              </div>
              <div className="tableContainerDashboard">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px",
                  }}
                  className="tableTitleContainerDashboard"
                >
                  {" "}
                  <div style={{ flex: "1" }}>
                    <Title name={"Recent Settlements"} type={"table"} />
                  </div>
                  {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search upload id."
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                  {/* <SearchBox
                      search_key="upload_id"
                      search_placeholder={"Search by upload Id"}
                      onSearch={handleSearch}
                    /> */}
                </div>
                <div
                  className="driversTableContainer"
                  style={{ maxHeight: `${maxTableHeight}px` }}
                >
                  <div className="recentSettelmentsTableContainer">
                    <DriversTablePOC
                      headers={settlementsTableHeaders}
                      data={settlementsTableData}
                      // activeTab={activeTab}
                      rowClick={true}
                      tableName={"weeklySettelmentsTableDashboard"}
                      paginationEnabled={false}
                      currentPage={currentPageDriverPOC}
                      itemsPerPage={itemsPerPageDriverPOC}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onNavigateAway={handleNavigateAway}
                    />
                  </div>
                </div>
              </div>
              <div className="leaderBoardContainer">
                <div
                  className="tableContainerDashboard"
                  style={{ width: "49%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "24px",
                    }}
                    className="tableTitleContainerDashboard"
                  >
                    <div style={{ flex: "1" }}>
                      <Title name={"Driver Leaderboard"} type={"table"} />
                    </div>
                  </div>
                  <div
                    className="driversTableContainer"
                    style={{ maxHeight: `${maxTableHeight}px` }}
                  >
                    <div className="recentSettelmentsTableContainer">
                      <DriversTablePOC
                        headers={driversTableHeaders}
                        data={driversTableData}
                        rowClick={true}
                        tableName={"driverLeaderBoard"}
                        paginationEnabled={false}
                        currentPage={currentPageDriverPOC}
                        itemsPerPage={itemsPerPageDriverPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tableContainerDashboard"
                  style={{ width: "49%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "24px",
                    }}
                    className="tableTitleContainerDashboard"
                  >
                    {" "}
                    <div style={{ flex: "1" }}>
                      <Title name={"Vehicle Leaderboard"} type={"table"} />
                    </div>
                  </div>
                  <div
                    className="driversTableContainer"
                    style={{ maxHeight: `${maxTableHeight}px` }}
                  >
                    <div className="recentSettelmentsTableContainer">
                      <DriversTablePOC
                        headers={vehicleTableHeaders}
                        data={vehicleTableData}
                        rowClick={true}
                        tableName={"vehicleLeaderBoard"}
                        paginationEnabled={false}
                        currentPage={currentPageDriverPOC}
                        itemsPerPage={itemsPerPageDriverPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <ToastContainer
            className={"toast-message"}
            toastStyle={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "8px 12px 8px 8px",
              background: "rgba(0, 188, 249, 0.1)",
              borderRadius: "54px",
              color: "#00bfff",
              fontFamily: "Red Hat Display",
              fontSize: "14px",
              fontWeight: "600",
              //height: "36px",
              minHeight: "none",
            }}
            hideProgressBar={true}
            closeButton={<img src={toastCross} />}
            icon={<img src={toastIcon} />}
          />
        </div>
      </Container>
    </React.Fragment>
  );
};
export default DashboardPOC;
