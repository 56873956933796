import React from "react";
import Layout from "antd/es/layout";
import Menu from "antd/es/menu";
import Header from "./Header";
import NoMatchRoute from "../common/components/NoMatchRoute";
import { PAGE_URLS } from "app/routes/app";
import { Switch } from "react-router-dom";
import PublicRoute from "app/views/common/components/PublicRoute";
import PAGES from "app/views/pages";
import "./styles.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import Labels from "app/views/commonComponents/Labels/Labels";
import NavTabs from "app/views/commonComponents/Tabs/NavTabs";
import KatherineMoss from "../../../assets/images/table/KatherineMoss.svg";
import KatherineMoss2 from "../../../assets/images/table/KatherineMoss2.svg";
import KatherineMoss3 from "../../../assets/images/table/KatherineMoss3.svg";
import userSquare from "../../../assets/images/table/userSquare.svg";
import twoArrows from "../../../assets/images/table/twoArrows.svg";
import fingerCircle from "../../../assets/images/table/fingerCircle.svg";
import IconGroup from "app/views/commonComponents/IconGroup/IconGroup";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import TableHeader from "app/views/commonComponents/Table/TableHeader/TableHeader";
import RowSelection from "app/views/commonComponents/RowSelection/RowSelection";
const { Sider, Content } = Layout;

function DefaultLayout(props) {
  const avatars = [
    KatherineMoss,
    KatherineMoss2,
    KatherineMoss3,
    userSquare,
    fingerCircle,
    twoArrows,
  ];
  const maxCount = 4;
  const tableHeaders = [
    "Date Issued",
    "Expiration Date",
    "Driver Licence No.",
    "State",
    "Type",
    "Class",
    "Endorsements",
    "Restrictions",
    "Status",
    <img src={twoArrows} alt="Two Arrows" />,
    <img src={twoArrows} alt="Two Arrows" />,
  ];
  const rowData = {
    selectedRow: false,
    title: "Title",
    subtitle: "Subtitle",
    actions: ["Action 1", "Action 2", "Action 3"],
    isCloseOption: false,
  };
  const rowData2 = {
    selectedRow: true,
    title: "12 Items Selected",
    // subtitle: "Subtitle",
    actions: ["Action 1", "Action 2", "Action 3"],
    isCloseOption: true,
  };

  return (
    <Layout className="app-page-container" id="app_page_container">
      <Header />
      <Layout>
        <Sider theme="light" width={200} id="app_sider">
          <Menu mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">Drivers</Menu.Item>
            <Menu.Item key="2">Motor Carriers</Menu.Item>
            <Menu.Item key="3">Owner Operators</Menu.Item>
          </Menu>
        </Sider>
        <Content id="app_content_container">
          {/* <Switch>
            <PublicRoute
              path={PAGE_URLS.LANDING}
              exact={true}
              component={PAGES.LANDING}
            />
            <PublicRoute
              path={PAGE_URLS.DRIVERS}
              exact={true}
              component={PAGES.DRIVERS}
            />
            <PublicRoute
              exact={true}
              path={PAGE_URLS.PAGE_NOT_FOUND}
              component={PAGES.PAGE_NOT_FOUND}
            />
            <NoMatchRoute />
          </Switch> */}
          <div>Normal</div>
          <div>Regular</div>
          <div style={{ display: "flex" }}>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="regular"
              buttonType="primary"
              style={{ marginLeft: "10px" }}
            ></Icon>
            <Icon
              size="regular"
              buttonType="primary"
              style={{ marginLeft: "10px" }}
              disabled
            ></Icon>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              {" "}
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="regular"
              buttonType="secondary"
              style={{ marginLeft: "10px" }}
            ></Icon>
            <Icon
              size="regular"
              buttonType="secondary"
              style={{ marginLeft: "10px" }}
              disabled
            ></Icon>
          </div>
          <div
            style={{ display: "flex", marginTop: "10px", marginBottom: "15px" }}
          >
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "10px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="regular"
              buttonType="ghost"
              style={{ marginLeft: "10px" }}
            ></Icon>
            <Icon
              size="regular"
              buttonType="ghost"
              style={{ marginLeft: "10px" }}
              disabled
            ></Icon>
          </div>
          <div>Small</div>
          <div style={{ display: "flex" }}>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              {" "}
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div
            style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}
          >
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="left"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              iconPosition="right"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"normal"}
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div>Responsive</div>
          <div>Regular</div>
          <div style={{ display: "flex" }}>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="primary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              {" "}
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="secondary"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div
            style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}
          >
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="ghost"
              size="regular"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div>Small</div>
          <div style={{ display: "flex" }}>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="primary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="primary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              {" "}
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="secondary"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="secondary"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>
          <div
            style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}
          >
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="left"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              iconPosition="right"
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Buttons
              dimensions={"responsive"}
              buttonType="ghost"
              size="small"
              style={{ marginLeft: "15px" }}
              disabled
            >
              Button
            </Buttons>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
            ></Icon>
            <Icon
              size="small"
              buttonType="ghost"
              style={{ marginLeft: "15px" }}
              disabled
            ></Icon>
          </div>

          <div>Labels</div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Labels
              labelType="light"
              labelTag="purple"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Claimed
            </Labels>
            <Labels
              labelType="light"
              labelTag="green"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Completed
            </Labels>{" "}
            <Labels
              labelType="light"
              labelTag="skyblue"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Pending
            </Labels>{" "}
            <Labels
              labelType="light"
              labelTag="yellow"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Unclaimed
            </Labels>{" "}
            <Labels
              labelType="light"
              labelTag="grey"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Available
            </Labels>{" "}
            <Labels
              labelType="light"
              labelTag="red"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Canceled
            </Labels>
          </div>
          <div
            style={{ display: "flex", marginTop: "20px", marginBottom: "15px" }}
          >
            <Labels
              labelType="dark"
              labelTag="purple"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Claimed
            </Labels>
            <Labels
              labelType="dark"
              labelTag="green"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Completed
            </Labels>{" "}
            <Labels
              labelType="dark"
              labelTag="skyblue"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Pending
            </Labels>{" "}
            <Labels
              labelType="dark"
              labelTag="yellow"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Unclaimed
            </Labels>{" "}
            <Labels
              labelType="dark"
              labelTag="grey"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Available
            </Labels>{" "}
            <Labels
              labelType="dark"
              labelTag="red"
              arrow={false}
              style={{ marginLeft: "15px" }}
            >
              Canceled
            </Labels>
          </div>
          {/* <div>Icon Group</div>
          <div style={{ display: "", marginTop: "15px", width: "" }}>
            <IconGroup avatars={avatars} maxCount={maxCount} />
          </div> */}
          <div>Navigation Tabs</div>
          <div>
            <NavTabs
              tabNames={["Tab 1", "Tab 2", "Tab 3", "Tab 4", "Tab 5", "Tab 6"]}
              navType={"horizontal"}
            />
          </div>
          <div>
            <NavTabs
              tabNames={["Tab 1", "Tab 2", "Tab 3"]}
              navType={"vertical"}
            />
          </div>
          <div>Table Cells</div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              iconPosition="right"
              image={twoArrows}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              iconPosition="left"
              image={twoArrows}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              iconPosition="left"
              image={KatherineMoss}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              style={{ marginRight: "5px" }}
            >
              <Labels labelTag="purple" labelType="light" style={{}}>
                Claimed
              </Labels>
              <IconGroup avatars={avatars} maxCount={maxCount} />
            </TableData>
            <TableData
              cellType={"headerCell"}
              size={"regular"}
              style={{ marginLeft: "95px" }}
            >
              <img src={twoArrows} alt={"Arrows"} />
            </TableData>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              iconPosition="right"
              image={fingerCircle}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              iconPosition="left"
              image={KatherineMoss}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              style={{ marginRight: "5px" }}
            >
              <Labels labelTag="purple" labelType="light" style={{}}>
                Claimed
              </Labels>
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              style={{ marginRight: "5px" }}
            >
              <IconGroup avatars={avatars} maxCount={maxCount} />
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"small"}
              style={{ marginRight: "5px" }}
            >
              <img src={twoArrows} alt={"Arrows"} />
            </TableData>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              iconPosition="right"
              image={fingerCircle}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              iconPosition="left"
              image={KatherineMoss}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              style={{ marginRight: "5px" }}
            >
              <Labels labelTag="purple" labelType="light" style={{}}>
                Claimed
              </Labels>
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              style={{ marginRight: "5px" }}
            >
              <IconGroup avatars={avatars} maxCount={maxCount} />
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"medium"}
              style={{ marginRight: "5px" }}
            >
              <img src={twoArrows} alt={"Arrows"} />
            </TableData>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              iconPosition="right"
              image={fingerCircle}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              iconPosition="left"
              image={KatherineMoss}
              style={{ marginRight: "5px" }}
            >
              Driver Licence No.
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              style={{ marginRight: "5px" }}
            >
              <Labels labelTag="purple" labelType="light" style={{}}>
                Claimed
              </Labels>
            </TableData>

            <TableData
              cellType={"bodyCell"}
              size={"large"}
              style={{ marginRight: "5px" }}
            >
              <IconGroup avatars={avatars} maxCount={maxCount} />
            </TableData>
            <TableData
              cellType={"bodyCell"}
              size={"large"}
              style={{ marginRight: "5px" }}
            >
              <img src={twoArrows} alt={"Arrows"} />
            </TableData>
          </div>
          <div
            style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}
          >
            <TableData
              cellType={"bodyCell"}
              contenType={"specialCase"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              234d
            </TableData>
            <TableData
              cellType={"bodyCell"}
              contenType={"specialCase"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              234d
            </TableData>
            <TableData
              cellType={"bodyCell"}
              contenType={"specialCase"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              234d
            </TableData>
            <TableData
              cellType={"bodyCell"}
              contenType={"specialCase"}
              iconPosition="left"
              image={userSquare}
              style={{ marginRight: "5px" }}
            >
              234d
            </TableData>
          </div>
          <div>Table Header</div>
          <div style={{ marginTop: "15px", width: "100%" }}>
            <TableHeader headers={tableHeaders} />
          </div>
          <div
            style={{ marginTop: "15px", marginBottom: "15px", width: "100%" }}
          >
            <TableHeader headers={tableHeaders} />
          </div>
          <div>Row Selection</div>
          <div style={{ marginTop: "15px" }}>
            <RowSelection {...rowData} />
          </div>
          <div style={{ marginTop: "15px" }}>
            <RowSelection {...rowData2} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
