import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import { Modal } from "@material-ui/core";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./VehicleDetailsPOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import backRightArrow from "../../../../../../assets/images/poc/backRightArrow.svg";
import userIcon from "../../../../../../assets/images/poc/userIcon.svg";
import { Link } from "react-router-dom";
import VehicleInformationPOC from "../VehicleInformationPOC/VehicleInformationPOC";
import ProfilePOC from "../../ProfilePOC/ProfilePOC";
import searchIcon from "../../../../../../assets/images/poc/searchIcon.svg";
import assignDriver from "../../../../../../assets/images/poc/assignDriver.svg";
import DriversTablePOC from "../../TablePOC/TablePOC";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import AssignDriver from "../AssignDriver/AssignDriver/AssignDriver";
import grossRevenueGradient from "../../../../../../assets/images/poc/grossRevenueGradient.svg";
import grossMarginGradient from "../../../../../../assets/images/poc/grossMarginGradient.svg";
import rpmGradient from "../../../../../../assets/images/poc/rpmGradient.svg";
import driverPaymentsGradient from "../../../../../../assets/images/poc/driverPaymentsGradient.svg";
import milesLoadedGradient from "../../../../../../assets/images/poc/milesLoadedGradient.svg";
import loadsDeliveredGradient from "../../../../../../assets/images/poc/loadsDeliveredGradient.svg";
import toastIcon from "../../../../../../assets/images/poc/toastIcon.svg";
import toastCross from "../../../../../../assets/images/poc/toastCross.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllVehiclesData,
  getVehicleDetailsHeaderKPIData,
  getVehicleDetailsProfileData,
  getVehicleDetailsLoadsData,
} from "../../../../../apiServices/apiServices";
const VehicleDetailsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTab, setActiveCalendarTab] = useState(0);
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [vehiclesOriginalData, setVehiclesOriginalData] = useState([]);
  const [vehiclesUploadId, setVehiclesUploadId] = useState([]);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [year, setYear] = useState("");
  const [vinNumber, setVinNumber] = useState("");
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [colour, setColour] = useState("");
  const [currentDriver, setCurrentDriver] = useState("");
  const [milesCovered, setMilesCovered] = useState("");
  const [loadsCompleted, setLoadsCompleted] = useState("");
  const [dateInService, setDateInService] = useState("");
  const [vehicleDriverOriginalData, setVehicleDriverOriginalData] = useState(
    []
  );
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [vehicleLoadsOriginalData, setVehicleLoadsOriginalData] = useState([]);
  const [driverId, setDriverId] = useState("");
  const [driverIdArray, setDriverIdArray] = useState("");
  const [driverName, setDriverName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("Jan 2024");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assignDriverModal, setAssignDriverModal] = useState(false);
  const [vehicleIdData, setVehicleIdData] = useState(null);
  const [vehicleAssign, setVehicleAssign] = useState(true);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const driverData = [
    // {
    //   icon: grossRevenueGradient,
    //   values: `$${Math.round(grossRevenue).toLocaleString("en-US")}`,
    //   title: "Gross Revenue",
    // },
    // {
    //   icon: driverPaymentsGradient,
    //   values: `$${Math.round(driverPayments).toLocaleString("en-US")}`,
    //   title: "Driver Payments",
    // },
    {
      icon: grossMarginGradient,
      values: `$${Math.round(grossMargin).toLocaleString("en-US")}`,
      title: "Gross Margin",
    },
    {
      icon: rpmGradient,
      values: `$${headerRPM.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      title: "RPM",
    },
    {
      icon: milesLoadedGradient,
      values: milesLoaded.toLocaleString("en-US"),
      title: "Loaded Miles",
    },
    {
      icon: loadsDeliveredGradient,
      values: loadsDelivered.toLocaleString("en-US"),
      title: "Delivered Loads",
    },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const tabsData = [
    { id: 0, label: "Profile" },
    { id: 1, label: "Loads" },
    // Add more tabs as needed
  ];
  const calendarTabsData = [
    { id: 0, label: "Month" },
    { id: 1, label: "Quarter" },
    { id: 2, label: "Year" },
    // Add more tabs as needed
  ];
  const loadsTableHeaders = {
    loadId: "Load No.",
    dates: "Dates",
    origin: "Origin",
    destination: "Destination",
    driver: "Driver",
    grossMargin: "Gross Margin",
    rpm: "RPM",
    milesLoaded: "Loaded Miles",
    revenue: "Revenue",
  };
  const loadsTableData = vehicleLoadsOriginalData.map((load, index) => {
    const originalDateString1 = load.pu_date;
    let originalDate1 = moment.utc(originalDateString1);
    const month1 = moment(originalDate1).format("MMM");
    const day1 = moment(originalDate1).format("DD");
    const year1 = moment(originalDate1).year();
    //const formattedDate1 = `${month1} ${day1}, ${year1}`;

    const originalDateString2 = load.del_date;
    let originalDate2 = moment.utc(originalDateString2);
    const month2 = moment(originalDate2).format("MMM");
    const day2 = moment(originalDate2).format("DD");
    const year2 = moment(originalDate2).year();
    let formattedDate1 = `${month1} ${day1}`;
    let formattedDate2 = `${month2} ${day2}`;
    let formattedDates = "";
    // Check if month1 is not equal to month2 and day1 is not equal to day2
    if (month1 !== month2 && day1 !== day2) {
      formattedDates = `${month1} ${day1} - ${month2} ${day2}, ${year1}`;
    } else {
      // Check if both dates have the same month and day
      if (month1 === month2 && day1 === day2) {
        formattedDates = `${formattedDate1}, ${year1}`;
      } else {
        // Check if the years are different
        if (year1 !== year2) {
          formattedDates = `${formattedDate1}, ${year1}-${formattedDate2}, ${year2}`;
        } else {
          // Adjust the format based on different cases
          if (formattedDate1 === formattedDate2) {
            formattedDates = `${formattedDate1}, ${year1}`;
          } else if (day1 === day2) {
            formattedDates = `${formattedDate1}${
              formattedDate2 ? `-${formattedDate2}` : ""
            }, ${year1}`;
          } else if (month1 === month2) {
            formattedDates = `${month1} ${day1}-${day2}, ${year1}`;
          }
        }
      }
    }
    // let formattedDate1 = `${month1} ${day1}`;
    // let formattedDate2 = `${month2} ${day2}`;
    // // Check if both dates have the same month and day
    // if (month1 === month2 && day1 === day2) {
    //   formattedDate1 = `${month1} ${day1}`;
    //   formattedDate2 = "";
    // } else {
    //   // Adjust the format based on different cases
    //   if (formattedDate1 == formattedDate2) {
    //     formattedDate2 = "";
    //   }
    //   if (day1 === day2) {
    //     formattedDate2 = "";
    //   }
    //   if (month1 === month2) {
    //     formattedDate1 = `${month1} ${day1}-${day2}`;
    //     formattedDate2 = "";
    //   }
    // }

    // const formattedDates = `${formattedDate1}${
    //   formattedDate2 ? `-${formattedDate2}` : ""
    // }, ${year1}`;
    // const formattedDate2 = `${month2} ${day2}, ${year2}`;
    return {
      loadId: load.load_id ? load.load_id : 0,
      dates: formattedDates,
      origin: load.pu_location ? load.pu_location : "",
      destination: load.del_location ? load.del_location : "",
      driver: `${load.driver_1_full_name} ${
        load.driver_2_full_name ? "-" : ""
      } ${load.driver_2_full_name ? load.driver_2_full_name : ""}`, // You can replace this with the driver's name if available in your data
      //vehicleId: load.vehicle_id,
      grossMargin: load.gross_margin
        ? `$${load.gross_margin.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : 0,
      rpm: load.rpm
        ? load.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      milesLoaded: load.loaded_mile ? load.loaded_miles.toLocaleString() : 0,
      revenue: load.load_revenue
        ? `$${load.load_revenue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : 0,
    };
  });
  const driversTableHeaders = {
    driverId: "Driver Id.",
    date: "Date",
    driver: "Driver",
    rpm: "RPM",
    milesLoaded: "Loaded Miles",
    loadsDelivered: "Delivered Loads",
    action: "Action",
  };
  // driversTableData
  const driversTableData = vehicleDriverOriginalData.map((item, index) => {
    const originalDateString = item.start_date ? item.start_date : "";
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).format("DD");
    const year = moment(originalDate).year();
    // const formattedDate = `${month} ${day}, ${year}`;
    const originalDateString2 = item.end_date && item.end_date;
    let originalDate2 = moment.utc(originalDateString2);
    const month2 = moment(originalDate2).format("MMM");
    const day2 = moment(originalDate2).format("DD");
    const year2 = moment(originalDate2).year();
    //const formattedDate2 = `${month2} ${day2}, ${year2}`;
    let formattedDate = `${month} ${day}`;
    let formattedDate2 = `${month2} ${day2}`;
    let formattedDates = "";
    //Check if month1 is not equal to month2 and day1 is not equal to day2
    if (month !== month2 && day !== day2) {
      formattedDates = `${month} ${day}${
        item.end_date ? `-${month2} ${day2}` : ""
      }, ${year}`;
    } else {
      // Check if both dates have the same month and day
      if (month === month2 && day === day2) {
        formattedDates = `${formattedDate} - ${formattedDate2}, ${year}`;
      } else {
        // Check if the years are different
        if (year !== year2) {
          formattedDates = `${formattedDate}, ${year}${
            item.end_date ? `-${formattedDate2}, ${year2}` : ""
          }`;
        } else {
          // Adjust the format based on different cases
          if (formattedDate === formattedDate2) {
            formattedDates = `${formattedDate}, ${year}`;
          } else if (day === day2) {
            formattedDates = `${formattedDate}${
              formattedDate2 ? `-${formattedDate2}` : ""
            }, ${year}`;
          } else if (month === month2) {
            formattedDates = `${month} ${day}-${day2}, ${year}`;
          }
        }
      }
    }
    return {
      driverId: item.driver_id ? item.driver_id : 0,
      date: `${formattedDates} ${item.end_date == null ? "- Present Day" : ""}`,
      endDate: item.end_date,
      driver: item.driver_name ? item.driver_name : "",
      rpm: item.rpm
        ? `$${item.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : 0,
      milesLoaded: item.loaded_miles ? item.loaded_miles.toLocaleString() : 0,
      loadsDelivered: item.loads_delivered
        ? item.loads_delivered.toLocaleString()
        : 0,
      //action: item.end_date === null ? null : item.end_date,
      action: "",
    };
  });
  // Access parameters from the URL
  const { vehicleId } = useParams();

  // Use the settlementDate and settelmentsUploadId as needed
  console.log("vehicleId", vehicleId);
  // setVehicleWeeklyDateRecieved(vehicleId);
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const handleCalendarTabChange = (tabId) => {
    setActiveCalendarTab(tabId);
  };

  const handleDriverModal = () => {
    console.log("hi", assignDriverModal);
    setAssignDriverModal(true);
  };

  const closeHandleDriverModal = () => {
    setAssignDriverModal(false);
  };

  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 422; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);

  const handleDateChange = (startDate, endDate) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    // Call getDetailsHeaderKPIDataAPI with final start and end dates
    getDetailsHeaderKPIDataAPI(startDate, endDate);
  };
  useEffect(() => {
    handleReturnVehicleLoads();
    getAllVehiclesDataAPI();
    getAllVehiclesProfileDataAPI();
    getAllVehiclesLoadsDataAPI();
    if (
      activeCalendarTab == 0 ||
      activeCalendarTab == 1 ||
      activeCalendarTab == 2
    ) {
      getDetailsHeaderKPIDataAPI();
    }
  }, [activeCalendarTab]);
  const getDetailsHeaderKPIDataAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    let currentStartDate = null;
    let currentEndDate = null;

    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0

    switch (activeCalendarTab) {
      case 0: // Current month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case 1: // Current quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case 2: // Current year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    const storedToken = localStorage.getItem("token");
    // fetch(
    //   `https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/vehicles/kpi?start_date=${finalStartDate}&end_date=${finalEndDate}&vehicle_id=${vehicleId}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //     },
    //   }
    // )
    fetch(
      `https://b2g3qcf43gczrgsrfuiqanyzzi0pgaaa.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/vehicles/kpi?start_date=${finalStartDate}&end_date=${finalEndDate}&vehicle_id=${vehicleId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    )
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          alert("Please check login credentials!");
        }
      })
      //getVehicleDetailsHeaderKPIData(finalStartDate, finalEndDate, vehicleId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("DetailsHeaderKPIData", response.data);
        console.log("DetailsHeaderKPIData1", response.data.gross_revenue);
        setGrossRevenue(
          response.data.gross_revenue ? response.data.gross_revenue : 0
        );
        setDriverPayments(
          response.data.driver_payments ? response.data.driver_payments : 0
        );
        setGrossMargin(
          response.data.gross_margin ? response.data.gross_margin : 0
        );
        setHeaderRPM(response.data.rpm ? response.data.rpm : 0);
        setMilesLoaded(
          response.data.miles_loaded ? response.data.miles_loaded : 0
        );
        setLoadsDelivered(
          response.data.loads_delivered ? response.data.loads_delivered : 0
        );
        // console.log("authUser2", loadCount);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getAllVehiclesDataAPI = () => {
    getAllVehiclesData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllVehiclesData", response.data);
        setVehiclesOriginalData(response.data ? response.data : []);
        const idArray = response.data.map((obj) =>
          obj.vehicle_id ? obj.vehicle_id : 0
        );
        console.log("settelmentsUploadId0", idArray);
        setVehiclesUploadId(idArray);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getAllVehiclesProfileDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getVehicleDetailsProfileData(vehicleId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllVehiclesProfileData", response.data);
        setMake(response.data.make ? response.data.make : "");
        setModel(response.data.model ? response.data.model : "");
        setType(response.data.type ? response.data.type : "");
        setYear(response.data.year ? response.data.year : "");
        setVinNumber(response.data.vin_number ? response.data.vin_number : "");
        setLicensePlateNumber(
          response.data.license_plate_number
            ? response.data.license_plate_number
            : ""
        );
        setStateRegistration(
          response.data.state_registration
            ? response.data.state_registration
            : ""
        );
        setColour(response.data.colour ? response.data.colour : "");
        setLoadsCompleted(
          response.data.loads_completed ? response.data.loads_completed : ""
        );
        setCurrentDriver(
          response.data.current_driver ? response.data.current_driver : ""
        );
        setMilesCovered(
          response.data.miles_covered ? response.data.miles_covered : ""
        );
        setDateInService(
          response.data.date_in_service ? response.data.date_in_service : ""
        );
        setVehicleDriverOriginalData(
          response.data.driverVehicles ? response.data.driverVehicles : []
        );
        const driversWithNullEndDate = response.data.driverVehicles.filter(
          (driver) => driver.end_date === null
        );
        const driversWithNullEndDateInfo = driversWithNullEndDate.map(
          (driver) => ({
            driver_id: driver.driver_id,
            driver_name: driver.driver_name,
          })
        );
        console.log("driversWithNullEndDateInfo", driversWithNullEndDateInfo);
        console.log(
          "driversWithNullEndDateInfo2",
          driversWithNullEndDateInfo.length
        );
        const driverId = response.data.driverVehicles[0].driver_id
          ? response.data.driverVehicles[0].driver_id
          : "";
        setDriverId(driverId);
        setDriverIdArray(driversWithNullEndDateInfo);
        const driverName = response.data.driverVehicles[0].driver_name
          ? response.data.driverVehicles[0].driver_name
          : "";
        setDriverName(driverName);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getAllVehiclesLoadsDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    getVehicleDetailsLoadsData(vehicleId)
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllVehiclesLoadsData", response.data);
        setVehicleLoadsOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  console.log("vehiclId1", vehicleId);
  const informationSection = {
    component: (
      <VehicleInformationPOC
        make={make}
        model={model}
        type={type}
        year={year}
        vinNumber={vinNumber}
        colour={colour}
        licensePlateNumber={licensePlateNumber}
        stateRegistration={stateRegistration}
        loadsCompleted={loadsCompleted}
        currentDriver={currentDriver}
        milesCovered={milesCovered}
        dateInService={dateInService}
        vehicleId={vehicleId}
      />
    ),
  };
  const [currentPageVehicleDetailsPOC, setCurrentPageVehicleDetailsPOC] =
    useState(1);
  const [itemsPerPageVehicleDetailsPOC, setItemsPerPageVehicleDetailsPOC] =
    useState(50);
  const handlePageLoadsChange = (pageNumber) => {
    setCurrentPageVehicleDetailsPOC(pageNumber);
  };
  const handleItemsPerPageLoadsChange = (perPage) => {
    setItemsPerPageVehicleDetailsPOC(perPage);
    setCurrentPageVehicleDetailsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateLoadsAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageVehicleDetailsPOC",
      currentPageVehicleDetailsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageVehicleDetailsPOC",
    //   itemsPerPageVehicleDetailsPOC
    // );
  };
  const handleReturnVehicleLoads = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageVehicleDetailsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageVehicleDetailsPOC"
    // );
    setCurrentPageVehicleDetailsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageVehicleDetailsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  const [assigned, setAssigned] = useState(false);
  const handleAssignedValue = (value) => {
    console.log("isVehicleAssigned6", value);
    setAssigned(value);
    toast("Driver is assigned.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleUnassignDriverStatus = (value) => {
    //  console.log("isVehicleAssigned6", value);
    //  setUpdated(value);
    toast("Driver is unassigned.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="details-page-design">
              <div className="detailsStickyHeaderVehicleDetails">
                {/* <Breadcrumbs items={breadcrumbItems} /> */}
                <div className="titleContainer">
                  {" "}
                  <div
                    className="backToDrivers"
                    style={{ display: "flex", width: "100%" }}
                  >
                    {" "}
                    <img src={backRightArrow} className="backRightArrow"></img>
                    <Link to="/vehicle" className="backLinkVehicleDetails">
                      Back
                    </Link>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <Title name={vehicleId} type={"page"} color={"#fff"} />
                  </div>
                </div>
                <div className="calenderChangeButtonContainer">
                  <CalenderChanger
                    activeTab={activeCalendarTab}
                    onMonthChange={handleDateChange}
                    onQuarterChange={handleDateChange}
                    onYearChange={handleDateChange}
                  />
                </div>
                <div className="stickyTabButtonsVehicleDetails">
                  <TabButtons
                    tabs={calendarTabsData}
                    onTabChange={handleCalendarTabChange}
                  />
                </div>
              </div>
              <div className="">
                <div
                  className="certificateSection"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="certificatesContainer">
                    <div className="serialNumbers">
                      <div className="driverNoVehicleDetails">{vinNumber}</div>
                      <div className="seperatorVehicleDetails">•</div>
                      <div className="truckNoVehicleDetails">
                        <img src={userIcon} />
                        {driverName}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "16px" }}>
                  <DetailsHeader data={driverData} />
                </div>
              </div>
              <div className="detailsTabButtonsVehicleDetails">
                <TabButtons tabs={tabsData} onTabChange={handleTabChange} />
              </div>
              <div>
                {/* <QuickActionsDropdown/> */}
                {/* <QuickActionsDropdown>
                    Drfiver Quick Actions
                  </QuickActionsDropdown> */}
              </div>
              {activeTab === 0 ? (
                // <VehicleProfilePOC />
                <>
                  <ProfilePOC sections={[informationSection]} />
                  <div className="tableContainer">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "24px 32px",
                      }}
                      className="tableTitleContainer"
                    >
                      {" "}
                      <div style={{ flex: "1" }}>
                        <Title
                          name={`Driver History (${driversTableData.length})`}
                          type={"table"}
                        />
                      </div>
                      <div style={{ flex: "" }}>
                        <div style={{ flex: "" }}>
                          {driverIdArray.length < 2 ? (
                            <Buttons
                              dimensions={"responsive"}
                              iconPosition={"left"}
                              buttonType={"secondary"}
                              size={"small"}
                              image={assignDriver}
                              onClick={handleDriverModal}
                              hover={false}
                              color={"#000000"}
                            >
                              Assign Driver
                            </Buttons>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="driversTableContainer"
                      style={{ maxHeight: `${maxTableHeight}px` }}
                    >
                      <DriversTablePOC
                        headers={driversTableHeaders}
                        data={driversTableData}
                        activeTab={activeTab}
                        driverName={driverName}
                        driverId={driverId}
                        driverIdArray={driverIdArray}
                        vehicleId={vehicleId}
                        tableName={"driversHistoryVehicles"}
                        paginationEnabled={false}
                        currentPage={currentPageVehicleDetailsPOC}
                        itemsPerPage={itemsPerPageVehicleDetailsPOC}
                        onPageChange={handlePageLoadsChange}
                        onItemsPerPageChange={handleItemsPerPageLoadsChange}
                        onNavigateAway={handleNavigateLoadsAway}
                        sendUnassignDriverStatus={handleUnassignDriverStatus}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="tableContainer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "24px",
                    }}
                    className="tableTitleContainer"
                  >
                    {" "}
                    <div style={{ flex: "1" }}>
                      {activeTab === 1 ? (
                        <Title
                          name={`Loads (${loadsTableData.length})`}
                          type={"table"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div style={{ flex: "" }} className="searchBox">
                        <img src={searchIcon} />
                        <input
                          type="text"
                          placeholder="Search load no."
                          className="searchTextStyle"
                          style={{
                            border: "none",
                            background: "#fcfcfc",
                            padding: "0",
                          }}
                        />
                      </div> */}
                  </div>
                  <div
                    className="driversTableContainer"
                    style={{
                      maxHeight: `${maxTableHeight}px`,
                      padding: "0 16px 0 16px",
                      marginBottom: "16px",
                    }}
                  >
                    {activeTab === 1 ? (
                      <div className="recentSettelmentsTableContainer">
                        <DriversTablePOC
                          headers={loadsTableHeaders}
                          data={loadsTableData}
                          activeTab={activeTab}
                          rowClick={true}
                          paginationEnabled={true}
                          currentPage={currentPageVehicleDetailsPOC}
                          itemsPerPage={itemsPerPageVehicleDetailsPOC}
                          onPageChange={handlePageLoadsChange}
                          onItemsPerPageChange={handleItemsPerPageLoadsChange}
                          onNavigateAway={handleNavigateLoadsAway}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Modal
          open={assignDriverModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          vehicleId={vehicleId}
          //disableBackdropClick
          style={{ overflowY: "scroll" }}
        >
          <AssignDriver
            onClose={closeHandleDriverModal}
            vehicleId={vehicleId}
            sendDriverAssignd={handleAssignedValue}
          />
        </Modal>
        <ToastContainer
          className={"toast-message"}
          toastStyle={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 12px 8px 8px",
            background: "rgba(0, 188, 249, 0.1)",
            borderRadius: "54px",
            color: "#00bfff",
            fontFamily: "Red Hat Display",
            fontSize: "14px",
            fontWeight: "600",
            //height: "36px",
            minHeight: "none",
          }}
          hideProgressBar={true}
          closeButton={<img src={toastCross} />}
          icon={<img src={toastIcon} />}
        />
      </Container>
    </React.Fragment>
  );
};

export default VehicleDetailsPOC;
