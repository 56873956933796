import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import closeAddDriver from "../../../../../../../assets/images/addDriver/closeAddDiver.svg";
import "./AssignDriver.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import Icon from "app/views/commonComponents/Icon/Icon";
import TablePOC from "../../../TablePOC/TablePOC";
import AssignDriverTable from "../AssignDriverTable/AssignDriverTable";
import { availableDrivers } from "app/apiServices/apiServices";
const AssignDriver = ({ onClose, vehicleId, sendDriverAssignd }) => {
  const [availableDriverOriginalData, setAvailableDriverOriginalData] =
    useState([]);
  const [isDriverAssigned, setIsDriverAssigned] = useState(false);
  const assignDriverAvailableHeaders = {
    //client_id: "Client Id.",
    //driver_classification: "Driver Classification",
    driver_id: "Driver Id",
    // preferred_name: "Preferred Name",
    //plan_id: "Plan Id",
    firstName: "First Name",
    // lastName: "Last Name",
    phone_number: "Number",
    home_address: "Home Address",
    initial_hire_date: "Hire Date",
    // email: "Email",
    AssignDriver: "AssignDriver",
  };
  const assignDriverTableData = [
    {
      driverId: "KH4235",
      coDriver: "-",
      make: "Volvo",
      type: "Tractor-Trailer",
      model: "Z-300R",
      year: "2013",
      action: "Assign",
    },
    {
      driverId: "KH4235",
      coDriver: "-",
      make: "Volvo",
      type: "Tractor-Trailer",
      model: "Z-300R",
      year: "2013",
      action: "Assign",
    },
    {
      driverId: "KH4235",
      coDriver: "Miles, Esther",
      make: "Volvo",
      type: "Tractor-Trailer",
      model: "Z-300R",
      year: "2013",
      action: "Assign",
    },
    {
      driverId: "KH4235",
      coDriver: "Miles, Esther",
      make: "Volvo",
      type: "Tractor-Trailer",
      model: "Z-300R",
      year: "2013",
      action: "Assign",
    },
    {
      driverId: "KH4235",
      coDriver: "Miles, Esther",
      make: "Volvo",
      type: "Tractor-Trailer",
      model: "Z-300R",
      year: "2013",
      action: "Assign",
    },
  ];

  useEffect(() => {
    availableDriverAPI();
  }, []);

  const availableDriverAPI = () => {
    const storedToken = localStorage.getItem("token");
    availableDrivers()
      .then((response) => {
        console.log("availableDriver", response.data);
        const formattedDriverData = response.data.map((item, index) => {
          return {
            // preferred_name: item.preferred_name,
            //plan_id: "Plan Id",
            driver_id: item.driver_id,
            firstName: item.firstName,
            // lastName: "Last Name",
            phone_number: item.phone_number,
            home_address: item.home_address,
            initial_hire_date: item.initial_hire_date,
            // email: item.email,
            AssignDriver: "AssignDriver",
          };
        });
        setAvailableDriverOriginalData(formattedDriverData);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleDriverAssign = (value) => {
    console.log("isDriverAssigned4", value);
    setIsDriverAssigned(value);
    sendDriverAssignd(value);
  };
  return (
    <Card className={"assignDriverCard"}>
      <CardBody>
        <div className="assignDriverContainer">
          <div className="assignDriverTitle" style={{ width: "100%" }}>
            <div className="assignDriverStyle">Assign Driver</div>
            <div>
              <Icon
                size="regular"
                buttonType="secondary"
                onMouseEnterImage={closeAddDriver}
                onMouseLeaveImage={closeAddDriver}
                imageReplace={true}
                onClick={onClose}
              ></Icon>
            </div>
          </div>
          <div
            className="assignDriverTableContainer"
            // style={{ maxWidth: "640px" }}
          >
            <AssignDriverTable
              headers={assignDriverAvailableHeaders}
              data={availableDriverOriginalData}
              vehicleId={vehicleId}
              tableName={"assignDriverTable"}
              onClose={onClose}
              onDriverAssign={handleDriverAssign}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AssignDriver;
