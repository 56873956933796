import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Container,
} from "reactstrap";
import { getAvailableVehiclesLoad } from "../../../../../../apiServices/apiServices";
import "./RecommendedVehicleDetails.scss";
import vehicleProfile from "assets/images/addLoad/vehicleProfile.svg";
import routing from "assets/images/addLoad/routing.svg";
import truck from "assets/images/addLoad/truck.svg";
import greenCircleTick from "assets/images/addLoad/greenCircleTick.svg";

const RecommendedVehicleDetails = ({
  driverName,
  driverDetails,
  vehicleDetails,
  borderBottom,
  checked,
  onSelect,
  billNumber,
}) => {
  //const [checked, setChecked] = useState(false);
  // const handleCheckBox = () => {
  //   setChecked(!checked);
  // };
  useEffect(() => {
    getAvailableVehiclesLoadAPI();
  }, []);
  const getAvailableVehiclesLoadAPI = () => {
    const loadId = billNumber;
    getAvailableVehiclesLoad(loadId)
      .then((response) => {
        console.log(
          "getAvailableVehiclesLoad",
          response.data ? response.data : []
        );
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <>
      <div
        className="recommendedVehicleSection"
        style={{ borderBottom: borderBottom }}
      >
        <img src={vehicleProfile} />
        <div className="detailsContainer">
          <div className="driverDetails">{driverName}</div>
          <div className="vehicleDetails">
            <div className="routeDetails">
              <img src={routing} />
              <div className="routeMiles">32 miles away</div>
            </div>
            <div className="dotContainer">•</div>
            <div className="routeDetails">
              <img src={truck} />
              <div className="routeMiles">{driverDetails}</div>
            </div>
            <div className="dotContainer">•</div>
            <div className="routeMiles">{vehicleDetails}</div>
          </div>
        </div>
        {!checked ? (
          <div className="checkBoxContainer" onClick={onSelect}></div>
        ) : (
          <div className="checkBoxContainer" onClick={onSelect}>
            <img
              src={greenCircleTick}
              style={{ height: "40px", width: "40px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default RecommendedVehicleDetails;
