import React, { useState, useEffect } from "react";
import Frame1 from "assets/images/login/Frame1.png";
import Frame2 from "assets/images/login/Frame2.png";
import Frame3 from "assets/images/login/Frame3.png";
import Frame4 from "assets/images/login/Frame4.png";
import Frame5 from "assets/images/login/Frame5.png";

const BackgroundContainer = ({ children }) => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const backgrounds = [Frame1, Frame2, Frame3, Frame4, Frame5];
  let lastRandomIndex = -1;
  useEffect(() => {
    // Shuffle the images when the component mounts
    shuffleImages();
  }, []);
  const shuffleImages = () => {
    let newIndex = lastRandomIndex;

    while (newIndex === lastRandomIndex) {
      newIndex = Math.floor(Math.random() * backgrounds.length);
    }
    lastRandomIndex = newIndex; // Update the last index reference
    setBackgroundIndex(newIndex);
  };
  return (
    <div
      className="container"
      style={{
        backgroundImage: `url(${backgrounds[backgroundIndex]})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        margin: "0",
        padding: "0",
      }}
    >
      {children}
    </div>
  );
};

export default BackgroundContainer;
