import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./UnassignDriver.scss";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import { unassignDriver } from "../../../../../apiServices/apiServices";
const UnassignDriver = ({
  onClose,
  driverId,
  vehicleId,
  driverName,
  onUnassignDriver,
  driverIdArray,
  unassignDriverId,
}) => {
  console.log("helloVehicle");
  console.log("vehicleIdUN1", vehicleId);
  console.log("vehicleIdUN2", driverId);
  console.log("vehicleIdUN3", driverName);
  console.log("vehicleIdUN4", driverIdArray);
  console.log("vehicleIdUN5", unassignDriverId);
  console.log("vehicleIdUN8", typeof unassignDriverId);
  const [isUnassignedDriver, setIsUnassignedDriver] = useState(false);
  const matchedDriver = driverIdArray.find(
    (driver) => driver.driver_id == unassignDriverId
  );
  console.log("vehicleIdUN9", matchedDriver);
  let matchedDriverId = "";
  let matchedDriverName = "";
  if (matchedDriver) {
    console.log("vehicleIdUN10", matchedDriver);
    matchedDriverId = matchedDriver.driver_id;
    matchedDriverName = matchedDriver.driver_name;

    console.log("vehicleIdUN6", matchedDriverId);
    console.log("vehicleIdUN7", matchedDriverName);
  }
  const removeDriver = (matchedDriverId) => {
    const storedToken = localStorage.getItem("token");

    const unassignDriverData = {
      driver_id: matchedDriverId,
      vehicle_id: vehicleId,
    };
    unassignDriver(unassignDriverData)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          setIsUnassignedDriver(true);
          handleUnassignedDriver(true);
          console.log("success unassigned");
          onClose();
        }
        //alert(response.message);
        // Automatically close the alert after 3 seconds
        setTimeout(() => {
          // Close the alert
          document.querySelector(".alert").style.display = "none";
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleUnassignedDriver = (value) => {
    onUnassignDriver(value);
  };
  return (
    <Card className={"unassignVehicleCard"}>
      <CardBody>
        <div className="unassignVehicleContainer">
          <div className="unassignVehicleTitleMessage">
            <div className="unassignVehicleTitle">
              <div className="unassignVehicleStyle">Are you sure?</div>
            </div>
            <div className="unassignVehicleMessage">
              You want to unassign driver{" "}
              <p className="unassignVehicleNumber">
                {(matchedDriverName ? matchedDriverName : "")
                  .replace(",", "")
                  .split(" ")
                  .reverse()
                  .join(" ")}{" "}
              </p>
              with {vehicleId} ?
            </div>
          </div>
          <div className="removeSection">
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              onClick={onClose}
              imageReplace={false}
            >
              No, Don’t Remove
            </Buttons>
            <Buttons
              size={"regular"}
              dimensions={"responsive"}
              buttonType={"secondary"}
              onClick={() => {
                removeDriver(matchedDriverId);
                onClose();
              }}
              imageReplace={false}
            >
              Yes Remove
            </Buttons>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UnassignDriver;
