import React from "react";
import "./Title.scss"; // Use a common SCSS file for styling
const Title = ({
  name,
  type,
  fontSize, //page or table or card
  color,
}) => {
  return type === "page" ? (
    <p className="page-title" style={{ fontSize: fontSize, color: color }}>
      {name}
    </p>
  ) : type === "table" ? (
    <p className="table-title" style={{ fontSize: fontSize }}>
      {name}
    </p>
  ) : type === "card" ? (
    <p className="card-title" style={{ fontSize: fontSize }}>
      {name}
    </p>
  ) : (
    ""
  );
};
export default Title;
