import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import checkMark from "../../../../../../assets/images/login/checkMark.svg";
import warningMark from "../../../../../../assets/images/login/warningMark.svg";
import { Link } from "react-router-dom";
import "./OTPInputs.scss";
import Loader from "react-js-loader";
import { HashSpinner } from "app/views/commonComponents/Spinners/Spinners";
const OTPInputs = ({ expectedOTP }) => {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  //const [otp, setOTP] = useState(["1", "1", "1", "1", "1", "1"]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [otpError, setOTPError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(599); // 9 minutes 59 seconds in seconds
  const [attemptsRemaining, setAttemptsRemaining] = useState(3); // Number of attempts allowed
  const [otpDeliveryMethod, setOtpDeliveryMethod] = useState("phone"); // Assuming OTP sent to phone initially
  useEffect(() => {
    const isAllDigitsEntered = otp.every((digit) => digit !== "");
    setIsLoading(isAllDigitsEntered);
    if (isAllDigitsEntered) {
      // Check if entered OTP matches the expected OTP
      const enteredOTP = otp.join("");
      setIsValidOTP(enteredOTP === expectedOTP);
      if (isValidOTP == false) {
        setOTPError(true);
      }
    }
  }, [otp, expectedOTP]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  // ... rest of your component code ...
  const handleResendCode = () => {
    // Implement your logic to resend the code here
    console.log("Resending code...");
    // You can add code here to trigger a resend action
  };
  const handleNoAccess = () => {
    setOtpDeliveryMethod((prevMethod) =>
      prevMethod === "phone" ? "email" : "phone"
    );
  };
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    // Handle pasted OTP data here, maybe split and assign to state accordingly
    // Example logic to split the pasted data into individual digits and update state
  };
  const handleOTPChange = (e, index) => {
    // Handle individual OTP input change and update state accordingly
    const digit = e.target.value;
    // Check if the pressed key is the backspace key
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const newOTP = [...otp];
      newOTP[index] = ""; // Clear the digit at the current index
      setOTP(newOTP);
      // If the value is deleted, focus on the previous input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      return;
    }
    // If the entered value is not a digit or if more than one character is entered, don't update the state
    if (!/^\d$/.test(digit)) {
      return;
    }
    const newOTP = [...otp];
    newOTP[index] = e.target.value;
    setOTP(newOTP);
    // You might want to focus on the next input after entering a digit
    // Example logic to focus on the next input after entering a digit
    if (e.target.value === "") {
      // If the value is deleted, focus on the previous input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else {
      // Focus on the next input after entering a digit
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
    if (isValidOTP && attemptsRemaining > 0) {
      // Reset attempts if the OTP was correct previously
      setAttemptsRemaining(3);
    }
    if (otpError && !isValidOTP && attemptsRemaining === 1) {
      // If attempts reach 0, handle the scenario (e.g., prevent further inputs, show error message)
    } else if (
      otpError &&
      !isValidOTP &&
      attemptsRemaining > 0 &&
      e.target.value !== ""
    ) {
      // Decrement attempts remaining on incorrect OTP entry
      setAttemptsRemaining(attemptsRemaining - 1);
    }
  };
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row xl={12} md={12} sm={12} xs={12}>
          <Col xl={12} md={12} sm={12} xs={12}>
            <div className="otp-details">
              <div className="otp-heading">We need to verify your login.</div>
              {isValidOTP ? (
                <div className="otp-message">
                  A message with a verification code was sent to your email.
                  <br />
                  Please enter the code below to continue.
                </div>
              ) : isLoading ? (
                <div className="otp-message">
                  Looks like you entered the incorrect code.
                  <br />
                  Please try again.
                </div>
              ) : (
                <div className="otp-message">
                  A message with a verification code was sent to your{" "}
                  {otpDeliveryMethod}.
                  <br />
                  Please enter the code below to continue.
                </div>
              )}
              <div className="otp-inputs-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="number"
                    maxLength={1}
                    className="otp-input"
                    value={digit}
                    onChange={(e) => handleOTPChange(e, index)}
                    onPaste={handleOTPPaste}
                    style={{ marginLeft: index == 0 ? "0" : "11px" }}
                    //disabled={disableInput} // Disable input if max attempts reached
                  />
                ))}
              </div>
              {/* Loader when all digits are entered */}
              {isLoading ? (
                isValidOTP ? (
                  <div className="otp-verification">
                    <img src={checkMark} />
                  </div>
                ) : !isValidOTP ? (
                  <div className="otp-verification">
                    <img src={warningMark} />
                  </div>
                ) : (
                  <div className="otp-loader">
                    {/* <Loader
                      type="spinner-circle"
                      bgColor={""}
                      color={"#FFFFFF"}
                      // title={"spinner-circle"}
                      size={100}
                    /> */}
                    {/* <HashSpinner /> */}
                  </div>
                )
              ) : (
                <>
                  <div>
                    <div
                      className="otp-message"
                      style={{
                        marginBottom:
                          otpError && !isValidOTP && attemptsRemaining > 0
                            ? "0"
                            : "40px",
                      }}
                    >
                      OTP expires in {formattedTime}
                    </div>
                    {otpError && !isValidOTP && attemptsRemaining > 0 && (
                      <div className="otp-message">
                        {`${attemptsRemaining} attempts remaining`}
                      </div>
                    )}
                  </div>
                  <div className="resendCode">
                    Didn’t receive the verification code?
                    <Button onClick={handleResendCode} className="resendButton">
                      RESEND CODE
                    </Button>
                  </div>
                  <div
                    className="resendCode"
                    style={{ marginLeft: "0", marginBottom: "0" }}
                  >
                    {otpDeliveryMethod === "phone" && (
                      <>
                        Don’t have access to your phone?
                        <Button
                          onClick={handleNoAccess}
                          className="resendButton"
                        >
                          <b>EMAIL</b>
                        </Button>
                      </>
                    )}
                    {otpDeliveryMethod === "email" && (
                      <>
                        Don’t have access to your email?
                        <Button
                          onClick={handleNoAccess}
                          className="resendButton"
                        >
                          <b>PHONE</b>
                        </Button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default OTPInputs;
