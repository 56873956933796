import { UserGraphQLService } from 'app/services/graphql';
import get from 'lodash/get';
import { store } from 'app/store';
import gql from 'graphql-tag';
export const COMPLETE_USER_TASK = gql`
  mutation COMPLETE_TASK($taskId: String!, $processVariables: JSON!) {
    completeTask(
      input: { task_id: $taskId, process_variables: $processVariables }
    ) {
      status
    }
  }
`;

export const startMockProcess = () => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res();
    }, 3000);
  });
};

export const completeTask = async (task_name, variables) => {
  const tasks = get(store.getState(), `notification.userTasks`, {});
  const taskId = tasks[task_name] ? tasks[task_name].id : null;
  if (taskId) {
    try {
      console.info(
        `[workflow] trying to complete ${task_name} with id ${taskId}`,
      );
      const res = await UserGraphQLService(
        COMPLETE_USER_TASK,
        { taskId: taskId, processVariables: variables },
        true,
      );
      console.info(`[workflow] completed ${task_name} with id ${taskId}`);
      return res;
    } catch (err) {
      console.error(
        `[workflow] error completing ${task_name} with id ${taskId}`,
      );
      throw new Error(err[0] ? err[0] : err);
    }
  } else {
    console.info(
      `[workflow] no such task id found for task ${task_name} or is already completed`,
    );
    return {isTaskAlreadyComplete:true};
  }
};
