import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Modal,
  TablePagination,
  Snackbar,
} from "@material-ui/core";
import "./AssignDriverTable.scss";
import TableData from "app/views/commonComponents/Table/TableCell/TableData";
import assignDriverIcon from "../../../../../../../assets/images/poc/assignDriver.svg";
import { useHistory } from "react-router-dom";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import { assignDriver } from "../../../../../../apiServices/apiServices";
const AssignDriverTable = ({
  headers,
  data,
  activeTab,
  tableName,
  subHeaders,
  subData,
  onClose,
  settlementsUploadId,
  rowClick,
  settlementDate,
  uploadId,
  driverId,
  vehicleId,
  fullDriverName,
  pagination,
  paginationEnabled = false,
  onDriverAssign,
  // handleMenuClick,
  // optionsMenuOpen,
}) => {
  const history = useHistory(); // Initialize useHistory hook
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [showAssignTruck, setShowAssignTruck] = useState(false);
  const [showUnassignTruck, setShowUnassignTruck] = useState(false);
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [selectedOptionsMenuItem, setSelectedOptionsMenuItem] = useState(null);
  const menuList = [
    { item: "Review", icon: "" },
    { item: "Paid", icon: "" },
  ];
  const [sortBy, setSortBy] = useState(""); // Initialize sortBy to the default column name
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedDriverData, setSortedDriverData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [showAssignDriver, setShowAssignDriver] = useState(false);
  const [showUnassignDriver, setShowunassignDriver] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isDriverAssigned, setIsDriverAssigned] = useState(false);
  const assignDriverService = (rowData) => {
    const storedToken = localStorage.getItem("token");
    const assignDriverData = {
      driver_id: rowData.driver_id,
      vehicle_id: vehicleId,
    };
    // fetch(
    //   "https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/vehicle_drivers/assign",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       driver_id: rowData.driver_id,
    //       vehicle_id: vehicleId,
    //       // start_date: rowData.initial_hire_date,
    //     }),
    //   }
    // )
    //   .then((result) => {
    //     console.log("res", result);
    //     if (result.status == 200 || result.status == 201) {
    //       setIsDriverAssigned(true);
    //       console.log("success");
    //       onClose();
    //       return result.json();
    //     } else {
    //       alert("Please check login credentials!");
    //     }
    //   })
    assignDriver(assignDriverData)
      .then((response) => {
        //alert(response.message);
        if (response.status == 200 || response.status == 201) {
          setIsDriverAssigned(true);
          console.log("success");
          onClose();
        }
        console.log("AssignDriverTable2");
        // Automatically close the alert after 3 seconds
        setTimeout(() => {
          // Close the alert
          document.querySelector(".alert").style.display = "none";
        }, 1000);
        setTimeout(() => {
          // Close the alert
          window.location.reload();
        }, 1200);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleAssignDriver = (rowData, event) => {
    console.log("params", rowData);
    assignDriverService(rowData);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleItemsPerPageChange = (event) => {
    //if (pagination && tableName == "loadsDetailsTable") {
    setItemsPerPage(parseInt(event.target.value));
    //setCurrentPage(1); // Reset to first page when changing items per page}
    // }
  };
  useEffect(() => {
    if (isDriverAssigned) {
      // Call the callback function passed from the parent
      console.log("isDriverAssigned3", isDriverAssigned);
      onDriverAssign(isDriverAssigned);
    }
  }, [isDriverAssigned, onDriverAssign]);
  console.log("isDriverAssigned", isDriverAssigned);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    sortedDriverData || data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div>
        <Table style={{ width: "100%" }}>
          <TableHead className={"tableStickyHeader"} style={{ width: "100%" }}>
            <TableRow>
              {Object.values(headers).map((header, index) => (
                <>
                  <TableData
                    key={index}
                    cellType={"headerCell"}
                    size={"regular"}
                    style={{
                      cursor: "pointer",
                      borderTopLeftRadius: index == 0 ? "8px" : "",
                      borderTopRightRadius:
                        index == headers.length ? "8px" : "",
                    }}
                  >
                    {header}
                  </TableData>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.length > 0 ? (
              currentItems.map((row, rowIndex) => (
                <>
                  <TableRow
                    key={rowIndex}
                    style={{
                      borderBottom:
                        rowIndex === data.length - 1
                          ? "0"
                          : "1px solid var(--T---LT-Gray, #ECEEF1)",
                    }}
                    className="driver-table"
                  >
                    {Object.entries(row).map(([key, cell], cellIndex) => (
                      <TableData
                        key={cellIndex}
                        cellType={"bodyCell"}
                        size={"large"}
                      >
                        {key === "uploadId" ||
                        key === "newSettlementDate" ||
                        key === "newUploadId" ? (
                          ""
                        ) : key === "totalOtherRevenue" ||
                          key === "totalDeduction" ? (
                          (() => {
                            const stringValue = cell;
                            const [part1, part2] = stringValue
                              .split("•")
                              .map((part) => part.trim());
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  //gap: "15px",
                                }}
                              >
                                <div
                                  style={{
                                    textAlign: "right",
                                    // flex: "3"
                                  }}
                                >
                                  {part1}
                                </div>
                                {tableName === "settelmentsPOC" ||
                                tableName === "driversSettelments" ? (
                                  ""
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      //gap: "10px",
                                      flex: "1",
                                    }}
                                  >
                                    <div style={{ textAlign: "" }}>•</div>
                                    <div style={{ textAlign: "" }}>{part2}</div>
                                  </div>
                                )}
                              </div>
                            );
                          })()
                        ) : key === "gross" ? (
                          <img src={cell} alt="Image" />
                        ) : cell === "AssignDriver" ? (
                          <Buttons
                            size={"small"}
                            dimensions={"normal"}
                            buttonType={"secondary"}
                            iconPosition={"left"}
                            image={assignDriverIcon}
                            width={"auto"}
                            onClick={(event) => handleAssignDriver(row, event)}
                            hover={false}
                            color={"#000000"}
                          >
                            Assign
                          </Buttons>
                        ) : (
                          cell
                        )}
                      </TableData>
                    ))}
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableData
                  colSpan={1}
                  size="large"
                  style={{ textAlign: "center" }}
                >
                  No records found
                </TableData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default AssignDriverTable;
