import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
import "./DetailsHeader.scss";
const DetailsBox = ({
  icon,
  values,
  title,
  keyName,
  borderLeft,
  background,
  inputBackground,
  editable,
  onValueChange,
  placeholder,
}) => {
  const [editableValue, setEditableValue] = useState(values);
  const handleValueChange = (e) => {
    let newValue = e.target.value;
    if (keyName === "billNumber") {
      // Convert to uppercase and truncate to 10 characters
      newValue = newValue.toUpperCase().slice(0, 10);
    }
    if (keyName === "statementId") {
      // Convert to uppercase and truncate to 10 characters
      newValue = newValue.toUpperCase().slice(0, 9);
    }
    setEditableValue(newValue);
    onValueChange(keyName, newValue); // Pass the updated value to the parent component
  };
  return (
    <div className="details-box" style={{ borderLeft, background }}>
      {icon && (
        <div className="iconContainer">
          <img src={icon} alt={title} />
        </div>
      )}
      <div className="valueHeaderContainer">
        <div className="title-value">
          <input
            type={
              editable == true
                ? keyName == "billNumber" || keyName == "statementId"
                  ? "text"
                  : "number"
                : "text"
            }
            style={{ background: inputBackground }}
            className={"valueHeader"}
            value={editable ? editableValue : values}
            onChange={handleValueChange}
            placeholder={placeholder ? placeholder : ""}
            readOnly={!editable}
          />
        </div>
        <div className="titleHeader">{title}</div>
      </div>
    </div>
  );
};

const DetailsHeader = ({
  data,
  background,
  inputBackground,
  onValueChange,
  pageName,
}) => {
  return (
    <div
      className="driver-header"
      style={{
        background: background ? background : "#fff",
        borderBottomLeftRadius: pageName == "Settelments" ? "0" : "12px",
        borderBottomRightRadius: pageName == "Settelments" ? "0" : "12px",
      }}
    >
      {data.map((item, index) => (
        <DetailsBox
          key={index}
          icon={item.icon}
          values={item.values}
          keyName={item.key || ""}
          title={item.title}
          borderLeft={index > 0 ? "1px solid var(--Gray-06, #E3E5E8)" : "none"}
          background={background}
          inputBackground={inputBackground}
          editable={item.editable}
          onValueChange={onValueChange}
          placeholder={item.placeholder}
        />
      ))}
    </div>
  );
};

export default DetailsHeader;
