import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./VehiclePOC.scss";
import Sidebar from "app/views/commonComponents/Sidebar/Sidebar";
import { Container } from "reactstrap";
import Breadcrumbs from "app/views/commonComponents/Breadcrumb/Breadcrumbs";
import Title from "app/views/commonComponents/Title/Title";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import driverProfile from "../../../../../../assets/images/drivers/driverProfile.svg";
import accidents from "../../../../../../assets/images/drivers/accidents.svg";
import calendarRemove from "../../../../../../assets/images/drivers/calendarRemove.svg";
import moneys from "../../../../../../assets/images/drivers/moneys.svg";
import presentionChart from "../../../../../../assets/images/drivers/presentionChart.svg";
import star from "../../../../../../assets/images/drivers/star.svg";
import userTag from "../../../../../../assets/images/drivers/userTag.svg";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTablePOC from "../../TablePOC/TablePOC";
//import DriversFilterButton from "../DriversFilterButton/DriversFilterButton";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import addIcon from "../../../../../../assets/images/poc/addIcon.svg";
import searchIcon from "../../../../../../assets/images/poc/searchIcon.svg";
import helloImage from "../../../../../../assets/images/poc/helloImage.png";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import { getAllVehiclesData } from "app/apiServices/apiServices";
import HeaderSection from "app/views/commonComponents/HeaderSection/HeaderSection";
const VehiclePOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTabId, setActiveCalendarTabId] = useState(0);
  const [activeCalendarTabLabel, setActiveCalendarTabLabel] = useState("Month");
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [isDriversFilterOpen, setIsDriversFilterOpen] = useState(false);
  const [vehiclesOriginalData, setVehiclesOriginalData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("Jan 2024");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];

  const driverData = [
    { icon: driverProfile, values: "21,242", title: "Gross Margin" },
    //{ icon: driverProfile, values: "21,242", title: "Total Drivers" },
    { icon: userTag, values: "23,435", title: "RPM" },
    { icon: star, values: "4.3", title: "Loaded Miles" },
    { icon: star, values: "4.3", title: "Delivered Loads" },
    //{ icon: accidents, values: "72", title: "Loads" },
    // { icon: calendarRemove, values: "13", title: "Near Expirations" },
    // { icon: moneys, values: "$3.54", title: "Avg. RPM" },
    // { icon: presentionChart, values: "86.3%", title: "Avg. RPM" },
    // Add more data items as needed
  ];
  const calendarTabsData = [
    { id: 0, label: "Month" },
    { id: 1, label: "Quarter" },
    { id: 2, label: "Year" },
    // Add more tabs as needed
  ];
  const vehicleTableHeaders = {
    vehicleNo: "Vehicle No.",
    year: "Year",
    make: "Make",
    model: "Model",
    type: "Type",
    driver: "Driver(s)",
    loadsDelivered: "Delivered Loads",
    milesLoaded: "Loaded Miles",
    rpm: "RPM",
    grossMargin: "Gross Margin",
    netProfit: "Net Profit",
  };
  const vehicleTableData = vehiclesOriginalData.map((vehicle) => {
    const truncatedDriverName1 =
      vehicle.driver_1_name && vehicle.driver_1_name.length > 20
        ? vehicle.driver_1_name.substring(0, 20) + "..."
        : vehicle.driver_1_name;
    const truncatedDriverName2 =
      vehicle.driver_1_name && vehicle.driver_2_name.length > 20
        ? vehicle.driver_2_name.substring(0, 20) + "..."
        : vehicle.driver_2_name;
    return {
      vehicleId: vehicle.vehicle_id ? vehicle.vehicle_id : 0,
      year: vehicle.year ? vehicle.year : "",
      // Replace this with the driver's name if available
      make: vehicle.make ? vehicle.make : "",
      model: vehicle.model ? vehicle.model : "",
      type: vehicle.type ? vehicle.type : "",
      driver: `${truncatedDriverName1} ${
        vehicle.driver_2_name ? "&" : ""
      } ${truncatedDriverName2}`,
      loadsDelivered: vehicle.loads_delivered
        ? vehicle.loads_delivered.toLocaleString()
        : 0,
      milesLoaded: vehicle.loaded_miles
        ? vehicle.loaded_miles.toLocaleString()
        : 0,
      rpm: vehicle.rpm
        ? `$${vehicle.rpm.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      grossMargin: vehicle.gross_margin
        ? `$${vehicle.gross_margin.toLocaleString("en-Us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      netProfit: vehicle.company_revenue
        ? `$${vehicle.company_revenue.toLocaleString("en-Us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
    };
  });
  const handleTabChange = (tabId, tabLabel) => {
    setActiveCalendarTabId(tabId);
    setActiveCalendarTabLabel(tabLabel);
  };
  useEffect(() => {
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 240; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    handleReturnVehiclePOC();
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");

    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }

    getAllVehiclesDataAPI();
  }, []);
  const getAllVehiclesDataAPI = () => {
    const storedToken = localStorage.getItem("token");
    // fetch(
    //   `https://axy2yyqxzng2tllot7fdch4suu0vrift.lambda-url.us-east-1.on.aws/fdcc-settlements/v1/admin/vehicles?start_date=${startDate}&end_date=${endDate}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${storedToken}`,
    //     },
    //   }
    // )
    //   //?start_date=${formattedStartDate}&end_date=${formattedEndDate}
    //   .then((result) => {
    //     if (result.status === 200) {
    //       return result.json();
    //     } else {
    //       alert("Please check login credentials!");
    //     }
    //   })
    getAllVehiclesData()
      .then((response) => {
        //console.log("insideif", response);
        console.log("getAllVehiclesData", response.data);
        setVehiclesOriginalData(response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageVehiclePOC, setCurrentPageVehiclePOC] = useState(1);
  const [itemsPerPageVehiclePOC, setItemsPerPageVehiclePOC] = useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageVehiclePOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageVehiclePOC(perPage);
    setCurrentPageVehiclePOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem("currentPageVehiclePOC", currentPageVehiclePOC);
    //localStorage.setItem("itemsPerPageVehiclePOC", itemsPerPageVehiclePOC);
  };
  const handleReturnVehiclePOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageVehiclePOC");
    //const savedItemsPerPage = localStorage.getItem("itemsPerPageVehiclePOC");
    setCurrentPageVehiclePOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageVehiclePOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  return (
    <React.Fragment>
      <Container fluid>
        <div className="main-page-design">
          <Row xl={12} md={12} sm={12} xs={12}>
            <Col xl={12} md={12} sm={12} xs={12}>
              <div className="stickyRowVehicle">
                <HeaderSection title={"Vehicles"}></HeaderSection>
                {/* <div className="calenderChangeButtonContainer">
                    <CalenderChanger 
                    activeTabLabel={activeCalendarTabLabel}
                    activeTabId={activeCalendarTabId}
                        onMonthChange={handleDateChange}
                        onQuarterChange={handleDateChange}
                        onYearChange={handleDateChange}/>
                  </div> */}
                {/* <div className="stickyTabButtonsVehicles">
                    <TabButtons
                        tabs={calendarTabsData}
                        onTabChange={handleTabChange}
                      />
                  </div> */}
                <div style={{ marginTop: "24px" }}>
                  {/* <DetailsHeader data={driverData} /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "24px",
                  }}
                  className="tableTitleContainerVehicle"
                >
                  {" "}
                  <div style={{ flex: "1" }}>
                    <Title
                      name={`Current Vehicles (${vehiclesOriginalData.length})`}
                      type={"table"}
                    />
                  </div>
                  {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search vehicle no."
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                </div>
              </div>
              <div className="tableContainerVehicle">
                <div
                  className="driversTableContainerVehicle"
                  style={{
                    maxHeight: `${maxTableHeight}px`,
                    padding: "0 16px 0 16px",
                    marginBottom: "16px",
                  }}
                >
                  <div className="recentSettelmentsTableContainer">
                    <DriversTablePOC
                      headers={vehicleTableHeaders}
                      data={vehicleTableData}
                      rowClick={true}
                      tableName={"vehicleDataDetailsTable"}
                      paginationEnabled={true}
                      currentPage={currentPageVehiclePOC}
                      itemsPerPage={itemsPerPageVehiclePOC}
                      onPageChange={handlePageChange}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onNavigateAway={handleNavigateAway}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default VehiclePOC;
