import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Container,
} from "reactstrap";
import "./RecommendedVehicle.scss";
import searchIcon from "assets/images/poc/searchIcon.svg";
import getDriverDetails from "assets/images/addLoad/getDriverDetails.svg";
import RecommendedVehicleDetails from "../RecommendedVehicleDetails/RecommendedVehicleDetails";
const RecommendedVehicle = () => {
  const [showRecommendedVehicle, setShowRecommendedVehicle] = useState(false);
  const [selectedDriverIndex, setSelectedDriverIndex] = useState(null);

  const handleSelectDriver = (index) => {
    setSelectedDriverIndex(index);
  };
  return (
    <>
      <div className="recommendVehicleContainer">
        <div className="titleSearchContainer">
          <div className="titleContainer">Recommended Vehicle</div>
          <div className="searchContainer">
            <img src={searchIcon} />
            <input
              type="text"
              placeholder="Search Driver"
              className="searchTextStyle"
              style={{
                border: "none",
                background: "#fcfcfc",
                padding: "0",
              }}
            />
          </div>
        </div>
        {!showRecommendedVehicle ? (
          <>
            <div className="imageVehicleSection">
              {/* <div className="imageVehicleSection"></div> */}
            </div>
            <div className="imageMessageSection">
              <div
                className="imageConatiner"
                onClick={() => {
                  setShowRecommendedVehicle(!showRecommendedVehicle);
                }}
              >
                <img src={getDriverDetails} />
              </div>
              <div className="messageContainer">
                Add route details to get the available driver’s list
              </div>
            </div>
            <div className="imageVehicleSection">
              {/* <div className="imageVehicleSection"></div> */}
            </div>
          </>
        ) : (
          <>
            {[1, 2, 3].map((index) => (
              <RecommendedVehicleDetails
                key={index}
                driverName="Dianne Russell - Nayan Pansara"
                driverDetails="VE23454"
                vehicleDetails="Volvo xmr242 - 2015"
                borderBottom={index == 3 ? "" : "1px solid #e3e5e8"}
                checked={selectedDriverIndex === index}
                onSelect={() => handleSelectDriver(index)}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};
export default RecommendedVehicle;
