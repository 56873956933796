import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
  Container,
} from "reactstrap";
import "./RoadMap.scss";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import tt from "@tomtom-international/web-sdk-maps";
import {
  services,
  calculateRoute,
} from "@tomtom-international/web-sdk-services";
import moment from "moment";
const RoadMap = ({ stopsData, latitude, longitude, onDistanceAndTime }) => {
  const [originCity, setOriginCity] = useState("");
  const [originState, setOriginState] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [originDate, setOriginDate] = useState("");
  const [destinationDate, setDestinationDate] = useState("");
  const [originTime, setOriginTime] = useState("");
  const [destinationTime, setDestinationTime] = useState("");
  const [distanceAndTime, setDistanceAndTime] = useState([]);
  const [totalTravelTime, setTotalTravelTime] = useState(0);
  const newLatitude = latitude;
  console.log("RoadMaplatitude", newLatitude);
  const newLongitude = longitude;
  console.log("RoadMaplongitude", newLongitude);
  console.log("coordinates", latitude, longitude);
  const apiKey = "0JGXcL7muZmxjGKs58GL2qudVSeoGUzQ";
  //console.log("RoadMapStopsData", stopsData);
  //const coordinates = [-122.4194, 37.7749];
  //const coordinates = [-97.0929, 38.6301];
  // const coordinates = [-121.91599, 37.36765];
  // const coordinates = [newLatitude, newLongitude];
  //const coordinates = [119.4179, 36.7783];
  const coordinates = [longitude, latitude];
  const [map, setMap] = useState(null);
  const [routeLayer, setRouteLayer] = useState(null);
  const mapRef = useRef();
  const AVERAGE_SPEED_MPH = 60;
  // useEffect(() => {
  //   const map = tt.map({
  //     key: apiKey,
  //     container: "map-container",
  //     center: coordinates,
  //     zoom: 13,
  //   });

  //   // Add controls (optional)
  //   map.addControl(new tt.NavigationControl());

  //   // Add markers or other elements to the map (optional)
  //   const marker = new tt.Marker().setLngLat([longitude, latitude]).addTo(map);
  //   // Make sure to clean up the map instance when component unmounts
  //   return () => {
  //     map.remove();
  //   };
  // }, [latitude, longitude]);
  // Orinin - Destination City and States
  useEffect(() => {
    console.log("stopsData", stopsData);
    const originStop = stopsData[0];
    const destinationStop =
      stopsData.length >= 2 ? stopsData[stopsData.length - 1] : "";
    console.log("stopsData1", originStop);
    console.log("stopsData2", originStop);
    // Extract city and state from first and last stop
    const originCity = originStop.city;
    const originState = originStop.state;
    const originDate = originStop.arrivalDate;
    const originTime = originStop.etaSelection;
    const destinationCity = destinationStop.city;
    const destinationState = destinationStop.state;
    const destinationDate = destinationStop.arrivalDate
      ? destinationStop.arrivalDate
      : "";
    const destinationTime = destinationStop.etaSelection
      ? destinationStop.etaSelection
      : "";
    // Function to abbreviate state name
    const abbreviateState = (state) => {
      const states = {
        Alabama: "AL",
        Alaska: "AK",
        Arizona: "AZ",
        Arkansas: "AR",
        California: "CA",
        Colorado: "CO",
        Connecticut: "CT",
        Delaware: "DE",
        Florida: "FL",
        Georgia: "GA",
        Hawaii: "HI",
        Idaho: "ID",
        Illinois: "IL",
        Indiana: "IN",
        Iowa: "IA",
        Kansas: "KS",
        Kentucky: "KY",
        Louisiana: "LA",
        Maine: "ME",
        Maryland: "MD",
        Massachusetts: "MA",
        Michigan: "MI",
        Minnesota: "MN",
        Mississippi: "MS",
        Missouri: "MO",
        Montana: "MT",
        Nebraska: "NE",
        Nevada: "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        Ohio: "OH",
        Oklahoma: "OK",
        Oregon: "OR",
        Pennsylvania: "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        Tennessee: "TN",
        Texas: "TX",
        Utah: "UT",
        Vermont: "VT",
        Virginia: "VA",
        Washington: "WA",
        "West Virginia": "WV",
        Wisconsin: "WI",
        Wyoming: "WY",
      };
      return states[state] || state; // Return abbreviation if found, otherwise return original state
    };
    function extractDate(dateString) {
      return dateString && dateString.match(/[A-Za-z]+\s\d{2}/)[0];
    }
    const abbreviatedOriginState = abbreviateState(originState);
    const abbreviatedDestinationState = abbreviateState(destinationState);
    const extractedOriginDate = extractDate(originDate);
    const extractedDestinationDate = extractDate(destinationDate);
    setOriginCity(originCity);
    setOriginState(abbreviatedOriginState);
    setOriginDate(extractedOriginDate);
    setOriginTime(originTime);
    setDestinationCity(destinationCity);
    setDestinationState(abbreviatedDestinationState);
    setDestinationDate(extractedDestinationDate);
    setDestinationTime(destinationTime);
    console.log(
      "stopsData3",
      originCity,
      abbreviatedOriginState,
      extractedOriginDate,
      originTime
    );
    console.log(
      "stopsData4",
      destinationCity,
      abbreviatedDestinationState,
      extractedDestinationDate,
      destinationTime
    );
  }, [stopsData]);
  //Distance and Time Calculation
  useEffect(() => {
    if (stopsData.length < 2) return;

    // Calculate distance and time for each pair of consecutive stops
    const calculateDistanceAndTime = async () => {
      const distanceAndTime = [];

      for (let i = 0; i < stopsData.length - 1; i++) {
        const originStop = stopsData[i];
        const destinationStop = stopsData[i + 1];

        // Calculate route between two consecutive stops
        const response = await services.calculateRoute({
          key: apiKey,
          locations: [
            { lng: originStop.longitude, lat: originStop.latitude },
            { lng: destinationStop.longitude, lat: destinationStop.latitude },
          ],
        });

        const route = response.routes[0];
        const summary = route.summary;
        console.log("distanceAndTimesummary1", summary.lengthInMeters);
        console.log("distanceAndTimesummary2", summary.travelTimeInSeconds);
        distanceAndTime.push({
          distance: summary.lengthInMeters * 0.000621371, // Convert meters to miles
          time: summary.travelTimeInSeconds / 60, // Convert seconds to hours
        });
      }
      console.log("distanceAndTime1", distanceAndTime);
      return distanceAndTime;
    };

    // Update state with distance and time for each pair of stops
    calculateDistanceAndTime().then((result) => {
      // Assuming you have a state variable to hold distance and time for each pair of stops
      onDistanceAndTime(result);
      setDistanceAndTime(result);
    });
  }, [stopsData, apiKey]);
  // Calculate total travel time
  useEffect(() => {
    console.log("stopsData", stopsData);
    // if (distanceAndTime.length === 0) return;

    // const totalTravelTime = distanceAndTime.reduce(
    //   (total, current) => total + current.time,
    //   0
    // );
    if (stopsData.length < 2) return;

    // Get first and last stops
    const firstStop = stopsData[0];
    const lastStop = stopsData[stopsData.length - 1];
    // Parse arrival date and ETA into Moment.js objects
    const firstStopDateTime = moment(
      `${firstStop.arrivalDate} ${firstStop.etaSelection}`,
      "MMM DD, YYYY hh:mma"
    );
    const lastStopDateTime = moment(
      `${lastStop.arrivalDate} ${lastStop.etaSelection}`,
      "MMM DD, YYYY hh:mma"
    );

    // Calculate the difference in hours
    const timeDifferenceHours = lastStopDateTime.diff(
      firstStopDateTime,
      "hours"
    );
    // Calculate the difference in minutes
    const timeDifferenceMinutes = lastStopDateTime.diff(
      firstStopDateTime,
      "minutes"
    );
    // Convert hours to days, hours, and minutes
    const days = Math.floor(timeDifferenceHours / 24);
    const hours = timeDifferenceHours % 24;
    const minutes = timeDifferenceMinutes % 60;
    const formattedTimeDifference = `${days ? days + "d " : ""}${
      hours ? hours + "h " : ""
    }${minutes ? minutes + "m" : ""}`;
    // const formattedTimeDifference = `${days}${
    //   days ? "d" : ""
    // } ${remainingHours}${remainingHours ? "h" : ""} ${moment
    //   .duration(timeDifferenceHours, "hours")
    //   .minutes()}${
    //   moment.duration(timeDifferenceHours, "hours").minutes() ? "m" : ""
    // }`;
    // const formattedTimeDifference = `${days ? days : "--"}${days ? "d" : ""} ${
    //   remainingHours > 0 ? `${remainingHours ? remainingHours : "--"}h` : ""
    // } ${
    //   moment.duration(timeDifferenceHours, "hours").minutes()
    //     ? moment.duration(timeDifferenceHours, "hours").minutes()
    //     : "--"
    // }${moment.duration(timeDifferenceHours, "hours").minutes() ? "m" : ""}`;
    console.log("TotalTime1", totalTravelTime);
    // Store the formatted time difference in a state variable
    // setFormattedTimeDifference(formattedTimeDifference);
    setTotalTravelTime(formattedTimeDifference);
  }, [stopsData]);
  // Function to format total travel time
  const formatTravelTime = (totalMinutes) => {
    if (totalMinutes < 60) {
      return `${Math.round(totalMinutes)}m`;
    } else {
      const days = Math.floor(totalMinutes / (24 * 60));
      const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
      const minutes = totalMinutes % 60;

      if (days > 0) {
        return `${days}d ${hours}h ${Math.round(minutes)}m`;
      } else {
        return `${hours}h ${Math.round(minutes)}m`;
      }
    }
  };
  // Map Rendering
  useEffect(() => {
    console.log("stopsDataTime", stopsData);
    // Ensure stopsData is an array
    if (!Array.isArray(stopsData)) {
      console.error("stopsData is not an array");
      return;
    }

    const coordinates = [longitude, latitude];

    const map = tt.map({
      key: apiKey,
      container: "map-container",
      center: coordinates,
      zoom: 13,
    });

    // Add controls (optional)
    map.addControl(new tt.NavigationControl());

    // Create an array to store coordinates of stops
    const stopsCoordinates = stopsData.map((stop) => [
      stop.longitude,
      stop.latitude,
    ]);

    // Add markers for each stop
    stopsCoordinates.forEach((coords) => {
      new tt.Marker().setLngLat(coords).addTo(map);
    });

    // Wait for the map style to load before adding the route layer
    map.on("style.load", () => {
      // Create a route if there are more than 1 stop
      if (stopsCoordinates.length > 1) {
        const routePoints = [...stopsCoordinates];
        // Calculate route
        services
          .calculateRoute({
            key: apiKey,
            locations: routePoints.map((point) => ({
              lng: point[0],
              lat: point[1],
            })),
            computeBestOrder: true,
          })
          .then((response) => {
            const geojson = response.toGeoJson();
            // Add the route as a layer to the map
            map.addLayer({
              id: "route",
              type: "line",
              source: {
                type: "geojson",
                data: geojson,
              },
              paint: {
                "line-color": "#007bff",
                "line-width": 6,
              },
            });

            // Fit the map bounds to display the entire route
            const coordinates = geojson.features[0].geometry.coordinates;
            const bounds = coordinates.reduce((bounds, coord) => {
              return bounds.extend(tt.LngLat.convert(coord));
            }, new tt.LngLatBounds());

            map.fitBounds(bounds, { padding: 75 });
          })
          .catch((error) => {
            console.error("Error calculating route:", error);
          });
      }
    });

    setMap(map);

    // Make sure to clean up the map instance when component unmounts
    return () => {
      map.remove();
    };
  }, [stopsData, latitude, longitude, apiKey]);

  console.log("stopsData5", originCity, originState, originDate, originTime);
  console.log(
    "stopsData6",
    destinationCity,
    destinationState,
    destinationDate,
    destinationTime
  );
  console.log("distanceAndTime2", distanceAndTime);
  console.log("TotalTime2", totalTravelTime);
  return (
    <>
      <div className="roadMapContainer">
        <div className="originDestinationContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <div className="originConatiner">
              <div className="originCity">
                {originCity && originState
                  ? `${originCity}, ${originState}`
                  : "--"}
              </div>
              <div className="orignDateTime">
                {originDate} {`${originTime ? "-" : ""}`} {originTime}
              </div>
            </div>
            <div className="middleStops">
              {stopsData.length > 1 ? (
                <>
                  <div className="totalStops">{stopsData.length} Stops</div>
                  <div className="totalStops">{totalTravelTime || "--"}</div>
                </>
              ) : (
                "--"
              )}
            </div>
            <div className="destinationConatiner">
              <div className="originCity">
                {" "}
                {destinationCity && destinationState
                  ? `${destinationCity}, ${destinationState}`
                  : "--"}
              </div>
              <div className="orignDateTime">
                {` ${destinationDate} ${
                  destinationTime ? "-" : ""
                } ${destinationTime}`}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mapContainer" ref={mapRef}></div> */}
        <div id="map-container" style={{ width: "100%", height: "400px" }} />
      </div>
    </>
  );
};
export default RoadMap;
