import { DRIVER_ONBOARDING_USER_TASKS } from "../workflow";

// i18next Language Keys
export const EN = "en";
export const ESP = "es";
export const FR = "fr";

// Theme Keys
export const DARK = "dark";
export const DEFAULT = "default";

// Conviction Types
export const MOTOR_CARRIER_OPERATION_DENIED = "MOTOR_CARRIER_OPERATION_DENIED";
export const SECTION_391_DOT_49_WAIVER = "SECTION_391_DOT_49_WAIVER";
export const DISQUALIFIED_UNDER_SECTION_383_391 =
  "DISQUALIFIED_UNDER_SECTION_383_391";

/**
 * Onboarding Form IDs
 * Sequenced w.r.t workflow tasks
 */
export const DRIVER_ONBOARDING_FORMS = {
  PERSONAL_DETAILS: "personalDetails",
  EMPLOYMENT_DETAILS: "employmentDetails",
  EDUCATION_DETAILS: "educationDetails",
  DRIVING_DETAILS: "drivingDetails",
  MEDICAL_DETAILS: "medicalDetails",
  REVIEW_CONSENT: "reviewConsent",
  TERMS_CONDITIONS: "termsConditions",
};

export const DRIVER_ONBOARDING_FORMS_TASK_MAP = {
  [DRIVER_ONBOARDING_USER_TASKS.PERSONAL_DETAILS]:
    DRIVER_ONBOARDING_USER_TASKS.PERSONAL_DETAILS_FORM_USER_TASK,
  [DRIVER_ONBOARDING_USER_TASKS.EMPLOYMENT_DETAILS]:
    DRIVER_ONBOARDING_USER_TASKS.EMPLOYMENT_DETAILS_FORM_USER_TASK,
  [DRIVER_ONBOARDING_USER_TASKS.EDUCATION_DETAILS]:
    DRIVER_ONBOARDING_USER_TASKS.EDUCATION_DETAILS_FORM_USER_TASK,
  [DRIVER_ONBOARDING_USER_TASKS.DRIVING_DETAILS]:
    DRIVER_ONBOARDING_USER_TASKS.DRIVING_LICENSE_HISTORY_DETAILS_FORM_USER_TASK,
  [DRIVER_ONBOARDING_USER_TASKS.MEDICAL_DETAILS]:
    DRIVER_ONBOARDING_USER_TASKS.MEDICAL_DETAILS_FORM_USER_TASK,
  [DRIVER_ONBOARDING_USER_TASKS.TERMS_CONDITIONS]:
    DRIVER_ONBOARDING_USER_TASKS.TERMS_CONDITIONS_FORM_USER_TASK,
};

export const DRIVER_ONBOARDING_TASK_FORM_MAP = [
  {
    formKey: DRIVER_ONBOARDING_FORMS.PERSONAL_DETAILS,
    taskKey: DRIVER_ONBOARDING_USER_TASKS.PERSONAL_DETAILS_FORM_USER_TASK,
  },
  {
    formKey: DRIVER_ONBOARDING_FORMS.EMPLOYMENT_DETAILS,
    taskKey: DRIVER_ONBOARDING_USER_TASKS.EMPLOYMENT_DETAILS_FORM_USER_TASK,
  },
  {
    formKey: DRIVER_ONBOARDING_FORMS.EDUCATION_DETAILS,
    taskKey: DRIVER_ONBOARDING_USER_TASKS.EDUCATION_DETAILS_FORM_USER_TASK,
  },
  {
    formKey: DRIVER_ONBOARDING_FORMS.DRIVING_DETAILS,
    taskKey:
      DRIVER_ONBOARDING_USER_TASKS.DRIVING_LICENSE_HISTORY_DETAILS_FORM_USER_TASK,
  },
  {
    formKey: DRIVER_ONBOARDING_FORMS.MEDICAL_DETAILS,
    taskKey: DRIVER_ONBOARDING_USER_TASKS.MEDICAL_DETAILS_FORM_USER_TASK,
  },

  {
    formKey: DRIVER_ONBOARDING_FORMS.TERMS_CONDITIONS,
    taskKey: DRIVER_ONBOARDING_USER_TASKS.TERMS_CONDITIONS_FORM_USER_TASK,
  },
];

export const UNAUTHORIZED_ERROR_CODE = "UNAUTHORIZED";
export const PREVIEW_IMAGE = "preview-image";
export const EMP_TYPE_UNEMPLOYED = "UNEMPLOYED";
export const EMP_TYPE_EMPLOYED = "EMPLOYED";
export const EMP_TYPE_SELF_EMPLOYED = "SELF_EMPLOYED";
