import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      lng: "en",
      fallbackLng: "en",
      whitelist: ["en", "es"],
      ns: ["landing"],
      defaultNS: ["landing"],
      backend: {
        loadPath: "/assets/i18n/{{lng}}/{{ns}}.json",
      },
      nsSeparator: ":",
      keySeparator: false,
      react: {
        bindI18n: "languageChanged",
        bindI18nStore: "",
        transEmptyNodeValue: "",
        useSuspense: true,
        // transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
      },
    },
    (err) => {
      if (err)
        return console.log(
          "Something went wrong with i18next initialization",
          err
        );
    }
  );

export default i18n;
