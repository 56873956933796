import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import leftDecrementArrow from "./../../../../assets/images/poc/leftDecrementArrow.svg";
import rightIncrementArrow from "./../../../../assets/images/poc/rightIncrementArrow.svg";
import "./CalendarChanger.scss";
import moment from "moment-timezone";
import showAdvancedDetailsIcon from "assets/images/addLoad/showAdvancedDetails.svg";

const CalendarChanger = ({
  activeTabLabel,
  onWeekChange,
  onMonthChange,
  onQuarterChange,
  onYearChange,
}) => {
  const dropdownRef = useRef(null);
  const currentYear = moment().year();
  const currentMonthIndex = moment().month();
  const currentQuarterIndex = Math.floor(moment().month() / 3);
  const currentWeekIndex = moment().week() - 1;
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(currentWeekIndex);
  const [selectedMonthIndex, setSelectedMonthIndex] =
    useState(currentMonthIndex);
  const [selectedQuarterIndex, setSelectedQuarterIndex] =
    useState(currentQuarterIndex);
  const [currentYearIndex, setCurrentYearIndex] = useState(currentYear - 2023);
  const [years, setYears] = useState([]);
  const [showDates, setShowDates] = useState(false);
  const handleShowDates = () => {
    setShowDates(!showDates);
  };
  useEffect(() => {
    const startYear = 2023; // Adjust as per your requirement
    const futureYears = [];
    for (let year = startYear; year <= currentYear; year++) {
      futureYears.push(year);
    }
    setYears(futureYears.reverse()); // Reverse the array before setting it
  }, [currentYear]);

  useEffect(() => {
    if (years.length === 0) return;
    if (activeTabLabel === "Weekly") {
      setSelectedWeekIndex(currentWeekIndex);
    } else if (activeTabLabel === "Month") {
      setSelectedMonthIndex(currentMonthIndex);
    } else if (activeTabLabel === "Quarter") {
      setSelectedQuarterIndex(currentQuarterIndex);
    } else if (activeTabLabel === "Year") {
      setCurrentYearIndex(years.indexOf(currentYear)); // Set to the latest year index
    }
  }, [
    activeTabLabel,
    currentMonthIndex,
    currentQuarterIndex,
    currentWeekIndex,
    years,
  ]);

  const [currentWeeks, setCurrentWeeks] = useState([]);
  const [currentMonths, setCurrentMonths] = useState([]);
  const [currentQuarters, setCurrentQuarters] = useState([]);

  useEffect(() => {
    if (years.length === 0) return;

    setCurrentMonths(generateMonths(years));
    setCurrentQuarters(generateQuarters(years));
    setCurrentWeeks(generateWeeks(years));
  }, [years]);

  const incrementWeek = () =>
    setSelectedWeekIndex((prevIndex) => (prevIndex + 1) % currentWeeks.length);

  const decrementWeek = () =>
    setSelectedWeekIndex(
      (prevIndex) => (prevIndex - 1 + currentWeeks.length) % currentWeeks.length
    );

  const incrementMonth = () =>
    setSelectedMonthIndex(
      (prevIndex) => (prevIndex + 1) % currentMonths.length
    );

  const decrementMonth = () =>
    setSelectedMonthIndex(
      (prevIndex) =>
        (prevIndex - 1 + currentMonths.length) % currentMonths.length
    );

  const incrementQuarter = () =>
    setSelectedQuarterIndex(
      (prevIndex) => (prevIndex + 1) % currentQuarters.length
    );

  const decrementQuarter = () =>
    setSelectedQuarterIndex(
      (prevIndex) =>
        (prevIndex - 1 + currentQuarters.length) % currentQuarters.length
    );

  const incrementYear = () =>
    setCurrentYearIndex((prevIndex) => (prevIndex + 1) % years.length);

  const decrementYear = () =>
    setCurrentYearIndex(
      (prevIndex) => (prevIndex - 1 + years.length) % years.length
    );

  useEffect(() => {
    if (years.length === 0) return;
    if (activeTabLabel === "Weekly") {
      const selectedWeek = currentWeeks[selectedWeekIndex];
      console.log("selectedWeek", selectedWeek);
      if (selectedWeek) {
        onWeekChange(
          selectedWeek.startDate,
          selectedWeek.endDate,
          currentWeeks[selectedWeekIndex]?.label
        );
      }
    } else if (activeTabLabel === "Month") {
      const selectedMonth = currentMonths[selectedMonthIndex];
      const startDate = moment(selectedMonth, "MMM YYYY")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(selectedMonth, "MMM YYYY")
        .endOf("month")
        .format("YYYY-MM-DD");
      onMonthChange(startDate, endDate, currentMonths[selectedMonthIndex]);
    } else if (activeTabLabel === "Quarter") {
      const selectedQuarter = currentQuarters[selectedQuarterIndex];
      const year = parseInt(selectedQuarter.split(" ")[1]);
      const quarterIndex =
        parseInt(selectedQuarter.split(" ")[0].substring(1)) - 1;
      const quarterStartDate = moment(`${year}-Q${quarterIndex + 1}`, "YYYY-Q")
        .startOf("quarter")
        .format("YYYY-MM-DD");
      const quarterEndDate = moment(`${year}-Q${quarterIndex + 1}`, "YYYY-Q")
        .endOf("quarter")
        .format("YYYY-MM-DD");
      onQuarterChange(
        quarterStartDate,
        quarterEndDate,
        currentQuarters[selectedQuarterIndex]
      );
    } else if (activeTabLabel === "Year") {
      const year = years[currentYearIndex];
      const yearStartDate = moment(year.toString(), "YYYY")
        .startOf("year")
        .format("YYYY-MM-DD");
      const yearEndDate = moment(year.toString(), "YYYY")
        .endOf("year")
        .format("YYYY-MM-DD");
      onYearChange(yearStartDate, yearEndDate, years[currentYearIndex]);
    }
  }, [
    selectedMonthIndex,
    selectedQuarterIndex,
    currentYearIndex,
    selectedWeekIndex,
    activeTabLabel,
    years,
    currentMonths,
    currentQuarters,
    currentWeeks,
  ]);
  const handleLeftArrowClick = () => {
    if (activeTabLabel === "Weekly") {
      decrementWeek();
    } else if (activeTabLabel === "Month") {
      decrementMonth();
    } else if (activeTabLabel === "Quarter") {
      decrementQuarter();
    } else if (activeTabLabel === "Year") {
      decrementYear();
    }
  };
  const handleRightArrowClick = () => {
    if (activeTabLabel === "Weekly") {
      incrementWeek();
    } else if (activeTabLabel === "Month") {
      incrementMonth();
    } else if (activeTabLabel === "Quarter") {
      incrementQuarter();
    } else if (activeTabLabel === "Year") {
      incrementYear();
    }
  };
  console.log("DivDateWeek", currentWeeks[selectedWeekIndex]?.label);
  console.log("DivDateMonth", currentMonths[selectedMonthIndex]);
  console.log("DivDateQuarter", currentQuarters[selectedQuarterIndex]);
  console.log("DivDateYear", years[currentYearIndex]);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDates(false);
    }
  };

  useEffect(() => {
    if (showDates) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDates]);
  const renderDropdownContent = () => {
    if (!showDates) return null;

    if (activeTabLabel === "Weekly") {
      return (
        <div className="calendarDropdown">
          {currentWeeks.map((week, index) => (
            <React.Fragment key={index}>
              <div>{week.label}</div>
              {/* {index !== currentWeeks.length - 1 && ( */}
              <div
                style={{
                  background: "#ECEEF1",
                  height: "1px",
                }}
              ></div>
              {/* )} */}
            </React.Fragment>
          ))}
        </div>
      );
    } else if (activeTabLabel === "Month") {
      return (
        <div className="calendarDropdown">
          {currentMonths.map((month, index) => (
            <div key={index}>{month}</div>
          ))}
        </div>
      );
    } else if (activeTabLabel === "Quarter") {
      return (
        <div className="calendarDropdown">
          {currentQuarters.map((quarter, index) => (
            <div key={index}>{quarter}</div>
          ))}
        </div>
      );
    } else if (activeTabLabel === "Year") {
      return (
        <div className="calendarDropdown">
          {years.map((year, index) => (
            <div key={index}>{year}</div>
          ))}
        </div>
      );
    }
  };
  return (
    <div className="calenderChangeButton">
      <div className="arrowLeftContainer" onClick={handleLeftArrowClick}>
        <img src={leftDecrementArrow} alt="Left Arrow" />
      </div>
      <div className="calendarValueContainer">
        {activeTabLabel === "Weekly" && currentWeeks[selectedWeekIndex]?.label}
        {activeTabLabel === "Month" && currentMonths[selectedMonthIndex]}
        {activeTabLabel === "Quarter" && currentQuarters[selectedQuarterIndex]}
        {activeTabLabel === "Year" && years[currentYearIndex]}
        <div onClick={handleShowDates} ref={dropdownRef}>
          {!showDates ? (
            <img src={showAdvancedDetailsIcon} />
          ) : (
            <img
              src={showAdvancedDetailsIcon}
              style={{ transform: showDates && "rotate(180deg)" }}
            />
          )}
        </div>
      </div>
      <div className="arrowRightContainer" onClick={handleRightArrowClick}>
        <img src={rightIncrementArrow} alt="Right Arrow" />
      </div>
      {renderDropdownContent()}
    </div>
  );
};

const generateWeeks = (years) => {
  let weeks = [];
  years.forEach((year) => {
    let startOfYear = moment(`${year}-01-01`);
    let endOfYear = moment(`${year}-12-31`);
    let current = startOfYear.clone().startOf("isoWeek").day(2); // Start from the first Tuesday of the year

    while (current.isBefore(endOfYear)) {
      let weekStart = current.clone();
      let weekEnd = current.clone().add(6, "days");
      let label = `W${weekStart.isoWeek()} • ${weekStart.format(
        "MMM D"
      )} - ${weekEnd.format("MMM D, YYYY")}`;
      weeks.push({
        label,
        startDate: weekStart.format("YYYY-MM-DD"),
        endDate: weekEnd.format("YYYY-MM-DD"),
      });
      current.add(1, "week");
    }
  });
  return weeks;
};

const generateMonths = (years) => {
  let months = [];
  for (let year of years) {
    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      months.push(`${moment().month(monthIndex).format("MMM")} ${year}`);
    }
  }
  return months;
};

const generateQuarters = (years) => {
  let quarters = [];
  for (let year of years) {
    for (let quarterIndex = 1; quarterIndex <= 4; quarterIndex++) {
      quarters.push(`Q${quarterIndex} ${year}`);
    }
  }
  return quarters;
};

export default CalendarChanger;
