import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Table,
} from "reactstrap";
//import DriversComponent from "../DriversOld/DriversComponent";
import "./SettlementsPOC.scss";
import { Container } from "reactstrap";
import DetailsHeader from "../../../../commonComponents/DetailsHeader/DetailsHeader";
import TabButtons from "app/views/commonComponents/TabButtons/TabButtons";
import DriversTablePOC from "../../TablePOC/TablePOC";
//import DriversFilterButton from "../DriversFilterButton/DriversFilterButton";
import Buttons from "app/views/commonComponents/Buttons/Buttons";
import CalenderChanger from "app/views/commonComponents/CalendarChanger/CalendarChanger";
import moment from "moment-timezone";
import starGradient from "assets/images/kpi/starGradient.svg";
import userGradient from "assets/images/kpi/userGradient.svg";
import userTagGradient from "assets/images/kpi/userTagGradient.svg";
import accidentGradient from "assets/images/kpi/accidentGradient.svg";
import {
  getDashboardDetailsHeaderKPIData,
  getWeeklySettlementsData,
  getLoadsWeeklyChart,
  getLoadsMonthlyChart,
  getLoadsQuarterlyChart,
  getLoadsYearlyChart,
} from "../../../../../apiServices/apiServices";
import HeaderSection from "app/views/commonComponents/HeaderSection/HeaderSection";
import downloadReportIcon from "assets/images/poc/downloadReportIcon.svg";
import ReactApexChart from "react-apexcharts";
import ScrollAnimation from "app/views/commonComponents/ScrollAnimation/ScrollAnimation";
const SettlementsPOC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTabId, setActiveCalendarTabId] = useState(0);
  const [activeCalendarTabLabel, setActiveCalendarTabLabel] =
    useState("Weekly");
  const [dateLabel, setDateLabel] = useState("");
  const [activeTableTabId, setActiveTableTabId] = useState(0);
  const [activeTableTabLabel, setActiveTableTabLabel] = useState("");
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [isDriversFilterOpen, setIsDriversFilterOpen] = useState(false);
  const [settlementsOriginalData, setSettlementsOriginalData] = useState([]);
  const [settlementsUploadId, setSettlementsUploadId] = useState("");
  const [firstNameUser, setFirstNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [grossRevenue, setGrossRevenue] = useState(0);
  const [driverPayments, setDriverPayments] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [headerRPM, setHeaderRPM] = useState(0);
  const [milesLoaded, setMilesLoaded] = useState(0);
  const [loadsDelivered, setLoadsDelivered] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [avgRevenuePerLoad, setAvgRevenuePerLoad] = useState(0);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Drivers", link: "/drivers" },
  ];
  const settelmentsKPIData = [
    {
      icon: starGradient,
      values: loadsDelivered
        ? Math.round(loadsDelivered).toLocaleString("en-US")
        : 0,
      title: "Loads Delivered",
    },
    {
      icon: starGradient,
      values: milesLoaded ? Math.round(milesLoaded).toLocaleString("en-US") : 0,
      title: "Miles Loaded",
    },
    {
      icon: userGradient,
      values: avgRevenuePerLoad
        ? `$${Math.round(avgRevenuePerLoad).toLocaleString("en-US")}`
        : "$0.00",
      title: "Avg. RPM",
    },
    {
      icon: starGradient,
      values: revenue ? `$${Math.round(revenue).toLocaleString("en-US")}` : 0,
      title: "Revenue",
    },
    {
      icon: userTagGradient,
      values: grossMargin
        ? `$${Math.round(grossMargin).toLocaleString("en-US")}`
        : "$0.00",
      title: "Net RPM",
    },
    {
      icon: accidentGradient,
      values: headerRPM
        ? `$${headerRPM.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "$0.00",
      title: "Net Profit",
    },
  ];
  const calendarTabsData = [
    { id: 0, label: "Weekly" },
    { id: 1, label: "Month" },
    { id: 2, label: "Quarter" },
    { id: 3, label: "Year" },
    // Add more tabs as needed
  ];
  const tableTabsData = [
    { id: 0, label: "All" },
    { id: 1, label: "Pending" },
    { id: 2, label: "Completed" },
    // Add more tabs as needed
  ];
  const weeklySettlementsTableHeaders = {
    driverID: "Driver Id.",
    driverName: "Driver Name",
    loadsDelivered: "Loads",
    MilesLoaded: "Loaded Miles",
    rpm: "RPM",
    totalRevenue: "Load Revenue",
    totalOtherRevenue: "Other Revenue",
    totalDeduction: "Deductions",
    netPay: "Net Pay",
    status: "Status",
  };
  const weeklySettlementsTableData = [];
  const settlementsTableHeaders = {
    settlementDate: "Settlement Date",
    totalLoad: "Loads",
    totalDriver: "Drivers",
    totalRevenue: "Load Revenue",
    totalOtherRevenue: "Other Revenue",
    totalDeduction: "Deductions",
    driverPayments: "Driver Payments",
    netProfit: "Net Profit",
  };
  const settlementsTableData = settlementsOriginalData.map((item, index) => {
    const originalDateString = item.statement_date;
    let originalDate = moment.utc(originalDateString);
    const month = moment(originalDate).format("MMM");
    const day = moment(originalDate).format("DD");
    const year = moment(originalDate).year();
    const formattedDate = `${month} ${day}, ${year}`;
    return {
      settlementDate: `${formattedDate}`,
      totalLoad: item.total_load_count.toLocaleString(),
      totalDriver: item.total_drivers.toLocaleString(),
      totalRevenue: `$${item.total_revenue.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      totalOtherRevenue: `$${item.total_other_revenue.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} • ${item.total_other_income_count.toLocaleString("en")}`,
      totalDeduction: `$${item.total_deduction.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} • ${item.total_deduction_count.toLocaleString("en")}`,
      // totalOtherRevenue: `$${item.total_other_revenue.toLocaleString("en", {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // })}`,
      // totalDeduction: `$${item.total_deduction.toLocaleString("en", {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // })}`,
      driverPayments: `$${item.driver_payment.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      netProfit: `$${item.company_revenue.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      uploadId: item._id,
    };
  });

  const handleCalenderTabChange = (tabId, tabLabel) => {
    console.log("handleCalenderTabChange1", tabId);
    console.log("handleCalenderTabChange2", tabLabel);
    setActiveCalendarTabId(tabId);
    setActiveCalendarTabLabel(tabLabel);
  };
  const handleTableTabChange = (tabId, tabLabel) => {
    setActiveTableTabId(tabId);
    setActiveTableTabLabel(tabLabel);
  };
  // Function to handle month change
  const handleDateChange = (startDate, endDate, dateLabel) => {
    console.log("currentNewDate1", startDate);
    console.log("currentNewDate2", endDate);
    setDateLabel(dateLabel);
    // Call getLoadsWeeklyChartAPI with  start and end dates
    //getLoadsWeeklyChartAPI(startDate, endDate);
    // Call getDetailsHeaderKPIDataAPI with final start and end dates
    getDetailsHeaderKPIData(startDate, endDate);
  };
  useEffect(() => {
    handleReturnSettelmentsPOC();
    const calculateMaxTableHeight = () => {
      // Get the window height
      const windowHeight = window.innerHeight;
      // Calculate the available height by subtracting the height of other elements if necessary
      const availableHeight =
        windowHeight - 332; /* subtract other elements height */
      // Set the max height of the table container
      setMaxTableHeight(availableHeight);
    };

    // Call the function initially and on window resize
    calculateMaxTableHeight();
    window.addEventListener("resize", calculateMaxTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateMaxTableHeight);
    };
  }, []);
  useEffect(() => {
    // Retrieve the data from localStorage
    const authUserData = localStorage.getItem("authUser");

    // Check if the data exists
    if (authUserData) {
      // Parse the JSON string back into a JavaScript object
      const userData = JSON.parse(authUserData);
      // setUserDetails(userData);
      // Now you can access the properties of the userData object
      console.log("userData", userData);
      console.log("userData2", userData.first_name);
      setFirstNameUser(userData.first_name);
      console.log("userData3", userData.last_name);
      setLastNameUser(userData.last_name);
    } else {
      // Handle the case where the data doesn't exist in localStorage
      console.log("No user data found in localStorage");
    }
    if (
      activeCalendarTabLabel == "Weekly" ||
      activeCalendarTabLabel == "Month" ||
      activeCalendarTabLabel == "Quarter" ||
      activeCalendarTabLabel == "Year"
    ) {
      getDetailsHeaderKPIData();
      //getLoadsWeeklyChartAPI();
    }
    getWeeklySettlementsDataAPI();
  }, [activeCalendarTabLabel]);
  const getWeeklySettlementsDataAPI = () => {
    const limit = 10;
    getWeeklySettlementsData(limit)
      .then((response) => {
        //console.log("insideif", response);
        console.log("WeeklySettlementsData1", response.data);
        setSettlementsOriginalData(response.data ? response.data : "");
        setSettlementsUploadId(response.data._id ? response.data._id : "");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const getDetailsHeaderKPIData = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    let currentStartDate = null;
    let currentEndDate = null;

    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0

    switch (activeCalendarTabLabel) {
      case "Month": // Current month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Quarter": // Current quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "Year": // Current year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    getDashboardDetailsHeaderKPIData(finalStartDate, finalEndDate)
      .then((response) => {
        //console.log("insideif", response);
        console.log("DetailsHeaderKPIData", response.data);
        console.log("DetailsHeaderKPIData1", response.data.gross_revenue);
        setGrossRevenue(response.data.gross_revenue);
        setDriverPayments(response.data.driver_payments);
        setGrossMargin(response.data.gross_margin);
        setHeaderRPM(response.data.rpm);
        setMilesLoaded(response.data.miles_loaded);
        setLoadsDelivered(response.data.loads_delivered);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const [currentPageSettlementsPOC, setCurrentPageSettlementsPOC] = useState(1);
  const [itemsPerPageSettlementsPOC, setItemsPerPageSettlementsPOC] =
    useState(50);
  const handlePageChange = (pageNumber) => {
    setCurrentPageSettlementsPOC(pageNumber);
  };
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPageSettlementsPOC(perPage);
    setCurrentPageSettlementsPOC(1); // Reset to the first page when changing items per page
  };
  const handleNavigateAway = () => {
    // Save current page and items per page
    // You can save these values to local storage or any other persistent storage
    localStorage.setItem(
      "currentPageSettlementsPOC",
      currentPageSettlementsPOC
    );
    // localStorage.setItem(
    //   "itemsPerPageSettlementsPOC",
    //   itemsPerPageSettlementsPOC
    // );
  };
  const handleReturnSettelmentsPOC = () => {
    // Retrieve saved page and items per page
    const savedPage = localStorage.getItem("currentPageSettlementsPOC");
    // const savedItemsPerPage = localStorage.getItem(
    //   "itemsPerPageSettlementsPOC"
    // );
    setCurrentPageSettlementsPOC(savedPage ? parseInt(savedPage) : 1);
    // setItemsPerPageSettlementsPOC(
    //   savedItemsPerPage ? parseInt(savedItemsPerPage) : 50
    // );
  };
  const [pastPeriodValues, setPastPeriodValues] = useState([]);
  const [currentYearValues, setCurrentYearValues] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const settelmentsSeries = [
    {
      name: "Past Period",
      data: [70, 80, 90, 70, 80, 90, 60, 80, 50, 70, 80, 90, 70],
      //data: pastPeriodValues,
    },
    {
      name: "Current Year",
      data: [50, 60, 60, 80, 20, 70, 90, 60, 60, 25, 50, 50, null],
      //data: currentYearValues,
    },
    {
      name: "Forecasting",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        90,
      ],
    },
  ];
  const settelmentsQuarterSeries = [
    {
      name: "Past Period",
      data: [80, 90, 70, 80, 90, 60, 80, 70],
    },
    {
      name: "Current Year",
      data: [60, 60, 80, 20, 70, 90, 60, null],
    },
    {
      name: "Forecasting",
      data: [null, null, null, null, null, null, null, 90],
    },
  ];
  const settelmentsWeeklyOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 0, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: 0,
              to: 25,
              color: "#F35631",
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 1],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 1], // Apply dash to the last series
    },
    xaxis: {
      categories: [
        "W45 • 2023",
        "W46 • 2023",
        "W47 • 2023",
        "W48 • 2023",
        "W49 • 2023",
        "W50 • 2023",
        "W51 • 2023",
        "W52 • 2023",
        "W1",
        "W2",
        "W3",
        "W4",
        "W5",
      ],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:12px;font-weight:400;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: [0, 0, 0.5],
        width: 4,
        //height: 6,
      },
    },
    series: [
      {
        name: "Past Period",
        data: [70, 80, 90, 70, 80, 90, 60, 80, 50, 70, 80, 90, 70],
      },
      {
        name: "Current Year",
        data: [50, 60, 60, 80, 20, 70, 90, 60, 60, 25, 50, 50, null],
      },
      {
        name: "Forecasting",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          90,
        ],
        // fill: {
        //   type: "pattern",
        //   opacity: 0.5,
        //   pattern: {
        //     style: ["slantedLines"], // Pattern style
        //     strokeWidth: 0.5,
        //     width: 0, // Different width for the pattern fill for this series
        //   },
        // },
      },
    ],
  };
  const settelmentsOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 1, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: 1,
              to: 25,
              color: "#F35631",
            },
          ],
        },
        // stroke: {
        //   show: false, // Disable stroke on bars
        //   //show: true,
        //   width: [4, 4, 4],
        //   colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
        //   //dashArray: [0, 0, 4], // Apply dash to the last series
        // },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 0],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 4], // Apply dash to the last series
    },
    xaxis: {
      categories: [
        "W45 • 2023",
        "W46 • 2023",
        "W47 • 2023",
        "W48 • 2023",
        "W49 • 2023",
        "W50 • 2023",
        "W51 • 2023",
        "W52 • 2023",
        "W1",
        "W2",
        "W3",
        "W4",
        "W5",
      ],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:12px;font-weight:400;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: 0.5,
        width: 2,
        //height: 6,
      },
    },
  };
  const settelmentsYearlySeries = [
    {
      name: "Past Period",
      data: [90, 90, 80],
      //data: pastPeriodValues,
    },
    {
      name: "Current Year",
      data: [80, 90, null],
      //data: currentYearValues,
    },
    {
      name: "Forecasting",
      data: [null, null, 90],
    },
  ];
  const settelmentsYearlyOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hide the toolbar options
      },
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: "3",
        borderRadiusApplication: "end",
        columnWidth: "25%",
        hideZeroBarsWhenGrouped: true,
        // Set stroke width dynamically based on data value
        dataLabels: {
          enabled: false, // Hide the values on the bars
        },
        colors: {
          ranges: [
            { from: 0, to: 0, color: "transparent" }, // Set bars with value 0 to transparent
            {
              from: -Infinity,
              to: 25,
              color: "#F35631",
            },
          ],
        },
      },
    },
    stroke: {
      show: true,
      width: [4, 4, 1],
      colors: ["#transparent", "#transparent", "#FFFFFF"], // Ensure the color of the dashed series
      //dashArray: [0, 0, 4], // Apply dash to the last series
    },
    xaxis: {
      categories: ["2023", "2024", "2025"],
      //tickAmount: 1, // Set the number of ticks to 1
      tickPlacement: "between",
      axisBorder: {
        show: true,
        color: "rgba(220, 220, 220, 0.2)",
        width: "100%",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value; // Return the value directly
        },
        style: {
          colors: "#FFFFFF", // Change this to your desired color for x-axis labels
        },
        // offsetX: 0,
        // offsetY: -7,
      },
    },
    dataLabels: {
      enabled: false, // Hide the values on the bars
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 3,
        width: [12, 12, 0],
      },
      onItemClick: {
        toggleDataSeries: true,
      },

      labels: {
        colors: "#FFFFFF", // Change this to your desired color for legend labels
        fontSize: "14px", // Change this to your desired font size for legend labels
      },
      formatter: function (seriesName, opts) {
        console.log("loadsChart3", seriesName);
        if (seriesName === "Forecasting") {
          return (
            '<div style="display:flex; align-items: center;">' +
            '<div class="slanted-lines" style="background:transparent;width:12px;height:12px;border-radius:3px;border:1px solid white;border-width:1px; margin-right:3px;"></div>' +
            '<span style="color:#FFFFFF; font-size:14px;">Forecasting</span>' +
            "</div>"
          );
        } else {
          return seriesName;
        }
      },
    },
    grid: {
      borderColor: "rgba(220, 220, 220, 0.2)", // Change this to your desired color for the grid lines
      xaxis: {
        lines: {
          show: false, // Show vertical grid lines
        },
      },
      yaxis: {
        lines: {
          show: true, // Show horizontal grid lines
        },
      },
    },
    colors: ["#434343", "#FFFFFF", undefined],
    fill: {
      //colors: [undefined, undefined, "rgba(255, 255, 255, 0.8)"],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: [undefined, undefined, "slantedLines"], // string or array of strings
        strokeWidth: 0.5,
        //width: 4,
        //height: 6,
      },
    },
  };
  const [chartSeries, setChartSeries] = useState(settelmentsQuarterSeries);
  console.log("DivDateLabel", dateLabel);
  // Helper function to generate week labels
  const generateWeekLabels = (dateLabel) => {
    const weeks = [];
    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }
    const parts = dateLabel.split(" ");
    let weekNum = parseInt(parts[0].slice(1));
    let year = parseInt(parts[parts.length - 1]);

    // Add the previous 11 weeks
    for (let i = -11; i <= 0; i++) {
      let newWeek = weekNum + i;
      let newYear = year;

      if (newWeek <= 0) {
        newYear -= 1;
        newWeek += 52; // Assuming the previous year had 52 weeks
      } else if (newWeek > 52) {
        newYear += 1;
        newWeek -= 52; // Moving into the next year
      }

      if (newYear !== year) {
        weeks.push(`W${newWeek} • ${newYear}`);
      } else {
        weeks.push(`W${newWeek}`);
      }
    }

    // Add the "next" week with year
    let nextWeek = weekNum + 1;
    let nextYear = year;
    if (nextWeek > 52) {
      nextWeek -= 52;
      nextYear += 1;
    }
    if (nextYear !== year) {
      weeks.push(`W${nextWeek} • ${nextYear}`);
    } else {
      weeks.push(`W${nextWeek}`);
    }

    return weeks;
  };
  // Helper function to generate month labels
  const generateMonthLabels = (dateLabel) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }

    const [month, year] = dateLabel.split(" ");
    const monthIndex = months.indexOf(month);
    const currentYear = parseInt(year);
    const xAxisLabels = [];

    for (let i = 11; i >= 0; i--) {
      let index = (monthIndex - i + 12) % 12;
      let labelYear = currentYear;
      if (index > monthIndex) {
        labelYear -= 1;
        xAxisLabels.push(`${months[index]} • ${labelYear}`);
      } else {
        xAxisLabels.push(`${months[index]}`);
      }
    }

    let nextMonthIndex = (monthIndex + 1) % 12;
    let nextMonthYear = currentYear;
    if (nextMonthIndex < monthIndex) {
      nextMonthYear += 1;
      xAxisLabels.push(`${months[nextMonthIndex]} • ${nextMonthYear}`);
    } else {
      xAxisLabels.push(`${months[nextMonthIndex]}`);
    }

    return xAxisLabels;
  };
  // Helper function to generate quarter labels
  const generateQuarterLabels = (dateLabel) => {
    const quarters = [];

    // Check if dateLabel is a string, if not, convert it to a string
    if (typeof dateLabel !== "string") {
      dateLabel = String(dateLabel);
    }

    const parts = dateLabel.split(" ");
    const quarterNum = parseInt(parts[0].slice(1));
    const year = parseInt(parts[parts.length - 1]);

    // Calculate the starting quarter and year based on the current quarter
    let startYear = 2023; // Start from Q1 2023
    let startQuarter = 1;

    // Generate quarter labels
    for (let y = startYear; y <= year; y++) {
      for (let q = 1; q <= 4; q++) {
        if (y === year && q > quarterNum) break;
        quarters.push(`Q${q} • ${y}`);
      }
    }

    // Add a forecasting quarter after the current quarter
    let forecastingQuarter = quarterNum + 1;
    let forecastingYear = year;
    if (forecastingQuarter > 4) {
      forecastingQuarter -= 4;
      forecastingYear++;
    }
    quarters.push(`Q${forecastingQuarter} • ${forecastingYear}`);

    // Only return the last 8 quarters
    return quarters.slice(-8);
  };
  // Helper function to generate year labels
  const generateYearLabels = (dateLabel) => {
    const currentYear = parseInt(dateLabel);
    const xAxisLabels = [];

    // Generate xAxis labels dynamically
    for (let i = -2; i <= 1; i++) {
      xAxisLabels.push(currentYear + i);
    }

    return xAxisLabels;
  };
  const getCurrentDate = () => {
    let currentStartDate = null;
    let currentEndDate = null;
    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1; // Month index starts from 0
    switch (activeCalendarTabLabel) {
      case "Weekly": //Current Week
        // Start from the first Tuesday of the current week
        const currentWeekStart = moment().startOf("isoWeek").day(2);
        // End on the following Monday
        const currentWeekEnd = moment().endOf("isoWeek").day(1);
        currentStartDate = currentWeekStart.format("YYYY-MM-DD");
        currentEndDate = currentWeekEnd.format("YYYY-MM-DD");
        break;
      case "Month": // Current Month
        currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Quarter": // Current Quarter
        const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;
        const quarterStartMonth = (currentQuarter - 1) * 3;
        currentStartDate = moment().startOf("quarter").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "Year": // Current Year
        currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
        currentEndDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      default:
        break;
    }
    return currentStartDate, currentEndDate;
  };
  const filterSeriesData = (series, length) => {
    return series.map((serie) => ({
      ...serie,
      data: serie.data.slice(0, length),
    }));
  };
  const truncateSeriesData = (labels, series) => {
    const labelCount = labels.length;
    return series.map((s) => ({
      ...s,
      data: s.data
        .slice(0, labelCount)
        .concat(new Array(Math.max(0, labelCount - s.data.length)).fill(null)),
    }));
  };
  // Update chart options when dateLabel changes
  useEffect(() => {
    let xAxisOptions = {};
    const updateXAxisOptions = (categories) => {
      xAxisOptions = {
        categories: categories,
        tickPlacement: "between",
        axisBorder: {
          show: true,
          color: "rgba(220, 220, 220, 0.2)",
          width: "100%",
        },
        axisTicks: { show: false },
        labels: { style: { colors: "#FFFFFF" } },
      };
    };
    if (activeCalendarTabLabel === "Weekly") {
      updateXAxisOptions(generateWeekLabels(dateLabel));
    } else if (activeCalendarTabLabel === "Month") {
      updateXAxisOptions(generateMonthLabels(dateLabel));
    } else if (activeCalendarTabLabel === "Quarter") {
      const quarterLabels = generateQuarterLabels(dateLabel);
      updateXAxisOptions(quarterLabels);
      const truncatedSeries = truncateSeriesData(
        quarterLabels,
        settelmentsQuarterSeries
      );
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: xAxisOptions,
        series: truncatedSeries,
      }));
      return;
      //setChartSeries(filterSeriesData(loadsQuarterSeries, categories.length));
    } else if (activeCalendarTabLabel === "Year") {
      updateXAxisOptions(generateYearLabels(dateLabel));
    }
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: xAxisOptions,
    }));
  }, [dateLabel, activeCalendarTabLabel]);
  const getLoadsWeeklyChartAPI = (startDate, endDate) => {
    console.log("currentNewDate5", startDate);
    console.log("currentNewDate6", endDate);
    const { currentStartDate, currentEndDate } = getCurrentDate();
    console.log("currentNewDate3", currentStartDate);
    console.log("currentNewDate4", currentEndDate);
    // Use current dates if no startDate or endDate provided
    const finalStartDate = startDate || currentStartDate;
    const finalEndDate = endDate || currentEndDate;
    console.log("currentNewDate7", finalStartDate);
    console.log("currentNewDate8", finalEndDate);
    if (activeCalendarTabLabel == "Weekly") {
      getLoadsWeeklyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.weeklyData) {
            if (response.data.weeklyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.weeklyData[week].currentYear
              );
              previousYearValues.push(
                response.data.weeklyData[week].previousYear
              );
            }
          }

          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Month") {
      getLoadsMonthlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];
          for (const week in response.data.monthlyData) {
            if (response.data.monthlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.monthlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.monthlyData[week].previousYear
              );
            }
          }
          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Quarter") {
      getLoadsQuarterlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.quarterlyData) {
            if (response.data.quarterlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.quarterlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.quarterlyData[week].previousYear
              );
            }
          }

          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else if (activeCalendarTabLabel == "Year") {
      getLoadsYearlyChart(finalStartDate, finalEndDate)
        .then((response) => {
          console.log("getLoadsChart", response.data ? response.data : []);
          const currentYearValues = [];
          const previousYearValues = [];

          for (const week in response.data.yearlyData) {
            if (response.data.yearlyData.hasOwnProperty(week)) {
              currentYearValues.push(
                response.data.yearlyData[week].currentYear
              );
              previousYearValues.push(
                response.data.yearlyData[week].previousYear
              );
            }
          }
          console.log("YearValuesCurrent:", currentYearValues);
          console.log("YearValuesPrevious:", previousYearValues);
          setPastPeriodValues(previousYearValues);
          setCurrentYearValues(currentYearValues);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };
  console.log("YearValuesCurrent2:", currentYearValues);
  console.log("YearValuesPrevious2:", pastPeriodValues);
  const [scrollingDown, setScrollingDown] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollingDown(scrollTop > (lastScrollTop || 0));
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    };

    let lastScrollTop = 0;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Row xl={12} md={12} sm={12} xs={12}>
          <div className="main-page-design">
            <Col xl={12} md={12} sm={12} xs={12}>
              <div className="stickyHeaderSectionSettelments">
                <HeaderSection title={"Settlements"}></HeaderSection>
              </div>
              {/* <div
                id="settelments-chart"
                //className="apex-charts scrollable-chart"
                className={`apex-charts scrollable-chart ${
                  scrollingDown ? "hide" : ""
                }`}
              >
                {activeCalendarTabLabel == "Weekly" ? (
                  <ReactApexChart
                    options={{ ...settelmentsWeeklyOptions, ...chartOptions }}
                    series={settelmentsWeeklyOptions.series}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Month" ? (
                  <ReactApexChart
                    options={{ ...settelmentsOptions, ...chartOptions }}
                    series={settelmentsSeries}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Quarter" ? (
                  <ReactApexChart
                    options={{ ...settelmentsOptions, ...chartOptions }}
                    // series={chartSeries}
                    series={chartOptions.series || settelmentsQuarterSeries}
                    type="bar"
                    height={210}
                  />
                ) : activeCalendarTabLabel == "Year" ? (
                  <ReactApexChart
                    options={settelmentsYearlyOptions}
                    series={settelmentsYearlySeries}
                    type="bar"
                    height={210}
                  />
                ) : (
                  ""
                )}
              </div> */}
              <div className="stickyPartContaier">
                <div className="tabButtonsCalendarSettelments">
                  <div className="calenderChangeTabButtonsContainerSettelments">
                    <div>
                      <TabButtons
                        tabs={calendarTabsData}
                        onTabChange={handleCalenderTabChange}
                      />
                    </div>
                    <div className="calenderChangeButtonContainerSettelments">
                      <CalenderChanger
                        activeTabLabel={activeCalendarTabLabel}
                        activeTabId={activeCalendarTabId}
                        onWeekChange={handleDateChange}
                        onMonthChange={handleDateChange}
                        onQuarterChange={handleDateChange}
                        onYearChange={handleDateChange}
                      />
                    </div>
                    {/* <div class="triangle"></div> */}
                  </div>
                  <div>
                    <Buttons
                      dimensions={"responsive"}
                      iconPosition={"left"}
                      buttonType="secondary"
                      size="small"
                      image={downloadReportIcon}
                      hover={false}
                      color={"#000000"}
                      fontSize={"12px"}
                    >
                      Download Report
                    </Buttons>
                  </div>
                </div>
                <div className="stickyDetailsHeaderSettelments">
                  <DetailsHeader
                    data={settelmentsKPIData}
                    pageName="Settelments"
                  />
                </div>
                {activeCalendarTabLabel == "Weekly" && (
                  <div
                    className="tableTitleContainerSettlements"
                    style={{ width: "100%" }}
                  >
                    <div style={{ flex: "1" }}>
                      <TabButtons
                        tabs={tableTabsData}
                        onTabChange={handleTableTabChange}
                      />
                    </div>
                    {/* <div style={{ flex: "" }} className="searchBox">
                      <img src={searchIcon} />
                      <input
                        type="text"
                        placeholder="Search upload Id."
                        className="searchTextStyle"
                        style={{
                          border: "none",
                          background: "#fcfcfc",
                          padding: "0",
                        }}
                      />
                    </div> */}
                  </div>
                )}
              </div>
              <div className="tableContainerSettlements">
                <div
                  className="driversTableContainerSettlements"
                  style={{
                    //maxHeight: `${maxTableHeight}px`,
                    width: "100%",
                    marginBottom: "16px",
                    background: "#fff",
                  }}
                >
                  <div className="recentSettelmentsTableContainer">
                    {activeCalendarTabLabel != "Weekly" ? (
                      <DriversTablePOC
                        headers={settlementsTableHeaders}
                        data={settlementsTableData}
                        activeTab={activeTab}
                        settlementsUploadId={settlementsUploadId}
                        rowClick={true}
                        tableName={"settelmentsPOC"}
                        paginationEnabled={true}
                        currentPage={currentPageSettlementsPOC}
                        itemsPerPage={itemsPerPageSettlementsPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    ) : (
                      <DriversTablePOC
                        headers={weeklySettlementsTableHeaders}
                        data={weeklySettlementsTableData}
                        activeTab={activeTab}
                        settlementsUploadId={settlementsUploadId}
                        rowClick={true}
                        tableName={"settelmentsPOC"}
                        paginationEnabled={true}
                        currentPage={currentPageSettlementsPOC}
                        itemsPerPage={itemsPerPageSettlementsPOC}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        onNavigateAway={handleNavigateAway}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default SettlementsPOC;
