import produce from 'immer';
import * as types from './types';

const initialState = {
  userTasks: {
  },
  metadata:{
    application_state:"",
    application_status:"",
    application_progress:0
  }
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_USER_TASK_SUCCESS: {
        draft.userTasks = action.payload.tasks;
        draft.metadata=action.payload.metadata;
        break;
      }
      default:
        break;
    }
  });

export default appReducer;
