import React from "react";
import PropTypes from "prop-types";
import dotsSpinner from "assets/images/spinner/loader.svg";
import HashLoader from "react-spinners/HashLoader";
import GridLoader from "react-spinners/GridLoader";
import "./Spinners.scss";

export const DotsSpinner = () => {
  return <img className="rotating" src={dotsSpinner} alt="spinner" />;
};

export const HashSpinner = ({ loading, size, color }) => {
  return <HashLoader css={""} size={size} color={color} loading={loading} />;
};

HashSpinner.defaultProps = {
  color: "#f8f8f8",
  size: 50,
  loading: true,
};

HashSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  loading: PropTypes.bool,
  css: PropTypes.object,
};

export const GridSpinner = ({ loading, size, color }) => {
  return <GridLoader css={""} size={size} color={color} loading={loading} />;
};

GridSpinner.defaultProps = {
  color: "#333",
  size: 40,
  loading: true,
};

GridSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  loading: PropTypes.bool,
  css: PropTypes.object,
};
