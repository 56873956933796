import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TabButtons.scss"; // Use a common SCSS file for styling

const TabButtons = ({ tabs, onTabChange }) => {
  const [activeTabId, setActiveTabId] = useState(tabs[0].id);
  const [activeTabLabel, setActiveTabLabel] = useState(tabs[0].label);

  const handleTabClick = (tabId, tabLabel) => {
    setActiveTabId(tabId);
    setActiveTabLabel(tabLabel);
    onTabChange(tabId, tabLabel); // Notify parent component about tab change
  };

  return (
    <div className="tabButtonsContainer">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          //className={`${tab.id} ${activeTabId === tab.id ? "active" : ""}`}
          className="tabButtons"
          onClick={() => handleTabClick(tab.id, tab.label)}
          style={{
            color: tab.id == activeTabId ? "#393a3d" : "#6B6C73",
            background: tab.id == activeTabId ? "#FFFFFF" : "#F4F5F8",
            borderTopLeftRadius:
              tab.id == tabs.length - 1
                ? "0"
                : tab.id > 0 && tab.id < tabs.length - 1
                ? "0"
                : "52px",
            borderTopRightRadius:
              tab.id == 0
                ? "0"
                : tab.id > 0 && tab.id < tabs.length - 1
                ? "0"
                : "52px",
            borderBottomLeftRadius:
              tab.id == tabs.length - 1
                ? "0"
                : tab.id > 0 && tab.id < tabs.length - 1
                ? "0"
                : "52px",
            borderBottomRightRadius:
              tab.id == 0
                ? "0"
                : tab.id > 0 && tab.id < tabs.length - 1
                ? "0"
                : "52px",
            borderRight:
              tab.id == tabs.length - 1
                ? "1px solid var(--Gray-05, #d2d7de)"
                : "",
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabButtons;
