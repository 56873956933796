import { EN, ESP } from 'app/constants/keys';
import es_ES from 'antd/es/locale/es_ES';
import en_US from 'antd/es/locale/en_US';
import 'moment/locale/es-us';

const LOCALE_MAP = {
  [EN]: { antd: en_US, moment: 'en' },
  [ESP]: { antd: es_ES, moment: 'es-us' },
};

export default LOCALE_MAP;
